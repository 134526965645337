import { IOrganization } from './organization';
import { IRegion } from './region';
import { IDistrict } from './district';
import { IBeach } from './beach';
import { IStation } from './station';
import { IUser } from './user';
import { IIncidentType } from './incidenttype';
import { IIncidentSeverity } from './incidentseverity';
import { IIncidentResponse } from './incidentresponse';
import { IMetadata } from './metadata';
import { IUUID } from './templates/uuid';

export interface IIncident extends IUUID {
    d?: 'IIncident';
    incident_number: string|undefined;
    organization: Partial<IOrganization>;
    region: Partial<IRegion>;
    district: Partial<IDistrict>;
    beach: Partial<IBeach>;
    type: Partial<IIncidentType>;
    severity: Partial<IIncidentSeverity>;
    source: string;
    status: string;
    location: string;
    primary_responder: Partial<IUser>;
    secondary_responder: Partial<IUser>;
    tertiary_responder: Partial<IUser>;
    responses: Partial<IIncidentResponse[]>
    station: Partial<IStation>;
    review: boolean;
    archive: boolean;
    starttime: string|undefined;
    dispatchtime: string|undefined;
    pendingtime: string|undefined;
    enroutetime: string|undefined;
    respondingtime: string|undefined;
    resolvedtime: string|undefined;
    closuretime: string|undefined;
    metadata: IMetadata[]|null;
    created: string|undefined;
    created_by: Partial<IUser>|undefined;
    last_modified: string|undefined;
    last_modified_by: Partial<IUser>|undefined;
}

export interface INewIncident {
    d?: 'INewIncident';
    organization: string;
    region?: string;
    district?: string;
    beach?: string;
    type: string;
    severity: string;
    source?: string;
    status?: string;
    location?: string;
    primary_responder?: string;
    secondary_responder?: string;
    tertiary_responder?: string;
    responses?: string
    station?: string;
    review?: boolean;
    archive?: boolean;
    starttime?: string|undefined;
    dispatchtime?: string|undefined;
    pendingtime?: string|undefined;
    enroutetime?: string|undefined;
    respondingtime?: string|undefined;
    resolvedtime?: string|undefined;
    closuretime?: string|undefined;
    metadata?: IMetadata[]|[]|null;
}

export interface IUpdatedIncident extends IUUID {
    d?: 'IUpdatedIncident';
    incident_number?: string;
    organization?: string;
    region?: string;
    district?: string;
    beach?: string;
    type?: string;
    severity?: string;
    source?: string;
    status?: string;
    location?: string;
    primary_responder?: string;
    secondary_responder?: string;
    tertiary_responder?: string;
    review?: boolean;
    archive?: boolean;
    station?: string;
    starttime?: string;
    dispatchtime?: string;
    pendingtime?: string;
    enroutetime?: string;
    respondingtime?: string;
    resolvedtime?: string;
    closuretime?: string;
    metadata?: IMetadata[]|[]|null;
}

export function CopyIncidentForUpdate(i: IIncident): IUpdatedIncident {
    return {
        d: 'IUpdatedIncident',
        id: i.id,
        organization: i.organization ? i.organization.id : undefined,
        type: i.type ? i.type.id : undefined,
        severity: i.severity ? i.severity.id : undefined,
        source: i.source ? i.source : undefined,
        status: i.status,
        location: i.location,
        region: i.region ? i.region.id : undefined,
        district: i.district ? i.district.id : undefined,
        beach: i.beach ? i.beach.id : undefined,
        station: i.station ? i.station.id : undefined,
        primary_responder: i.primary_responder ? i.primary_responder.id : undefined,
        secondary_responder: i.secondary_responder ? i.secondary_responder.id : undefined,
        tertiary_responder: i.tertiary_responder ? i.tertiary_responder.id : undefined,
        review: i.review ? i.review : undefined,
        archive: i.archive ? i.archive : undefined,
        starttime: i.starttime ? i.starttime : undefined,
        dispatchtime: i.dispatchtime ? i.dispatchtime : undefined,
        pendingtime: i.pendingtime ? i.pendingtime : undefined,
        enroutetime: i.enroutetime ? i.enroutetime : undefined,
        respondingtime: i.respondingtime ? i.respondingtime : undefined,
        resolvedtime: i.resolvedtime ? i.resolvedtime : undefined,
        closuretime: i.closuretime ? i.closuretime : undefined,
        metadata: i.metadata
    } as IUpdatedIncident;

}
