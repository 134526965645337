// NGRX
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

// NGRX Local Storage
import { localStorageSync } from 'ngrx-store-localstorage';

// States and Reducers
import { notificationFeatureKey, NotificationReducer, NotificationState } from './notification.reducers';
import { authFeatureKey, AuthReducer, AuthState } from './auth.reducers';
import { locationFeatureKey, LocationReducer, LocationState } from './location.reducers';
import { weatherFeatureKey, WeatherReducer, WeatherState } from './weather.reducers';
import { ambianceFeatureKey, AmbianceReducer, AmbianceState } from './ambiance.reducers';
import { beachFeatureKey, BeachReducer, BeachState } from './beach.reducers';
import { districtFeatureKey, DistrictReducer, DistrictState } from './district.reducers';
import { incidentFeatureKey, IncidentReducer, IncidentState } from './incident.reducers';
import { incidentResponseFeatureKey, IncidentResponseReducer, IncidentResponseState } from './incidentresponse.reducers';
import { incidentSeverityFeatureKey, IncidentSeverityReducer, IncidentSeverityState } from './incidentseverity.reducers';
import { incidenttypeFeatureKey, IncidentTypeReducer, IncidentTypeState } from './incidenttype.reducers';
import { regionFeatureKey, RegionReducer, RegionState } from './region.reducers';
import { stationFeatureKey, StationReducer, StationState } from './station.reducers';
import { timeclockFeatureKey, TimeclockReducer, TimeclockState } from './timeclock.reducers';
import { userFeatureKey, UserReducer, UserState } from './user.reducers';
import { homepageFeatureKey, HomepageReducer, HomepageState } from '../../containers/home/store/home.page.reducers';
import { operationsPageFeatureKey, OperationsPageReducer, OperationsPageState } from '../../containers/operations/store/operations.page.reducers';
import { metadataFeatureKey, MetadataReducer, MetadataState } from './metadata.reducers';
import { runbookPageFeatureKey, RunbookPageReducer, RunbookPageState } from '../../containers/runbook/store/runbook.page.reducers';
import { feedbackFeatureKey, FeedbackReducer, FeedbackState } from './feedback.reducers';
import { stationSettingFeatureKey, StationSettingReducer, StationSettingState } from './stationsetting.reducers';
import { containerSettingFeatureKey, ContainerSettingReducer, ContainerSettingState } from './containersetting.reducers';
import { tableFeatureKey, TableReducer, TableState } from './table.reducers';


export interface SeacomState {
  [ambianceFeatureKey]: AmbianceState,
  [authFeatureKey]: AuthState,
  [beachFeatureKey]: BeachState,
  [containerSettingFeatureKey]: ContainerSettingState,
  [operationsPageFeatureKey]: OperationsPageState,
  [districtFeatureKey]: DistrictState,
  [feedbackFeatureKey]: FeedbackState,
  [homepageFeatureKey]: HomepageState,
  [incidentFeatureKey]: IncidentState,
  [runbookPageFeatureKey]: RunbookPageState,
  [incidentResponseFeatureKey]: IncidentResponseState,
  [incidentSeverityFeatureKey]: IncidentSeverityState,
  [incidenttypeFeatureKey]: IncidentTypeState,
  [locationFeatureKey]: LocationState,
  [metadataFeatureKey]: MetadataState,
  [notificationFeatureKey]: NotificationState,
  [regionFeatureKey]: RegionState,
  [tableFeatureKey]: TableState,
  [stationFeatureKey]: StationState,
  [stationSettingFeatureKey]: StationSettingState,
  [timeclockFeatureKey]: TimeclockState,
  [userFeatureKey]: UserState,
  [weatherFeatureKey]: WeatherState,
}

export const reducers: ActionReducerMap<SeacomState> = {
  [ambianceFeatureKey]: AmbianceReducer,
  [authFeatureKey]: AuthReducer,
  [beachFeatureKey]: BeachReducer,
  [containerSettingFeatureKey]: ContainerSettingReducer,
  [operationsPageFeatureKey]: OperationsPageReducer,
  [districtFeatureKey]: DistrictReducer,
  [feedbackFeatureKey]: FeedbackReducer,
  [homepageFeatureKey]: HomepageReducer,
  [incidentFeatureKey]: IncidentReducer,
  [runbookPageFeatureKey]: RunbookPageReducer,
  [incidentResponseFeatureKey]: IncidentResponseReducer,
  [incidentSeverityFeatureKey]: IncidentSeverityReducer,
  [incidenttypeFeatureKey]: IncidentTypeReducer,
  [locationFeatureKey]: LocationReducer,
  [metadataFeatureKey]: MetadataReducer,
  [notificationFeatureKey]: NotificationReducer,
  [regionFeatureKey]: RegionReducer,
  [tableFeatureKey]: TableReducer,
  [stationFeatureKey]: StationReducer,
  [stationSettingFeatureKey]: StationSettingReducer,
  [timeclockFeatureKey]: TimeclockReducer,
  [userFeatureKey]: UserReducer,
  [weatherFeatureKey]: WeatherReducer,
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'auth',
      'ambiance'
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer
];
