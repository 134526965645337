import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IIncidentSeverity } from 'src/app/interfaces/seacom/incidentseverity';
import * as fromReducers from '../reducers/incidentseverity.reducers';
import { sortFactory } from '../utils/sort.utils';

export const selectIncidentSeverityState = createFeatureSelector<fromReducers.IncidentSeverityState>(
  fromReducers.incidentSeverityFeatureKey
);

export const selectIncidentSeveritiesCount = createSelector(
  selectIncidentSeverityState,
  (state: fromReducers.IncidentSeverityState): number => Object.keys(state.entities).length
);

export const selectIncidentSeveritiesLastUpdated = createSelector(
  selectIncidentSeverityState,
  (state: fromReducers.IncidentSeverityState): string => state.lastUpdated
);

export const selectIncidentSeveritiesLoading = createSelector(
  selectIncidentSeverityState,
  (state: fromReducers.IncidentSeverityState): boolean => state.loading
);

export const selectAllIncidentSeverities = createSelector(
  selectIncidentSeveritiesCount,
  selectIncidentSeverityState,
  (count: number, state: fromReducers.IncidentSeverityState): IIncidentSeverity[] => {
    if(count > 0) {
      return Object.keys(state.entities).map(i => state.entities[i])
        .sort((a, b) => sortFactory(a, b, [{field: 'name', ascending: true}]));
    } else {
      return [];
    }
  }
);

export const selectIncidentSeverityById = (id: string) => createSelector(selectIncidentSeverityState, (incidentSeverities): IIncidentSeverity => {
  return incidentSeverities?.entities[id];
});

export const selectIncidentSeveritiesByLevel = (minLevel: number, maxLevel: number) => createSelector(selectAllIncidentSeverities, (incidentSeverities): IIncidentSeverity[] => {
  return incidentSeverities
    .filter(
      (is) => {
        if (is.level >= minLevel && is.level <= maxLevel) {
          return true;
        } else {
          return false;
        }
      }
    )
    .sort((a,b) => sortFactory(a,b,[{field: 'name', ascending: true}]));
});
