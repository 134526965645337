// Angular
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

// RXJS
import { Subscription, timer, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Google Maps
import { Loader } from '@googlemaps/js-api-loader';

// Globals
import { skipNull } from 'src/app/common/globals';

// State
import { SeacomState } from 'src/app/store';

// Interfaces
import { IDistrict } from 'src/app/interfaces/seacom/district';
import { IRegion } from 'src/app/interfaces/seacom/region';
import { IBeach } from 'src/app/interfaces/seacom/beach';
import { IIncident } from 'src/app/interfaces/seacom/incident';
import { IIncidentResponse } from 'src/app/interfaces/seacom/incidentresponse';
import { IUser } from 'src/app/interfaces/seacom/user';
import { IStation } from 'src/app/interfaces/seacom/station';
import { IMapFilterSet } from 'src/app/interfaces/mapfilter';

// Services
import { EnvironmentService } from 'src/app/services/environment.service';

// Selectors
import { selectIncidentById } from 'src/app/store/selectors/incident.selectors';
import { selectUserById } from 'src/app/store/selectors/user.selectors';
import { selectAllStations, selectStationById } from 'src/app/store/selectors/station.selectors';
import { selectIncidentResponseById } from '../../store/selectors/incidentresponse.selectors';
import { selectOperationsMapFilteredIncidents, selectOperationsMapBounds, selectOperationsMapCenterCoordinates, selectOperationsMapFilteredResponses, selectOperationsMapFilteredStations, selectOperationsMapFilters, selectOperationsMapType, selectOperationsMapZoom } from 'src/app/containers/operations/store/operations.page.selectors';
import { selectLatestLocation } from 'src/app/store/selectors/location.selectors';
import { selectAllRegions } from 'src/app/store/selectors/region.selectors';
import { selectAllDistricts } from 'src/app/store/selectors/district.selectors';
import { selectAllBeaches } from 'src/app/store/selectors/beach.selectors';
import { selectLocationDisabled } from '../../store/selectors/location.selectors';
import { selectBrightness } from 'src/app/store/selectors/ambiance.selectors';

// Actions
import * as fromActions from '../../containers/operations/store/operations.page.actions';


@Component({
  selector: 'app-operations-map',
  templateUrl: './operations-map.component.html',
  styleUrls: ['./operations-map.component.scss']
})
export class OperationsMapComponent implements OnInit, OnDestroy {
  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    if(event.cancelable) {
      event.stopPropagation();
    }
  }
  
  brightness$: Observable<'light' | 'dark'>;

  updateTimerSub: Subscription;

  // Seacom Data
  stations$: Observable<IStation[]>;
  regions$: Observable<IRegion[]>;
  districts$: Observable<IDistrict[]>;
  beaches$: Observable<IBeach[]>;
  filteredStations$: Observable<IStation[]>;
  filteredIncidents$: Observable<IIncident[]>;
  filteredResponses$: Observable<IIncidentResponse[]>;
  filteredResponders$: Observable<IUser[]>;
  mapFilters$: Observable<IMapFilterSet>;

  // Map State
  mapCenter$: Observable<{ lat: number, lng: number }>;
  mapBounds$: Observable<google.maps.LatLngBoundsLiteral>;
  mapZoom$: Observable<number>;
  mapType$: Observable<'roadmap' | 'satellite' | 'hybrid' | 'terrain'>;
  mapState: Subscription;

  styles: google.maps.StyledMapTypeOptions;
  infowindow: google.maps.InfoWindow;

  style = [];

  map: google.maps.Map;

  mapTypes = [
    { value: 'roadmap', display: 'Road Map' },
    { value: 'satellite', display: 'Satellite' },
    { value: 'hybrid', display: 'Hybrid' },
    { value: 'terrain', display: 'Terrain' }
  ];

  mapOptions: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    scaleControl: false,
    mapTypeControl: false,
    maxZoom: 22,
    minZoom: 3,
    zoom: 6,
    styles: [{ elementType: 'geometry', stylers: this.style }]
  }
  center: google.maps.LatLngLiteral = null;
  markers: google.maps.Marker[] = [];
  markerOption: string;
  clickEventSubscriptions: Subscription;
  stationsclickEventSubscriptions: Subscription;
  assetsclickEventSubscriptions: Subscription;
  selectedType: string = '';
  selectedSeverity: string = '';
  selectedStatus: string = '';

  mapTypeForm;
  mapTypeControl = new FormControl('');
  mapFilterForm;

  constructor(
    private environmentService: EnvironmentService,
    private store: Store<SeacomState>,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.store.dispatch(fromActions.MapEnter());

    // Subscribe to ambiance brightness
    this.brightness$ = this.store.select(selectBrightness);

    // Filter selectors
    this.mapFilters$ = this.store.select(selectOperationsMapFilters);

    this.mapFilterForm = this.fb.group({
      regions: new FormControl(''),
      districts: new FormControl(''),
      beaches: new FormControl(''),
      stations: new FormControl('')
    });

    this.mapFilters$.pipe(skipNull()).subscribe(
      (fs) => {
        if (fs !== undefined && fs !== null) {
          this.mapFilterForm.patchValue(fs);
        }
      }
    );

    // Map Type -- build form, subscribe to state updates
    this.mapTypeForm = this.fb.group({
      mapType: this.mapTypeControl
    });

    this.mapType$ = this.store.select(selectOperationsMapType);

    this.mapType$.pipe(skipNull()).subscribe(
      (type) => {
        if (type !== undefined && type !== null) {
          this.mapTypeControl.setValue(type);
        }
      }
    )

    const loader = new Loader({
      apiKey: this.environmentService.googleMapsKey,
      version: "weekly"
    });

    loader.load().then(() => {
      console.log('Google Maps API Loaded.');

      this.map = new google.maps.Map(document.getElementById('OperationsMap'), this.mapOptions);

      this.store.select(selectLocationDisabled).pipe(first()).subscribe(
        (locationDisabled) => {
          this.store.select(selectLatestLocation).pipe(first()).subscribe(
            (loc) => {
              if (!locationDisabled && loc !== undefined && loc !== null) {
                let lat = loc.latitude;
                let lng = loc.longitude;
                this.map.setCenter({ lat: lat, lng: lng });
              } else {
                // Location disabled or unknown, set scope to world view -- will be updated later if there are markers on map
                var worldBounds = new google.maps.LatLngBounds(
                  new google.maps.LatLng(70.4043, -143.5291),  //Top-left
                  new google.maps.LatLng(-46.11251, 163.4288)  //Bottom-right
                );
                const boundsLiteral: google.maps.LatLngBoundsLiteral = {
                  north: worldBounds.getNorthEast().lat(),
                  south: worldBounds.getSouthWest().lat(),
                  east: worldBounds.getNorthEast().lng(),
                  west: worldBounds.getSouthWest().lng()
                };

                this.store.dispatch(fromActions.SetMapBounds({ payload: { bounds: boundsLiteral } }));

                /*let actualBounds = this.map.getBounds();
                if (actualBounds.getSouthWest().lng() == -180 && actualBounds.getNorthEast().lng() == 180) {
                  this.store.dispatch(fromActions.SetMapZoom({ payload: { zoomLevel: this.map.getZoom() + 1 } }));
                }*/
              }
            }
          );
        }
      );

      // Map state selectors
      this.mapCenter$ = this.store.select(selectOperationsMapCenterCoordinates);
      this.mapBounds$ = this.store.select(selectOperationsMapBounds);
      this.mapZoom$ = this.store.select(selectOperationsMapZoom);
      this.mapType$ = this.store.select(selectOperationsMapType);

      this.subscribeToMapStateUpdates();

      // Map filter options data
      this.regions$ = this.store.select(selectAllRegions);
      this.districts$ = this.store.select(selectAllDistricts);
      this.beaches$ = this.store.select(selectAllBeaches);
      this.stations$ = this.store.select(selectAllStations);

      // Map marker data
      this.filteredStations$ = this.store.select(selectOperationsMapFilteredStations)
      this.filteredIncidents$ = this.store.select(selectOperationsMapFilteredIncidents);
      this.filteredResponses$ = this.store.select(selectOperationsMapFilteredResponses);

      // run update on markers initially
      this.updateMarkerPositions();

      // Update map bounds, if necessary
      this.updateBounds();

      // run update on markers every 30 seconds
      this.updateTimerSub = timer(30000, 30000).subscribe(
        () => {
          this.updateMarkerPositions();
        }
      );
    });

  }

  ngOnDestroy() {
    this.updateTimerSub.unsubscribe();

    this.mapState.unsubscribe();

    this.store.dispatch(fromActions.MapExit());
  }

  ngAfterViewInit() {
    this.setCenter();
  }

  subscribeToMapStateUpdates() {
    this.mapState = this.mapCenter$.subscribe(
      (newCenter) => {
        if (
          newCenter !== undefined &&
          newCenter !== null &&
          newCenter.lat !== undefined &&
          newCenter.lat !== null &&
          newCenter.lng !== undefined &&
          newCenter.lng !== null
        ) {
          this.map.panTo(newCenter);
        }
      }
    );

    this.mapState = this.mapBounds$.subscribe(
      (newBounds) => {
        if (
          newBounds !== undefined &&
          newBounds !== null
        ) {
          this.map.fitBounds(newBounds);
        }
      }
    );

    this.mapState.add(this.mapZoom$.subscribe(
      (newZoom) => {
        if (
          newZoom !== undefined &&
          newZoom !== null &&
          newZoom >= 3 &&
          newZoom <= 26
        ) {
          this.map.setZoom(newZoom);
        }
      }
    ));

    this.mapState.add(this.mapType$.subscribe(
      (newType) => {
        if (newType !== undefined && newType !== null) {
          switch (newType) {
            case 'hybrid':
              this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
              break;
            case 'satellite':
              this.map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
              break;
            case 'terrain':
              this.map.setMapTypeId(google.maps.MapTypeId.TERRAIN);
              break;
            case 'roadmap':
            default:
              this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
              break;
          }
        }
      }
    ));
  }

  updateMarkerPositions() {
    for (let marker of this.markers) {
      marker.setMap(null);
    }

    let bounds = new google.maps.LatLngBounds();

    let newMarkers = [] as google.maps.Marker[];
    this.filteredStations$.pipe(first()).subscribe(
      (stations) => {
        if (stations !== undefined && stations !== null) {
          for (let station of stations) {
            let marker = this.getStationMarker(station);
            if (marker !== null) {
              newMarkers.push(marker);
              bounds.extend(marker.getPosition());
            }
          }
        }
      }
    );

    this.filteredIncidents$.pipe(first()).subscribe(
      (incidents) => {
        if (incidents !== undefined && incidents !== null) {
          for (let incident of incidents) {
            let marker = this.getIncidentMarker(incident);
            if (marker !== null) {
              newMarkers.push(marker);
              bounds.extend(marker.getPosition());
            }
          }
        }
      }
    )

    this.filteredResponses$.pipe(first()).subscribe(
      (incidentResponses) => {
        if (incidentResponses !== undefined && incidentResponses !== null) {
          for (let incidentResponse of incidentResponses) {
            let marker = this.getIncidentResponseMarker(incidentResponse);
            if (marker !== null) {
              newMarkers.push(marker);
              bounds.extend(marker.getPosition());
            }
          }
        }
      }
    )

    this.markers = newMarkers;
  }

  updateBounds() {
    if (this.markers.length > 0) {
      let bounds = new google.maps.LatLngBounds();

      for (let marker of this.markers) {
        bounds.extend(marker.getPosition());
      }

      const boundsLiteral: google.maps.LatLngBoundsLiteral = {
        north: bounds.getNorthEast().lat(),
        south: bounds.getSouthWest().lat(),
        east: bounds.getNorthEast().lng(),
        west: bounds.getSouthWest().lng()
      };

      this.store.dispatch(fromActions.SetMapBounds({ payload: { bounds: boundsLiteral } }));
    }
  }

  getStationMarker(station: IStation): google.maps.Marker | null {
    if (station.location === undefined || station.location === null) {
      return null;
    }

    let coords = station.location.split(',');
    let position = { lat: parseFloat(coords[0].trim()), lng: parseFloat(coords[1].trim()) } as google.maps.LatLngLiteral;

    let options = {
      map: this.map,
      position: position,
      /*icon: {
        url: '/assets/icons/station.jpg'
      },*/
      animation: google.maps.Animation.DROP,
      title: station.name
    } as google.maps.MarkerOptions;

    return new google.maps.Marker(options);
  }

  getIncidentMarker(incident: IIncident): google.maps.Marker | null {
    if (incident.location === undefined || incident.location === null) {
      return null;
    }

    let coords = incident.location.split(',');
    let position = { lat: parseFloat(coords[0].trim()), lng: parseFloat(coords[1].trim()) } as google.maps.LatLngLiteral;

    let options = {
      map: this.map,
      position: position,
      /*icon: {
        url: '/assets/icons/incident_map.jpg'
      },*/
      animation: google.maps.Animation.DROP,
      title: incident.incident_number
    } as google.maps.MarkerOptions;

    return new google.maps.Marker(options);
  }

  getIncidentResponseMarker(incidentResponse: IIncidentResponse): google.maps.Marker | null {
    if (incidentResponse.location === undefined || incidentResponse.location === null) {
      return null;
    }

    let coords = incidentResponse.location.split(',');
    let position = { lat: parseFloat(coords[0].trim()), lng: parseFloat(coords[1].trim()) } as google.maps.LatLngLiteral;

    let options = {
      map: this.map,
      position: position,
      /*icon: {
        url: '/assets/icons/incident_map.jpg'
      },*/
      animation: google.maps.Animation.DROP,
      title: incidentResponse.incident.incident_number + ((incidentResponse.type !== undefined && incidentResponse.type !== null) ? (': ' + incidentResponse.type.name) : '')
    } as google.maps.MarkerOptions;

    return new google.maps.Marker(options);
  }

  getResponderMarker(responder: Partial<IUser>): google.maps.Marker | null {
    if (responder.location === undefined || responder.location === null) {
      return null;
    }

    let coords = responder.location.split(',');
    let position = { lat: parseFloat(coords[0].trim()), lng: parseFloat(coords[1].trim()) } as google.maps.LatLngLiteral;

    let options = {
      position: position,
      /* icon: {
        url: '/assets/icons/assets_units.png'
      }, */
      animation: google.maps.Animation.BOUNCE,
      title: responder.first_name + ' ' + responder.last_name
    } as google.maps.MarkerOptions;

    return new google.maps.Marker(options);
  }

  updateMapFilters() {
    let newFilterSet = this.mapFilterForm.value;

    this.store.dispatch(fromActions.UpdateMapFilters({
      payload: {
        newFilterSet: newFilterSet
      }
    }));

    this.updateMarkerPositions();
  }

  zoomIn() {
    this.store.dispatch(fromActions.ZoomMapIn());
  }

  zoomOut() {
    this.store.dispatch(fromActions.ZoomMapOut());
  }

  focusMap(event: google.maps.MapMouseEvent) {
    this.store.dispatch(fromActions.FocusMapTo({
      payload: {
        coordinates: {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        }
      }
    }));
  }

  infoContent(id: string): string {
    let ids = id.split('-');
    let res: string;
    switch (ids[0]) {
      case 's':
        this.store.select(selectStationById(ids[1])).pipe(skipNull(), first()).subscribe(
          (station) => {
            res = '<div class="info">' +
              '<h5>' + (station.name) + '</h5>' +
              '</div>';
          }
        );
        break;
      case 'i':
        this.store.select(selectIncidentById(ids[1])).pipe(skipNull(), first()).subscribe(
          (incident) => {
            res = '<div class="info" >' +
              '<h5>' + (incident.incident_number) + '</h5>' +
              (incident.type ? incident.type.name : '') +
              '<br/>' +
              '<label><b>Status: </b></label>' +
              (incident.status) + '&emsp;' + '&emsp;' +
              '<label><b>Severity: </b> </label>' +
              (incident.severity.name) +
              '<br/><br/>' +
              '<label><b>Reported Time: </b></label>' +
              (incident.starttime) + '&emsp;' + '&emsp;' +
              '<label><b>Completion Time: </b></label>' +
              (incident.resolvedtime ? incident.resolvedtime : '') +
              '</div>';
          }
        );
        break;
      case 'ir':
        this.store.select(selectIncidentResponseById(ids[1])).pipe(skipNull(), first()).subscribe(
          (incidentResponse) => {
            res = '<div class="info" >' +
              '<h5>' + incidentResponse.incident.incident_number + '</h5><br />' +
              '<h5>' + (incidentResponse.type ? incidentResponse.type.name : '') + '@' + incidentResponse.starttime + '</h5><br /><br />' +
              '<label><b>Status: </b></label>' +
              (incidentResponse.status === 'inprogress' ? 'In Progress' : 'Complete') +
              '&emsp;' + '&emsp;' +
              '<label><b>Severity: </b> </label>' +
              (incidentResponse.severity.name) +
              '<br/><br/>' +
              '<label><b>Start Time: </b></label>' +
              (incidentResponse.starttime) + '&emsp;' + '&emsp;' +
              '<label><b>End Time: </b></label>' +
              (incidentResponse.endtime ? incidentResponse.endtime : 'In Progress') +
              '</div>';
          }
        );
        break;
      case 'u':
        this.store.select(selectUserById(ids[1])).pipe(skipNull(), first()).subscribe(
          (responder) => {
            res = '<div class="info">' +
              '<h5>' + (responder.first_name) + ' ' + (responder.last_name) + '</h5>' +
              '<h5>' + (responder.role.role.name) + '</h5>' +
              '</div>';
          }
        );
        break;
    }
    return res;
  }

  /*openInfoWindow(id: string) {
    let ids = id.split('-');
    let content = this.infoContent(id);
    this.infowindow.open({
      anchor: this.markers$.value.get(id),
      shouldFocus: false
    }, this.markers$.value.get(id));
  }*/

  setCenter() {
  }

  updateMapType(event) {
    this.store.dispatch(fromActions.ChangeOperationsMapType({ payload: { mapType: this.mapTypeControl.value as ('roadmap' | 'satellite' | 'hybrid' | 'terrain') } }));
  }

  /* openInfo(marker: MapMarker, content) {
    //this.infoContent = content
    this.infoWindow.open(marker)
  } */

}
