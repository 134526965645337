// Angular
import { Injectable } from '@angular/core';

// RXJS
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// State
import { SeacomState } from './store';

// Actions
import * as actions from './store/actions';


@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>
  ) { }

  // Main app entry effects
  enter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromAppActions.Enter
        ),
        map((_) => {
          this.store.dispatch(actions.ClearAuthAtEnter());
        })
      )
  },
    { dispatch: false });

  // Main app exit effects
  exit$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromAppActions.Exit
        ),
        map((_) => {
          this.store.dispatch(actions.ClearAuthAtExit());
        })
      )
  },
    { dispatch: false });
}
