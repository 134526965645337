import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IIncidentResponse } from 'src/app/interfaces/seacom/incidentresponse';
import * as fromReducers from '../reducers/incidentresponse.reducers';
import { sortFactory } from '../utils/sort.utils';
import { selectAllIncidentSeverities } from './incidentseverity.selectors';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { filterObject } from '../utils/filter.utils';
import { searchFactory } from '../utils/search.utils';

import { selectAllStations } from './station.selectors';
import { IIncidentSeverity } from 'src/app/interfaces/seacom/incidentseverity';
import { IStation } from 'src/app/interfaces/seacom/station';

export const selectIncidentResponseState = createFeatureSelector<fromReducers.IncidentResponseState>(
  fromReducers.incidentResponseFeatureKey
);

export const selectIncidentResponsesCount = createSelector(
  selectIncidentResponseState,
  (state: fromReducers.IncidentResponseState): number => Object.keys(state.entities).length
);

export const selectIncidentResponsesLastUpdated = createSelector(
  selectIncidentResponseState,
  (state: fromReducers.IncidentResponseState): string => state.lastUpdated
);

export const selectIncidentResponsesLoading = createSelector(
  selectIncidentResponseState,
  (state: fromReducers.IncidentResponseState): boolean => state.loading
);

export const selectAllIncidentResponses = createSelector(
  selectIncidentResponsesCount,
  selectIncidentResponseState,
  (count: number, state: fromReducers.IncidentResponseState): IIncidentResponse[] => {
    if(count > 0) {
      return Object.keys(state.entities).map(i => state.entities[i])
        .sort((a, b) => sortFactory(a, b, [{field: 'starttime', ascending: false}]));
    } else {
      return [];
    }
  }
);

export const selectNewIncidentResponsesCount = createSelector(
  selectAllIncidentResponses,
  (incidentResponses: IIncidentResponse[]): number => {
    return incidentResponses.filter((ir: IIncidentResponse) =>
      ir.status === 'new'
    )
    .length;
  }
);

export const selectOpenIncidentResponsesCount = createSelector(
  selectAllIncidentResponses,
  (incidentResponses: IIncidentResponse[]): number => {
    return incidentResponses
      .filter((ir: IIncidentResponse) =>
        ir.status !== 'closed'
      )
      .length;
  }
);

export const selectOpenIncidentResponsesCountBySeverity = createSelector(
  selectAllIncidentResponses,
  selectAllIncidentSeverities,
  (incidentResponses: IIncidentResponse[], incidentSeverities: IIncidentSeverity[]): { [name: string] : number } => {
    return incidentSeverities.reduce(
      (entities: { [name: string] : number }, item: IIncidentSeverity) => {
        return {
          ...entities,
          [item.name]: incidentResponses.filter(ir => ir.status !== 'closed').length
        };
      },
      {}
    );
  }
);

export const selectIncidentResponsesWithFilter = (filters?: IFieldFilter[], order?: string, search?: string) => createSelector(selectAllIncidentResponses, (incidentResponses): IIncidentResponse[] => {
  let res = incidentResponses;

  if (filters !== undefined && filters !== null) {
    res = res.filter(i => filterObject(i, filters));
  }

  if (search !== undefined && search !== null) {
    res = res.filter(i => searchFactory(i, search));
  }

  if (order !== undefined && order !== null) {
    let sortAsc = true;
    if (order.substring(0, 1) === '-') {
      sortAsc = false;
    }
    let sortField = order.replace('-', '').replace('+', '');
    return res.sort((a, b) => sortFactory(a, b, [{field: sortField, ascending: sortAsc}]));
  } else {
    // Sort descending by starttime by default
    return res.sort((a, b) => sortFactory(a, b, [{field: 'starttime', ascending: false}]));
  }
});


export const selectIncidentResponseById = (id: string) => createSelector(selectIncidentResponseState, (incidentResponses): IIncidentResponse => {
  return incidentResponses?.entities[id];
});

export const selectIncidentResponsesByStatus = (status: string) => createSelector(selectAllIncidentResponses, (incidentResponses): IIncidentResponse[] => {
  return incidentResponses
    .filter(
      (ir) => {
        if (ir.status === status) {
          return true;
        } else {
          return false;
        }
      }
    )
    .sort((a, b) => sortFactory(a, b, [{field: 'starttime', ascending: false}]));
});

export const selectIncidentResponsesByStatuses = (statuses: string[]) => createSelector(selectAllIncidentResponses, (incidentResponses): IIncidentResponse[] => {
  return incidentResponses
    .filter(ir => statuses.indexOf(ir.status) !== -1)
    .sort((a, b) => sortFactory(a, b, [{field: 'starttime', ascending: false}]));
});

export const selectIncidentResponsesWithLocation = createSelector(
  selectAllIncidentResponses,
  (incidentResponses): IIncidentResponse[] => {
    return incidentResponses
      .filter(ir => ir.location !== undefined && ir.location !== null)
      .sort((a, b) => sortFactory(a, b, [{field: 'starttime', ascending: false}]));
  }
);

export const selectIncidentResponsesBySeverity = (severity: string) => createSelector(selectAllIncidentResponses, (incidentResponses): IIncidentResponse[] => {
  return incidentResponses
    .filter(
      (ir) => {
        if (ir.severity === severity) {
          return true;
        } else {
          return false;
        }
      }
    )
    .sort((a, b) => sortFactory(a, b, [{field: 'starttime', ascending: false}]));
});

export const selectMyIncidentResponses = (myId: string, myRole: string) => createSelector(selectAllIncidentResponses, (incidentResponses): IIncidentResponse[] => {
  return incidentResponses
    .filter(
      (ir) => {
        if(ir.created_by === myId || ir.responders.map(r => r.id).indexOf(myId) !== -1) {
          return true;
        } else {
          return false;
        }
      }
    )
    .sort((a, b) => sortFactory(a, b, [{field: 'starttime', ascending: false}]));
});

export const selectMyIncidentResponseCount = (myId: string, myRole: string) => createSelector(selectIncidentResponseState, (): number => {
  return selectMyIncidentResponses(myId, myRole).length;
});

export const selectLatestIncidentResponseByStation = (statuses: string[]) => createSelector(
  selectIncidentResponsesByStatuses(statuses),
  selectAllStations,
  (incidentResponses, stations): { [id: string]: IIncidentResponse } => {
    return stations.reduce(
      (entities: { [id: string]: IIncidentResponse }, item: IStation) => {
        let irs = incidentResponses.filter(ir => ir.stations.map(s => s.id).indexOf(item.id) !== -1).sort((a,b) => sortFactory(a,b,[{field: 'starttime', ascending: false}]));
        let ir: IIncidentResponse;
        if(irs.length > 0) {
          ir = irs[0];
        } else {
          ir = null;
        }
        return {
          ...entities,
          [item.id]: ir
        };
      },
      {}
    );
  }
)

export const selectLatestIncidentResponseInProgressByStation = createSelector(
  selectAllIncidentResponses,
  selectAllStations,
  (incidentResponses, stations): { [id: string]: IIncidentResponse } => {
    let res = stations.reduce(
      (entities: { [id: string]: IIncidentResponse }, item: IStation) => {
        let irs = incidentResponses.filter(ir => ir.stations.map(s => s.id).indexOf(item.id) !== -1 && ir.status === 'inprogress').sort((a,b) => sortFactory(a,b,[{field: 'starttime', ascending: false}]));
        let ir: IIncidentResponse;
        if(irs.length > 0) {
          ir = irs[0];
        } else {
          ir = null;
        }
        return {
          ...entities,
          [item.id]: ir
        };
      },
      {}
    );
    return res;

  }
)

export const selectLatestIncidentResponseInProgressByStationId = (stationId: string) => createSelector(
  selectLatestIncidentResponseInProgressByStation,
  (responses): IIncidentResponse => {
    return responses[stationId];
  }
)

export const selectIncidentResponsesByStations = createSelector(
  selectAllIncidentResponses,
  selectAllStations,
  (incidentResponses, stations): { [id: string]: IIncidentResponse[] } => {
    return stations.reduce(
      (entities: { [id: string]: IIncidentResponse[] }, item: IStation) => {
        return {
          ...entities,
          [item.id]: incidentResponses
            .filter(i => i.stations.map(s => s.id).indexOf(item.id) !== -1)
            .sort((a,b) => sortFactory(a,b,[{field: 'starttime', ascending: false}]))
        };
      },
      {}
    );
  }
);

export const selectIncidentResponsesByStationId = (stationId: string) => createSelector(
  selectAllIncidentResponses,
  (incidentResponses): IIncidentResponse[] => {
    return incidentResponses.filter(ir => ir.stations.map(s => s.id).indexOf(stationId) !== -1).sort((a,b) => sortFactory(a,b,[{field: 'starttime', ascending: false}]))
  }
)

export const selectIncidentResponsesByStationIdAndStatus = (stationId: string, statuses: string[]) => createSelector(
  selectIncidentResponsesByStationId(stationId),
  (incidentResponses): IIncidentResponse[] => {
    return incidentResponses.filter(ir => statuses.indexOf(ir.status) !== -1).sort((a,b) => sortFactory(a,b,[{field: 'starttime', ascending: false}]))
  }
)

export const selectLatestIncidentResponseByStationId = (stationId: string) => createSelector(
  selectIncidentResponsesByStationId(stationId),
  (incidentResponses): IIncidentResponse => {
    if(incidentResponses.length > 0) {
      return incidentResponses[0];
    } else {
      return null;
    }
  }
)

export const selectLatestIncidentResponseByStationIdAndStatus = (stationId: string, statuses: string[]) => createSelector(
  selectIncidentResponsesByStationIdAndStatus(stationId, statuses),
  (incidentResponses): IIncidentResponse => {
    if(incidentResponses.length > 0) {
      return incidentResponses[0];
    } else {
      return null;
    }
  }
)

export const selectIncidentResponseCountLast24ByStation = createSelector(
  selectAllIncidentResponses,
  selectAllStations,
  (incidentResponses, stations): { [id: string]: number } => {
    var yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000)); // 24 hrs
    return stations.reduce(
      (entities: { [id: string]: number }, item: IStation) => {
        return {
          ...entities,
          [item.id]: incidentResponses
            .filter(i => i.stations.map(s => s.id).indexOf(item.id) !== -1 && (new Date(i.starttime)).getTime() >= yesterday.getTime())
            .length
        };
      },
      {}
    );
  }
)

export const selectIncidentResponsesByIncidentId = (incidentId: string) => createSelector(
  selectAllIncidentResponses,
  (responses): IIncidentResponse[] => {
    return responses.filter(r => r.incident.id === incidentId).sort((a,b) => sortFactory(a,b,[{field: 'starttime', ascending: false}]));
  }
)
