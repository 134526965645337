import { createAction, props } from "@ngrx/store";
import { INewNotification } from '../../../interfaces/seacom/notification';

const actionKey = '[Notifications Page] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

export const UpdateStatusFilter = createAction(
  actionKey + 'Change List Filter',
  props<{ payload: { status: string }}>()
)

export const ArchiveNotification = createAction(
  actionKey + 'Archive Notification',
  props<{ payload: { notificationId: string }}>()
)

export const DeleteNotification = createAction(
  actionKey + 'Delete Notification',
  props<{ payload: { notificationId: string }}>()
)

export const AddNotification = createAction(
  actionKey + 'Add Notification',
  props<{ payload: { notification: INewNotification }}>()
)
