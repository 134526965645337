// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// RXJS
import { Observable } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';

// Globals
import { skipNull } from 'src/app/common/globals';

// Store
import { SeacomState } from 'src/app/store';

// Interfaces
import { IUser } from 'src/app/interfaces/seacom/user';

// Actions
import * as fromActions from './store/headerbar.actions';

// Selectors
import { selectCurrentUser } from 'src/app/store/selectors/auth.selectors';
import { selectBrightness } from 'src/app/store/selectors/ambiance.selectors';


@Component({
  selector: 'app-headerbar',
  templateUrl: './headerbar.component.html',
  styleUrls: ['./headerbar.component.scss'],
})
export class HeaderbarComponent implements OnInit, OnDestroy {
  isMenuOpen = false;

  currentUser$: Observable<IUser>;

  brightness$: Observable<'light'|'dark'>;

  constructor(
    public store: Store<SeacomState>,
    public router: Router
  ) { }

  ngOnInit() {
    this.store.dispatch(fromActions.Enter());
    this.brightness$ = this.store.select(selectBrightness);
    this.currentUser$ = this.store.select(selectCurrentUser).pipe(skipNull());
  }

  ngOnDestroy() {
    this.store.dispatch(fromActions.Exit());
  }

  goHome() {
    this.store.dispatch(fromActions.NavigateToHomePage());
  }

  openSettingsPage() {
    this.isMenuOpen = false;
    this.store.dispatch(fromActions.NavigateToSettingsPage());
  }

  openFeedbackPage() {
    this.isMenuOpen = false;
    this.store.dispatch(fromActions.NavigateToFeedbackPage());
  }

  logout() {
    this.store.dispatch(fromActions.Logout());
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
