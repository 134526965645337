import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { EnvironmentService } from "./environment.service";

@Injectable({
	providedIn: 'root'
})
export class ToastService implements OnInit, OnDestroy {


	// Constructor
	constructor(
		private environmentService: EnvironmentService,
	) {
	}

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }
}