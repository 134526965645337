import { Injectable } from '@angular/core';
import { SeacomApiService } from '../seacom-api.service';
import { Observable, AsyncSubject, throwError, of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import { skipNull } from '../../../common/globals';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';

@Injectable({
  providedIn: 'root'
})
export class ApiNodeService<T, N extends { d?: string }, U extends { d?: string }> {
  node: string;
  //store: Map<string, T> = new Map<string, T>();
  lastUpdated: Date = new Date();

  constructor(
    //public environmentService: EnvironmentService, 
    public seacomApi: SeacomApiService
  ) { }

  public get(id: string) {
    let getResult = new AsyncSubject<T>();
    /*if (this.store.has(id) && (new Date().getTime() - this.lastUpdated.getTime()) < this.environmentService.apiCacheTime) {
      getResult.next(this.store.get(id));
    } else {*/
    this.seacomApi.getSpecificItem(this.node, id).pipe(skipNull()).subscribe(
      (res: HttpResponse<any>) => {
        if (res) {
          switch (res.status) {
            case 200:
              if (res.body !== undefined) {
                getResult.next(res.body as T);
              } else {
                getResult.error(true);
              }
              getResult.complete();
              break;
            default:
              getResult.error(res.body);
              getResult.complete();
              break;
          }
        }
      },
      (err) => {
        getResult.error(err);
        getResult.complete();
      }
    );
    //}

    return getResult.asObservable().pipe(skipNull());
  }

  public getList(queryParams?: IFieldFilter[], orderParams?: string, searchParams?: string, concatItems?: T[]): Observable<T[] | any> {
    let done = false;
    let items: T[] = [];

    if (concatItems !== undefined && concatItems !== null) {
      items = Array.from(concatItems);
    }

    let qParams: IFieldFilter[] = [];

    if (queryParams !== undefined && queryParams !== null) {
      qParams = Array.from(queryParams);
    }

    return this.seacomApi.getItemList(this.node, qParams, orderParams, searchParams, true).pipe(
      switchMap(
        (res: HttpResponse<any>) => {
          if (res !== undefined && res !== null) {
            switch (res.status) {
              case 200:
                if (res.body.results !== undefined && res.body.results !== null) {
                  items = items.concat(res.body.results as T[]);
                  if (res.body.next !== undefined && res.body.next !== null) {
                    // More pages, adjust page number and fetch more items
                    let pgqpidx = qParams.map(p => p.field).indexOf('page');
                    if (pgqpidx !== -1) {
                      qParams[pgqpidx] = {
                        field: 'page',
                        filter: (parseInt(qParams[pgqpidx].filter as string) + 1).toString()
                      } as IFieldFilter;
                    } else {
                      qParams.push(
                        {
                          d: 'IFieldFilter',
                          field: 'page',
                          filter: '2'
                        } as IFieldFilter
                      );
                    }
                    return this.getList(qParams, orderParams, searchParams, items);
                  } else {
                    // No more pages, return items
                    return of(items);
                  }
                } else {
                  done = true;
                }
              default:
                return throwError(res.body);
            }
          } else {
            return throwError("No API Result.");
          }
        }
      )
    );
  }

  public create(createData: N): Observable<T | never> {
    console.log('creating...' + this.node);
    let data = { ...createData, d: undefined };
    return this.seacomApi.createItem(this.node, data)
      .pipe(
        switchMap(
          (res: HttpResponse<any>) => {
            if (res) {
              switch (res.status) {
                case 201:
                  if (res.body !== undefined) {
                    return of(res.body as T);
                  } else {
                    return of({} as T);
                  }
                default:
                  return throwError(res.body);
              }
            }
          }
        )
      );
  }

  public update(id: string, updateData: U) {
    let data = { ...updateData, d: undefined };
    return this.seacomApi.updateItem(this.node, id, data)
      .pipe(
        switchMap(
          (res: HttpResponse<any>) => {
            if (res) {
              switch (res.status) {
                case 200:
                  if (res.body !== undefined) {
                    return of(res.body as T);
                  } else {
                    return of({} as T);
                  }
                default:
                  return throwError(res.body);
              }
            }
          }
        )
      );
  }

  public updateFields(id: string, fieldData: U) {
    let data = { ...fieldData, d: undefined };
    return this.seacomApi.updateItemFields(this.node, id, data)
      .pipe(
        switchMap(
          (res: HttpResponse<any>) => {
            if (res) {
              switch (res.status) {
                case 200:
                  if (res.body !== undefined) {
                    return of(res.body as T);
                  } else {
                    return of({} as T);
                  }
                default:
                  return throwError(res.body);
              }
            }
          }
        )
      );
  }

  public delete(id: string) {
    return this.seacomApi.deleteItem(this.node, id)
      .pipe(
        switchMap(
          (res: HttpResponse<any>) => {
            if (res) {
              switch (res.status) {
                case 204:
                  return of(id);
                default:
                  return throwError(res.body);
              }
            }
          }
        )
      );
  }
}
