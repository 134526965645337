import { createAction, props } from '@ngrx/store';
import { IIncidentResponse, INewIncidentResponse, IUpdatedIncidentResponse } from 'src/app/interfaces/seacom/incidentresponse';

// incidentresponse action types
export const LOAD_INCIDENT_RESPONSES = '[Page Effects] Load Incident Responses';
export const LOAD_INCIDENT_RESPONSES_CANCELLED = '[Incident Response Effects] Load Incident Responses Cancelled';
export const LOAD_INCIDENT_RESPONSES_FAIL = '[Incident Response Effects] Load Incident Responses Fail';
export const LOAD_INCIDENT_RESPONSES_SUCCESS = '[Incident Response Effects] Load Incident Responses Success';
export const LOAD_INCIDENT_RESPONSE = '[Component Effects] Load Incident Response';
export const LOAD_INCIDENT_RESPONSE_FAIL = '[Incident Response Effects] Load Incident Response Fail';
export const LOAD_INCIDENT_RESPONSE_SUCCESS = '[Incident Response Effects] Load Incident Response Success';
export const UPDATE_INCIDENT_RESPONSE = '[SEACOM] Update Incident Response';
export const UPDATE_INCIDENT_RESPONSE_FIELDS = '[SEACOM] Update Incident Response Fields';
export const UPDATE_INCIDENT_RESPONSE_SUCCESS = '[Incident Response Effects] Update Incident Response Success';
export const END_STATION_RESPONSE_SUCCESS = '[Incident Response Effects] End Station Response Success';
export const UPDATE_INCIDENT_RESPONSE_FAIL = '[Incident Response Effects] Update Incident Response Fail';
export const END_STATION_RESPONSE_FAIL = '[Incident Response Effects] End Station Response Fail';
export const ADD_INCIDENT_RESPONSE = '[SEACOM] Add Incident Response';
export const ADD_INCIDENT_RESPONSE_SUCCESS = '[Incident Response Effects] Add Incident Response Success';
export const ADD_STATION_INCIDENT_RESPONSE_SUCCESS = '[Incident Response Effects] Add Station Incident Response Success';
export const ADD_QUICK_INCIDENT_RESPONSE_SUCCESS = '[Incident Response Effects] Add Quick Incident Response Success';
export const STATION_INCIDENT_TRANSFER_SUCCESS = '[Incident Response Effects] Station Incident Transfer Success';
export const ADD_INCIDENT_RESPONSE_FAIL = '[Incident Response Effects] Add Incident Response Fail';
export const ADD_STATION_INCIDENT_RESPONSE_FAIL = '[Incident Response Effects] Add Station Incident Response Fail';
export const ADD_QUICK_INCIDENT_RESPONSE_FAIL = '[Incident Response Effects] Add Quick Incident Response Fail';
export const STATION_INCIDENT_TRANSFER_FAIL = '[Incident Response Effects] Station Incident Transfer Fail';
export const DELETE_INCIDENT_RESPONSE = '[SEACOM] Delete Incident Response';
export const DELETE_INCIDENT_RESPONSE_FAIL = '[Incident Response Effects] Delete Incident Response Fail';
export const DELETE_INCIDENT_RESPONSE_SUCCESS = '[Incident Response Effects] Delete Incident Response Success';

export const LoadIncidentResponses = createAction(
	LOAD_INCIDENT_RESPONSES
);

export const LoadIncidentResponsesCancelled = createAction(
    LOAD_INCIDENT_RESPONSES_CANCELLED
);

export const LoadIncidentResponsesSuccess = createAction(
    LOAD_INCIDENT_RESPONSES_SUCCESS,
    props<{
        payload: IIncidentResponse[]
    }>()
);

export const LoadIncidentResponsesFail = createAction(
    LOAD_INCIDENT_RESPONSES_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadIncidentResponse = createAction(
	LOAD_INCIDENT_RESPONSE,
    props<{
        payload: string // ID
    }>()
);

export const LoadIncidentResponseSuccess = createAction(
    LOAD_INCIDENT_RESPONSE_SUCCESS,
    props<{
        payload: IIncidentResponse
    }>()
);

export const LoadIncidentResponseFail = createAction(
    LOAD_INCIDENT_RESPONSE_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateIncidentResponse = createAction(
    UPDATE_INCIDENT_RESPONSE,
    props<{
        payload: IUpdatedIncidentResponse
    }>()
);

export const UpdateIncidentResponseFields = createAction(
    UPDATE_INCIDENT_RESPONSE_FIELDS,
    props<{
        payload: IUpdatedIncidentResponse
    }>()
);

export const UpdateIncidentResponseSuccess = createAction(
    UPDATE_INCIDENT_RESPONSE_SUCCESS,
    props<{
        payload: IIncidentResponse
    }>()
);

export const EndStationResponseSuccess = createAction(
    END_STATION_RESPONSE_SUCCESS,
    props<{
        payload: IIncidentResponse
    }>()
);

export const UpdateIncidentResponseFail = createAction(
    UPDATE_INCIDENT_RESPONSE_FAIL,
    props<{
        payload: any
    }>()
);

export const EndStationResponseFail = createAction(
    END_STATION_RESPONSE_FAIL,
    props<{
        payload: any
    }>()
);

export const AddIncidentResponse = createAction(
    ADD_INCIDENT_RESPONSE,
    props<{
        payload: INewIncidentResponse
    }>()
);

export const AddIncidentResponseSuccess = createAction(
    ADD_INCIDENT_RESPONSE_SUCCESS,
    props<{
        payload: IIncidentResponse
    }>()
);

export const AddStationIncidentResponseSuccess = createAction(
    ADD_STATION_INCIDENT_RESPONSE_SUCCESS,
    props<{
        payload: IIncidentResponse
    }>()
);

export const AddQuickIncidentResponseSuccess = createAction(
    ADD_QUICK_INCIDENT_RESPONSE_SUCCESS,
    props<{
        payload: IIncidentResponse
    }>()
);

export const StationIncidentTransferSuccess = createAction(
    STATION_INCIDENT_TRANSFER_SUCCESS,
    props<{
        payload: IIncidentResponse
    }>()
);

export const AddIncidentResponseFail = createAction(
    ADD_INCIDENT_RESPONSE_FAIL,
    props<{
        payload: any
    }>()
);

export const AddStationIncidentResponseFail = createAction(
    ADD_STATION_INCIDENT_RESPONSE_FAIL,
    props<{
        payload: any
    }>()
);

export const AddQuickIncidentResponseFail = createAction(
    ADD_QUICK_INCIDENT_RESPONSE_FAIL,
    props<{
        payload: any
    }>()
);

export const StationIncidentTransferFail = createAction(
    STATION_INCIDENT_TRANSFER_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteIncidentResponse = createAction(
    DELETE_INCIDENT_RESPONSE,
    props<{
        payload: string // ID
    }>()
);

export const DeleteIncidentResponseSuccess = createAction(
    DELETE_INCIDENT_RESPONSE_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteIncidentResponseFail = createAction(
    DELETE_INCIDENT_RESPONSE_FAIL,
    props<{
        payload: any
    }>()
);
