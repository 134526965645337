import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { ITimeRange } from 'src/app/interfaces/seacom/timerange';

import { ITimeclock } from '../../interfaces/seacom/timeclock';

import * as actions from '../actions';
import { addItem } from '../utils/itementity';
import { addTimeRange, updateTimeRange } from '../utils/timerangeentity';


export const timeclockFeatureKey = 'timeclock';

export interface TimeclockState {
	timeClock: ITimeclock,
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	clockingIn: boolean,
	clockInError?: any,
	clockingOut: boolean,
	clockOutError: any,
}

export const TimeclockInitialState: TimeclockState = {
	timeClock: {} as ITimeclock,
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	clockingIn: false,
	clockInError: null,
	clockingOut: false,
	clockOutError: null,
};

export const TimeclockReducer = createReducer(
	TimeclockInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...TimeclockInitialState
		} as TimeclockState;
	}),
	// Load Timeclocks
	on(actions.LoadTimeclock, (state, action) => {
		return {
			...state,
			loading: true,
		} as TimeclockState;
	}),
	on(actions.LoadTimeclockCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as TimeclockState;
	}),
	on(actions.LoadTimeclockSuccess, (state, action) => {
		return {
			...state,
			timeClock: action.payload,
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true,
			loadError: null,
		} as TimeclockState;
	}),
	on(actions.LoadTimeclockFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as TimeclockState;
	}),

	// Clock In
	// TODO: Move to this being a local action and home page effect triggering it
	on(actions.fromHomePageActions.TimeclockClockIn, (state, action) => {
		return {
			...state,
			clockingIn: true,
			clockInError: null
		} as TimeclockState;
	}),
	on(actions.ClockInSuccess, (state, action) => {
		return {
			...state,
			timeClock: action.payload,
			clockingIn: false,
			clockedIn: true,
			clockedOut: false,
			clockInError: null,
		} as TimeclockState;
	}),
	on(actions.ClockInFail, (state, action) => {
		return {
			...state,
			clockingIn: false,
			clockedIn: false,
			clockInError: action.error,
		} as TimeclockState;
	}),

	// Clock Out
	// TODO: Move to this being a local action and home page effect triggering it
	on(actions.fromHomePageActions.TimeclockClockOut, (state, action) => {
		return {
			...state,
			clockingOut: true,
			clockedOut: false,
			clockOutError: null,
		} as TimeclockState;
	}),
	on(actions.ClockOutSuccess, (state, action) => {
		return {
			...state,
			timeClock: action.payload,
			lastUpdated: toLocalISOString(new Date()),
			clockedIn: false,
			clockingOut: false,
			clockedOut: true,
			clockOutError: null,
		} as TimeclockState;
	}),
	on(actions.ClockOutFail, (state, action) => {
		return {
			...state,
			clockingOut: false,
			clockedOut: false,
			clockOutError: action.error,
		} as TimeclockState;
	}),
);
