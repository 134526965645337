import { createAction, props } from '@ngrx/store';
import { IRegister, IRegisterResult } from 'src/app/interfaces/seacom/register';
import { IUpdatedUser, IUser } from 'src/app/interfaces/seacom/user';

// user action types
export const LOAD_USERS = '[Page Effects] Load Users';
export const LOAD_USERS_CANCELLED = '[Users Effects] Load Users Cancelled';
export const LOAD_USERS_FAIL = '[Users Effects] Load Users Fail';
export const LOAD_USERS_SUCCESS = '[Users Effects] Load Users Success';
export const LOAD_USER = '[Component Effects] Load User';
export const LOAD_USER_FAIL = '[Users Effects] Load User Fail';
export const LOAD_USER_SUCCESS = '[Users Effects] Load User Success';
export const UPDATE_USER = '[SEACOM] Update User';
export const UPDATE_USER_FAIL = '[Users Effects] Update User Fail';
export const UPDATE_USER_SUCCESS = '[Users Effects] Update User Success';
export const REGISTER_USER = '[SEACOM] Register User';
export const REGISTER_USER_FAIL = '[Users Effects] Register User Fail';
export const REGISTER_USER_SUCCESS = '[Users Effects] Register User Success';
export const DELETE_USER = '[SEACOM] Delete User';
export const DELETE_USER_FAIL = '[Users Effects] Delete User Fail';
export const DELETE_USER_SUCCESS = '[Users Effects] Delete User Success';

export const LoadUsers = createAction(
	LOAD_USERS
);

export const LoadUsersCancelled = createAction(
    LOAD_USERS_CANCELLED
);

export const LoadUsersSuccess = createAction(
    LOAD_USERS_SUCCESS,
    props<{
        payload: IUser[]
    }>()
);

export const LoadUsersFail = createAction(
    LOAD_USERS_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadUser = createAction(
	LOAD_USER,
    props<{
        payload: string // ID
    }>()
);

export const LoadUserSuccess = createAction(
    LOAD_USER_SUCCESS,
    props<{
        payload: IUser
    }>()
);

export const LoadUserFail = createAction(
    LOAD_USER_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateUser = createAction(
    UPDATE_USER,
    props<{
        payload: IUpdatedUser
    }>()
);

export const UpdateUserSuccess = createAction(
    UPDATE_USER_SUCCESS,
    props<{
        payload: IUser
    }>()
);

export const UpdateUserFail = createAction(
    UPDATE_USER_FAIL,
    props<{
        payload: any
    }>()
);

export const RegisterUser = createAction(
    REGISTER_USER,
    props<{
        payload: IRegister
    }>()
);

export const RegisterUserSuccess = createAction(
    REGISTER_USER_SUCCESS,
    props<{
        payload: IRegisterResult
    }>()
);

export const RegisterUserFail = createAction(
    REGISTER_USER_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteUser = createAction(
    DELETE_USER,
    props<{
        payload: string // ID
    }>()
);

export const DeleteUserSuccess = createAction(
    DELETE_USER_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteUserFail = createAction(
    DELETE_USER_FAIL,
    props<{
        payload: any
    }>()
);
