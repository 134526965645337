import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  production: boolean = environment.production;
  apiURL: string = environment.apiURL;
  apiTimeout: number = environment.apiTimeout;
  apiCacheTime: number = environment.apiCacheTime;
  weatherApiURL: string = environment.weatherApiURL;
  weatherApiKey: string = environment.weatherApiKey;
  weatherApiTimeout: number = environment.weatherApiTimeout;
  weatherApiCacheTime: number = environment.weatherApiCacheTime;
  version: string = environment.version;
  title: string = environment.title;
  googleMapsKey: string = environment.googleMapsKey;

  constructor() {
  }
}
