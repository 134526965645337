// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { IDistrict, INewDistrict, IUpdatedDistrict } from '../../interfaces/seacom/district';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';


@Injectable({
  providedIn: 'root'
})
export class DistrictsService extends ApiNodeService<IDistrict, INewDistrict, IUpdatedDistrict> {
  node = 'districts';

  constructor(public seacomApiService: SeacomApiService) {
    super(seacomApiService);
  }

}
