// Angular
import { Injectable } from '@angular/core';

// RXJS
import { of } from 'rxjs';
import { catchError, map, concatMap, exhaustMap } from 'rxjs/operators';


// NGRX
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Store
import { SeacomState } from '../reducers';

// Actions
import * as actions from '../actions';

// Selectors
import { selectCurrentUser } from '../selectors/auth.selectors';

// Services
import { TimeclockService } from 'src/app/services/seacom/timeclock.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { selectStationSettingsLastUpdated } from '../selectors/stationsetting.selectors';
import { selectTimeclocksLastUpdated } from '../selectors/timeclock.selectors';


@Injectable()
export class TimeclockEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private timeclockService: TimeclockService
  ) { }

  loadTimeclock$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Timeclocks
        ofType(
          actions.LoadTimeclock
        ),
        concatLatestFrom((action) => this.store.select(selectTimeclocksLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.timeclockService.getTimeclock().pipe(
              map((timeclock) => actions.LoadTimeclockSuccess({ payload: timeclock })),
              catchError((error) => of(actions.LoadTimeclockFail({ payload: error })))
            );
          } else {
            return of(actions.LoadTimeclockCancelled());
          }
        })
      )
  });

  clockIn$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHomePageActions.TimeclockClockIn
        ),
        concatLatestFrom(() => this.store.select(selectCurrentUser)),
        concatMap(([action, user]) => this.timeclockService.clockIn(user.id, action.payload.type, action.payload.start).pipe(
          map((timeclock) => actions.ClockInSuccess({ payload: timeclock })),
          catchError((error) => of(actions.ClockInFail({ error: error })))
        ))
      )
  });

  clockOut$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHomePageActions.TimeclockClockOut
        ),
        concatLatestFrom(() => this.store.select(selectCurrentUser)),
        concatMap(([action, user]) => this.timeclockService.clockOut(user.id, action.payload.end).pipe(
          map((timeclock) => actions.ClockOutSuccess({ payload: timeclock })),
          catchError((error) => of(actions.ClockOutFail({ error: error })))
        ))
      )
  });
}
