import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IMetadata } from '../../interfaces/seacom/metadata';

import * as actions from '../actions';
import { updateItems, updateItem, addItem, deleteItem } from '../utils/itementity';


export const metadataFeatureKey = 'metadata';

export interface MetadataState {
	entities: IItemEntity<IMetadata>,
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const MetadataInitialState: MetadataState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

export const MetadataReducer = createReducer(
	MetadataInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...MetadataInitialState
		} as MetadataState;
	}),
	// Load Metadatas
	on(actions.LoadMetadatas, (state, action) => {
		return {
			...state,
			loading: true,
		} as MetadataState;
	}),
	on(actions.LoadMetadatasCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as MetadataState;
	}),
	on(actions.LoadMetadatasSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as MetadataState;
	}),
	on(actions.LoadMetadatasFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as MetadataState;
	}),

	// Load Metadata
	on(actions.LoadMetadata, (state, action) => {
		return {
			...state,
			loading: true,
		} as MetadataState;
	}),
	on(actions.LoadMetadataSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as MetadataState;
	}),
	on(actions.LoadMetadataFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as MetadataState;
	}),

	// Update Metadata
	on(actions.UpdateMetadata, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateMetadataSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateMetadataFieldsSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateMetadataFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),
	on(actions.UpdateMetadataFieldsFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),

	// Add Metadata
	on(actions.AddMetadata, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddMetadataSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddMetadataFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),

	// Delete Metadata
	on(actions.DeleteMetadata, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteMetadataSuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteMetadataFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	}),
);
