// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { ITimeclock } from '../../interfaces/seacom/timeclock';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';
import { AsyncSubject, Observable, Subscription, timer } from 'rxjs';
import { skipNull, takeFirstReal, toLocalISOString } from 'src/app/common/globals';
import { HttpResponse } from '@angular/common/http';
import { IClockIn } from 'src/app/interfaces/seacom/clockin';
import { IClockOut } from 'src/app/interfaces/seacom/clockout';
import { SeacomState } from 'src/app/store';
import { Store } from '@ngrx/store';
import { selectTimeclock } from 'src/app/store/selectors/timeclock.selectors';
import { first } from 'rxjs/operators';
import { LoadTimeclockSuccess } from 'src/app/store/actions/timeclock.actions';


@Injectable({
  providedIn: 'root'
})
export class TimeclockService extends ApiNodeService<ITimeclock, ITimeclock, ITimeclock> {
  node = 'timeclock';
  clockedIn: boolean;
  changedTime: Date;
  timerSub: Subscription;
  initialTodayMinutes: number;
  initialWeeklyMinutes: number;
  initialTodayHours: number;
  initialWeeklyHours: number;
  initialclocked_in_hours: number;
  initialclocked_in_minutes: number;
  todayMinutes: number;
  weeklyMinutes: number;
  todayHours: number;
  weeklyHours: number;
  clocked_in_hours: number;
  clocked_in_minutes: number;

  constructor(
    private store: Store<SeacomState>,
    public seacomApiService: SeacomApiService
    ) {
    super(seacomApiService);
  }

  private updateLocalTime(timeClock: ITimeclock) {
    this.changedTime = new Date();
    this.clockedIn = timeClock.status === 'clocked_in';
    this.initialTodayMinutes = timeClock.today_minutes;
    this.initialWeeklyMinutes = timeClock.weekly_minutes;
    this.initialTodayHours = timeClock.today_hours;
    this.initialWeeklyHours = timeClock.weekly_hours;
    this.initialclocked_in_hours = timeClock.clocked_in_hours;
    this.initialclocked_in_minutes = timeClock.clocked_in_minutes;
    this.todayHours = timeClock.today_hours;
    this.todayMinutes = timeClock.today_minutes;
    this.weeklyHours = timeClock.weekly_hours;
    this.weeklyMinutes = timeClock.weekly_minutes;
    this.clocked_in_hours = timeClock.clocked_in_hours;
    this.clocked_in_minutes = timeClock.clocked_in_minutes;
  }

  private stopTimer() {
    try {
      this.timerSub.unsubscribe();
    } catch {}
  }

  private startTimer() {
    try {
      this.timerSub.unsubscribe();
    } catch {}
    this.timerSub = timer(30000,30000).subscribe(
      () => {
        this.store.select(selectTimeclock).pipe(skipNull(), first()).subscribe(
          (ct) => {
            if(ct.status === 'clocked_in') {
              let now = new Date().getTime();
              let then = this.changedTime.getTime();
              this.todayHours = this.initialTodayHours + ((now-then)/(60*60*1000));
              this.todayMinutes = this.initialTodayMinutes + ((now-then)/(60*1000));
              this.weeklyHours = this.initialWeeklyHours + ((now-then)/(60*60*1000));
              this.weeklyMinutes = this.initialWeeklyMinutes + ((now-then)/(60*60*1000));
              this.clocked_in_hours = this.initialclocked_in_hours + ((now-then)/(60*60*1000));
              this.clocked_in_minutes = this.initialclocked_in_minutes + ((now-then)/(60*1000));
              let newTc = {
                ...ct,
                todayHours: this.todayHours,
                todayMinutes: this.todayMinutes,
                weeklyHours: this.weeklyHours,
                weeklyMinutes: this.weeklyMinutes,
                clocked_in_hours: this.clocked_in_hours,
                clocked_in_minutes: this.clocked_in_minutes
              } as ITimeclock;
              this.store.dispatch(LoadTimeclockSuccess({ payload: newTc }));
            }
          }
        )
        
      }

    )
  }

  public getTimeclock() {
    let getResult = new AsyncSubject<ITimeclock>();
    this.seacomApi.getSpecificItem(this.node, '').pipe(skipNull()).subscribe(
      (res: HttpResponse<any>) => {
        if (res) {
          switch (res.status) {
            case 200:
              if (res.body !== undefined) {
                let tc = res.body as ITimeclock;
                this.updateLocalTime(tc);
                this.startTimer();
                getResult.next(tc);
              } else {
                getResult.error(true);
              }
              getResult.complete();
              break;
            default:
              getResult.error(res.body);
              getResult.complete();
              break;
          }
        }
      },
      (err) => {
        getResult.error(err);
        getResult.complete();
      }
    );

    return getResult.asObservable().pipe(skipNull());
  }

  public clockIn(userId: string, type: string, start: Date): Observable<ITimeclock> {
    let clockInResult = new AsyncSubject<ITimeclock>();
    const clockInData: IClockIn = {
      user: userId,
      type: type,
      start: toLocalISOString(start)
    }
    this.seacomApi.createItem(this.node + '/clockin', clockInData).pipe(takeFirstReal()).subscribe(
      (res: HttpResponse<any>) => {
        switch (res.status) {
          case 201:
            if (res.body !== undefined) {
              let tc = res.body as ITimeclock;
              this.stopTimer();
              this.updateLocalTime(tc);
              this.startTimer();
              clockInResult.next(tc);
            } else {
              clockInResult.next(null);
            }
            clockInResult.complete();
            break;
          default:
            clockInResult.error(res.body);
            clockInResult.complete();
            break;
        }
      },
      (err) => {
        clockInResult.error(err);
        clockInResult.complete();
      }
    );
    return clockInResult.asObservable();
  }

  public clockOut(userId: string, end: Date): Observable<ITimeclock> {
    let clockOutResult = new AsyncSubject<ITimeclock>();
    const clockOutData: IClockOut = {
      user: userId,
      end: toLocalISOString(end)
    }
    this.seacomApi.createItem(this.node + '/clockout', clockOutData).pipe(takeFirstReal()).subscribe(
      (res: HttpResponse<any>) => {
        switch (res.status) {
          case 201:
            if (res.body !== undefined) {
              this.stopTimer();
              clockOutResult.next(res.body as ITimeclock);
            } else {
              clockOutResult.next(null);
            }
            clockOutResult.complete();
            break;
          default:
            clockOutResult.error(res.body);
            clockOutResult.complete();
            break;
        }
      },
      (err) => {
        clockOutResult.error(err);
        clockOutResult.complete();
      }
    );
    return clockOutResult.asObservable();
  }
}
