// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { IContainerSetting, INewContainerSetting, IUpdatedContainerSetting } from 'src/app/interfaces/seacom/containersetting';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';


@Injectable({
  providedIn: 'root'
})
export class ContainerSettingsService extends ApiNodeService<IContainerSetting, INewContainerSetting, IUpdatedContainerSetting> {
  node = 'containersettings';

  constructor(public seacomApiService: SeacomApiService) {
    super(seacomApiService);
  }
}
