import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IIncidentResponse } from '../../interfaces/seacom/incidentresponse';

import * as actions from '../actions';
import { addItem, deleteItem, updateItem, updateItems } from '../utils/itementity';


export const incidentResponseFeatureKey = 'incidentresponses';

export interface IncidentResponseState {
	entities: { [id: string]: IIncidentResponse },
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const IncidentResponseInitialState: IncidentResponseState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

export const IncidentResponseReducer = createReducer(
	IncidentResponseInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...IncidentResponseInitialState
		} as IncidentResponseState;
	}),
	// Load IncidentResponses
	on(actions.LoadIncidentResponses, (state, action) => {
		return {
			...state,
			loading: true,
			loaded: false,
			loadError: null
		} as IncidentResponseState;
	}),
	on(actions.LoadIncidentResponsesCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as IncidentResponseState;
	}),
	on(actions.LoadIncidentResponsesSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as IncidentResponseState;
	}),
	on(actions.LoadIncidentResponsesFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as IncidentResponseState;
	}),

	// Load IncidentResponse
	on(actions.LoadIncidentResponse, (state, action) => {
		return {
			...state,
			loading: true,
		} as IncidentResponseState;
	}),
	on(actions.UpdateMetadataFieldsSuccess, (state, action) => {
		return {
			...state,
			loading: true,
		} as IncidentResponseState;
	}),
	on(actions.AddMetadataSuccess, (state, action) => {
		return {
			...state,
			loading: true,
		} as IncidentResponseState;
	}),
	on(actions.LoadIncidentResponseSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as IncidentResponseState;
	}),
	on(actions.LoadIncidentResponseFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as IncidentResponseState;
	}),

	// Update IncidentResponse
	on(actions.UpdateIncidentResponse, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.fromOperationsPageActions.EndStationResponse, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateIncidentResponseSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.EndStationResponseSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateIncidentResponseFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),
	on(actions.EndStationResponseFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),
	// Add IncidentResponse
	on(actions.AddIncidentResponse, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.fromOperationsPageActions.AddStationIncidentResponse, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.fromOperationsPageActions.TransferIncidentToStation, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddQuickIncidentSuccess, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddIncidentResponseSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.fromOperationsPageActions.AddStationIncidentResponse, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.StationIncidentTransferSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddStationIncidentResponseSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddQuickIncidentResponseSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddIncidentResponseFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),
	on(actions.AddStationIncidentResponseFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),
	on(actions.AddQuickIncidentResponseFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),
	on(actions.StationIncidentTransferFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),

	// Delete IncidentResponse
	on(actions.DeleteIncidentResponse, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteIncidentResponseSuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteIncidentResponseFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	}),

);
