// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

// RXJS
import { Observable, AsyncSubject, of, Subscription, throwError, interval } from 'rxjs';
import { map, timeout, exhaustMap, catchError, last, switchMap, withLatestFrom, first } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Services
import { EnvironmentService } from './environment.service';

// Interfaces
import { IAuthTokenSet } from '../interfaces/seacom/authtokenset';
import { ICredentials } from '../interfaces/seacom/credentials';
import { IAuthTokenPayload } from '../interfaces/seacom/authtokenpayload';
import { IUser } from '../interfaces/seacom/user';
import { IOrganization } from '../interfaces/seacom/organization';
import { IUserRole } from '../interfaces/seacom/userrole';
import { ISeacomRole } from '../interfaces/seacom/seacomrole';

// Globals
import { skipNull } from '../common/globals';

// Store
import { SeacomState } from '../store';

// Actions
import * as fromActions from '../store/actions';

// Selectors
import { selectTokens, selectAccessTokenExpires, selectRefreshTokenExpires } from '../store/selectors/auth.selectors';



@Injectable({
	providedIn: 'root'
})
export class AuthService implements OnDestroy {
	private tokenGrace = 30000; // 30 seconds

	private tokenExpirationSub: Subscription;

	// Constructor
	constructor(
		private environmentService: EnvironmentService,
		private store: Store<SeacomState>,
		private httpClient: HttpClient
	) {	}

	ngOnDestroy(): void {
		this.tokenExpirationSub.unsubscribe();
	}

	public subscribeForTokenExp(): void {
		// Remove any previous subscriptions
		if (this.tokenExpirationSub) { this.tokenExpirationSub.unsubscribe(); }

		this.tokenExpirationSub = interval(5000)
			.pipe(
				switchMap(() => {
					return this.store.select(selectAccessTokenExpires)
						.pipe(
							first(),
							withLatestFrom(this.store.select(selectRefreshTokenExpires).pipe(first())),
						);
				})
			)
			.subscribe(
				([ate, rte]) => {
					if (!ate || !rte) { return; }

					const atExp = ate * 1000;
					const rtExp = rte * 1000;
					const curDate = (new Date()).getTime();
					const graceDate = curDate + this.tokenGrace;

					/* console.log(atExp);
					console.log(rtExp);
					console.log(curDate);
					console.log(graceDate); */

					if (curDate >= atExp || curDate >= rtExp) {
						// Tokens expired...proceed to session timeout
						if (!this.environmentService.production) {
							console.log("Tokens expired...proceeding to session timeout");
						}

						this.tokenExpirationSub.unsubscribe();

						this.store.dispatch(fromActions.ReturnToLogin({
							payload: {
								loginError: "Your session has timed out.",
								apiError: ""
							}
						}));
					} else if (graceDate >= atExp || graceDate >= rtExp) {
						// Tokens expiring soon...proceed to token refresh
						if (!this.environmentService.production) {
							console.log("Tokens expiring soon...proceeding to token refresh");
						}

						this.tokenExpirationSub.unsubscribe();

						this.store.dispatch(fromActions.RefreshTokens());
					} /* else {
						// Tokens are good for now
						if (!this.environmentService.production) {
							console.log("Tokens are good");
						}
					} */
				}
			);
	}

	public unsubscribeForTokenExp() {
		try {
			this.tokenExpirationSub.unsubscribe();
		} catch {}
	}

	public checkTokenSanity(tokens: IAuthTokenSet): boolean {
		try {
			let accessTokenJWTPayload = this.decodeJwt(tokens.access);
			let refreshTokenJWTPayload = this.decodeJwt(tokens.refresh);

			if (
				!accessTokenJWTPayload.hasOwnProperty('exp') ||
				!refreshTokenJWTPayload.hasOwnProperty('exp')
			) {
				return false;
			}

			let accessTokenExp = new Date(accessTokenJWTPayload.exp * 1000);
			let refreshTokenExp = new Date(refreshTokenJWTPayload.exp * 1000);
			let curDateTimestamp = (new Date()).getTime();

			if (
				accessTokenExp.getTime() < curDateTimestamp ||
				refreshTokenExp.getTime() < curDateTimestamp
			) {
				// One or more tokens is expired
				return false;
			}
		} catch {
			// If there was an exception, there is a very very good possibility it was due to the tokens being bad
			return false;
		}

		return true;
	}

	public tokensAreSane(tokens: IAuthTokenSet): Observable<boolean> {
		return of(this.checkTokenSanity(tokens));
	}

	private parseUserFromJwt(jwtMap: IAuthTokenPayload): Partial<IUser> {
		let user: Partial<IUser> = {
			// user role
			role: {
				name: jwtMap.role_name,
				// SeacomRole
				role: (
					{
						name: jwtMap.role_type.split('_')[0]
					} as Partial<ISeacomRole>
				)
			} as Partial<IUserRole>,
			// user id
			id: jwtMap.user_id,
			// email
			email: jwtMap.email,
			// first name
			first_name: jwtMap.first_name,
			// last name
			last_name: jwtMap.last_name,
			// start screen
			start_screen: jwtMap.start_screen,
			// organization
			organization: {
				id: jwtMap.organization_id,
				name: jwtMap.organization
			} as Partial<IOrganization>,
			is_verified: jwtMap.is_verified
		}

		return user;
	}

	public checkTokenExpiration(): Observable<boolean> {
		return this.store.select(selectTokens)
			.pipe(
				first(),
				map((tokens) => {
					if (tokens !== undefined || tokens !== null) {
						let jwtPayload = this.decodeJwt(tokens.access);
						let expiryDate = jwtPayload.exp * 1000;
						if ((new Date()).getTime() > expiryDate) {
							return false; // Token expired
						} else {
							return true; // Token still good
						}
					} else {
						return false; // No token in store?
					}
				})
			);
	}

	// Request login with credentials
	public checkAPIReachability(): Observable<boolean> {
		return this.httpClient.get<any>(this.environmentService.apiURL + 'ping', { responseType: 'json', observe: 'response' }).pipe(
			timeout(this.environmentService.apiTimeout),
			switchMap((response: HttpResponse<any>) =>
				response.status === 200
					? of(true)
					: of(false)
			),
			catchError((err) => of(false))
		);
	}

	// Request login with credentials
	public login(email: string, password: string): Observable<{ user: IUser, accessToken: string, refreshToken: string, accessExpiresAt: number, refreshExpiresAt: number }> {
		if (!this.environmentService.production) {
			console.log('AuthService: Making POST request to: ' + this.environmentService.apiURL + 'accounts/login');
			console.log('email: ' + email);
			console.log('password: ' + password);
		}
		const creds: ICredentials = {
			email: email,
			password: password
		};
		return this.httpClient.post<IAuthTokenSet>(
			this.environmentService.apiURL + 'accounts/login',
			creds,
			{ responseType: 'json', observe: 'response' })
			.pipe(
				timeout(this.environmentService.apiTimeout),
				switchMap((response: HttpResponse<IAuthTokenSet>) => {
					switch (response.status) {
						case 200:
							let tokens = response.body as IAuthTokenSet;
							let accessJWTPayload = this.decodeJwt(tokens.access);
							let refreshJWTPayload = this.decodeJwt(tokens.refresh);
							let partialUser = this.parseUserFromJwt(accessJWTPayload);
							return this.getUserRecord(partialUser.id, tokens.access).pipe(
								last(),
								first(),
								map((user) => {
									return {
										user: user,
										accessToken: tokens.access,
										refreshToken: tokens.refresh,
										accessExpiresAt: accessJWTPayload.exp,
										refreshExpiresAt: refreshJWTPayload.exp
									};
								}),
								catchError(() => {
									throw new Error("Unable to retrieve user profile!");
								})
							);
							break;
						default:
							if ((response as any)?.error?.detail) {
								throw new Error((response as any).error.detail);
							} else {
								throw new Error("Unknown error occurred during login. Please try again momentarily.");
							}
							break;
					}
				}),
				catchError((err) => {
					if (err?.error?.detail) {
						throw new Error(err.error.detail);
					} else {
						throw new Error("Unknown error occurred during login. Please try again momentarily.");
					}
				})
			);
	}

	// Request login with tokens
	public loginWithTokens(tokens: IAuthTokenSet): Observable<{ user: IUser, accessToken: string, refreshToken: string, accessExpiresAt: number, refreshExpiresAt: number }> {
		if (!this.environmentService.production) {
			console.log('AuthService: Making POST request to: ' + this.environmentService.apiURL + 'accounts/login/refresh');
		}
		const authHeaders = {
			Authorization: 'Bearer ' + tokens.access
		};
		return this.httpClient.post<IAuthTokenSet>(
			this.environmentService.apiURL + 'accounts/login/refresh',
			{ refresh: tokens.refresh },
			{ headers: authHeaders, observe: 'response' })
			.pipe(
				timeout(this.environmentService.apiTimeout),
				switchMap((response) => {
					switch (response.status) {
						case 200:
							let tokens = response.body as IAuthTokenSet;
							let accessJWTPayload = this.decodeJwt(tokens.access);
							let refreshJWTPayload = this.decodeJwt(tokens.refresh);
							let partialUser = this.parseUserFromJwt(accessJWTPayload);
							return this.getUserRecord(partialUser.id, tokens.access).pipe(
								last(),
								first(),
								map((user) => {
									return {
										user: user,
										accessToken: tokens.access,
										refreshToken: tokens.refresh,
										accessExpiresAt: accessJWTPayload.exp,
										refreshExpiresAt: refreshJWTPayload.exp
									};
								}),
								catchError(() => {
									return throwError("Unable to retrieve user profile!");
								})
							);
							break;
						default:
							if ((response as any)?.error?.detail) {
								throw new Error((response as any).error.detail);
							} else {
								throw new Error("Unknown error occurred during login. Please try again momentarily.");
							}
							break;
					}
				}),
				catchError((err) => {
					if (err?.error?.detail) {
						throw new Error(err.error.detail);
					} else {
						throw new Error("Unknown error occurred during login. Please try again momentarily.");
					}
				})
			);
	}

	// Process logout request with server
	public logout(): Observable<boolean> {
		return this.store.select(selectTokens).pipe(
			first(),
			exhaustMap((tokens) => this.httpClient.post<any>(
				this.environmentService.apiURL + 'accounts/logout',
				{ refresh: tokens.refresh },
				{
					headers: {
						Authorization: 'Bearer ' + tokens.access
					},
					observe: 'response'
				}
			)),
			catchError(() => of(false)),
			map((response: HttpResponse<any>): boolean => {
				if (response.status === 205) {
					return true;
				}

				return false;
			})
		);
	}

	public refreshTokens(tokens: IAuthTokenSet): Observable<{ user: IUser, accessToken: string, refreshToken: string, accessExpiresAt: number, refreshExpiresAt: number }> {
		const authHeaders = {
			Authorization: 'Bearer ' + tokens.access
		};
		return this.httpClient.post<IAuthTokenSet>(
			this.environmentService.apiURL + 'accounts/login/refresh',
			{ refresh: tokens.refresh },
			{ headers: authHeaders, observe: "response" })
			.pipe(
				timeout(this.environmentService.apiTimeout),
				map((response) => {
					switch (response.status) {
						case 200:
							const tokens = response.body as IAuthTokenSet;
							let accessJWTPayload = this.decodeJwt(tokens.access);
							let refreshJWTPayload = this.decodeJwt(tokens.refresh);
							return {
								user: undefined,
								accessToken: tokens.access,
								refreshToken: tokens.refresh,
								accessExpiresAt: accessJWTPayload.exp,
								refreshExpiresAt: refreshJWTPayload.exp
							};
							break;
						default:
							if ((response as any)?.error?.detail) {
								throw new Error((response as any).error.detail);
							} else {
								throw new Error("Unknown error occurred during refresh.");
							}
							break;
					}
				}),
				catchError((err) => {
					if (err?.error?.detail) {
						throw new Error(err.error.detail);
					} else {
						throw new Error("Unknown error occurred during refresh.");
					}
				})
			);
	}

	private decodeJwt(accessToken: string): IAuthTokenPayload {
		var base64Url = accessToken.split('.')[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
		);

		return JSON.parse(jsonPayload) as IAuthTokenPayload;
	}

	private getUserRecord(userId, accessToken: string): Observable<IUser> {
		let resSubject = new AsyncSubject<IUser>();
		if (!this.environmentService.production) {
			console.log('AuthService: Making GET request to: ' + this.environmentService.apiURL + 'accounts/' + userId);
		}
		let authHeaders = {
			Authorization: 'Bearer ' + accessToken
		};
		this.httpClient.get<HttpResponse<any>>(this.environmentService.apiURL + 'accounts/' + userId, { headers: authHeaders, observe: 'response' })
			.pipe(
				skipNull(),
				timeout(this.environmentService.apiTimeout)
			)
			.subscribe(
				(response: HttpResponse<any>) => {
					switch (response.status) {
						case 200:
							resSubject.next(response.body as IUser);
							resSubject.complete();
						default:
							resSubject.error(response.statusText);
							resSubject.complete();
							break;
					}
				},
				(error: string) => {
					resSubject.error(error);
					resSubject.complete();
				}
			);
		return resSubject.asObservable();
	}
}
