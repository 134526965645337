// NGRX
import { createAction, props } from '@ngrx/store';

// Interfaces
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { IColumnSetting, INewColumnSetting, IUpdatedColumnSetting } from '../../interfaces/seacom/columnsetting';

// Column Settings
export const LOAD_COLUMNSETTINGS = '[Page Effects] Load ColumnSettings';
export const LOAD_COLUMNSETTINGS_CANCELLED = '[Table Effects] Load ColumnSettings Cancelled';
export const LOAD_COLUMNSETTINGS_WITH_FILTERS = '[Component Effects] Load ColumnSettings With Filters';
export const LOAD_COLUMNSETTINGS_FAIL = '[Table Effects] Load ColumnSettings Fail';
export const LOAD_COLUMNSETTINGS_SUCCESS = '[Table Effects] Load ColumnSettings Success';
export const LOAD_COLUMNSETTING = '[Table Effects] Load ColumnSetting';
export const LOAD_COLUMNSETTING_FAIL = '[Table Effects] Load ColumnSetting Fail';
export const LOAD_COLUMNSETTING_SUCCESS = '[Table Effects] Load ColumnSetting Success';
export const UPDATE_COLUMNSETTING = '[Page Effects] Update ColumnSetting';
export const UPDATE_COLUMNSETTING_FIELDS = '[SEACOM] Update ColumnSetting Fields';
export const UPDATE_COLUMNSETTING_FAIL = '[Table Effects] Update ColumnSetting Fail';
export const UPDATE_COLUMNSETTING_SUCCESS = '[Table Effects] Update ColumnSetting Success';
export const REORDER_COLUMNSETTINGS = '[Page Effects] Reorder ColumnSettings';
export const ADD_COLUMNSETTING = '[Page Effects] Add ColumnSetting';
export const ADD_COLUMNSETTING_FAIL = '[Table Effects] Add ColumnSetting Fail';
export const ADD_COLUMNSETTING_SUCCESS = '[Table Effects] Add ColumnSetting Success';
export const DELETE_COLUMNSETTING = '[Page Effects] Delete ColumnSetting';
export const DELETE_COLUMNSETTING_FAIL = '[Table Effects] Delete ColumnSetting Fail';
export const DELETE_COLUMNSETTING_SUCCESS = '[Table Effects] Delete ColumnSetting Success';

// Column Selector
export const OPEN_COLUMN_SELECTOR = '[Page Effects] Open Column Selector';
export const CLOSE_COLUMN_SELECTOR = '[Page Effects] Close Column Selector';
export const TOGGLE_COLUMN_SELECTOR = '[Page Effects] Toggle Column Selector';

// Table Row Expansion
export const EXPAND_TABLE_ROW = '[Page Effects] Expand Table Row';
export const COLLAPSE_TABLE_ROW = '[Page Effects] Collapse Table Row';
export const TOGGLE_TABLE_ROW_EXPANSION = '[Page Effects] Toggle Table Row Expansion';

// Column Filters
export const REPLACE_COLUMN_FILTERS = '[Page Effects] Replace Column Filters';
export const REPLACE_TABLE_FILTERS = '[Page Effects] Replace Table Filters';
export const REMOVE_COLUMN_FILTER = '[Page Effects] Remove Column Filter';
export const REMOVE_TABLE_FILTERS = '[Page Effects] Remove Table Filters';


// Column Setting Actions

export const LoadColumnSettings = createAction(
    LOAD_COLUMNSETTINGS
);

export const LoadColumnSettingsCancelled = createAction(
    LOAD_COLUMNSETTINGS_CANCELLED
);

export const LoadColumnSettingsWithFilters = createAction(
    LOAD_COLUMNSETTINGS_WITH_FILTERS,
    props<{
        filters?: IFieldFilter[],
        order?: string,
        search?: string
    }>()
);

export const LoadColumnSettingsSuccess = createAction(
    LOAD_COLUMNSETTINGS_SUCCESS,
    props<{
        payload: IColumnSetting[]
    }>()
);

export const LoadColumnSettingsFail = createAction(
    LOAD_COLUMNSETTINGS_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadColumnSetting = createAction(
    LOAD_COLUMNSETTING,
    props<{
        payload: string // ID
    }>()
);

export const LoadColumnSettingSuccess = createAction(
    LOAD_COLUMNSETTING_SUCCESS,
    props<{
        payload: IColumnSetting
    }>()
);

export const LoadColumnSettingFail = createAction(
    LOAD_COLUMNSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateColumnSetting = createAction(
    UPDATE_COLUMNSETTING,
    props<{
        payload: IUpdatedColumnSetting
    }>()
);

export const UpdateColumnSettingFields = createAction(
    UPDATE_COLUMNSETTING_FIELDS,
    props<{
        payload: IUpdatedColumnSetting
    }>()
);

export const UpdateColumnSettingSuccess = createAction(
    UPDATE_COLUMNSETTING_SUCCESS,
    props<{
        payload: IColumnSetting
    }>()
);

export const UpdateColumnSettingFail = createAction(
    UPDATE_COLUMNSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const AddColumnSetting = createAction(
    ADD_COLUMNSETTING,
    props<{
        payload: INewColumnSetting
    }>()
);

export const AddColumnSettingSuccess = createAction(
    ADD_COLUMNSETTING_SUCCESS,
    props<{
        payload: IColumnSetting
    }>()
);

export const AddColumnSettingFail = createAction(
    ADD_COLUMNSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteColumnSetting = createAction(
    DELETE_COLUMNSETTING,
    props<{
        payload: string // ID
    }>()
);

export const DeleteColumnSettingSuccess = createAction(
    DELETE_COLUMNSETTING_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteColumnSettingFail = createAction(
    DELETE_COLUMNSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const ReorderColumnSettings = createAction(
    REORDER_COLUMNSETTINGS,
    props<{
        payload: {
            container: string,
            component: string,
            table: string,
            newOrder: string[],
            from: number,
            to: number
        }
    }>()
);

/*
*
* Column Selector Actions
*
*/

export const OpenColumnSelector = createAction(
    OPEN_COLUMN_SELECTOR,
    props<{
        payload: {
            container: string,
            component: string,
            table: string
        }
    }>()
);

export const CloseColumnSelector = createAction(
    CLOSE_COLUMN_SELECTOR,
    props<{
        payload: {
            container: string,
            component: string,
            table: string
        }
    }>()
);

export const ToggleColumnSelector = createAction(
    TOGGLE_COLUMN_SELECTOR,
    props<{
        payload: {
            container: string,
            component: string,
            table: string
        }
    }>()
);

/*
*
* Row Expansion Actions
*
*/

export const ExpandTableRow = createAction(
    EXPAND_TABLE_ROW,
    props<{
        payload: {
            container: string,
            component: string,
            table: string,
            entityId: string
        }
    }>()
);

export const CollapseTableRow = createAction(
    COLLAPSE_TABLE_ROW,
    props<{
        payload: {
            container: string,
            component: string,
            table: string,
            entityId: string
        }
    }>()
);

export const ToggleTableRowExpansion = createAction(
    TOGGLE_TABLE_ROW_EXPANSION,
    props<{
        payload: {
            container: string,
            component: string,
            table: string,
            entityId: string
        }
    }>()
);


/*
*
* Column Filter Actions
*
*/

export const ReplaceColumnFilters = createAction(
    REPLACE_COLUMN_FILTERS,
    props<{
        payload: {
            container: string,
            component: string,
            table: string,
            newFilters: IFieldFilter[]
        }
    }>()
);

export const ReplaceTableFilters = createAction(
    REPLACE_TABLE_FILTERS,
    props<{
        payload: {
            container: string,
            component: string,
            table: string,
            newFilters: IFieldFilter[]
        }
    }>()
);

export const RemoveColumnFilter = createAction(
    REMOVE_COLUMN_FILTER,
    props<{
        payload: {
            container: string,
            component: string,
            table: string,
            column: string
        }
    }>()
);

export const RemoveTableFilters = createAction(
    REMOVE_TABLE_FILTERS,
    props<{
        payload: {
            container: string,
            component: string,
            table: string
        }
    }>()
);
