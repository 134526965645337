import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-stations-widget',
  templateUrl: './stations-widget.component.html',
  styleUrls: ['../widget.styles.scss', './stations-widget.component.scss']
})
export class StationsWidgetComponent implements OnInit {
  @Input() brightness$: Observable<'light'|'dark'>;
  @Input('InServiceStationsCount') inServiceStationsCount: number;
  @Input('OutOfServiceStationsCount') outOfServiceStationsCount: number;
  @Input('TotalStationsCount') totalStationsCount: number;
  @Input('Loading$') stationsLoading$: Observable<boolean>;
  @Output() hideWidgetEvent = new EventEmitter<string>();
  @Output() navigateToStationsEvent = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  navigateToStationsPage(event) {
    event.stopPropagation();
    this.navigateToStationsEvent.emit();
  }

  hideWidget() {
    this.hideWidgetEvent.emit('stations');
  }
}
