// Angular
import { Injectable } from '@angular/core';

// RXJS
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// State
import { SeacomState } from 'src/app/store';

// Actions
import * as actions from '../../../store/actions';



@Injectable()
export class LoginPageEffects {

  constructor(private actions$: Actions, private store: Store<SeacomState>) { }

  enter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromLoginPageActions.Enter
        ),
        map((action) => {
          return;
        }),
      )
  },
  { dispatch: false }
  );

  login$ = createEffect(() => {
    return this.actions$
    .pipe(
      ofType(
        actions.fromLoginPageActions.Login
      ),
      switchMap((action) => of(actions.UserLogin({ credentials: action.payload.credentials })))
    )
  });
}
