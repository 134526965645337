import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducers from './home.page.reducers';
import { IWidget } from 'src/app/interfaces/widget';
import { selectCurrentUserRole } from '../../../store/selectors/auth.selectors';

export const selectHomepageState = createFeatureSelector<fromReducers.HomepageState>(
  fromReducers.homepageFeatureKey
);

export const selectWidgets = createSelector(
  selectHomepageState,
  (state: fromReducers.HomepageState): IWidget[] => state.widgets
);

export const selectAllWidgets = createSelector(
  selectCurrentUserRole,
  selectWidgets,
  (cuRole: string, widgets: IWidget[]): IWidget[] => {
    return widgets.filter(w => w.roles === undefined || w.roles.includes(cuRole));
  }
);

export const selectDisplayedWidgets = createSelector(
  selectAllWidgets,
  (widgets: IWidget[]): IWidget[] => {
    return widgets
      .filter(w => w.visible);
  }
)
