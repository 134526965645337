import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FooterbarComponent } from './footerbar.component';
import { StoreModule } from '@ngrx/store';
import { locationFeatureKey, LocationReducer } from 'src/app/store/reducers/location.reducers';
import { ambianceFeatureKey } from 'src/app/store/reducers/ambiance.reducers';
import { AmbianceReducer } from '../../store/reducers/ambiance.reducers';
import { EffectsModule } from '@ngrx/effects';
import { LocationEffects } from 'src/app/store/effects/location.effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        StoreModule.forFeature(locationFeatureKey, LocationReducer),
        StoreModule.forFeature(ambianceFeatureKey, AmbianceReducer),
        EffectsModule.forFeature([LocationEffects])
    ],
    declarations: [
        FooterbarComponent,
    ],
    exports: [
        FooterbarComponent,
    ]
})

export class FooterbarModule { }
