// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Component
import { OperationsMapComponent } from './operations-map.component';
import { EnvironmentService } from 'src/app/services/environment.service';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    EnvironmentService
  ],
  declarations: [
    OperationsMapComponent
  ],
  exports: [
    OperationsMapComponent
  ]
})
export class OperationsMapModule { }
