// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../actions';

// Services
import { IncidentSeveritiesService } from 'src/app/services/seacom/incidentseverities.service';
import { Store } from '@ngrx/store';
import { SeacomState } from '../reducers';
import { EnvironmentService } from 'src/app/services/environment.service';
import { selectIncidentSeveritiesLastUpdated } from '../selectors/incidentseverity.selectors';

@Injectable()
export class IncidentSeverityEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private incidentSeveritiesService: IncidentSeveritiesService
  ) { }

  loadIncidentSeverities$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load IncidentSeverities
        ofType(
          actions.LoadIncidentSeverities
        ),
        concatLatestFrom((action) => this.store.select(selectIncidentSeveritiesLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.incidentSeveritiesService.getList().pipe(
              map((incidentSeverities) => actions.LoadIncidentSeveritiesSuccess({ payload: incidentSeverities })),
              catchError((error) => of(actions.LoadIncidentSeveritiesFail(error)))
            );
          } else {
            return of(actions.LoadIncidentSeveritiesCancelled());
          }
        })
      );
  });
  loadIncidentSeverity$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load IncidentSeverity
        ofType(actions.LoadIncidentSeverity),
        exhaustMap((action) =>
          this.incidentSeveritiesService.get(action.payload).pipe(
            map((incidentSeverity) => actions.LoadIncidentSeveritySuccess({ payload: incidentSeverity })),
            catchError((error) => of(actions.LoadIncidentSeveritiesFail(error)))
          )
        )
      );
  });
  addIncidentSeverity$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add IncidentSeverity
        ofType(actions.AddIncidentSeverity),
        concatMap((action) =>
          this.incidentSeveritiesService.create(action.payload).pipe(
            map((incidentSeverity) => actions.AddIncidentSeveritySuccess({ payload: incidentSeverity })),
            catchError((error) => of(actions.AddIncidentSeverityFail(error)))
          )
        )
      );
  });
  updateIncidentSeverity$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update IncidentSeverity
        ofType(actions.UpdateIncidentSeverity),
        concatMap((action) =>
          this.incidentSeveritiesService.update(action.payload.id, action.payload).pipe(
            map((incidentSeverity) => actions.UpdateIncidentSeveritySuccess({ payload: incidentSeverity })),
            catchError((error) => of(actions.UpdateIncidentSeverityFail(error)))
          )
        )
      );
  });
  deleteIncidentSeverity$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete IncidentSeverity
        ofType(actions.DeleteIncidentSeverity),
        mergeMap((action) =>
          this.incidentSeveritiesService.delete(action.payload).pipe(
            map((incidentSeverityId) => actions.DeleteIncidentSeveritySuccess({ payload: incidentSeverityId })),
            catchError((error) => of(actions.DeleteIncidentSeverityFail(error)))
          )
        )
      )
  });
}
