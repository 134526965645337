// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// State
import { SeacomState } from '../reducers';

// Actions
import * as actions from '../actions';

// Selectors
import { selectStationsLastUpdated } from '../selectors/station.selectors';

// Services
import { StationsService } from 'src/app/services/seacom/stations.service';
import { EnvironmentService } from 'src/app/services/environment.service';


@Injectable()
export class StationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private stationsService: StationsService
  ) { }
  
  loadStations$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Stations
        ofType(
          actions.LoadStations
        ),
        concatLatestFrom((action) => this.store.select(selectStationsLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.stationsService.getList().pipe(
              map((stations) => actions.LoadStationsSuccess({ payload: stations })),
              catchError((error) => of(actions.LoadStationsFail(error)))
            );
          } else {
            return of(actions.LoadStationsCancelled());
          }
        })
      );
  });
  loadStation$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Station
        ofType(actions.LoadStation),
        exhaustMap((action) =>
          this.stationsService.get(action.payload).pipe(
            map((station) => actions.LoadStationSuccess({ payload: station })),
            catchError((error) => of(actions.LoadStationsFail(error)))
          )
        )
      );
  });
  addStation$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add Station
        ofType(actions.AddStation),
        concatMap((action) =>
          this.stationsService.create(action.payload).pipe(
            map((station) => actions.AddStationSuccess({ payload: station })),
            catchError((error) => of(actions.AddStationFail(error)))
          )
        )
      );
  });
  updateStation$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Station
        ofType(actions.UpdateStation),
        concatMap((action) =>
          this.stationsService.update(action.payload.id, action.payload).pipe(
            map((station) => actions.UpdateStationSuccess({ payload: station })),
            catchError((error) => of(actions.UpdateStationFail(error)))
          )
        )
      );
  });
  updateStationResponders$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Station
        ofType(actions.fromOperationsPageActions.UpdateStationResponders),
        concatMap((action) =>
          this.stationsService.updateFields(action.payload.stationId, { responders: action.payload.responders }).pipe(
            map((station) => actions.UpdateStationRespondersSuccess({ payload: station })),
            catchError((error) => of(actions.UpdateStationRespondersFail(error)))
          )
        )
      );
  });
  setStationStatus$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Station
        ofType(actions.fromOperationsPageActions.SetStationStatus),
        concatMap((action) =>
          this.stationsService.updateFields(action.payload.stationId, { status: action.payload.status }).pipe(
            map((station) => actions.SetStationStatusSuccess({ payload: station })),
            catchError((error) => of(actions.SetStationStatusFail(error)))
          )
        )
      );
  });

  updateStationFields$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Station
        ofType(actions.UpdateStationFields),
        concatMap((action) =>
          this.stationsService.updateFields(action.payload.id, action.payload).pipe(
            map((station) => actions.UpdateStationSuccess({ payload: station })),
            catchError((error) => of(actions.UpdateStationFail(error)))
          )
        )
      );
  });
  deleteStation$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete Station
        ofType(actions.DeleteStation),
        mergeMap((action) =>
          this.stationsService.delete(action.payload).pipe(
            map((stationId) => actions.DeleteStationSuccess({ payload: stationId })),
            catchError((error) => of(actions.DeleteStationFail(error)))
          )
        )
      )
  });
}
