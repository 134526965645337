import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-operations-widget',
  templateUrl: './operations-widget.component.html',
  styleUrls: ['../widget.styles.scss', './operations-widget.component.scss']
})
export class OperationsWidgetComponent implements OnInit {
  @Input() brightness$: Observable<'light'|'dark'>;
  @Input('OpenIncidentsCount') openIncidentsCount: number;
  @Input('OpenIncidentCountsBySeverity') openIncidentCountsBySeverity: { name: string, count: number, color: string, bgcolor: string }[];
  @Input('Loading$') incidentsLoading$: Observable<boolean>;
  @Output() hideWidgetEvent = new EventEmitter<string>();
  @Output() navigateToOperationsEvent = new EventEmitter<void>();
  
  constructor(
  ) { }

  ngOnInit() {
  }

  hideWidget() {
    this.hideWidgetEvent.emit('operations');
  }

  navigateToOperations() {
    this.navigateToOperationsEvent.emit();
  }
}
