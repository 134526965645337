// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, map, mergeMap, exhaustMap, concatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../actions';

// Services
import { MetadatasService } from 'src/app/services/seacom/metadatas.service';
import { Store } from '@ngrx/store';
import { SeacomState } from '../reducers';
import { EnvironmentService } from 'src/app/services/environment.service';
import { selectMetadatasLastUpdated } from '../selectors/metadata.selectors';

@Injectable()
export class MetadataEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private metadatasService: MetadatasService
  ) { }

  loadMetadatas$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Metadatas
        ofType(
          actions.LoadMetadatas
        ),
        concatLatestFrom((action) => this.store.select(selectMetadatasLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.metadatasService.getList().pipe(
              map((metadatatexts) => actions.LoadMetadatasSuccess({ payload: metadatatexts })),
              catchError((error) => of(actions.LoadMetadatasFail(error)))
            );
          } else {
            return of(actions.LoadMetadatasCancelled());
          }
        })
      )
  });
  loadMetadata$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Metadata
        ofType(
          actions.LoadMetadata
        ),
        exhaustMap((action) =>
          this.metadatasService.get(action.payload).pipe(
            map((metadatatext) => actions.LoadMetadataSuccess({ payload: metadatatext })),
            catchError((error) => of(actions.LoadMetadatasFail(error)))
          )
        )
      )
  });
  addMetadata$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add Metadata
        ofType(
          actions.AddMetadata
        ),
        concatMap((action) =>
          this.metadatasService.create(action.payload).pipe(
            map((metadatatext) => actions.AddMetadataSuccess({ payload: metadatatext })),
            catchError((error) => of(actions.AddMetadataFail(error)))
          )
        )
      )
  });
  updateMetadata$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Metadata
        ofType(
          actions.UpdateMetadata
        ),
        concatMap((action) =>
          this.metadatasService.update(action.payload.id, action.payload).pipe(
            map((metadata) => actions.UpdateMetadataSuccess({ payload: metadata })),
            catchError((error) => of(actions.UpdateMetadataFail(error)))
          )
        ),
      )
  });
  updateMetadataFields$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Metadata
        ofType(
          actions.UpdateMetadataFields
        ),
        concatMap((action) =>
          this.metadatasService.updateFields(action.payload.id, { ...action.payload }).pipe(
            map((metadatatext) => actions.UpdateMetadataFieldsSuccess({ payload: metadatatext })),
            catchError((error) => of(actions.UpdateMetadataFieldsFail(error)))
          )
        ),
      )
  });
  deleteMetadata$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete Metadata
        ofType(
          actions.DeleteMetadata
        ),
        mergeMap((action) =>
          this.metadatasService.delete(action.payload).pipe(
            map((metadatatextId) => actions.DeleteMetadataSuccess({ payload: metadatatextId })),
            catchError((error) => of(actions.DeleteMetadataFail(error)))
          )
        )
      )
  });
}
