// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, map, mergeMap, exhaustMap, switchMap, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// State
import { SeacomState } from '../reducers';

// Actions
import * as actions from '../actions';

// Services
import { NotificationsService } from 'src/app/services/seacom/notifications.service';
import { selectNotificationsLastUpdated } from '../selectors/notification.selectors';
import { EnvironmentService } from 'src/app/services/environment.service';


@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private notificationsService: NotificationsService
  ) { }

  loadNotifications$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Notifications
        ofType(
          actions.LoadNotifications
        ),
        concatLatestFrom((action) => this.store.select(selectNotificationsLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.notificationsService.getList().pipe(
              map((alerts) => actions.LoadNotificationsSuccess({ payload: alerts })),
              catchError((error) => of(actions.LoadNotificationsFail(error)))
            );
          } else {
            return of(actions.LoadNotificationsCancelled());
          }
        })
      )
  });
  loadNotification$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Notification
        ofType(
          actions.LoadNotification
        ),
        exhaustMap((action) =>
          this.notificationsService.get(action.payload).pipe(
            map((alert) => actions.LoadNotificationSuccess({ payload: alert })),
            catchError((error) => of(actions.LoadNotificationsFail(error)))
          )
        )
      )
  });
  addNotification$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add Notification
        ofType(
          actions.AddNotification
        ),
        concatMap((action) =>
          this.notificationsService.create(action.payload).pipe(
            map((alert) => actions.AddNotificationSuccess({ payload: alert })),
            catchError((error) => of(actions.AddNotificationFail(error)))
          )
        )
      )
  });
  updateNotification$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Notification
        ofType(
          actions.UpdateNotification
        ),
        concatMap((action) =>
          this.notificationsService.update(action.payload.id, action.payload).pipe(
            map((alert) => actions.UpdateNotificationSuccess({ payload: alert })),
            catchError((error) => of(actions.UpdateNotificationFail(error)))
          )
        ),
      )
  });
  updateNotificationFields$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Notification Fields
        ofType(
          actions.UpdateNotificationFields
        ),
        concatMap((action) =>
          this.notificationsService.updateFields(action.payload.id, action.payload).pipe(
            map((alert) => actions.UpdateNotificationFieldsSuccess({ payload: alert })),
            catchError((error) => of(actions.UpdateNotificationFieldsFail(error)))
          )
        ),
      )
  });
  deleteNotification$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete Notification
        ofType(
          actions.DeleteNotification
        ),
        mergeMap((action) =>
          this.notificationsService.delete(action.payload).pipe(
            map((alertId) => actions.DeleteNotificationSuccess({ payload: alertId })),
            catchError((error) => of(actions.DeleteNotificationFail(error)))
          )
        )
      )
  });
}
