import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StationsWidgetComponent } from './stations-widget.component';
import { IonicModule } from '@ionic/angular';
import { LoadingSpinnerModule } from '../../loading-spinner/loading-spinner.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    LoadingSpinnerModule
  ],
  declarations: [
    StationsWidgetComponent
  ],
  exports: [
    StationsWidgetComponent
  ]
})
export class StationsWidgetModule { }
