// Angular
import { Injectable } from '@angular/core';

// RXJS
import { map } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../../../store/actions';

// State
import { SeacomState } from '../../../store/reducers';


@Injectable()
export class StationsPageEffects {

  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>
  ) { }

  enter$ = createEffect(() => {
    return this.actions$
    .pipe(
      ofType(
        actions.fromStationsPageActions.Enter
      ),
      map(() => {
        this.store.dispatch(actions.LoadColumnSettings());
      }),
      map(() => {
        this.store.dispatch(actions.LoadStationSettings());
      }),
      map(() => {
        this.store.dispatch(actions.LoadStations());
      }),
      map(() => {
        this.store.dispatch(actions.LoadIncidents());
      }),
    );
  },
  { dispatch: false });

  toggleStationRowExpansion$ = createEffect(() => {
    return this.actions$
    .pipe(
      ofType(
        actions.fromStationsPageActions.ToggleStationRowExpansion
      ),
      map((action) => {
        this.store.dispatch(actions.ToggleTableRowExpansion({
          payload: {
            container: 'stations',
            component: 'stations',
            table: 'stations',
            entityId: action.payload.stationId
          }
        }));
      })
    );
  },
  { dispatch: false });
}
