import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../interfaces/seacom/user';
import { getTrueFalseString, getYesNoString } from './globals';

/*
 * Given IUser, returns user's full name
*/
@Pipe({name: 'FullName'})
export class UserFullNamePipe implements PipeTransform {
  transform(value: IUser|Partial<IUser>): string {
    return value.first_name + ' ' + value.last_name;
  }
}

/*
 * Given string, replaces underscores with spaces
*/
@Pipe({name: 'USpace'})
export class UnderscoreSpacePipe implements PipeTransform {
  transform(value: string): string {
    return value.replaceAll('_', ' ');
  }
}

/*
 * Converts provided boolean to Yes/No string literal
*/
@Pipe({name: 'YesNo'})
export class YesNoPipe implements PipeTransform {
  transform(value: string|number|boolean): string {
    return getYesNoString(value);
  }
}

/*
 * Converts provided boolean to True/False string literal
*/
@Pipe({name: 'TrueFalse'})
export class TrueFalsePipe implements PipeTransform {
  transform(value: string|number|boolean): string {
    return getTrueFalseString(value);
  }
}
