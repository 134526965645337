// Angular
import { Injectable } from '@angular/core';

// RXJS
import { map, switchMap } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../../../store/actions';

// State
import { SeacomState } from '../../../store/reducers';

// Selectors
import { of } from 'rxjs';
import { NavigationService } from 'src/app/services/navigation.service';


@Injectable()
export class FooterBarEffects {

  constructor(
    private actions$: Actions,
    private navigationService: NavigationService
  ) { }

  enter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromFooterbarActions.Enter
        ),
        map(() => {
        })
      );
  },
  { dispatch: false });

  exit$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromFooterbarActions.Exit
        ),
        map(() => {
        })
      );
  },
  { dispatch: false });

  navigateBack$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromFooterbarActions.NavigateBack
        ),
        map((action) => {
          this.navigationService.back();
        })
      );
  }, { dispatch: false });

  changeBrightness$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromFooterbarActions.ChangeBrightness
        ),
        map((action) => {
          return actions.SetBrightness({ payload: { brightness: action.payload.brightness }})
        })
      );
  });
}
