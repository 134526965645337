import { createReducer, on } from '@ngrx/store';
import { IMapFilterSet } from 'src/app/interfaces/mapfilter';
import { IMapView } from 'src/app/interfaces/mapview';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';

import * as actions from '../../../store/actions';

export const operationsPageFeatureKey = 'operationspage';

export interface OperationsPageState {
  expandedStationId: string,
  map: IMapView,
  mapFilters: IMapFilterSet,
  mapExpanded: boolean,
  stationsListExpanded: boolean,
  incidentsListExpanded: boolean,
  lastExpandedSwipeIn: null | 'map' | 'stations' | 'incidents';
  incidentFormOpen: boolean,
  incidentFormIncident: string,
  transferPopoverOpen: boolean,
  stationTransferring: string,
  quickIncidentChoicePopoverOpen: boolean
};

export const OperationsPageInitialState: OperationsPageState = {
  expandedStationId: null,
  map: {
    centerCoordinates: null,
    zoomLevel: 6,
    mapTypeId: 'roadmap',
    bounds: null
  },
  mapFilters: {
    regions: [],
    districts: [],
    beaches: [],
    stations: []
  },
  mapExpanded: false,
  stationsListExpanded: false,
  incidentsListExpanded: false,
  lastExpandedSwipeIn: null,
  incidentFormOpen: false,
  incidentFormIncident: null,
  transferPopoverOpen: false,
  stationTransferring: null,
  quickIncidentChoicePopoverOpen: false
};

export const OperationsPageReducer = createReducer(
  OperationsPageInitialState,
  on(actions.ReturnToLogin, (state, action) => {
    return {
      ...OperationsPageInitialState
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.Enter, (state, action) => {
    return {
      ...state
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.Exit, (state, action) => {
    return {
      ...state
    } as OperationsPageState;
  }),

  // Map slide-in
  on(actions.fromOperationsPageActions.ToggleMap, (state, action) => {
    return {
      ...state,
      mapExpanded: !state.mapExpanded,
      lastExpandedSwipeIn: (!state.mapExpanded === true) ? 'map' : (state.mapExpanded === false && state.stationsListExpanded === false && state.incidentsListExpanded === false ? null : state.lastExpandedSwipeIn)
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.HideMap, (state, action) => {
    return {
      ...state,
      mapExpanded: false,
      lastExpandedSwipeIn: (state.lastExpandedSwipeIn === 'map' ? null : state.lastExpandedSwipeIn)
    } as OperationsPageState;
  }),

  // Stations List slide-in
  on(actions.fromOperationsPageActions.ToggleStationsList, (state, action) => {
    return {
      ...state,
      stationsListExpanded: !state.stationsListExpanded,
      lastExpandedSwipeIn: (!state.stationsListExpanded === true) ? 'stations' : (state.mapExpanded === false && state.stationsListExpanded === false && state.incidentsListExpanded === false ? null : state.lastExpandedSwipeIn)
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.HideStationsList, (state, action) => {
    return {
      ...state,
      stationsListExpanded: false,
      lastExpandedSwipeIn: (state.lastExpandedSwipeIn === 'stations' ? null : state.lastExpandedSwipeIn)
    } as OperationsPageState;
  }),

  // Incidents List slide-in
  on(actions.fromOperationsPageActions.ToggleIncidentsList, (state, action) => {
    return {
      ...state,
      incidentsListExpanded: !state.incidentsListExpanded,
      lastExpandedSwipeIn: (!state.incidentsListExpanded === true) ? 'incidents' : (state.mapExpanded === false && state.stationsListExpanded === false && state.incidentsListExpanded === false ? null : state.lastExpandedSwipeIn)
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.HideIncidentsList, (state, action) => {
    return {
      ...state,
      incidentsListExpanded: false,
      lastExpandedSwipeIn: (state.lastExpandedSwipeIn === 'incidents' ? null : state.lastExpandedSwipeIn)
    } as OperationsPageState;
  }),

  on(actions.fromOperationsPageActions.ToggleStationExpansion, (state, action) => {
    return {
      ...state,
      expandedStationId: (state.expandedStationId === action.payload.stationId) ? null : action.payload.stationId
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.AddStationIncidentResponse, (state, action) => {
    return {
      ...state,
    } as OperationsPageState;
  }),

  // Station Incident Transferring
  on(actions.fromOperationsPageActions.OpenIncidentTransferPopover, (state, action) => {
    return {
      ...state,
      transferPopoverOpen: true,
      stationTransferring: action.payload.stationId
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.CancelStationIncidentTransfer, (state, action) => {
    return {
      ...state,
      transferPopoverOpen: false,
      stationTransferring: null
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.TransferIncidentToStation, (state, action) => {
    return {
      ...state,
      transferPopoverOpen: false
    } as OperationsPageState;
  }),
  on(actions.StationIncidentTransferSuccess, (state, action) => {
    return {
      ...state,
      stationTransferring: null
    } as OperationsPageState;
  }),
  on(actions.StationIncidentTransferFail, (state, action) => {
    return {
      ...state,
      stationTransferring: null
    } as OperationsPageState;
  }),

  // Station Responders
  on(actions.fromOperationsPageActions.UpdateStationResponders, (state, action) => {
    return {
      ...state
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.DisplayQuickIncidentChoicePopover, (state, action) => {
    return {
      ...state,
      quickIncidentChoicePopoverOpen: true
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.ConfirmQuickIncidentChoice, (state, action) => {
    return {
      ...state,
      quickIncidentChoicePopoverOpen: false
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.CancelQuickIncidentChoice, (state, action) => {
    return {
      ...state,
      quickIncidentChoicePopoverOpen: false
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.EndStationResponse, (state, action) => {
    return {
      ...state
    } as OperationsPageState;
  }),

  // Map
  on(actions.fromOperationsPageActions.MapEnter, (state, action) => {
    return {
      ...state
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.MapExit, (state, action) => {
    return {
      ...state
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.ChangeOperationsMapType, (state, action) => {
    return {
      ...state,
      map: {
        ...state.map,
        mapTypeId: action.payload.mapType
      }
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.UpdateMapFilters, (state, action) => {
    console.log(action.payload.newFilterSet);
    return {
      ...state,
      mapFilters: action.payload.newFilterSet
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.FocusMapTo, (state, action) => {
    return {
      ...state,
      map: {
        ...state.map,
        centerCoordinates: { lat: action.payload.coordinates.lat, lng: action.payload.coordinates.lng },
        bounds: null
      }
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.ZoomMapIn, (state, action) => {
    return {
      ...state,
      map: {
        ...state.map,
        zoomLevel: state.map.zoomLevel++
      }
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.ZoomMapOut, (state, action) => {
    return {
      ...state,
      map: {
        ...state.map,
        zoomLevel: state.map.zoomLevel--
      }
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.SetMapZoom, (state, action) => {
    return {
      ...state,
      map: {
        ...state.map,
        zoomLevel: action.payload.zoomLevel
      }
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.SetMapBounds, (state, action) => {
    return {
      ...state,
      map: {
        ...state.map,
        centerCoordinates: null,
        bounds: action.payload.bounds
      }
    } as OperationsPageState;
  }),

  // Stations List
  on(actions.fromOperationsPageActions.StationsListEnter, (state, action) => {
    return {
      ...state
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.StationsListExit, (state, action) => {
    return {
      ...state
    } as OperationsPageState;
  }),

  // Incidents List
  on(actions.fromOperationsPageActions.IncidentsListEnter, (state, action) => {
    return {
      ...state
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.IncidentsListExit, (state, action) => {
    return {
      ...state
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.DisplayIncidentForm, (state, action) => {
    return {
      ...state,
      incidentFormOpen: true,
      incidentFormIncident: action.payload.incidentId
    } as OperationsPageState;
  }),
  on(actions.fromOperationsPageActions.CloseIncidentForm, (state, action) => {
    return {
      ...state,
      incidentFormOpen: false,
      incidentFormIncident: null
    } as OperationsPageState;
  })
);
