import { createAction, props } from '@ngrx/store';
import { IMetadata, INewMetadata, IUpdatedMetadata } from '../../interfaces/seacom/metadata';

// alert action types
export const LOAD_METADATAS = '[Page Effects] Load Metadatas';
export const LOAD_METADATAS_CANCELLED = '[Metadata Effects] Load Metadatas Cancelled';
export const LOAD_METADATAS_SUCCESS = '[Metadata Effects] Load Metadatas Success';
export const LOAD_METADATAS_FAIL = '[Metadata Effects] Load Metadatas Fail';
export const LOAD_METADATA = '[Component Effects] Load Metadata';
export const LOAD_METADATA_FAIL = '[Metadata Effects] Load Metadata Fail';
export const LOAD_METADATA_SUCCESS = '[Metadata Effects] Load Metadata Success';
export const UPDATE_METADATA = '[Page Effects] Update Metadata';
export const UPDATE_METADATA_FIELDS = '[Page Effects] Update Metadata Fields';
export const UPDATE_METADATA_SUCCESS = '[Metadata Effects] Update Metadata Success';
export const UPDATE_METADATA_FIELDS_SUCCESS = '[Metadata Effects] Update Metadata Fields Success';
export const UPDATE_METADATA_FAIL = '[Metadata Effects] Update Metadata Fail';
export const UPDATE_METADATA_FIELDS_FAIL = '[Metadata Effects] Update Metadata Fields Fail';
export const ADD_METADATA = '[Page Effects] Add Metadata';
export const ADD_METADATA_FAIL = '[Metadata Effects] Add Metadata Fail';
export const ADD_METADATA_SUCCESS = '[Metadata Effects] Add Metadata Success';
export const DELETE_METADATA = '[Page Effects] Delete Metadata';
export const DELETE_METADATA_FAIL = '[Metadata Effects] Delete Metadata Fail';
export const DELETE_METADATA_SUCCESS = '[Metadata Effects] Delete Metadata Success';

export const LoadMetadatas = createAction(
	LOAD_METADATAS
);

export const LoadMetadatasCancelled = createAction(
    LOAD_METADATAS_CANCELLED
);

export const LoadMetadatasSuccess = createAction(
    LOAD_METADATAS_SUCCESS,
    props<{
        payload: IMetadata[]
    }>()
);

export const LoadMetadatasFail = createAction(
    LOAD_METADATAS_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadMetadata = createAction(
	LOAD_METADATA,
    props<{
        payload: string // ID
    }>()
);

export const LoadMetadataSuccess = createAction(
    LOAD_METADATA_SUCCESS,
    props<{
        payload: IMetadata
    }>()
);

export const LoadMetadataFail = createAction(
    LOAD_METADATA_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateMetadata = createAction(
    UPDATE_METADATA,
    props<{
        payload: IUpdatedMetadata
    }>()
);

export const UpdateMetadataFields = createAction(
    UPDATE_METADATA_FIELDS,
    props<{
        payload: IUpdatedMetadata
    }>()
);

export const UpdateMetadataSuccess = createAction(
    UPDATE_METADATA_SUCCESS,
    props<{
        payload: IMetadata
    }>()
);

export const UpdateMetadataFieldsSuccess = createAction(
    UPDATE_METADATA_FIELDS_SUCCESS,
    props<{
        payload: IMetadata
    }>()
);

export const UpdateMetadataFail = createAction(
    UPDATE_METADATA_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateMetadataFieldsFail = createAction(
    UPDATE_METADATA_FIELDS_FAIL,
    props<{
        payload: any
    }>()
);

export const AddMetadata = createAction(
    ADD_METADATA,
    props<{
        payload: INewMetadata
    }>()
);

export const AddMetadataSuccess = createAction(
    ADD_METADATA_SUCCESS,
    props<{
        payload: IMetadata
    }>()
);

export const AddMetadataFail = createAction(
    ADD_METADATA_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteMetadata = createAction(
    DELETE_METADATA,
    props<{
        payload: string // ID
    }>()
);

export const DeleteMetadataSuccess = createAction(
    DELETE_METADATA_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteMetadataFail = createAction(
    DELETE_METADATA_FAIL,
    props<{
        payload: any
    }>()
);
