import { IUUID } from './templates/uuid';
import { IOrganization } from './organization';
import { IUser } from './user';
import { IStation } from './station';

export interface IStationSetting extends IUUID {
    d?: 'IStationSetting';
    organization: Partial<IOrganization>;
    user: Partial<IUser>;
    station: Partial<IStation>;
    after: string;
    created: string;
    created_by: Partial<IUser>;
    last_modified: string;
    last_modified_by: Partial<IUser>
}

export interface INewStationSetting {
    d?: 'INewStationSetting';
    organization?: string;
    user?: string;
    station: string;
    after?: string;
}

export interface IUpdatedStationSetting extends IUUID {
    d?: 'IUpdatedStationSetting';
    organization?: string;
    user?: string;
    station?: string;
    after?: string;
}

export function CopyStationSettingForUpdate(cs: IStationSetting): IUpdatedStationSetting {
    return {
        d: 'IUpdatedStationSetting',
        id: cs.id,
        organization: cs.organization ? cs.organization.id : undefined,
        user: cs.user ? cs.user.id : undefined,
        station: cs.station.id,
        after: cs.after
    } as IUpdatedStationSetting;
}

export function CopyStationSettingForNew(cs: IStationSetting): INewStationSetting {
    return {
        d: 'INewStationSetting',
        organization: cs.organization ? cs.organization.id : undefined,
        user: cs.user ? cs.user.id : undefined,
        station: cs.station.id,
        after: cs.after
    } as INewStationSetting;
}
