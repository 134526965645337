// Angular
import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

// RXJS
import { map } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../../../store/actions';

// State
import { SeacomState } from '../../../store/reducers';

// Selectors
import { selectColumnSettingsByCCT } from '../../../store/selectors/table.selectors';
import { selectCurrentUser } from '../../../store/selectors/auth.selectors';

// Interfaces
import { CopyColumnSettingForNew, INewColumnSetting, IUpdatedColumnSetting } from 'src/app/interfaces/seacom/columnsetting';
import { UpdateMetadataFields } from '../../../store/actions/metadata.actions';


@Injectable()
export class RunbookPageEffects {
  constructor(private actions$: Actions, private store: Store<SeacomState>) { }

  enter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromRunbookPageActions.Enter
        ),
        map(() => {
          this.store.dispatch(actions.LoadRegions());
        }),
        map(() => {
          this.store.dispatch(actions.LoadDistricts());
        }),
        map(() => {
          this.store.dispatch(actions.LoadBeaches());
        }),
        map(() => {
          this.store.dispatch(actions.LoadStations());
        }),
        map(() => {
          this.store.dispatch(actions.LoadUsers());
        }),
        map(() => {
          this.store.dispatch(actions.LoadColumnSettings());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidents());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidentSeverities());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidentTypes());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidentResponses());
        })
      );
  },
  { dispatch: false }
  );

  displayIncidentsTableColumn$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromRunbookPageActions.DisplayIncidentListColumn
        ),
        concatLatestFrom((action) => [
          this.store.select(selectColumnSettingsByCCT('runbook', 'incidents', action.payload.column)),
          this.store.select(selectCurrentUser)
        ]),
        map(([action, cs, cu]) => {
          if (
            cs[0].organization !== undefined &&
            cs[0].organization !== null &&
            cs[0].organization.id === cu.organization.id &&
            cs[0].user !== undefined &&
            cs[0].user !== null &&
            cs[0].user.id === cu.id
          ) {
            let ncs = {
              id: cs[0].id,
              displayed: true
            } as IUpdatedColumnSetting;
            return actions.UpdateColumnSettingFields({ payload: ncs });
          } else {
            let ncs = {
              ...CopyColumnSettingForNew(cs[0]),
              organization: cu.organization.id,
              user: cu.id,
              displayed: true
            } as INewColumnSetting;
            return actions.AddColumnSetting({ payload: ncs });
          }
        })
      );
  });
  hideIncidentsTableColumn$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromRunbookPageActions.HideIncidentListColumn
        ),
        concatLatestFrom((action) => [
          this.store.select(selectColumnSettingsByCCT('runbook', 'incidents', action.payload.column)),
          this.store.select(selectCurrentUser)
        ]),
        map(([action, cs, cu]) => {
          if (
            cs[0].organization !== undefined &&
            cs[0].organization !== null &&
            cs[0].organization.id === cu.organization.id &&
            cs[0].user !== undefined &&
            cs[0].user !== null &&
            cs[0].user.id === cu.id
          ) {
            let ncs = {
              id: cs[0].id,
              displayed: false
            } as IUpdatedColumnSetting;
            return actions.UpdateColumnSettingFields({ payload: ncs });
          } else {
            let ncs = {
              ...CopyColumnSettingForNew(cs[0]),
              organization: cu.organization.id,
              user: cu.id,
              displayed: false
            } as INewColumnSetting;
            return actions.AddColumnSetting({ payload: ncs });
          }
        })
      );
  });

    // Incident operations
    createIncidentFormIncident$ = createEffect(() => {
      return this.actions$
        .pipe(
          ofType(
            actions.fromRunbookPageActions.CreateIncidentFormIncident
          ),
          map((action) => actions.AddIncident({ payload: action.payload })
          )
        );
    });
  
    updateIncidentFormIncident$ = createEffect(() => {
      return this.actions$
        .pipe(
          ofType(
            actions.fromRunbookPageActions.UpdateIncidentFormIncident
          ),
          map((action) => actions.UpdateIncidentFields({ payload: action.payload })
          )
        );
    });
  
    // Response operations
    createIncidentFormResponse$ = createEffect(() => {
      return this.actions$
        .pipe(
          ofType(
            actions.fromRunbookPageActions.CreateIncidentFormResponse
          ),
          map((action) => actions.AddIncidentResponse({ payload: action.payload })
          )
        );
    });
  
    updateIncidentFormResponse$ = createEffect(() => {
      return this.actions$
        .pipe(
          ofType(
            actions.fromRunbookPageActions.UpdateIncidentFormResponse
          ),
          map((action) => actions.UpdateIncidentResponseFields({ payload: action.payload })
          )
        );
    });
  
    deleteIncidentFormResponse$ = createEffect(() => {
      return this.actions$
        .pipe(
          ofType(
            actions.fromRunbookPageActions.DeleteIncidentFormResponse
          ),
          map((action) => actions.DeleteIncidentResponse({ payload: action.payload })
          )
        );
    });
  
    // Metadata operations
    createMetadata$ = createEffect(() => {
      return this.actions$
        .pipe(
          ofType(
            actions.fromRunbookPageActions.CreateMetadata
          ),
          map((action) => actions.AddMetadata({ payload: action.payload })
          )
        );
    });
  
    updateMetadata$ = createEffect(() => {
      return this.actions$
        .pipe(
          ofType(
            actions.fromRunbookPageActions.UpdateMetadata
          ),
          map((action) => actions.UpdateMetadataFields({ payload: action.payload })
          )
        );
    });

}
