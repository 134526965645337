import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IIncident } from 'src/app/interfaces/seacom/incident';
import * as fromReducers from '../reducers/incident.reducers';
import { sortFactory } from '../utils/sort.utils';
import { selectAllIncidentSeverities } from './incidentseverity.selectors';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { filterObject, incidentFilterFactory } from '../utils/filter.utils';
import { searchFactory } from '../utils/search.utils';

import { selectAllStations } from './station.selectors';
import { IIncidentSeverity } from 'src/app/interfaces/seacom/incidentseverity';
import { IStation } from 'src/app/interfaces/seacom/station';
import { selectCurrentUser } from './auth.selectors';
import { selectFilterSettingsByCCT, selectSearchSettingByCCT, selectSortSettingsByCCT } from './table.selectors';
import { ISortField } from 'src/app/interfaces/sortfield';

export const selectIncidentState = createFeatureSelector<fromReducers.IncidentState>(
  fromReducers.incidentFeatureKey
);

export const selectIncidentsLastUpdated = createSelector(
  selectIncidentState,
  (state: fromReducers.IncidentState): string => state.lastUpdated
);

export const selectIncidentsCount = createSelector(
  selectIncidentState,
  (state: fromReducers.IncidentState): number => Object.keys(state.entities).length
);

export const selectIncidentsLoading = createSelector(
  selectIncidentState,
  (state: fromReducers.IncidentState): boolean => state.loading
);

export const selectIncidentIsUpdating = (id: string) => createSelector(
  selectIncidentState,
  (state: fromReducers.IncidentState): boolean => {
    if (state.updatingEntities !== undefined && state.updatingEntities !== null) {
      let fnd = state.updatingEntities.indexOf(id);
      if (fnd !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
);

export const selectIncidentIsDeleting = (id: string) => createSelector(
  selectIncidentState,
  (state: fromReducers.IncidentState): boolean => {
    if (state.deletingEntities !== undefined && state.deletingEntities !== null) {
      let fnd = state.deletingEntities.indexOf(id);
      if (fnd !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
);

export const selectAllIncidents = createSelector(
  selectIncidentsCount,
  selectIncidentState,
  (count: number, state: fromReducers.IncidentState): IIncident[] => {
    if (count > 0) {
      return Object.keys(state.entities).map(i => state.entities[i])
        .sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]));
    } else {
      return [];
    }
  }
);

export const selectNewIncidentsCount = createSelector(
  selectAllIncidents,
  (incidents: IIncident[]): number => {
    return incidents.filter((i: IIncident) =>
      i.status === 'new'
    ).length;
  }
);

export const selectOpenIncidentsCount = createSelector(
  selectAllIncidents,
  (incidents: IIncident[]): number => {
    return incidents.filter((i: IIncident) =>
      i.status !== 'closed'
    ).length;
  }
);

export const selectOpenIncidentsCountBySeverity = createSelector(
  selectAllIncidents,
  selectAllIncidentSeverities,
  (incidents: IIncident[], severities: IIncidentSeverity[]): { name: string, count: number, color: string, bgcolor: string }[] => {
    if (severities.length > 0) {
      return severities.map(s => {
        return {
          name: s.name,
          level: s.level,
          color: s.color,
          bgcolor: s.bgcolor,
          count: incidents.length > 0 ? incidents.filter(i => i.severity.id === s.id).length : 0,
        };
      })
        .sort((a, b) => sortFactory(a, b, [{ field: 'level', ascending: true }])) as { name: string, count: number, color: string, bgcolor: string }[];
    } else {
      return [];
    }
  }
);

export const selectActiveIncidents = createSelector(
  selectAllIncidents,
  (incidents: IIncident[]): IIncident[] => {
    return incidents
      .filter(i => i.status !== 'closed') // anything but closed
      .sort((a, b) => sortFactory(a, b, [{ field: 'incident_number', ascending: false }])); // Starttime descending
  }
);

export const selectActiveIncidentsCount = createSelector(
  selectActiveIncidents,
  (activeIncidents: IIncident[]): number => {
    return activeIncidents.length;
  }
);

export const selectActiveAndRecentIncidents = createSelector(
  selectActiveIncidents,
  selectAllIncidents,
  (activeIncidents: IIncident[], incidents: IIncident[]): IIncident[] => {
    let stdt = new Date((new Date()).getTime() - (24 * 60 * 60 * 1000)); // 24 hours ago
    let ri = incidents
      .filter(i => new Date(i.starttime) >= stdt) // filter by starttime
      .filter(i => !activeIncidents.map(i2 => i2.id).includes(i.id)); // remove those already in the list
    return ri
      .concat(activeIncidents) // add in active incidents
      .sort((a, b) => sortFactory(a, b, [{ field: 'incident_number', ascending: false }])); // Starttime descending
  }
);

export const selectIncidentsWithFilter = (filters?: IFieldFilter[], order?: string, search?: string) => createSelector(selectAllIncidents, (incidents): IIncident[] => {
  let res = incidents;
  console.log('filters:');
  console.log(filters);

  if (filters !== undefined && filters !== null) {
    res = res.filter(i => incidentFilterFactory(i, filters));
  }

  if (search !== undefined && search !== null) {
    res = res.filter(i => searchFactory(i, search));
  }

  if (order !== undefined && order !== null) {
    let sortAsc = true;
    if (order.substring(0, 1) === '-') {
      sortAsc = false;
    }
    let sortField = order.replace('-', '').replace('+', '');
    return res.sort((a, b) => sortFactory(a, b, [{ field: sortField, ascending: sortAsc }]));
  } else {
    // Sort descending by starttime by default
    return res.sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]));
  }
});


export const selectIncidentById = (id: string) => createSelector(selectIncidentState, (incidents): IIncident => {
  return incidents?.entities[id];
});

export const selectIncidentsByStatus = (status: string) => createSelector(selectAllIncidents, (incidents): IIncident[] => {
  return incidents
    .filter(
      (i) => {
        i.status === status;
      }
    )
    .sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]));
});

export const selectIncidentsByStatuses = (statuses: string[]) => createSelector(selectAllIncidents, (incidents): IIncident[] => {
  return incidents
    .filter(i => statuses.indexOf(i.status) !== -1)
    .sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]));
});

export const selectIncidentsWithLocation = createSelector(
  selectAllIncidents,
  (incidents): IIncident[] => {
    return incidents
      .filter(
        (i) => {
          i.location !== undefined &&
            i.location !== null
        }
      )
      .sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]));
  }
);

export const selectIncidentsBySeverity = (severity: string) => createSelector(selectAllIncidents, (incidents): IIncident[] => {
  return incidents
    .filter(
      (i) => {
        i.severity === severity
      }
    )
    .sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]));
});

export const selectMyIncidents = createSelector(
  selectCurrentUser,
  selectAllIncidents,
  (user, incidents): IIncident[] => {
    return incidents
      .filter(
        (i) => {
          if (!!user && !!user.role && !!user.role.role && user.role.role.name === 'responder') {
            if ((i.source === 'responder' && i.created_by.id === user.id) ||
              i.primary_responder.id === user.id ||
              i.secondary_responder.id === user.id ||
              i.tertiary_responder.id === user.id
            ) {
              return true;
            } else if (i.source === user.role.role.name && i.created_by.id === user.role.role.name) {
              return true;
            } else {
              return false;
            }
          }
        }
      )
      .sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]));
  });

export const selectMyIncidentCount = createSelector(
  selectMyIncidents,
  (incidents): number => {
    return incidents.length;
  }
);

export const selectLatestIncidentByStation = (statuses: string[]) => createSelector(
  selectIncidentsByStatuses(statuses),
  selectAllStations,
  (incidents, stations): { [id: string]: IIncident } => {
    return stations.reduce(
      (entities: { [id: string]: IIncident }, item: IStation) => {
        let is = incidents.filter(i => {
          if (i.station.id === item.id) {
            return true;
          } else if (i.responses.filter(ir => ir.stations.map(s => s.id).indexOf(item.id) !== -1).length > 0) {
            return true;
          } else {
            return false;
          }
        }).sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]));
        let i: IIncident;
        if (is.length > 0) {
          i = is[0];
        } else {
          i = null;
        }
        return {
          ...entities,
          [item.id]: i
        };
      },
      {}
    );
  }
)

export const selectLatestIncidentsInProgressByStation = createSelector(
  selectAllIncidents,
  selectAllStations,
  (incidents, stations): { [id: string]: IIncident } => {
    return stations.reduce(
      (entities: { [id: string]: IIncident }, item: IStation) => {
        let is = incidents.filter(i => {
          if (i.station !== undefined && i.station !== null && i.station.id !== undefined) {
            if (i.station.id === item.id && ['en route', 'responding'].indexOf(i.status) !== -1) {
              return true;
            } else if (i.responses.filter(ir => ir.stations.map(s => s.id).indexOf(item.id) !== -1 && ir.status === 'inprogress').length > 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }).sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]));
        let i: IIncident;
        if (is.length > 0) {
          i = is[0];
        } else {
          i = null;
        }
        return {
          ...entities,
          [item.id]: i
        };
      },
      {}
    );
  }
);

export const selectIncidentsByStations = createSelector(
  selectAllIncidents,
  selectAllStations,
  (incidents, stations): { [id: string]: IIncident[] } => {
    return stations.reduce(
      (entities: { [id: string]: IIncident[] }, item: IStation) => {
        return {
          ...entities,
          [item.id]: incidents.filter(i => {
            if(i.station !== undefined && i.station !== null && i.station.hasOwnProperty('id')) {
              return i.station.id === item.id;
            } else {
              return false;
            }
          }).sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]))
        };
      },
      {}
    );
  }
);

export const selectIncidentsByStationId = (stationId: string) => createSelector(
  selectAllIncidents,
  (incidents): IIncident[] => {
    return incidents
      .filter(
        i => {
          if (i.station.id === stationId) {
            return true;
          } else if (i.responses.map(r => r.stations).filter(s => s.map(s => s.id).indexOf(stationId) !== -1).length > 0) {
            return true;
          } else {
            return false;
          }
        }
      )
      .sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: true }]))
  }
);

export const selectIncidentsByStationIdAndStatus = (stationId: string, statuses: string[]) => createSelector(
  selectIncidentsByStationId(stationId),
  (incidents): IIncident[] => {
    return incidents.filter(i => statuses.indexOf(i.status) !== -1).sort((a, b) => sortFactory(a, b, [{ field: 'starttime', ascending: false }]))
  }
);

export const selectLatestIncidentByStationId = (stationId: string) => createSelector(
  selectIncidentsByStationId(stationId),
  (incidents): IIncident => {
    if (incidents.length > 0) {
      return incidents[0];
    } else {
      return null;
    }
  }
);

export const selectLatestIncidentByStationIdAndStatus = (stationId: string, statuses: string[]) => createSelector(
  selectIncidentsByStationIdAndStatus(stationId, statuses),
  (incidents): IIncident => {
    if (incidents.length > 0) {
      return incidents[0];
    } else {
      return null;
    }
  }
);

export const selectIncidentTableData = (container: string, component: string, table: string) => createSelector(
  selectSearchSettingByCCT(container, component, table),
  selectFilterSettingsByCCT(container, component, table),
  selectSortSettingsByCCT(container, component, table),
  selectAllIncidents,
  (searchSetting: string, filterSettings: IFieldFilter[], sortSettings: ISortField[], incidents: IIncident[]): IIncident[] => {
    let res = Array.from(incidents);

    // search or filter
    res = res.filter(i => {
      if (searchSetting !== null) {
        return searchFactory(i, searchSetting);
      } else if (filterSettings !== undefined && filterSettings !== null && filterSettings.length > 0) {
        return filterObject(i, filterSettings);
      } else {
        return true;
      }
    });

    // sort
    return res.sort((a, b) => sortFactory(a, b, sortSettings));
  }
);
