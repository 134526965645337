import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationsWidgetComponent } from './operations-widget.component';
import { IonicModule } from '@ionic/angular';
import { LoadingSpinnerModule } from '../../loading-spinner/loading-spinner.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    LoadingSpinnerModule
  ],
  declarations: [
    OperationsWidgetComponent
  ],
  exports: [
    OperationsWidgetComponent
  ]
})
export class OperationsWidgetModule { }
