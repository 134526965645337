// Angular
import { Injectable } from '@angular/core';

// RXJS
import { map } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../../../store/actions';

// State
import { SeacomState } from '../../../store/reducers';


@Injectable()
export class ColumnSelectorEffects {

  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>
  ) { }

  openColumnSelector$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromColumnSelectorActions.ToggleColumnSelector
        ),
        map((action) => {
          this.store.dispatch(actions.ToggleColumnSelector({
            payload: {
              container: action.payload.container,
              component: action.payload.component,
              table: action.payload.table
            }
          }));
        })
      );
  }, { dispatch: false });

  reorderColumn$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromColumnSelectorActions.ReorderColumns
        ),
        map((action) => {
          this.store.dispatch(actions.ReorderColumnSettings({
            payload: {
              container: action.payload.container,
              component: action.payload.component,
              table: action.payload.table,
              newOrder: action.payload.newOrder,
              from: action.payload.from,
              to: action.payload.to
            }
          }));
        })
      );
  }, { dispatch: false });

}



