import { createAction, props } from "@ngrx/store";
import { INewFeedback } from '../../../interfaces/seacom/feedback';

const actionKey = '[Feedback Page] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

export const AddFeedback = createAction(
  actionKey + 'Add Feedback',
  props<{ payload: { feedback: INewFeedback }}>()
);
