import { createAction, props } from "@ngrx/store";
import { IMapFilterSet } from "src/app/interfaces/mapfilter";
import { IFieldFilter } from "src/app/interfaces/seacom/fieldfilter";
import { IIncidentResponse, INewIncidentResponse, IUpdatedIncidentResponse } from "src/app/interfaces/seacom/incidentresponse";
import { ISortField } from "src/app/interfaces/sortfield";
import { IStationTile } from "src/app/interfaces/stationtile";
import { IIncident, INewIncident, IUpdatedIncident } from '../../../interfaces/seacom/incident';
import { INewMetadata, IUpdatedMetadata } from '../../../interfaces/seacom/metadata';

const actionKey = '[Operations Page] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

// Side-bar Expandables

const sidebarKey = '[Sidebar] ';

export const ToggleMap = createAction(
  actionKey + sidebarKey + 'Toggle Map'
);

export const HideMap = createAction(
  actionKey + sidebarKey + 'Hide Map'
);

export const ToggleStationsList = createAction(
  actionKey + sidebarKey + 'Toggle Stations List'
);

export const HideStationsList = createAction(
  actionKey + sidebarKey + 'Hide Stations List'
);

export const ToggleIncidentsList = createAction(
  actionKey + sidebarKey + 'Toggle Incidents List'
);

export const HideIncidentsList = createAction(
  actionKey + sidebarKey + 'Hide Incidents List'
);

// Station Tile

const stationTileKey = '[Station Tile] ';

export const ToggleStationExpansion = createAction(
  actionKey + stationTileKey + 'Toggle Station Tile Expansion',
  props<{ payload: { stationId: string }}>()
);

export const AddStationIncidentResponse = createAction(
  actionKey + stationTileKey + 'Add Incident Response',
  props<{ payload: { incidentResponse: INewIncidentResponse }}>()
);

export const FilterIncidentsListByStation = createAction(
  actionKey + stationTileKey + 'Filter Incidents List by Station',
  props<{ payload: { stationId: string }}>()
);

export const OpenIncidentTransferPopover = createAction(
  actionKey + stationTileKey + 'Open Incident Transfer Popover',
  props<{ payload: { stationId: string }}>()
);

export const CancelStationIncidentTransfer = createAction(
  actionKey + '[Incident Transfer Popover]' + 'Cancel Station Incident Transfer'
);

export const TransferIncidentToStation = createAction(
  actionKey + stationTileKey + 'Transfer Incident',
  props<{ payload: { destinationStationId: string }}>()
);

export const UpdateStationResponders = createAction(
  actionKey + stationTileKey + 'Update Responders',
  props<{ payload: { stationId: string, responders: string[] }}>()
);

export const AddQuickIncident = createAction(
  actionKey + stationTileKey + 'Quick Incident',
  props<{ payload: { stationId: string, quickIncident: INewIncident, firstResponse: INewIncidentResponse }}>()
);

export const AddQuickIncidentSuccess = createAction(
  actionKey + stationTileKey + 'Quick Incident',
  props<{ payload: { stationId: string, quickIncident: IIncident, firstResponse: IIncidentResponse }}>()
);

export const DisplayQuickIncidentChoicePopover = createAction(
  actionKey + stationTileKey + 'Quick Incident Choice Popover',
  props<{ payload: { stationId: string }}>()
);

export const CancelQuickIncidentChoice = createAction(
  actionKey + stationTileKey + 'Cancel Quick Incident Choice'
);

export const ConfirmQuickIncidentChoice = createAction(
  actionKey + stationTileKey + 'Confirm Quick Incident Choice',
  props<{ payload: { incidentTypeId: string }}>()
);

export const EndStationResponse = createAction(
  actionKey + stationTileKey + 'End Incident Response',
  props<{ payload: { stationId: string }}>()
);

// Map Actions

const mapKey = '[Map] ';

export const MapEnter = createAction(
  actionKey + mapKey + 'Enter'
);

export const MapExit = createAction(
  actionKey + mapKey + 'Exit'
);

export const ChangeOperationsMapType = createAction(
  actionKey + mapKey + 'Change Layer Type',
  props<{ payload: { mapType: ('roadmap'|'satellite'|'hybrid'|'terrain') }}>()
);

export const UpdateMapFilters = createAction(
  actionKey + mapKey + 'Update Map Filters',
  props<{ payload: { newFilterSet: IMapFilterSet }}>()
);

export const FocusMapTo = createAction(
  actionKey + mapKey + 'Focus On Location',
  props<{ payload: { coordinates: { lat: number, lng: number } }}>()
);

export const FocusMapOnIncident = createAction(
  actionKey + mapKey + 'Focus On Incident',
  props<{ payload: { incidentId: string }}>()
);

export const ZoomMapIn = createAction(
  actionKey + mapKey + 'Zoom In'
);

export const ZoomMapOut = createAction(
  actionKey + mapKey + 'Zoom Out'
);

export const SetMapZoom = createAction(
  actionKey + mapKey + 'Set Map Zoom',
  props<{ payload: { zoomLevel: number }}>()
);

export const SetMapBounds = createAction(
  actionKey + mapKey + 'Set Map Bounds',
  props<{ payload: { bounds: google.maps.LatLngBoundsLiteral }}>()
);

// List filter Actions
export const ReplaceColumnFilters = createAction(
  actionKey + 'Replace Column Filters',
  props<{ payload: {
    container: string,
    component: string,
    table: string,
    newFilters: IFieldFilter[]
  }}>()
);

export const RemoveColumnFilters = createAction(
  actionKey + 'Remove Column Filters',
  props<{ payload: {
    container: string,
    component: string,
    table: string,
    column: string
  }}>()
);

// Stations List Actions

const stationsListKey = '[Stations List] ';

export const StationsListEnter = createAction(
  actionKey + stationsListKey + 'Enter'
);

export const StationsListExit = createAction(
  actionKey + stationsListKey + 'Exit'
);

// Filters

export const RevertStationsListFilters = createAction(
  actionKey + stationsListKey + 'Revert Stations List Filters',
);

export const SetStationStatus = createAction(
  actionKey + stationsListKey + 'Set Station Status',
  props<{ payload: { stationId: string, status: 'in_service'|'out_of_service' }}>()
);

export const UpdateStationOrder = createAction(
  actionKey + stationsListKey + 'Update Station Order',
  props<{ 
    payload: {
      newOrder: string[],
      from: number,
      to: number
    }
  }>()
);

/*
*
* Incidents List
*
*/

const incidentsListKey = '[Incidents List] ';

export const IncidentsListEnter = createAction(
  actionKey + incidentsListKey + 'Incidents List Enter'
);

export const IncidentsListExit = createAction(
  actionKey + incidentsListKey + 'Incidents List Exit'
);

// Filters

export const RevertIncidentsListFilters = createAction(
  actionKey + incidentsListKey + 'Revert Incidents List Filters',
);

// Sorting
export const ReplaceIncidentsListSortSetting = createAction(
  actionKey + incidentsListKey + 'Replace Sort Setting',
  props<{ payload: { newSortSetting: ISortField[] }}>()
);

export const RevertIncidentsListSortSetting = createAction(
  actionKey + incidentsListKey + 'Revert Sort Setting'
);

// Incident Form
export const DisplayIncidentForm = createAction(
  actionKey + incidentsListKey + 'Display Incident Form',
  props<{ payload: { incidentId: string }}>()
);

// Pinning
export const PinIncidentsList = createAction(
  actionKey + incidentsListKey + 'Pin Incidents List'
);

export const UnpinIncidentsList = createAction(
  actionKey + incidentsListKey + 'Unpin Incidents List'
);

/*
*
* Incident Form
*
*/
const incidentFormKey = '[Incident Form] ';

export const CloseIncidentForm = createAction(
  actionKey + incidentFormKey + 'Close Incident Form'
);
