import { IOrganization } from './organization';
import { IUser } from './user';
import { IUUID } from './templates/uuid';

export interface IContainerSetting extends IUUID {
    d?: 'IContainerSetting';
    organization: Partial<IOrganization>;
    user: Partial<IUser>;
    container: string;
    component: string;
    setting: string;
    value: string;
    created: string;
    created_by: Partial<IUser>;
    last_modified: string;
    last_modified_by: Partial<IUser>
}

export interface INewContainerSetting extends IUUID {
    d?: 'INewContainerSetting';
    organization?: string;
    user?: string;
    container: string;
    component: string;
    setting: string;
    value: string;
}

export interface IUpdatedContainerSetting extends IUUID {
    d?: 'IUpdatedContainerSetting';
    organization?: string;
    user?: string;
    container?: string;
    component?: string;
    setting?: string;
    value?: string;
    displayname?: string;
    displayed?: boolean;
}

export function CopyContainerSettingForNew(cs: IContainerSetting): INewContainerSetting {
    return {
        d: 'INewContainerSetting',
        organization: cs.organization ? cs.organization.id : undefined,
        user: cs.user? cs.user.id : undefined,
        container: cs.container,
        component: cs.component,
        setting: cs.setting,
        value: cs.value
    } as INewContainerSetting;
}

export function CopyContainerSettingForUpdate(cs: IContainerSetting): IUpdatedContainerSetting {
    return {
        d: 'IUpdatedContainerSetting',
        id: cs.id,
        organization: cs.organization ? cs.organization.id : undefined,
        user: cs.user? cs.user.id : undefined,
        container: cs.container,
        component: cs.component,
        setting: cs.setting,
        value: cs.value
    } as IUpdatedContainerSetting;
}
