import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { NotificationsWidgetComponent } from './notifications-widget.component';
import { LoadingSpinnerModule } from '../../loading-spinner/loading-spinner.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    LoadingSpinnerModule
  ],
  declarations: [
    NotificationsWidgetComponent
  ],
  exports: [
    NotificationsWidgetComponent
  ]
})
export class NotificationsWidgetModule { }
