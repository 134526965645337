// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../actions';

// Services
import { FeedbackService } from 'src/app/services/seacom/feedback.service';
import { SeacomState } from '../reducers';
import { Store } from '@ngrx/store';
import { selectCurrentUser } from '../selectors/auth.selectors';
import { EnvironmentService } from 'src/app/services/environment.service';
import { selectFeedbacksLastUpdated } from '../selectors/feedback.selectors';


@Injectable()
export class FeedbackEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private feedbackService: FeedbackService
  ) { }

  loadFeedbacks$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Feedbacks
        ofType(
          actions.LoadFeedbacks
        ),
        concatLatestFrom((action) => this.store.select(selectFeedbacksLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.feedbackService.getList().pipe(
              map((feedbacks) => actions.LoadFeedbacksSuccess({ payload: feedbacks })),
              catchError((error) => of(actions.LoadFeedbacksFail(error)))
            );
          } else {
            return of(actions.LoadFeedbacksCancelled());
          }
        })
      );
  });
  addFeedback$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add Feedback
        ofType(
          actions.fromFeedbackPageActions.AddFeedback
        ),
        concatLatestFrom((action) => this.store.select(selectCurrentUser)),
        concatMap(([action, user]) =>
          this.feedbackService.create({ organization: user.organization.id, ...action.payload.feedback }).pipe(
            map((feedback) => actions.AddFeedbackSuccess({ payload: feedback })),
            catchError((error) => of(actions.AddFeedbackFail(error)))
          )
        )
      );
  });
  updateFeedback$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Feedback
        ofType(actions.UpdateFeedback),
        concatMap((action) =>
          this.feedbackService.update(action.payload.id, action.payload).pipe(
            map((feedback) => actions.UpdateFeedbackSuccess({ payload: feedback })),
            catchError((error) => of(actions.UpdateFeedbackFail(error)))
          )
        ),
      )
  });
}
