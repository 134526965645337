import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-timeclock-widget',
  templateUrl: './timeclock-widget.component.html',
  styleUrls: ['../widget.styles.scss', './timeclock-widget.component.scss']
})
export class TimeclockWidgetComponent implements OnInit {
  @Input() brightness$: Observable<'light'|'dark'>;
  @Input('WeeklyHours') weeklyHours: number;
  @Input('DailyHours') dailyHours: number;
  @Input('ClockedIn') clockedIn: boolean;
  @Input('Loading$') timeclockLoading$: Observable<boolean>;
  @Output() hideWidgetEvent = new EventEmitter<string>();
  @Output() clockInEvent = new EventEmitter<void>();
  @Output() clockOutEvent = new EventEmitter<void>();
  
  constructor() { }

  ngOnInit() {
  }

  hideWidget() {
    this.hideWidgetEvent.emit('timeclock');
  }

  clockIn() {
    this.clockInEvent.emit();
  }

  clockOut() {
    this.clockOutEvent.emit();
  }
}
