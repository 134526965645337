import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IStation } from '../../interfaces/seacom/station';

import * as actions from '../actions';
import { addItem, deleteItem, updateItem, updateItems } from '../utils/itementity';


export const stationFeatureKey = 'stations';

export interface StationState {
	entities: { [id: string]: IStation },
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const StationInitialState: StationState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

export const StationReducer = createReducer(
	StationInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...StationInitialState
		} as StationState;
	}),
	// Load Stations
	on(actions.LoadStations, (state, action) => {
		return {
			...state,
			loading: true,
		} as StationState;
	}),
	on(actions.LoadStationsCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as StationState;
	}),
	on(actions.LoadStationsSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as StationState;
	}),
	on(actions.LoadStationsFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as StationState;
	}),

	// Load Station
	on(actions.LoadStation, (state, action) => {
		return {
			...state,
			loading: true,
		} as StationState;
	}),
	on(actions.LoadStationSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as StationState;
	}),
	on(actions.LoadStationFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as StationState;
	}),

	// Update Station
	on(actions.UpdateStation, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.fromOperationsPageActions.UpdateStationResponders, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateStationSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateStationRespondersSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateStationFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),
	on(actions.UpdateStationRespondersFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),

	// Add Station
	on(actions.AddStation, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddStationSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddStationFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),
	on(actions.SetStationStatusSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities)
		};
	}),

	// Delete Station
	on(actions.DeleteStation, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteStationSuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteStationFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	}),
);
