import { createAction, props } from '@ngrx/store';
import { INotification, INewNotification, IUpdatedNotification } from 'src/app/interfaces/seacom/notification';

// alert action types
export const LOAD_NOTIFICATIONS = '[Page Effects] Load Notifications';
export const LOAD_NOTIFICATIONS_CANCELLED = '[Notifications Effects] Load Notifications Cancelled';
export const LOAD_NOTIFICATIONS_SUCCESS = '[Notifications Effects] Load Notifications Success';
export const LOAD_NOTIFICATIONS_FAIL = '[Notifications Effects] Load Notifications Fail';
export const LOAD_NOTIFICATION = '[Component Effects] Load Notification';
export const LOAD_NOTIFICATION_FAIL = '[Notifications Effects] Load Notification Fail';
export const LOAD_NOTIFICATION_SUCCESS = '[Notifications Effects] Load Notification Success';
export const UPDATE_NOTIFICATION = '[SEACOM] Update Notification';
export const UPDATE_NOTIFICATION_FAIL = '[Notifications Effects] Update Notification Fail';
export const UPDATE_NOTIFICATION_SUCCESS = '[Notifications Effects] Update Notification Success';
export const UPDATE_NOTIFICATION_FIELDS = '[SEACOM] Update Notification Fields';
export const UPDATE_NOTIFICATION_FIELDS_FAIL = '[Notifications Effects] Update Notification Fields Fail';
export const UPDATE_NOTIFICATION_FIELDS_SUCCESS = '[Notifications Effects] Update Notification Fields Success';
export const ADD_NOTIFICATION = '[SEACOM] Add Notification';
export const ADD_NOTIFICATION_FAIL = '[Notifications Effects] Add Notification Fail';
export const ADD_NOTIFICATION_SUCCESS = '[Notifications Effects] Add Notification Success';
export const DELETE_NOTIFICATION = '[SEACOM] Delete Notification';
export const DELETE_NOTIFICATION_FAIL = '[Notifications Effects] Delete Notification Fail';
export const DELETE_NOTIFICATION_SUCCESS = '[Notifications Effects] Delete Notification Success';

export const LoadNotifications = createAction(
	LOAD_NOTIFICATIONS
);

export const LoadNotificationsCancelled = createAction(
    LOAD_NOTIFICATIONS_CANCELLED
);

export const LoadNotificationsSuccess = createAction(
    LOAD_NOTIFICATIONS_SUCCESS,
    props<{
        payload: INotification[]
    }>()
);

export const LoadNotificationsFail = createAction(
    LOAD_NOTIFICATIONS_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadNotification = createAction(
	LOAD_NOTIFICATION,
    props<{
        payload: string // ID
    }>()
);

export const LoadNotificationSuccess = createAction(
    LOAD_NOTIFICATION_SUCCESS,
    props<{
        payload: INotification
    }>()
);

export const LoadNotificationFail = createAction(
    LOAD_NOTIFICATION_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateNotification = createAction(
    UPDATE_NOTIFICATION,
    props<{
        payload: IUpdatedNotification
    }>()
);

export const UpdateNotificationSuccess = createAction(
    UPDATE_NOTIFICATION_SUCCESS,
    props<{
        payload: INotification
    }>()
);

export const UpdateNotificationFail = createAction(
    UPDATE_NOTIFICATION_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateNotificationFields = createAction(
    UPDATE_NOTIFICATION_FIELDS,
    props<{
        payload: IUpdatedNotification
    }>()
);

export const UpdateNotificationFieldsSuccess = createAction(
    UPDATE_NOTIFICATION_FIELDS_SUCCESS,
    props<{
        payload: INotification
    }>()
);

export const UpdateNotificationFieldsFail = createAction(
    UPDATE_NOTIFICATION_FIELDS_FAIL,
    props<{
        payload: any
    }>()
);

export const AddNotification = createAction(
    ADD_NOTIFICATION,
    props<{
        payload: INewNotification
    }>()
);

export const AddNotificationSuccess = createAction(
    ADD_NOTIFICATION_SUCCESS,
    props<{
        payload: INotification
    }>()
);

export const AddNotificationFail = createAction(
    ADD_NOTIFICATION_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteNotification = createAction(
    DELETE_NOTIFICATION,
    props<{
        payload: string // ID
    }>()
);

export const DeleteNotificationSuccess = createAction(
    DELETE_NOTIFICATION_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteNotificationFail = createAction(
    DELETE_NOTIFICATION_FAIL,
    props<{
        payload: any
    }>()
);
