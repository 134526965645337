import { createAction, props } from '@ngrx/store';
import { INewRegion, IRegion, IUpdatedRegion } from 'src/app/interfaces/seacom/region';

// region action types
export const LOAD_REGIONS = '[Page Effects] Load Regions';
export const LOAD_REGIONS_CANCELLED = '[Regions Effects] Load Regions Cancelled';
export const LOAD_REGIONS_FAIL = '[Regions Effects] Load Regions Fail';
export const LOAD_REGIONS_SUCCESS = '[Regions Effects] Load Regions Success';
export const LOAD_REGION = '[Component Effects] Load Region';
export const LOAD_REGION_FAIL = '[Regions Effects] Load Region Fail';
export const LOAD_REGION_SUCCESS = '[Regions Effects] Load Region Success';
export const UPDATE_REGION = '[SEACOM] Update Region';
export const UPDATE_REGION_FAIL = '[Regions Effects] Update Region Fail';
export const UPDATE_REGION_SUCCESS = '[Regions Effects] Update Region Success';
export const ADD_REGION = '[SEACOM] Add Region';
export const ADD_REGION_FAIL = '[Regions Effects] Add Region Fail';
export const ADD_REGION_SUCCESS = '[Regions Effects] Add Region Success';
export const DELETE_REGION = '[SEACOM] Delete Region';
export const DELETE_REGION_FAIL = '[Regions Effects] Delete Region Fail';
export const DELETE_REGION_SUCCESS = '[Regions Effects] Delete Region Success';

export const LoadRegions = createAction(
	LOAD_REGIONS
);

export const LoadRegionsCancelled = createAction(
	LOAD_REGIONS_CANCELLED
);

export const LoadRegionsSuccess = createAction(
    LOAD_REGIONS_SUCCESS,
    props<{
        payload: IRegion[]
    }>()
);

export const LoadRegionsFail = createAction(
    LOAD_REGIONS_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadRegion = createAction(
	LOAD_REGION,
    props<{
        payload: string // ID
    }>()
);

export const LoadRegionSuccess = createAction(
    LOAD_REGION_SUCCESS,
    props<{
        payload: IRegion
    }>()
);

export const LoadRegionFail = createAction(
    LOAD_REGION_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateRegion = createAction(
    UPDATE_REGION,
    props<{
        payload: IUpdatedRegion
    }>()
);

export const UpdateRegionSuccess = createAction(
    UPDATE_REGION_SUCCESS,
    props<{
        payload: IRegion
    }>()
);

export const UpdateRegionFail = createAction(
    UPDATE_REGION_FAIL,
    props<{
        payload: any
    }>()
);

export const AddRegion = createAction(
    ADD_REGION,
    props<{
        payload: INewRegion
    }>()
);

export const AddRegionSuccess = createAction(
    ADD_REGION_SUCCESS,
    props<{
        payload: IRegion
    }>()
);

export const AddRegionFail = createAction(
    ADD_REGION_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteRegion = createAction(
    DELETE_REGION,
    props<{
        payload: string // ID
    }>()
);

export const DeleteRegionSuccess = createAction(
    DELETE_REGION_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteRegionFail = createAction(
    DELETE_REGION_FAIL,
    props<{
        payload: any
    }>()
);
