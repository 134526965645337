import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IBeach } from 'src/app/interfaces/seacom/beach';
import * as fromReducers from '../reducers/beach.reducers';
import { sortFactory } from '../utils/sort.utils';

export const selectBeachState = createFeatureSelector<fromReducers.BeachState>(
  fromReducers.beachFeatureKey
);

export const selectBeachesCount = createSelector(
  selectBeachState,
  (state: fromReducers.BeachState): number => Object.keys(state.entities).length
);

export const selectBeachesLastUpdated = createSelector(
  selectBeachState,
  (state: fromReducers.BeachState): string => state.lastUpdated
);

export const selectBeachLoading = createSelector(
  selectBeachState,
  (state: fromReducers.BeachState): boolean => state.loading
);

export const selectAllBeaches = createSelector(
  selectBeachesCount,
  selectBeachState,
  (count: number, state: fromReducers.BeachState): IBeach[] => {
    if(count > 0) {
      return Object.keys(state.entities)
        .map(e => state.entities[e])
        .sort((a,b) => sortFactory(a,b,[{field: 'name', ascending: true}]));
    } else {
      return [];
    }
  }
);

export const selectBeachById = (id: string) => createSelector(selectBeachState, (beaches): IBeach => {
  return beaches?.entities[id];
});
