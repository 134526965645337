// Angular
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  @Input() brightness$: Observable<'light'|'dark'>;

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
