// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap, last } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// State
import { SeacomState } from '../reducers';

// Actions
import * as actions from '../actions';

// Selectors
import { selectIncidentsLastUpdated } from '../selectors/incident.selectors';

// Interfaces
import { IIncident } from 'src/app/interfaces/seacom/incident';
import { IFieldFilter } from '../../interfaces/seacom/fieldfilter';

// Services
import { EnvironmentService } from 'src/app/services/environment.service';
import { IncidentsService } from 'src/app/services/seacom/incidents.service';

// Utilities
import { dedupeItemsMap, toLocalISOString } from 'src/app/common/globals';


@Injectable()
export class IncidentEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private incidentsService: IncidentsService
  ) { }

  loadIncidents$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Incidents
        ofType(
          actions.LoadIncidents
        ),
        concatLatestFrom((action) => this.store.select(selectIncidentsLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            let starttime = new Date(now);
            starttime.setDate(now.getDate() - 1);
            let last24Filter = [
              {
                field: 'starttime__gte',
                filter: toLocalISOString(starttime)
              }
            ] as IFieldFilter[];
            let notClosedFilter = [
              {
                field: 'status__in',
                filter: [
                  'new',
                  'dispatching',
                  'pending',
                  'en route', 
                  'responding',
                  'resolved'
                ]
              }
            ] as IFieldFilter[];
            return combineLatest([
              this.incidentsService.getList(last24Filter),
              this.incidentsService.getList(notClosedFilter)
            ]).pipe(
              dedupeItemsMap(),
              map((incidents: IIncident[]) => actions.LoadIncidentsSuccess({ payload: incidents })),
              catchError((error) => of(actions.LoadIncidentsFail(error)))
            );
          } else {
            return of(actions.LoadIncidentsCancelled());
          }
        })
      );
  });
  
  loadIncidentsWithFilters$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Incidents with Filters
        ofType(actions.LoadIncidentsWithFilters),
        exhaustMap((action) => this.incidentsService.getList(action.filters, action.order, action.search).pipe(
          map((incidents) => actions.LoadIncidentsSuccess({ payload: incidents })),
          catchError((error) => of(actions.LoadIncidentsFail(error)))
        ))
      );
  });

  loadIncident$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Incident
        ofType(actions.LoadIncident),
        exhaustMap((action) => this.incidentsService.get(action.payload).pipe(
          map((incident) => actions.LoadIncidentSuccess({ payload: incident })),
          catchError((error) => of(actions.LoadIncidentsFail(error)))
        ))
      );
  });

  loadIncidentWithNewMetadata$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Incident
        ofType(
          actions.UpdateMetadataFieldsSuccess,
          actions.AddMetadataSuccess
        ),
        exhaustMap((action) => {
          if (action.payload.incident !== undefined && action.payload.incident !== null) {
            return this.incidentsService.get(action.payload.incident.id).pipe(
              map((incident) => actions.LoadIncidentSuccess({ payload: incident })),
              catchError((error) => {
                console.log(error);
                return of(actions.LoadIncidentFail(error));
              })
            );
          } else {
            return of(actions.LoadIncidentFail({ payload: 'Not incident related' }));
          }
        }),
      );
  });

  addIncident$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add Incident
        ofType(actions.AddIncident),
        concatMap((action) => this.incidentsService.create(action.payload).pipe(
          map((incident) => actions.AddIncidentSuccess({ payload: incident })),
          catchError((error) => of(actions.AddIncidentFail(error)))
        ))
      );
  });

  addQuickIncident$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.AddQuickIncident
        ),
        exhaustMap((action) => this.incidentsService.create(action.payload.quickIncident).pipe(
          map((incident) => actions.AddQuickIncidentSuccess({ payload: { quickIncident: incident, firstResponse: { ...action.payload.firstResponse, incident: incident.id } } })),
          catchError((error) => of(actions.AddQuickIncidentFail(error)))
        ))
      )
  });

  updateIncident$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Incident
        ofType(actions.UpdateIncident),
        concatMap((action) => this.incidentsService.update(action.payload.id, action.payload).pipe(
          map((incident) => actions.UpdateIncidentSuccess({ payload: incident })),
          catchError((error) => of(actions.UpdateIncidentFail(error)))
        ))
      );
  });

  updateIncidentFields$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Incident fields
        ofType(actions.UpdateIncidentFields),
        concatMap((action) => this.incidentsService.updateFields(action.payload.id, action.payload).pipe(
          map((incident) => actions.UpdateIncidentSuccess({ payload: incident })),
          catchError((error) => of(actions.UpdateIncidentFail({ payload: { id: action.payload.id, error: error }})))
        ))
      );
  });

  deleteIncident$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete Incident
        ofType(actions.DeleteIncident),
        mergeMap((action) =>
          this.incidentsService.delete(action.payload).pipe(
            map((incidentId) => actions.DeleteIncidentSuccess({ payload: incidentId })),
            catchError((error) => of(actions.DeleteIncidentFail({ payload: { id: action.payload, error: error }})))
          )
        )
      )
  });
}
