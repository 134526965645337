import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { RunbookWidgetComponent } from './runbook-widget.component';
import { LoadingSpinnerModule } from '../../loading-spinner/loading-spinner.module';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    LoadingSpinnerModule
  ],
  declarations: [
    RunbookWidgetComponent
  ],
  exports: [
    RunbookWidgetComponent
  ]
})
export class RunbookWidgetModule { }
