import { createAction, props } from '@ngrx/store';
import { ITimeclock } from 'src/app/interfaces/seacom/timeclock';

// alert action types
export const LOAD_TIMECLOCK = '[SEACOM] Load Timeclock';
export const LOAD_TIMECLOCK_CANCELLED = '[Timeclock Effect] Load Timeclock Cancelled';
export const LOAD_TIMECLOCK_SUCCESS = '[Timeclock Effect] Load Timeclock Success';
export const LOAD_TIMECLOCK_FAIL = '[Timeclock Effect] Load Timeclock Fail';
export const CLOCK_IN_SUCCESS = '[Timeclock Effect] Clock In Success';
export const CLOCK_IN_FAIL = '[Timeclock Effect] Clock In Fail';
export const CLOCK_OUT_SUCCESS = '[Timeclock Effect] Clock Out Success';
export const CLOCK_OUT_FAIL = '[Timeclock Effect] Clock Out Fail';


export const LoadTimeclock = createAction(
	LOAD_TIMECLOCK
);

export const LoadTimeclockCancelled = createAction(
    LOAD_TIMECLOCK_CANCELLED
);

export const LoadTimeclockSuccess = createAction(
    LOAD_TIMECLOCK_SUCCESS,
    props<{
        payload: ITimeclock
    }>()
);

export const LoadTimeclockFail = createAction(
    LOAD_TIMECLOCK_FAIL,
    props<{
        payload: any
    }>()
);

export const ClockInSuccess = createAction(
    CLOCK_IN_SUCCESS,
    props<{
        payload: ITimeclock
    }>()
);

export const ClockInFail = createAction(
    CLOCK_IN_FAIL,
    props<{
        error: any
    }>()
);

export const ClockOutSuccess = createAction(
    CLOCK_OUT_SUCCESS,
    props<{
        payload: ITimeclock
    }>()
);

export const ClockOutFail = createAction(
    CLOCK_OUT_FAIL,
    props<{
        error: any
    }>()
);
