// NGRX
import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';

// Interfaces
import { IAmbiance } from '../../interfaces/ambiance';

// Actions
import * as actions from '../actions';


export const ambianceFeatureKey = 'ambiances';

export interface AmbianceState {
	ambiance: IAmbiance,
	lastUpdated: string,
}

export const AmbianceInitialState: AmbianceState = {
	ambiance: {
		brightness: 'light'
	},
	lastUpdated: toLocalISOString(new Date()),
};

export const AmbianceReducer = createReducer(
	AmbianceInitialState,
	// Set Brightness
	on(actions.SetBrightness, (state, action) => {
		return {
			...state,
			ambiance: {
				...state.ambiance,
				brightness: action.payload.brightness
			},
			lastUpdated: toLocalISOString(new Date())
		} as AmbianceState;
	})
);
