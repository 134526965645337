// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Ionic
import { IonicModule } from '@ionic/angular';

// Components
import { LoadingSpinnerComponent } from './loading-spinner.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule
    ],
    declarations: [
        LoadingSpinnerComponent,
    ],
    exports: [
        LoadingSpinnerComponent,
    ]
})

export class LoadingSpinnerModule { }
