import { createAction, props } from '@ngrx/store';
import { IFeedback, INewFeedback, IUpdatedFeedback } from 'src/app/interfaces/seacom/feedback';

// feedback action types
export const LOAD_FEEDBACKS = '[Page Effects] Load Feedbacks';
export const LOAD_FEEDBACKS_CANCELLED = '[Feedback Effects] Load Feedbacks Cancelled';
export const LOAD_FEEDBACKS_FAIL = '[Feedback Effects] Load Feedbacks Fail';
export const LOAD_FEEDBACKS_SUCCESS = '[Feedback Effects] Load Feedbacks Success';
export const UPDATE_FEEDBACK = '[SEACOM] Update Feedback';
export const UPDATE_FEEDBACK_FAIL = '[Feedback Effects] Update Feedback Fail';
export const UPDATE_FEEDBACK_SUCCESS = '[Feedback Effects] Update Feedback Success';
export const ADD_FEEDBACK = '[SEACOM] Add Feedback';
export const ADD_FEEDBACK_FAIL = '[Feedback Effects] Add Feedback Fail';
export const ADD_FEEDBACK_SUCCESS = '[Feedback Effects] Add Feedback Success';

export const LoadFeedbacks = createAction(
	LOAD_FEEDBACKS
);

export const LoadFeedbacksCancelled = createAction(
    LOAD_FEEDBACKS_CANCELLED
);

export const LoadFeedbacksSuccess = createAction(
    LOAD_FEEDBACKS_SUCCESS,
    props<{
        payload: IFeedback[]
    }>()
);

export const LoadFeedbacksFail = createAction(
    LOAD_FEEDBACKS_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateFeedback = createAction(
    UPDATE_FEEDBACK,
    props<{
        payload: IUpdatedFeedback
    }>()
);

export const UpdateFeedbackSuccess = createAction(
    UPDATE_FEEDBACK_SUCCESS,
    props<{
        payload: IFeedback
    }>()
);

export const UpdateFeedbackFail = createAction(
    UPDATE_FEEDBACK_FAIL,
    props<{
        payload: any
    }>()
);

export const AddFeedback = createAction(
    ADD_FEEDBACK,
    props<{
        payload: INewFeedback
    }>()
);

export const AddFeedbackSuccess = createAction(
    ADD_FEEDBACK_SUCCESS,
    props<{
        payload: IFeedback
    }>()
);

export const AddFeedbackFail = createAction(
    ADD_FEEDBACK_FAIL,
    props<{
        payload: any
    }>()
);
