import { createAction, props } from "@ngrx/store";
import { IWidget } from "src/app/interfaces/widget";
import { INewIncident } from '../../../interfaces/seacom/incident';

const actionKey = '[Home Page] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

export const CreateEmergencyIncident = createAction(
  actionKey + 'Create Emergency Incident'
);

export const AddEmergencyIncident = createAction(
  actionKey + 'Add Emergency Incident',
  props<{ payload: INewIncident }>()
);

export const HideWidget = createAction(
  actionKey + 'Hide Widget',
  props<{ payload: { widgetName: string }}>()
);

export const NavigateToRunbookPage = createAction(
  actionKey + 'Navigate to Runbook page'
);

export const NavigateToNotificationsPage = createAction(
  actionKey + 'Navigate to Notifications page'
);

export const NavigateToConditionsPage = createAction(
  actionKey + 'Navigate to Conditions page'
);

export const TimeclockClockIn = createAction(
  actionKey + 'Timeclock Clock In',
  props<{ payload: { type: string, start: Date }}>()
);

export const TimeclockClockOut = createAction(
  actionKey + 'Timeclock Clock Out',
  props<{ payload: { end: Date }}>()
);

export const NavigateToStationsPage = createAction(
  actionKey + 'Navigate to Stations page'
);

export const NavigateToOperationsPage = createAction(
  actionKey + 'Navigate to Operations page'
);

const droppableKey = '[Settings Page Droppable] ';

export const SetHomepageWidgetOrder = createAction(
  droppableKey + 'Set Homepage Widgets',
  props<{ payload: { newWidgets: IWidget[] }}>()
);
