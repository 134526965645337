import { createAction, props } from "@ngrx/store";
import { INewIncident, IUpdatedIncident } from "src/app/interfaces/seacom/incident";
import { INewIncidentResponse, IUpdatedIncidentResponse } from "src/app/interfaces/seacom/incidentresponse";
import { INewMetadata, IUpdatedMetadata } from "src/app/interfaces/seacom/metadata";

const actionKey = '[Incident Form] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

// Incidents
export const CreateIncidentFormIncident = createAction(
  actionKey + 'Create Incident',
  props<{ payload: INewIncident }>()
);

export const UpdateIncidentFormIncidentFields = createAction(
  actionKey + 'Update Incident Fields',
  props<{ payload: IUpdatedIncident }>()
);

// Incident Responses
export const CreateIncidentFormResponse = createAction(
  actionKey + 'Create Incident Response',
  props<{ payload: INewIncidentResponse }>()
);

export const UpdateIncidentFormResponse = createAction(
  actionKey + 'Update Incident Response',
  props<{ payload: IUpdatedIncidentResponse }>()
);

export const DeleteIncidentFormResponse = createAction(
  actionKey + 'Delete Incident Response',
  props<{ payload: string }>()
);

// Metadata
export const CreateMetadata = createAction(
  actionKey + 'Create Metadata',
  props<{ payload: INewMetadata }>()
);

export const UpdateMetadata = createAction(
  actionKey + 'Update Metadata',
  props<{ payload: IUpdatedMetadata }>()
);
