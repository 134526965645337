import { createAction, props } from "@ngrx/store";

const actionKey = '[Header Bar] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

export const NavigateToHomePage = createAction(
  actionKey + 'Navigate To Home Page'
);

export const Logout = createAction(
  actionKey + 'Logout'
);

export const DisplayProfileMenu = createAction(
  actionKey + 'Display Profile Menu'
);

export const HideProfileMenu = createAction(
  actionKey + 'Hide Profile Menu'
);

export const NavigateToSettingsPage = createAction(
  actionKey + 'Navigate To Settings Page'
);

export const NavigateToFeedbackPage = createAction(
  actionKey + 'Navigate To Feedback Page'
);
