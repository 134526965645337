// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { IColumnSetting, INewColumnSetting, IUpdatedColumnSetting } from 'src/app/interfaces/seacom/columnsetting';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';


@Injectable({
  providedIn: 'root'
})
export class ColumnSettingsService extends ApiNodeService<IColumnSetting, INewColumnSetting, IUpdatedColumnSetting> {
  node = 'columnsettings';

  constructor(public seacomApiService: SeacomApiService) {
    super(seacomApiService);
  }

}
