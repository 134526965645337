// Angular
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

// RXJS
import { of } from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Store
import { AuthState } from '../store/reducers/auth.reducers';

// Services
import { AuthService } from './auth.service';

// Actions
import { ReturnToLogin } from '../store/actions/auth.actions';

// Selectors
import { selectIsAuthenticated } from '../store/selectors/auth.selectors';



@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private store: Store<AuthState>,
    private authService: AuthService
  ) { }

  checkAuthentication() {
    return this.store.select(selectIsAuthenticated).pipe(first());
  }

  checkTokenSanity() {
    return this.authService.checkTokenExpiration().pipe(first());
  }

  canActivate() {
    // Checks token expiration, 
    //  if it succeeds, check authentication state
    //    if it succeeds, route to page
    //    if it fails, return to login page
    //  if it fails, return to login page
    return this.checkTokenSanity().pipe(
      map((tokenSanity) => {
        if (!tokenSanity) {
          this.store.dispatch(ReturnToLogin({
            payload: {
              apiError: "",
              loginError: "Your session has timed out!"
            }
          }));
          return of(false);
        }
        return this.checkAuthentication();
      }),
      map((authenticated) => {
        if (!authenticated) {
          this.store.dispatch(ReturnToLogin({
            payload: {
              apiError: "",
              loginError: "Your session has timed out!"
            }
          }));
          return false;
        }

        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
}
