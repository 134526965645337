import { createAction, props } from '@ngrx/store';

export * from './notification.actions';
export * from './ambiance.actions';
export * from './auth.actions';
export * from './beach.actions';
export * from './table.actions';
export * from './containersetting.actions';
export * from './district.actions';
export * from './feedback.actions';
export * from './incident.actions';
export * from './incidentresponse.actions';
export * from './incidentseverity.actions';
export * from './incidenttype.actions';
export * from './location.actions';
export * from './metadata.actions';
export * from './region.actions';
export * from './station.actions';
export * from './stationsetting.actions';
export * from './table.actions';
export * from './timeclock.actions';
export * from './user.actions';
export * from './weather.actions';

export * as fromAppActions from '../../app.actions';
export * as fromNotificationsPageActions from '../../containers/notifications/store/notifications.page.actions';
export * as fromColumnSelectorActions from '../../components/column-selector/store/columnselector.actions';
export * as fromConditionsPageActions from '../../containers/conditions/store/conditions.page.actions';
export * as fromOperationsPageActions from '../../containers/operations/store/operations.page.actions';
export * as fromFeedbackPageActions from '../../containers/feedback/store/feedback.page.actions';
export * as fromFooterbarActions from '../../components/footerbar/store/footerbar.actions';
export * as fromHeaderbarActions from '../../components/headerbar/store/headerbar.actions';
export * as fromHomePageActions from '../../containers/home/store/home.page.actions';
export * as fromIncidentFormActions from '../../containers/incidentform/store/incidentform.actions';
export * as fromRunbookPageActions from '../../containers/runbook/store/runbook.page.actions';
export * as fromLoginPageActions from '../../containers/login/store/login.page.actions';
export * as fromProfilePageActions from '../../containers/settings/store/settings.page.actions';
export * as fromStationsPageActions from '../../containers/stations/store/stations.page.actions';

export const CLEAR_ALL_STATE: string = '[SEACOM] Clear All State';
export const API_REACHABILITY_ERROR: string = '[SEACOM] API Reachability Error';

export const ClearAllState = createAction(
  CLEAR_ALL_STATE
);

export const APIReachabilityError = createAction(
  API_REACHABILITY_ERROR,
  props<{ payload: { apiError: string }}>()
);
