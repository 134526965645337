import { createAction, props } from "@ngrx/store";

const actionKey = '[Footer Bar] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

export const NavigateBack = createAction(
  actionKey + 'Navigate Back'
)

export const ChangeBrightness = createAction(
  actionKey + 'Change Brightness',
  props<{payload: { brightness: 'light'|'dark' } }>()
)
