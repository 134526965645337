import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INotification } from 'src/app/interfaces/seacom/notification';
import * as fromReducers from '../reducers/notification.reducers';
import { sortFactory } from '../utils/sort.utils';

export const selectNotificationState = createFeatureSelector<fromReducers.NotificationState>(
  fromReducers.notificationFeatureKey
);

export const selectNotificationsCount = createSelector(
  selectNotificationState,
  (state: fromReducers.NotificationState): number => Object.keys(state.entities).length
);

export const selectNotificationsLastUpdated = createSelector(
  selectNotificationState,
  (state: fromReducers.NotificationState): string => state.lastUpdated
);

export const selectNotificationsLoading = createSelector(
  selectNotificationState,
  (state: fromReducers.NotificationState): boolean => state.loading
);

export const selectAllNotifications = createSelector(
  selectNotificationsCount,
  selectNotificationState,
  (count: number, state: fromReducers.NotificationState): INotification[] => {
    if(count > 0) {
      return Object.keys(state.entities)
        .map(e => state.entities[e])
        .sort((a,b) => sortFactory(a,b,[{field: 'created', ascending: false}]));
    } else {
      return [];
    }
  }
);

export const selectNotificationsCountByStatus = (status: string) => createSelector(
  selectAllNotifications,
  (alerts): number => {
    return alerts.filter((a: INotification) =>
      a.status === status
    ).length;
  }
);

export const selectNotificationById = (id: string) => createSelector(selectNotificationState, (alerts): INotification => {
  return alerts?.entities[id];
});

export const selectNotificationsByStatus = (status: string) => createSelector(selectAllNotifications, (alerts): INotification[] => {
  return alerts
    .filter(a => a.status === status)
    .sort((a, b) => sortFactory(a, b, [{field: 'created', ascending: false}]));
});

export const selectNotificationsBySeverity = (severity: string) => createSelector(selectAllNotifications, (alerts): INotification[] => {
  return alerts
    .filter(a => a.severity === severity)
    .sort((a, b) => sortFactory(a, b, [{field: 'created', ascending: false}]));
});
