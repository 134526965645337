import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMetadata } from 'src/app/interfaces/seacom/metadata';
import * as fromReducers from '../reducers/metadata.reducers';
import { sortFactory } from '../utils/sort.utils';

export const selectMetadataState = createFeatureSelector<fromReducers.MetadataState>(
  fromReducers.metadataFeatureKey
);

export const selectMetadatasLastUpdated = createSelector(
  selectMetadataState,
  (state: fromReducers.MetadataState): string => state.lastUpdated
);

export const selectMetadatasCount = createSelector(
  selectMetadataState,
  (state: fromReducers.MetadataState): number => Object.keys(state.entities).length
);

export const selectAllMetadatas = createSelector(
  selectMetadatasCount,
  selectMetadataState,
  (count: number, state: fromReducers.MetadataState): IMetadata[] => {
    if(count > 0) {
      return Object.keys(state.entities)
        .map(e => state.entities[e]);
    } else {
      return [];
    }
  }
);

export const selectMetadataById = (id: string) => createSelector(selectMetadataState, (state: fromReducers.MetadataState): IMetadata => {
  return state.entities[id];
});

export const selectMetadataByFieldId = (id: string) => createSelector(selectAllMetadatas, (metadatas): IMetadata[] => {
  return metadatas.filter(m => m.field.id === id);
});

export const selectMetadataByIncidentId = (id: string) => createSelector(selectAllMetadatas, (metadatas): IMetadata[] => {
  return metadatas.filter(m => m.incident.id === id);
});

export const selectMetadataByResponseId = (id: string) => createSelector(selectAllMetadatas, (metadatas): IMetadata[] => {
  return metadatas.filter(m => m.response.id === id);
});
