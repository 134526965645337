// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { IMetadata, INewMetadata, IUpdatedMetadata } from '../../interfaces/seacom/metadata';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';


@Injectable({
  providedIn: 'root'
})
export class MetadatasService extends ApiNodeService<IMetadata, INewMetadata, IUpdatedMetadata> {
  node = 'metadata';

  constructor(public seacomApiService: SeacomApiService) {
    super(seacomApiService);
  }

}
