import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IIncidentSeverity } from '../../interfaces/seacom/incidentseverity';

import * as actions from '../actions';
import { addItem, deleteItem, updateItem, updateItems } from '../utils/itementity';


export const incidentSeverityFeatureKey = 'incidentseverities';

export interface IncidentSeverityState {
	entities: { [id: string]: IIncidentSeverity },
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const IncidentSeverityInitialState: IncidentSeverityState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

export const IncidentSeverityReducer = createReducer(
	IncidentSeverityInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...IncidentSeverityInitialState
		} as IncidentSeverityState;
	}),
	// Load IncidentSeverities
	on(actions.LoadIncidentSeverities, (state, action) => {
		return {
			...state,
			loading: true,
		} as IncidentSeverityState;
	}),
	on(actions.LoadIncidentSeveritiesCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as IncidentSeverityState;
	}),
	on(actions.LoadIncidentSeveritiesSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as IncidentSeverityState;
	}),
	on(actions.LoadIncidentSeveritiesFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as IncidentSeverityState;
	}),

	// Load IncidentSeverity
	on(actions.LoadIncidentSeverity, (state, action) => {
		return {
			...state,
			loading: true,
		} as IncidentSeverityState;
	}),
	on(actions.LoadIncidentSeveritySuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as IncidentSeverityState;
	}),
	on(actions.LoadIncidentSeverityFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as IncidentSeverityState;
	}),

	// Update IncidentSeverity
	on(actions.UpdateIncidentSeverity, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateIncidentSeveritySuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateIncidentSeverityFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),

	// Add IncidentSeverity
	on(actions.AddIncidentSeverity, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddIncidentSeveritySuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddIncidentSeverityFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),

	// Delete IncidentSeverity
	on(actions.DeleteIncidentSeverity, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteIncidentSeveritySuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteIncidentSeverityFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	}),
);
