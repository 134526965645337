import { IColumnSetting } from "src/app/interfaces/seacom/columnsetting";
import { ISortField } from "src/app/interfaces/sortfield";

export function sortFactory(a: object, b: object, field: ISortField[]) {
  let prevRes: number = null;
  field.forEach(f => {
    if (prevRes !== 0 && prevRes !== null) {
      return;
    }
    prevRes = sortCompare(a, b, f.field, f.ascending);
  });
  return prevRes;
}

function sortCompare(a: object, b: object, field: string, asc: boolean = true): number {
  let res: number;

  switch (typeof (a[field])) {
    case 'number':
      if (a[field] > b[field]) {
        res = 1;
      } else if (a[field] === b[field]) {
        res = 0;
      } else {
        res = -1;
      }
      break;
    case 'boolean':
      if (a[field] && !b[field]) {
        res = 1;
      } else if (a[field] === b[field]) {
        res = 0;
      } else {
        res = -1;
      }
      break;
    case 'object':
      if (a[field] instanceof Date) {
        if (a[field] > b[field]) {
          res = 1;
        } else if (a[field] === b[field]) {
          res = 0;
        } else {
          res = -1;
        }
      } else if (a[field] === null) {
        if (b[field] !== undefined && b[field] !== null) {
          res = -1;
        } else if (b[field] === undefined || b[field] === null) {
          res = 0;
        } else {
          res = 1;
        }
      } else if (b[field] === null) {
        if (a[field] !== undefined && a[field] !== null) {
          res = 1;
        } else if (a[field] === undefined || a[field] === null) {
          res = 0;
        } else {
          res = -1;
        }
      } else {
        res = a[field].id.localeCompare(b[field].id);
      }
      break;
    case 'string':
    default:
      if(a[field] !== undefined && b[field] !== undefined) {
        res = a[field].localeCompare(b[field]);
      } else if(a[field] === undefined) {
        res = -1;
      } else {
        res = 1;
      }
  }

  if (asc) {
    return res;
  } else {
    return -1 * res;
  }
}
