// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

// RXJS
import { Observable } from 'rxjs';
import { first, timeout } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Services
import { EnvironmentService } from '../environment.service';

// Store
import { AuthState } from 'src/app/store/reducers/auth.reducers';

// Utils
import { skipNull } from '../../common/globals';
import { selectAccessToken } from 'src/app/store/selectors/auth.selectors';
import { filtersToQueryString, IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';


@Injectable({
    providedIn: 'root'
})
export class SeacomApiService {
    constructor(
        private environmentService: EnvironmentService,
        private httpClient: HttpClient,
        private authStore: Store<AuthState>,
    ) { }

    /** Retrieves a list of items identified by "node" parameter utilizing the provided parameters. */
    public getItemList(
        nodePath: string,
        queryparams?: IFieldFilter[],
        orderparams?: string,
        searchparam?: string,
        authRequest: boolean = true
    ): Observable<HttpResponse<any>> {
        let authHeaders = {};

        if (authRequest) {
            this.authStore.select(selectAccessToken).pipe(skipNull(), first()).subscribe(
                (accessToken) => {
                    authHeaders = {
                        Authorization: 'Bearer ' + accessToken
                    };
                }
            );
        }

        let params: string;

        if (searchparam !== undefined && searchparam !== null && searchparam.length > 0) {
            // Append search parameter
            params = '?search=' + searchparam;
        } else {
            // Append filter parameters
            params = filtersToQueryString(queryparams);
        }

        // Append order parameter
        if (orderparams !== undefined && orderparams !== null && orderparams.length > 0) {
            if (
                (queryparams !== undefined && queryparams !== null && Array.isArray(queryparams)) ||
                (searchparam !== undefined && searchparam !== null && searchparam.length > 0)
            ) {
                params = params + '&';
            } else {
                params = params + '?';
            }
            params = params + 'order=' + orderparams;
        }

        if (!this.environmentService.production) {
            console.log('Making GET request to: ' + this.environmentService.apiURL + nodePath + params);
        }

        // Perform GET (or timeout after 3 seconds) and return observable IApiResult
        return this.httpClient.get<any>(this.environmentService.apiURL + nodePath + params, { headers: authHeaders, observe: 'response' })
            .pipe(
                timeout(this.environmentService.apiTimeout),
                skipNull()
            );
    }

    /** Retrieves a specific item identified by "nodePath" parameter with the id of "id" parameter. */
    public getSpecificItem(nodePath: string, id: string, authRequest: boolean = true): Observable<HttpResponse<any>> {
        let authHeaders = {};
        if (authRequest) {
            this.authStore.select(selectAccessToken).pipe(skipNull(), first()).subscribe(
                (accessToken) => {
                    authHeaders = {
                        Authorization: 'Bearer ' + accessToken
                    };
                }
            );
        }

        // Perform GET and return observable IApiResult
        return this.httpClient.get<HttpResponse<any>>(this.environmentService.apiURL + nodePath + '/' + id, { headers: authHeaders, observe: 'response' })
            .pipe(skipNull(), timeout(this.environmentService.apiTimeout));
    }

    /** Posts (creates) a new item to the identified "nodePath". */
    public createItem(nodePath: string, item?: unknown, authRequest: boolean = true): Observable<HttpResponse<any>> {
        let authHeaders = {};
        if (authRequest) {
            this.authStore.select(selectAccessToken).pipe(skipNull(), first()).subscribe(
                (accessToken) => {
                    authHeaders = {
                        Authorization: 'Bearer ' + accessToken
                    };
                }
            );
        }
        if (!this.environmentService.production) {
            console.log('Making POST request to: ' + this.environmentService.apiURL + nodePath);
            console.log(JSON.stringify(item));
        }
        return this.httpClient.post<HttpResponse<any>>(
            this.environmentService.apiURL + nodePath,
            item,
            { headers: authHeaders, observe: 'response' }
        )
            .pipe(
                timeout(this.environmentService.apiTimeout),
                skipNull()
            );
    }

    /** Puts (updates) a specific item at the specified "nodePath". */
    public updateItem(nodePath: string, id: string, item: unknown, authRequest: boolean = true): Observable<HttpResponse<any>> {
        let authHeaders = {};
        if (authRequest) {
            this.authStore.select(selectAccessToken).pipe(skipNull(), first()).subscribe(
                (accessToken) => {
                    authHeaders = {
                        Authorization: 'Bearer ' + accessToken
                    };
                }
            );
        }

        if (!this.environmentService.production) {
            console.log('Making PUT request to: ' + this.environmentService.apiURL + nodePath + '/' + id);
            console.log(JSON.stringify(item));
        }
        return this.httpClient.put<HttpResponse<any>>(
            this.environmentService.apiURL + nodePath + '/' + id, item,
            { headers: authHeaders, observe: 'response' }
        )
            .pipe(
                timeout(this.environmentService.apiTimeout),
                skipNull()
            );
    }

    /** Patches (updates) a specific item at the specified "nodePath" with specified "fieldUpdates". */
    public updateItemFields(nodePath: string, id: string, fieldUpdates: unknown, authRequest: boolean = true): Observable<HttpResponse<any>> {
        let authHeaders = {};
        if (authRequest) {
            this.authStore.select(selectAccessToken).pipe(skipNull(), first()).subscribe(
                (accessToken) => {
                    authHeaders = {
                        Authorization: 'Bearer ' + accessToken
                    };
                }
            );
        }

        if (!this.environmentService.production) {
            console.log('Making PATCH request to: ' + this.environmentService.apiURL + nodePath + '/' + id);
            console.log(JSON.stringify(fieldUpdates));
        }
        return this.httpClient.patch<HttpResponse<any>>(
            this.environmentService.apiURL + nodePath + '/' + id, fieldUpdates,
            { headers: authHeaders, observe: 'response' }
        )
            .pipe(
                timeout(this.environmentService.apiTimeout),
                skipNull()
            );
    }

    /** Deletes (removes) a specific item at the specified "nodePath" identified by "id". */
    public deleteItem(nodePath: string, id: string, authRequest: boolean = true): Observable<HttpResponse<any>> {
        let authHeaders = {};
        if (authRequest) {
            this.authStore.select(selectAccessToken).pipe(skipNull(), first()).subscribe(
                (accessToken) => {
                    authHeaders = {
                        Authorization: 'Bearer ' + accessToken
                    };
                }
            );
        }
        if (!this.environmentService.production) {
            console.log('Making DELETE request to: ' + this.environmentService.apiURL + nodePath + '/' + id);
        }
        return this.httpClient.delete<HttpResponse<any>>(this.environmentService.apiURL + nodePath + '/' + id, { headers: authHeaders, observe: 'response' })
            .pipe(skipNull(), timeout(this.environmentService.apiTimeout));
    }
}
