// Angular
import { Injectable } from '@angular/core';

// RXJS
import { map, switchMap, tap } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../../../store/actions';

// State
import { SeacomState } from '../../../store/reducers';

// Interfaces
import { CopyContainerSettingForNew, INewContainerSetting, IUpdatedContainerSetting } from 'src/app/interfaces/seacom/containersetting';

// Services
import { IncidentResponsesService } from 'src/app/services/seacom/incidentresponses.service';

// Selectors
import { selectCurrentUser } from '../../../store/selectors/auth.selectors';
import { selectContainerSettingByContainerComponentAndSetting } from '../../../store/selectors/containersetting.selectors';
import { skipNull, takeFirstReal } from 'src/app/common/globals';


@Injectable()
export class OperationsPageEffects {

  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>
  ) { }

  enter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.Enter
        ),
        map(() => {
          this.store.dispatch(actions.LoadRegions());
        }),
        map(() => {
          this.store.dispatch(actions.LoadDistricts());
        }),
        map(() => {
          this.store.dispatch(actions.LoadBeaches());
        }),
        map(() => {
          this.store.dispatch(actions.LoadStations());
        }),
        map(() => {
          this.store.dispatch(actions.LoadStationSettings());
        }),
        map(() => {
          this.store.dispatch(actions.LoadUsers());
        }),
        map(() => {
          this.store.dispatch(actions.LoadColumnSettings());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidents());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidentSeverities());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidentTypes());
        }),
        map(() => {
          this.store.dispatch(actions.LoadMetadatas());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidentResponses());
        })
      );
  },
    { dispatch: false });

  pinIncidentsList$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.PinIncidentsList
        ),
        concatLatestFrom(() => [
          this.store.select(selectContainerSettingByContainerComponentAndSetting('operations', 'incidents', 'pinned')), // May return null -- don't skip it!
          this.store.select(selectCurrentUser)
        ]),
        map(([_, cs, cu]) => {
          if(
            cs !== undefined &&
            cs !== null
          ) {
            if (
              cs.organization !== undefined &&
              cs.organization !== null &&
              cs.user !== undefined &&
              cs.user !== null
            ) {
              let updCS = {
                id: cs.id,
                value: 'true'
              } as IUpdatedContainerSetting;
              return actions.UpdateContainerSettingFields({ payload: updCS });
            } else {
              let newCS = {
                ...CopyContainerSettingForNew(cs),
                organization: cu.organization.id,
                user: cu.id,
                value: 'true'
              } as INewContainerSetting;
              return actions.AddContainerSetting({ payload: newCS });
            }
          } else {
            console.log('main else');
            let newCS = {
              organization: cu.organization.id,
              user: cu.id,
              container: 'operations',
              component: 'incidents',
              setting: 'pinned',
              value: 'true'
            } as INewContainerSetting;
            return actions.AddContainerSetting({ payload: newCS });
          }
        })
      );
  });

  unpinIncidentsList$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.UnpinIncidentsList
        ),
        concatLatestFrom(() => [
          this.store.select(selectContainerSettingByContainerComponentAndSetting('operations', 'incidents', 'pinned')), // May return null -- don't skip it!
          this.store.select(selectCurrentUser)
        ]),
        map(([_, cs, cu]) => {
          if(
            cs !== undefined &&
            cs !== null
          ) {
            if (
              cs.organization !== undefined &&
              cs.organization !== null &&
              cs.user !== undefined &&
              cs.user !== null
            ) {
              return actions.UpdateContainerSettingValue({ payload: { id: cs.id, newValue: 'false' }});
            } else {
              let newCS = {
                ...CopyContainerSettingForNew(cs),
                organization: cu.organization.id,
                user: cu.id,
                value: 'false'
              } as INewContainerSetting;
              return actions.AddContainerSetting({ payload: newCS });
            }
          } else {
            let newCS = {
              ...CopyContainerSettingForNew(cs),
              organization: cu.organization.id,
              user: cu.id,
              container: 'operations',
              component: 'incidents',
              setting: 'pinned',
              value: 'false'
            } as INewContainerSetting;
            return actions.AddContainerSetting({ payload: newCS });
          }
        })
      );
  });

  updateStationOrder$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.UpdateStationOrder
        ),
        map((action) => {
          this.store.dispatch(actions.UpdateStationOrder({
            payload: {
              newOrder: action.payload.newOrder,
              from: action.payload.from,
              to: action.payload.to
            }
          }));
        })
      );
  }, { dispatch: false });

  // Column Filters
  replaceColumnFilters$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.ReplaceColumnFilters
        ),
        map((action) => {
          this.store.dispatch(actions.ReplaceColumnFilters({
            payload: {
              container: action.payload.container,
              component: action.payload.component,
              table: action.payload.table,
              newFilters: action.payload.newFilters
            }
          }));
        })
      );
  }, { dispatch: false });

  removeColumnFilters$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.RemoveColumnFilters
        ),
        map((action) => {
          this.store.dispatch(actions.RemoveColumnFilter({
            payload: {
              container: action.payload.container,
              component: action.payload.component,
              table: action.payload.table,
              column: action.payload.column
            }
          }));
        })
      );
  }, { dispatch: false });

  revertStationsListFilters$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.RevertStationsListFilters
        ),
        map((action) => {
          this.store.dispatch(actions.RemoveTableFilters({
            payload: {
              container: 'operations',
              component: 'stations',
              table: 'stations'
            }
          }));
        })
      );
  }, { dispatch: false });

  revertIncidentsListFilters$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.RevertIncidentsListFilters
        ),
        map((action) => {
          this.store.dispatch(actions.RemoveTableFilters({
            payload: {
              container: 'operations',
              component: 'incidents',
              table: 'incidents'
            }
          }));
        })
      );
  }, { dispatch: false });

  filterIncidentsListByStation$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.FilterIncidentsListByStation
        ),
        map((action) => {
          this.store.dispatch(actions.ReplaceTableFilters({
            payload: {
              container: 'operations',
              component: 'incidents',
              table: 'incidents',
              newFilters: [{
                field: 'station',
                op: 'in',
                filter: [action.payload.stationId]
              }]
            }
          }));
        })
      );
  }, { dispatch: false });
}
