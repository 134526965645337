import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUser } from 'src/app/interfaces/seacom/user';
import * as fromReducers from '../reducers/user.reducers';
import { sortFactory } from '../utils/sort.utils';

export const selectUserState = createFeatureSelector<fromReducers.UserState>(
  fromReducers.userFeatureKey
);

export const selectUsersLastUpdated = createSelector(
  selectUserState,
  (state: fromReducers.UserState): string => state.lastUpdated
);

export const selectUsersCount = createSelector(
  selectUserState,
  (state: fromReducers.UserState): number => Object.keys(state.entities).length
);

export const selectAllUsers = createSelector(
  selectUserState,
  (state: fromReducers.UserState): IUser[] => {
    return Object.keys(state.entities).map(i => state.entities[i])
      .sort((a, b) => sortFactory(a, b, [{field: 'first_name', ascending: true}, {field: 'last_name', ascending: true}]));
  }
);

export const selectUserById = (id: string) => createSelector(selectUserState, (users): IUser => {
  let res = users?.entities[id];
  if(res !== undefined) {
    return res;
  } else {
    return null;
  }
});

export const selectUsersByRole = (roles: string[]) => createSelector(selectAllUsers, (users): IUser[] => {
  return users
    .filter(
      (u) => {
        if (u !== undefined && u !== null && u.role !== undefined && u.role !== null && u.role.role !== undefined && u.role.role !== null && u.role.role.name !== undefined && u.role.role.name !== null) {
          if (roles.indexOf(u.role.role.name) !== -1) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    )
    .sort((a, b) => sortFactory(a, b, [{field: 'first_name', ascending: true}, {field: 'last_name', ascending: true}]));
});

export const selectUsersWithLocationByRole = (roles: string[]) => createSelector(selectUsersByRole(roles), (users): IUser[] => {
  return users.filter(
    (x) => {
      x.location !== undefined &&
        x.location !== null
    }
  ).sort(
    (a, b) => sortFactory(a, b, [{field: 'first_name', ascending: true}, {field: 'last_name', ascending: true}])
  );
});

export const selectUsersFullNameById = (id: string) => createSelector(selectUserById(id), (user): string => {
  if(user !== null) {
    return user.first_name + ' ' + user.last_name;
  } else {
    return 'Unknown User';
  }
});
