export interface IFieldFilter {
  d?: 'IFieldFilter';
  field: string;
  op: 'eq' | 'in' | 'gte' | 'gt' | 'lte' | 'lt';
  filter: any | any[];
}

export function filtersToQueryString(filters: IFieldFilter[]): string {
  let qs = '';
  if (filters !== undefined && filters !== null && Array.isArray(filters)) {
    filters.forEach((f, idx) => {
      if (idx > 0) {
        qs = qs + '&';
      } else {
        qs = '?';
      }

      if (f.op !== undefined && f.op !== null) {
        qs = qs + f.field + '__' + f.op + '=';
      } else {
        qs = qs + f.field + '=';
      }

      if (Array.isArray(f.filter)) {
        qs = qs + f.filter.join(',');
      } else {
        qs = qs + f.filter;
      }
    });
  }
  return qs;
}