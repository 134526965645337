import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ITimeclock } from 'src/app/interfaces/seacom/timeclock';
import * as fromReducers from '../reducers/timeclock.reducers';

export const selectTimeclockState = createFeatureSelector<fromReducers.TimeclockState>(
  fromReducers.timeclockFeatureKey
);

export const selectTimeclock = createSelector(
  selectTimeclockState,
  (state: fromReducers.TimeclockState): ITimeclock => state.timeClock
);

export const selectTimeclocksLastUpdated = createSelector(
  selectTimeclockState,
  (state: fromReducers.TimeclockState): string => state.lastUpdated
);

export const selectTimeclocksLoading = createSelector(
  selectTimeclockState,
  (state: fromReducers.TimeclockState): boolean => state.loading
);

export const selectClockedInStatus = createSelector(
  selectTimeclockState,
  (state: fromReducers.TimeclockState): boolean => state.timeClock.status === 'clocked_in'
);

export const selectClockedOutStatus = createSelector(
  selectTimeclockState,
  (state: fromReducers.TimeclockState): boolean => state.timeClock.status === 'clocked_out'
);

export const selectWeeklyMinutes = createSelector(
  selectTimeclockState,
  (state: fromReducers.TimeclockState): number => state.timeClock.weekly_minutes
);

export const selectWeeklyHours = createSelector(
  selectTimeclockState,
  (state: fromReducers.TimeclockState): number => state.timeClock.weekly_hours
);

export const selectDailyMinutes = createSelector(
  selectTimeclockState,
  (state: fromReducers.TimeclockState): number => state.timeClock.today_minutes
);

export const selectDailyHours = createSelector(
  selectTimeclockState,
  (state: fromReducers.TimeclockState): number => state.timeClock.today_hours
);

export const selectMinutes = createSelector(
  selectTimeclockState,
  (state: fromReducers.TimeclockState): { today_minutes: number, weekly_minutes: number } => {
    return { today_minutes: state.timeClock.today_minutes, weekly_minutes: state.timeClock.weekly_minutes }
  }
);
