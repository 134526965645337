import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeclockWidgetComponent } from './timeclock-widget.component';
import { IonicModule } from '@ionic/angular';
import { LoadingSpinnerModule } from '../../loading-spinner/loading-spinner.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    LoadingSpinnerModule
  ],
  declarations: [
    TimeclockWidgetComponent
  ],
  exports: [
   TimeclockWidgetComponent
  ]
})
export class TimeclockWidgetModule { }
