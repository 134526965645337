// Angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IIncidentType } from 'src/app/interfaces/seacom/incidenttype';
import { FormArray, FormControl } from '@angular/forms';

// RXJS
import { interval, Subscription, BehaviorSubject, Observable } from 'rxjs';

// Interfaces
import { IStation } from '../../interfaces/seacom/station';
import { IIncident, INewIncident } from '../../interfaces/seacom/incident';
import { IIncidentResponse, INewIncidentResponse } from '../../interfaces/seacom/incidentresponse';
import { IUser } from '../../interfaces/seacom/user';
import { toLocalISOString } from 'src/app/common/globals';


@Component({
  selector: 'app-station-tile',
  templateUrl: './stationtile.component.html',
  styleUrls: ['./stationtile.component.scss']
})
export class StationTileComponent implements OnInit {
  @Input() brightness$: Observable<'light'|'dark'>;
  @Input('expandedStation') expandedStation: string;
  @Input('station') station: IStation;
  @Input('stationResponders') stationResponders: IUser[];
  @Input('stations') stations: IStation[];
  @Input('responders') responders: IUser[];
  @Input('dispatchers') dispatchers: IUser[];
  @Input('supervisors') supervisors: IUser[];
  @Input('incidentTypes') incidentTypes: IIncidentType[];
  @Input('currentIncident') currentIncident: IIncident;
  @Input('currentIncidentType') currentIncidentType: IIncidentType;
  @Input('currentResponse') currentResponse: IIncidentResponse;
  @Input('currentUser') currentUser: IUser;

  // Events
  @Output() setStationStatusEvent = new EventEmitter<{ stationId: string, status: 'in_service' | 'out_of_service' }>();
  @Output() filterIncidentsEvent = new EventEmitter<string>();
  @Output() toggleStationExpandedEvent = new EventEmitter<string>();
  @Output() updateRespondersEvent = new EventEmitter<{ stationId: string, responders: string[] }>();
  @Output() quickIncidentEvent = new EventEmitter<{ stationId: string, quickIncident: INewIncident, firstResponse: INewIncidentResponse }>();
  @Output() addResponseEvent = new EventEmitter<INewIncidentResponse>();
  @Output() openIncidentTransferPopEvent = new EventEmitter<string>();
  @Output() displayQuickIncidentOptionsEvent = new EventEmitter<string>(); // string: stationId
  @Output() endResponseEvent = new EventEmitter<string>();

  selectedResponders = new FormControl([]);

  timerSubscription: Subscription;
  time: number = 0; // used to store seconds of timer
  timer$ = new BehaviorSubject<string>('00:00'); // used for displayable timer

  currentHeaderStyle$ = new BehaviorSubject<{ color?: string, background?: string }>({});

  constructor(
  ) { }

  ngOnInit(): void {
    this.startTimer();

    if (this.stationResponders !== undefined) {
      this.selectedResponders.setValue(this.stationResponders.map(r => r.id));
    }
  }

  ngOnDestroy(): void {
    this.timerSubscription.unsubscribe();
  }

  startTimer() {
    try {
      this.timerSubscription.unsubscribe();
    } catch { }

    this.timerSubscription = interval(1000).subscribe(
      () => {
        //********Runs once a second!********/
        this.updateTimer();

        this.updateHeaderStyle();
      }
    );
  }

  stopTimer() {
    try {
      this.timerSubscription.unsubscribe();
    } catch { }
    this.timer$.next('00:00');
    this.time = 0;

    this.updateHeaderStyle();
  }

  // Accepts string representing the ID of the Incident Type, not the NAME!
  quickIncident(type: string): void {
    if(
      this.currentResponse !== undefined &&
      this.currentResponse !== null &&
      this.currentResponse.stations !== undefined &&
      this.currentResponse.stations !== null &&
      this.currentResponse.stations.length === 1 &&
      this.currentResponse.stations[0].id === this.station.id) {
      // If this station has an active response, and this station is the only one on it, 
      //  end the response before starting a new one.
      this.endResponse();
    }
    
    let startDate = new Date();

    this.toggleStationExpandedEvent.emit(this.station.id);

    let incType = this.incidentTypes.find(t => t.id === type);
    if (incType === undefined) {
      incType = null;
    }

    console.log("Quick incident! Type ID: " + type);

    const newIncident = {
      organization: this.station.organization.id,
      region: this.station.region ? this.station.region.id : undefined,
      district: this.station.district ? this.station.district.id : undefined,
      beach: this.station.beach ? this.station.beach.id : undefined,
      type: type,
      source: this.currentUser.role.role.name,
      severity: incType !== null ? incType.default_severity.id : undefined,
      status: 'responding',
      location: this.station.location,
      primary_responder: (this.station.responders !== undefined && this.station.responders.length > 0) ? this.station.responders[0].id : undefined,
      secondary_responder: (this.station.responders !== undefined && this.station.responders.length > 1) ? this.station.responders[1].id : undefined,
      tertiary_responder: (this.station.responders !== undefined && this.station.responders.length > 2) ? this.station.responders[2].id : undefined,
      station: this.station.id,
      starttime: toLocalISOString(startDate),
      dispatchtime: toLocalISOString(startDate),
      pendingtime: toLocalISOString(startDate),
      enroutetime: toLocalISOString(startDate),
      respondingtime: toLocalISOString(startDate),
    } as INewIncident;

    console.log(newIncident);

    const newResponse = {
      organization: this.station.organization.id,
      incident: null, // Will be added by incident response effect prior to service adding the response
      type: type,
      severity: incType !== null ? incType.default_severity.id : undefined,
      status: 'inprogress',
      location: this.station.location,
      region: this.station.region ? this.station.region.id : undefined,
      district: this.station.district ? this.station.district.id : undefined,
      beach: this.station.beach ? this.station.beach.id : undefined,
      stations: [this.station.id],
      responders: this.stationResponders.map(r => r.id),
      starttime: toLocalISOString(startDate)
    } as INewIncidentResponse;

    this.quickIncidentEvent.emit({ stationId: this.station.id, quickIncident: newIncident, firstResponse: newResponse });
  }

  quickIncidentOptions() {
    this.displayQuickIncidentOptionsEvent.emit(this.station.id);
  }

  updateResponders() {
    this.updateRespondersEvent.emit({ stationId: this.station.id, responders: this.selectedResponders.value });
  }

  addResponse(event): void {
    event.stopPropagation();

    if(
      this.currentResponse !== undefined &&
      this.currentResponse !== null &&
      this.currentResponse.stations !== undefined &&
      this.currentResponse.stations !== null &&
      this.currentResponse.stations.length === 1 &&
      this.currentResponse.stations[0].id === this.station.id) {
      // If this station has an active response, and this station is the only one on it, 
      //  end the response before starting a new one.
      this.endResponse();
    }

    // Build out new response for this station
    let newResponse = {
      organization: this.station.organization.id,
      incident: this.currentIncident.id,
      status: 'inprogress',
      location: this.station.location,
      region: this.station.region ? this.station.region.id : undefined,
      district: this.station.district ? this.station.district.id : undefined,
      beach: this.station.beach ? this.station.beach.id : undefined,
      stations: [this.station.id],
      responders: this.station.responders.map(u => u.id),
      starttime: toLocalISOString(new Date())
    } as INewIncidentResponse;

    this.addResponseEvent.emit(newResponse);
  }

  endResponse(): void {
    this.stopTimer();
    this.endResponseEvent.emit(this.station.id);
  }

  hideTile(event) {
    event.stopPropagation();
    this.setStationStatusEvent.emit({ stationId: this.station.id, status: 'out_of_service' });
  }

  filterIncidents(event) {
    event.stopPropagation();
    this.filterIncidentsEvent.emit(this.station.id);
  }

  toggleExpansion(): void {
    this.toggleStationExpandedEvent.emit(this.station.id);
  }

  openTransferPopup(): void {
    event.stopPropagation();
    this.openIncidentTransferPopEvent.emit(this.station.id);
  }

  updateTimer() {
    if (this.currentResponse !== undefined && this.currentResponse !== null) {
      let startDate = new Date(this.currentResponse.starttime);

      this.time = Math.round(((new Date()).getTime() - startDate.getTime()) / 1000);

      let hour = Math.floor(this.time / 3600);
      let minute = Math.floor((this.time - (hour * 3600)) / 60);
      let second = this.time - (hour * 3600) - (minute * 60);

      let newTime =
        (hour > 0 ? hour.toString() + ':' : '') +
          minute.toString().padStart(2, '0') + ':' +
          second.toString().padStart(2, '0');

      this.timer$.next(newTime);
    }
  }

  updateHeaderStyle() {
    if (this.currentIncidentType !== undefined && this.currentIncidentType !== null) {
      if (this.currentIncidentType.time_monitoring !== undefined && this.currentIncidentType.time_monitoring) {
        if (this.time >= this.currentIncidentType.critical_time) {
          this.currentHeaderStyle$.next({
            color: this.currentIncidentType.critical_color,
            background: this.currentIncidentType.critical_bgcolor
          });
        } else if (this.time >= this.currentIncidentType.warning_time) {
          this.currentHeaderStyle$.next({
            color: this.currentIncidentType.warning_color,
            background: this.currentIncidentType.warning_bgcolor
          });
        } else {
          this.currentHeaderStyle$.next({});
        }
      } else {
        this.currentHeaderStyle$.next({});
      }
    } else {
      this.currentHeaderStyle$.next({});
    }
  }
}
