// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Store
import * as actions from '../actions';

// Services
import { UsersService } from 'src/app/services/seacom/users.service';
import { RegisterService } from '../../services/seacom/register.service';
import { Store } from '@ngrx/store';
import { SeacomState } from '../reducers';
import { EnvironmentService } from 'src/app/services/environment.service';
import { selectUsersLastUpdated } from '../selectors/user.selectors';


@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private usersService: UsersService,
    private registerService: RegisterService
  ) { }

  loadUsers$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Users
        ofType(
          actions.LoadUsers
        ),
        concatLatestFrom((action) => this.store.select(selectUsersLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.usersService.getList().pipe(
              map((users) => actions.LoadUsersSuccess({ payload: users })),
              catchError((error) => of(actions.LoadUsersFail(error)))
            );
          } else {
            return of(actions.LoadUsersCancelled());
          }
        })
      );
  });
  loadUser$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load User
        ofType(actions.LoadUser),
        exhaustMap((action) =>
          this.usersService.get(action.payload).pipe(
            map((user) => actions.LoadUserSuccess({ payload: user })),
            catchError((error) => of(actions.LoadUsersFail(error)))
          )
        )
      );
  });
  registerUser$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Register User
        ofType(actions.RegisterUser),
        concatMap((action) =>
          this.registerService.registerUser(action.payload).pipe(
            map((user) => actions.RegisterUserSuccess({ payload: user })),
            catchError((error) => of(actions.RegisterUserFail(error)))
          )
        )
      );
  });
  updateUser$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update User
        ofType(actions.UpdateUser),
        concatMap((action) =>
          this.usersService.update(action.payload.id, action.payload).pipe(
            map((user) => actions.UpdateUserSuccess({ payload: user })),
            catchError((error) => of(actions.UpdateUserFail(error)))
          )
        )
      );
  });
  deleteUser$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete User
        ofType(actions.DeleteUser),
        mergeMap((action) =>
          this.usersService.delete(action.payload).pipe(
            map((userId) => actions.DeleteUserSuccess({ payload: userId })),
            catchError((error) => of(actions.DeleteUserFail(error)))
          )
        )
      )
  });
}
