// Store Effects
import { NotificationEffects } from "./notification.effects";
import { AuthEffects } from "./auth.effects";
import { BeachEffects } from "./beach.effects";
import { DistrictEffects } from "./district.effects";
import { IncidentEffects } from "./incident.effects";
import { IncidentResponseEffects } from "./incidentresponse.effects";
import { IncidentSeverityEffects } from "./incidentseverity.effects";
import { IncidentTypeEffects } from "./incidenttype.effects";
import { LocationEffects } from "./location.effects";
import { RegionEffects } from "./region.effects";
import { StationEffects } from "./station.effects";
import { TimeclockEffects } from "./timeclock.effects";
import { UserEffects } from "./user.effects";
import { WeatherEffects } from "./weather.effects";
import { MetadataEffects } from './metadata.effects';
import { FeedbackEffects } from './feedback.effects';
import { TableEffects } from "./table.effects";
import { ContainerSettingEffects } from "./containersetting.effects";
import { StationSettingEffects } from "./stationsetting.effects";

// Container Effects
import { ConditionsPageEffects } from "../../containers/conditions/store/conditions.page.effects";
import { FeedbackPageEffects } from '../../containers/feedback/store/feedback.page.effects';
import { HomePageEffects } from "../../containers/home/store/home.page.effects";
import { IncidentFormEffects } from "../../containers/incidentform/store/incidentform.effects";
import { LoginPageEffects } from '../../containers/login/store/login.page.effects';
import { NotificationsPageEffects } from "../../containers/notifications/store/notifications.page.effects";
import { OperationsPageEffects } from '../../containers/operations/store/operations.page.effects';
import { RunbookPageEffects } from "../../containers/runbook/store/runbook.page.effects";
import { StationsPageEffects } from "../../containers/stations/store/stations.page.effects";

// Component Effects
import { HeaderbarEffects } from "../../components/headerbar/store/headerbar.effects";
import { ColumnSelectorEffects } from '../../components/column-selector/store/columnselector.effects';
import { FooterBarEffects } from "src/app/components/footerbar/store/footerbar.effects";
import { AppEffects } from "src/app/app.effects";


export const SeacomEffects = [
  AppEffects,
  NotificationEffects,
  NotificationsPageEffects,
  AuthEffects,
  BeachEffects,
  ColumnSelectorEffects,
  ConditionsPageEffects,
  ContainerSettingEffects,
  OperationsPageEffects,
  DistrictEffects,
  FeedbackEffects,
  FeedbackPageEffects,
  FooterBarEffects,
  HeaderbarEffects,
  HomePageEffects,
  IncidentEffects,
  IncidentFormEffects,
  RunbookPageEffects,
  IncidentResponseEffects,
  IncidentSeverityEffects,
  IncidentTypeEffects,
  LocationEffects,
  LoginPageEffects,
  MetadataEffects,
  RegionEffects,
  StationEffects,
  StationSettingEffects,
  StationsPageEffects,
  TableEffects,
  TimeclockEffects,
  UserEffects,
  WeatherEffects
];
