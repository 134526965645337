import { IOrganization } from './organization';
import { IUser } from './user';
import { IUUID } from './templates/uuid';

export interface IColumnSetting extends IUUID {
    d?: 'IColumnSetting';
    organization: Partial<IOrganization>;
    user: Partial<IUser>;
    container: string;
    component: string;
    table: string;
    column: string;
    datatype: 'action'|'string'|'titlestring'|'namedstring'|'namedtitlestring'|'datetime'|'user'|'location'|'yesno'|'truefalse'|'metadata';
    displayname: string;
    displayed: boolean;
    order: number;
    created: string;
    created_by: Partial<IUser>;
    last_modified: string;
    last_modified_by: Partial<IUser>
}

export interface INewColumnSetting extends IUUID {
    d?: 'INewColumnSetting';
    organization?: string;
    user?: string;
    container: string;
    component: string;
    table: string;
    column: string;
    datatype: 'action'|'string'|'titlestring'|'namedstring'|'namedtitlestring'|'datetime'|'user'|'location'|'yesno'|'truefalse'|'metadata';
    displayname: string|null;
    displayed: boolean;
    order: number;
}

export interface IUpdatedColumnSetting extends IUUID {
    d?: 'IUpdatedColumnSetting';
    organization?: string;
    user?: string;
    container?: string;
    component?: string;
    table?: string;
    column?: string;
    datatype?: 'action'|'string'|'titlestring'|'namedstring'|'namedtitlestring'|'datetime'|'user'|'location'|'yesno'|'truefalse'|'metadata';
    displayname?: string;
    displayed?: boolean;
    order?: number;
}

export function CopyColumnSettingForNew(cs: IColumnSetting): INewColumnSetting {
    return {
        d: 'INewColumnSetting',
        id: cs.id,
        organization: cs.organization ? cs.organization.id : undefined,
        user: cs.user ? cs.user.id : undefined,
        container: cs.container,
        component: cs.component,
        table: cs.table,
        column: cs.column,
        datatype: cs.datatype,
        displayname: cs.displayname,
        displayed: cs.displayed,
        order: cs.order
    } as INewColumnSetting;
}

export function CopyColumnSettingForUpdate(cs: IColumnSetting): IUpdatedColumnSetting {
    return {
        d: 'IUpdatedColumnSetting',
        id: cs.id,
        organization: cs.organization ? cs.organization.id : undefined,
        user: cs.user ? cs.user.id : undefined,
        container: cs.container,
        component: cs.component,
        table: cs.table,
        column: cs.column,
        datatype: cs.datatype,
        displayname: cs.displayname,
        displayed: cs.displayed,
        order: cs.order
    } as IUpdatedColumnSetting;
}
