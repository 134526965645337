import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRegion } from 'src/app/interfaces/seacom/region';
import * as fromReducers from '../reducers/region.reducers';
import { sortFactory } from '../utils/sort.utils';

export const selectRegionState = createFeatureSelector<fromReducers.RegionState>(
  fromReducers.regionFeatureKey
);

export const selectRegionsLastUpdated = createSelector(
  selectRegionState,
  (state: fromReducers.RegionState): string => state.lastUpdated
);

export const selectRegionsCount = createSelector(
  selectRegionState,
  (state: fromReducers.RegionState): number => Object.keys(state.entities).length
);

export const selectAllRegions = createSelector(
  selectRegionState,
  (state: fromReducers.RegionState): IRegion[] => {
    return Object.keys(state.entities).map(i => state.entities[i])
      .sort((a, b) => sortFactory(a, b, [{field: 'name', ascending: true}]));
  }
);

export const selectRegionById = (id: string) => createSelector(selectRegionState, (regions): IRegion => {
  return regions?.entities[id];
});
