// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationHome_section {
  padding: 0 10px;
  font-size: 14px;
  overflow-y: auto;
  display: block;
  line-height: 1.3;
  color: var(--color-black-light);
}

.notificationHome_section ul {
  padding: 0;
  margin-left: 20px;
  list-style-type: disc;
  margin-top: 5px;
  margin-bottom: 5px;
}

.notificationHome_section ul li {
  margin: 5px 0;
}

.align-center {
  text-align: center;
  padding-top: 25%;
  font-size: 20px;
}

/* dark theme styles */
.dark .notificationHome_section {
  color: var(--color-black-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/components/widgets/notifications-widget/notifications-widget.component.scss","webpack://./../../../../../Ryan%20Ticer/source/repos/seacom3/ui/src/app/components/widgets/notifications-widget/notifications-widget.component.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,+BAAA;ACAF;;ADGA;EACE,UAAA;EACA,iBAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;ACAF;;ADGA;EACE,aAAA;ACAF;;ADGA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;ACAF;;ADGA,sBAAA;AAEE;EACE,8BAAA;ACDJ","sourcesContent":["\r\n.notificationHome_section {\r\n  padding: 0 10px;\r\n  font-size: 14px;\r\n  overflow-y: auto;\r\n  display: block;\r\n  line-height: 1.3;\r\n  color: var(--color-black-light);\r\n}\r\n\r\n.notificationHome_section ul {\r\n  padding: 0;\r\n  margin-left: 20px;\r\n  list-style-type: disc;\r\n  margin-top: 5px;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.notificationHome_section ul li {\r\n  margin: 5px 0;\r\n}\r\n\r\n.align-center {\r\n  text-align: center;\r\n  padding-top: 25%;\r\n  font-size: 20px;\r\n}\r\n\r\n/* dark theme styles */\r\n.dark {\r\n  .notificationHome_section {\r\n    color: var(--color-black-dark);\r\n  }\r\n}\r\n",".notificationHome_section {\n  padding: 0 10px;\n  font-size: 14px;\n  overflow-y: auto;\n  display: block;\n  line-height: 1.3;\n  color: var(--color-black-light);\n}\n\n.notificationHome_section ul {\n  padding: 0;\n  margin-left: 20px;\n  list-style-type: disc;\n  margin-top: 5px;\n  margin-bottom: 5px;\n}\n\n.notificationHome_section ul li {\n  margin: 5px 0;\n}\n\n.align-center {\n  text-align: center;\n  padding-top: 25%;\n  font-size: 20px;\n}\n\n/* dark theme styles */\n.dark .notificationHome_section {\n  color: var(--color-black-dark);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
