// RXJS
import { combineLatest, Observable } from "rxjs";
import { filter, first, map, skipWhile } from "rxjs/operators";

// Interfaces
import { IUUID } from "../interfaces/seacom/templates/uuid";
import { IUser } from '../interfaces/seacom/user';


//
// Subscription helpers
//
export const skipNull = () =>
  <T>(source: Observable<T>): Observable<T> =>
    source.pipe(skipWhile(x => x === null || x === undefined));

export const takeFirstReal = () =>
  <T>(source: Observable<T>): Observable<T> =>
    source.pipe(filter(x => x !== undefined && x !== null), first());

export const searchItemsField = (search: Observable<string>, field: string) =>
  <T>(source: Observable<T[]>): Observable<T[]> => {
    return combineLatest([source, search]).pipe(
      map(([o, s]) => o.filter(i => {
        if (s.length > 0 && s !== null && s !== undefined) {
          return (i[field] as string).toLowerCase().includes(s.toLowerCase());
        } else {
          return true;
        }
      }))
    );
  };

export const searchItems = (search: Observable<string>) =>
  (source: Observable<string[]>): Observable<string[]> => {
    return combineLatest([source, search]).pipe(
      map(([o, s]) => o.filter(i => {
        if (s.length > 0 && s !== null && s !== undefined) {
          return (i as string).toLowerCase().includes(s.toLowerCase());
        } else {
          return true;
        }
      }))
    );
  };

export const searchUsers = (search: Observable<string>) =>
  (source: Observable<IUser[]>): Observable<IUser[]> => {
    return combineLatest([source, search]).pipe(
      map(([o, s]) => o.filter(u => {
        if (s.length > 0 && s !== null && s !== undefined) {
          return (u.first_name + ' ' + u.last_name).toLowerCase().includes(s.toLowerCase());
        } else {
          return true;
        }
      }))
    );
  };

export const filterItems = (predicate: (value, filter, array) => unknown) =>
  <T>(source: Observable<T[]>): Observable<T[]> => {
    return source.pipe(
      map((o: T[]) => o.filter((v, f, a) => predicate(v, f, a)))
    );
  };

export const selectItemsField = (field: string) =>
  (source: Observable<Array<any>>): Observable<Array<any>> =>
    source.pipe(
      map(o => o.map(i => i[field]))
    );

export const dedupeItemsMap = () =>
  <T extends IUUID>(source: Observable<[T[], T[]]>): Observable<T[]> =>
    source.pipe(
      map(([a, b]) => {
        let newList: T[] = Array.from(a);
        let aIds = newList.map(a => a.id);
        b.forEach(b => {
          if (aIds.indexOf(b.id) === -1) {
            newList.push(b);
          }
        });
        return newList;
      })
    );

export const reorderItems = (order: Observable<string[]>) =>
  <T extends IUUID>(source: Observable<T[]>): Observable<T[]> => {
    return combineLatest([source, order]).pipe(
      map(([source, order]) => {
        return order.map(o => {
          let fnd = source.find(i => i.id === o);
          if (fnd !== undefined) {
            return fnd;
          } else {
            return null;
          }
        });
      })
    );
  };

//
// Format helpers
//
export const toTimerFormat = (s: number): string => {
  let hrs = Math.floor(s / 3600);
  let mins = Math.floor(((s / 3600) - hrs) * 100);
  let secs = s - (hrs * 3600) - (mins * 60);
  return hrs > 0 ? '00:' : '' + mins.toString().padStart(2, '0') + secs.toString().padStart(2, '0');
}

//
// Type helpers
//
export const instOf = (object: any, type: string): boolean => {
  return object.d === type;
}

export const instType = (object: any): string => {
  return object.d;
}

export const getBoolean = (value: string | boolean | number | null): boolean => {
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
}

export const getBooleanString = (value: string | boolean | number | null): string => {
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return 'true';
    default:
      return 'false';
  }
}

export const getYesNoString = (value: string | boolean | number | null): string => {
  switch (getBoolean(value)) {
    case true:
      return 'Yes';
    default:
      return 'No';
  }
}

export const getTrueFalseString = (value: string | boolean | number | null): string => {
  switch (getBoolean(value)) {
    case true:
      return 'True';
    default:
      return 'False';
  }
}

export const getCheckboxValue = (value): 'on' | 'off' => {
  if (value) {
    return 'on';
  }
  return 'off';
}

/**
 * Convert minutes to a UTC offset.
 *
 * @param {Number} minutes - The minutes to convert to an offset.
 * @returns {String} A time of the form '+10:00'.
 */
function convertMinutesToOffset(minutes) {
  if (minutes === 0) {
    return 'Z';
  }

  let absMinutes = Math.abs(minutes);
  let h = Math.floor(absMinutes / 60).toString();
  let m = (absMinutes % 60).toString();
  h = parseInt(h) < 10 ? '0' + h : h;
  m = parseInt(m) < 10 ? '0' + m : m;

  let hoursMins = h + ':' + m;
  return minutes > 0 ? `+${hoursMins}` : `-${hoursMins}`;
}

/**
 * Retrieves the current time or converts a Date into
 * an ISO timezone aware string. i.e '2021-06-13T09:22:15.926-05:00'
 * By default, new Date().toISOString() would be UTC.
 *
 * @param {Date|null} date - Optionally, the date to
 *     convert to a timezone aware ISO string. Otherwise,
 *     the current time will be used.
 *
 * @returns {String} A timezone aware ISO date string.
 */
export const toLocalISOString = (date: Date = null): string => {
  let tzOffsetMin = new Date().getTimezoneOffset();
  let tzOffsetMs = tzOffsetMin * 60000;
  let timeMs = date instanceof Date ? date.getTime() : Date.now();

  return new Date(timeMs - tzOffsetMs).toISOString().replace(
    'Z',
    // A '-' is used here since the offset sign is
    // flipped from what we want when we
    // getTimezoneOffset(). Ex. UTC+10 => -600
    // However, we want +10:00 in the ISO string,
    // not -10:00.
    convertMinutesToOffset(-tzOffsetMin)
  );
}


