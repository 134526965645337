import { createAction, props } from '@ngrx/store';
import { IDistrict, INewDistrict, IUpdatedDistrict } from 'src/app/interfaces/seacom/district';

// district action types
export const LOAD_DISTRICTS = '[Page Effects] Load Districts';
export const LOAD_DISTRICTS_CANCELLED = '[District Effects] Load Districts Cancelled';
export const LOAD_DISTRICTS_FAIL = '[District Effects] Load Districts Fail';
export const LOAD_DISTRICTS_SUCCESS = '[District Effects] Load Districts Success';
export const LOAD_DISTRICT = '[Component Effects] Load District';
export const LOAD_DISTRICT_FAIL = '[District Effects] Load District Fail';
export const LOAD_DISTRICT_SUCCESS = '[District Effects] Load District Success';
export const UPDATE_DISTRICT = '[SEACOM] Update District';
export const UPDATE_DISTRICT_FAIL = '[District Effects] Update District Fail';
export const UPDATE_DISTRICT_SUCCESS = '[District Effects] Update District Success';
export const ADD_DISTRICT = '[SEACOM] Add District';
export const ADD_DISTRICT_FAIL = '[District Effects] Add District Fail';
export const ADD_DISTRICT_SUCCESS = '[District Effects] Add District Success';
export const DELETE_DISTRICT = '[SEACOM] Delete District';
export const DELETE_DISTRICT_FAIL = '[District Effects] Delete District Fail';
export const DELETE_DISTRICT_SUCCESS = '[District Effects] Delete District Success';

export const LoadDistricts = createAction(
	LOAD_DISTRICTS
);

export const LoadDistrictsCancelled = createAction(
	LOAD_DISTRICTS_CANCELLED
);

export const LoadDistrictsSuccess = createAction(
    LOAD_DISTRICTS_SUCCESS,
    props<{
        payload: IDistrict[]
    }>()
);

export const LoadDistrictsFail = createAction(
    LOAD_DISTRICTS_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadDistrict = createAction(
	LOAD_DISTRICT,
    props<{
        payload: string // ID
    }>()
);

export const LoadDistrictSuccess = createAction(
    LOAD_DISTRICT_SUCCESS,
    props<{
        payload: IDistrict
    }>()
);

export const LoadDistrictFail = createAction(
    LOAD_DISTRICT_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateDistrict = createAction(
    UPDATE_DISTRICT,
    props<{
        payload: IUpdatedDistrict
    }>()
);

export const UpdateDistrictSuccess = createAction(
    UPDATE_DISTRICT_SUCCESS,
    props<{
        payload: IDistrict
    }>()
);

export const UpdateDistrictFail = createAction(
    UPDATE_DISTRICT_FAIL,
    props<{
        payload: any
    }>()
);

export const AddDistrict = createAction(
    ADD_DISTRICT,
    props<{
        payload: INewDistrict
    }>()
);

export const AddDistrictSuccess = createAction(
    ADD_DISTRICT_SUCCESS,
    props<{
        payload: IDistrict
    }>()
);

export const AddDistrictFail = createAction(
    ADD_DISTRICT_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteDistrict = createAction(
    DELETE_DISTRICT,
    props<{
        payload: string // ID
    }>()
);

export const DeleteDistrictSuccess = createAction(
    DELETE_DISTRICT_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteDistrictFail = createAction(
    DELETE_DISTRICT_FAIL,
    props<{
        payload: any
    }>()
);
