// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../actions';

// Services
import { ColumnSettingsService } from 'src/app/services/seacom/columnsettings.service';
import { CopyColumnSettingForNew, IColumnSetting, IUpdatedColumnSetting } from 'src/app/interfaces/seacom/columnsetting';
import { IItemEntity } from 'src/app/interfaces/itementity';
import { SeacomState } from '../reducers';
import { Store } from '@ngrx/store';
import { selectColumnSettingByCCT, selectColumnSettingsByCCT, selectColumnSettingsLastUpdated, selectOrderedColumnSettingsByCCT } from '../selectors/table.selectors';
import { selectCurrentUser } from '../selectors/auth.selectors';
import { reduceItems } from '../utils/itementity';
import { INewColumnSetting } from '../../interfaces/seacom/columnsetting';
import { EnvironmentService } from 'src/app/services/environment.service';
import { selectPossibleMetadataFields } from '../selectors/incidenttype.selectors';
import { getNewColumnOrder } from '../utils/columnsettings.utils';

// get new column setting order field values based on neworders array with target index of column move
function getNewOrder(newOrders: number[], idx: number, tgtIdx: number): number {
  let nO: number = newOrders[idx];

  if (newOrders[idx] === null || newOrders[idx] === undefined) {
    // Change needed
    if (idx === 0) {
      nO = Math.round(newOrders[idx + 1] / 2);
      if (nO === 0 || nO === newOrders[idx + 1]) {
        nO = Math.round(getNewOrder(newOrders, idx + 1, tgtIdx) / 2);
      }
    } else if (idx === (newOrders.length - 1)) {
      nO = newOrders[idx - 1] + 512;
    } else {
      nO = newOrders[idx - 1] + Math.round((newOrders[idx + 1] - newOrders[idx - 1]) / 2);
    }
  } else if ( idx >= tgtIdx && idx === 0 && newOrders[idx] >= newOrders[idx + 1]) {
    // Change needed
    if (newOrders[idx + 1] === newOrders[idx]) {
      nO = Math.round(getNewOrder(newOrders, idx + 1, tgtIdx) / 2);
    } else {
      nO = Math.round(newOrders[idx + 1] / 2);
    }
  } else if (idx >= tgtIdx && idx === (newOrders.length - 1) && newOrders[idx] <= newOrders[idx - 1]) {
    if(newOrders[idx - 1] === 262145) {
      nO = newOrders[idx - 2] + Math.round((newOrders[idx - 1] - newOrders[idx - 2]) / 2);
    } else {
      nO = newOrders[idx - 1] + 512;
    }
  } else if (idx >= tgtIdx && (newOrders[idx] <= newOrders[idx - 1] || newOrders[idx] >= newOrders[idx + 1])) {
    if (newOrders[idx + 1] === newOrders[idx - 1] || newOrders[idx + 1] === (newOrders[idx - 1] + 1)) {
      nO = Math.round(getNewOrder(newOrders, idx + 1, tgtIdx) / 2);
    } else if (newOrders[idx + 1] === (newOrders[idx - 1] - 2)) {
      nO = newOrders[idx - 1] + 1;
    } else {
      nO = newOrders[idx - 1] + Math.round((newOrders[idx + 1] - newOrders[idx - 1]) / 2);
    }
  }

  return nO;
}

function adjustNewOrders(newOrders: number[], newOrder: string[], targetIdx: number): { id: string, newOrder: number }[] {
  console.log(targetIdx);
  let adjustedOrders = Array.from(newOrders);
  let updatedColumnOrders: { id: string, newOrder: number }[] = [];

  newOrders.forEach((o, idx) => {
    adjustedOrders[idx] = getNewOrder(adjustedOrders, idx, targetIdx);

    if (o !== adjustedOrders[idx]) {
      updatedColumnOrders.push({
        id: newOrder[idx],
        newOrder: adjustedOrders[idx]
      });
    }
  });

  return updatedColumnOrders;
}


@Injectable()
export class TableEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private columnSettingsService: ColumnSettingsService,
  ) { }

  /*
  *
  * Columm Settings effects
  *
  */

  loadColumnSettings$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load ColumnSettings
        ofType(
          actions.LoadColumnSettings
        ),
        concatLatestFrom((action) => this.store.select(selectColumnSettingsLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if (now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.columnSettingsService.getList().pipe(
              map((columnSettings) => actions.LoadColumnSettingsSuccess({ payload: columnSettings })),
              catchError((error) => of(actions.LoadColumnSettingsFail(error)))
            );
          } else {
            return of(actions.LoadColumnSettingsCancelled());
          }
        })
      );
  });
  loadColumnSettingsWithFilters$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load ColumnSettings with Filters
        ofType(actions.LoadColumnSettingsWithFilters),
        exhaustMap((action) => this.columnSettingsService.getList(action.filters, action.order, action.search).pipe(
          map((columnSettings) => actions.LoadColumnSettingsSuccess({ payload: columnSettings })),
          catchError((error) => of(actions.LoadColumnSettingsFail(error)))
        ))
      );
  });
  loadColumnSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load ColumnSetting
        ofType(actions.LoadColumnSetting),
        exhaustMap((action) => this.columnSettingsService.get(action.payload).pipe(
          map((columnSetting) => actions.LoadColumnSettingSuccess({ payload: columnSetting })),
          catchError((error) => of(actions.LoadColumnSettingsFail(error)))
        ))
      );
  });
  addColumnSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add ColumnSetting
        ofType(actions.AddColumnSetting),
        concatMap((action) => this.columnSettingsService.create(action.payload).pipe(
          map((columnSetting) => actions.AddColumnSettingSuccess({ payload: columnSetting })),
          catchError((error) => of(actions.AddColumnSettingFail(error)))
        ))
      );
  });
  updateColumnSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update ColumnSetting
        ofType(actions.UpdateColumnSetting),
        concatMap((action) => this.columnSettingsService.update(action.payload.id, action.payload).pipe(
          map((columnSetting) => actions.UpdateColumnSettingSuccess({ payload: columnSetting })),
          catchError((error) => of(actions.UpdateColumnSettingFail(error)))
        ))
      );
  });
  updateColumnSettingFields$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update ColumnSetting fields
        ofType(actions.UpdateColumnSettingFields),
        switchMap((action) => this.columnSettingsService.updateFields(action.payload.id, action.payload).pipe(
          map((columnSetting) => actions.UpdateColumnSettingSuccess({ payload: columnSetting })),
          catchError((error) => of(actions.UpdateColumnSettingFail(error)))
        ))
      );
  });
  deleteColumnSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete ColumnSetting
        ofType(actions.DeleteColumnSetting),
        mergeMap((action) =>
          this.columnSettingsService.delete(action.payload).pipe(
            map((columnSettingId) => actions.DeleteColumnSettingSuccess({ payload: columnSettingId })),
            catchError((error) => of(actions.DeleteColumnSettingFail(error)))
          )
        )
      )
  });
  displayColumn$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromColumnSelectorActions.DisplayColumn
        ),
        concatLatestFrom((action) => [
          this.store.select(selectColumnSettingByCCT(action.payload.container, action.payload.component, action.payload.table, action.payload.column)),
          this.store.select(selectCurrentUser),
          this.store.select(selectPossibleMetadataFields)
        ]),
        map(([action, cs, cu, pmfs]) => {
          if (
            cs === null ||
            cs.organization === undefined ||
            cs.organization === null ||
            cs.user === undefined ||
            cs.user === null
          ) {
            let mfs = pmfs.map(mf => mf.id);
            let mfid = mfs.indexOf(action.payload.column);
            let ncs: INewColumnSetting;
            if (mfid !== -1) {
              ncs = {
                organization: cu.organization.id,
                user: cu.id,
                container: action.payload.container,
                component: action.payload.component,
                table: action.payload.table,
                column: action.payload.column,
                datatype: 'metadata',
                displayname: pmfs[mfid].name,
                displayed: true
              } as INewColumnSetting;
            } else {
              ncs = {
                ...CopyColumnSettingForNew(cs),
                organization: cu.organization.id,
                user: cu.id,
                container: action.payload.container,
                component: action.payload.component,
                table: action.payload.table,
                column: action.payload.column,
                displayed: true
              } as INewColumnSetting;
            }

            return actions.AddColumnSetting({ payload: ncs });
          } else if (
            cs.organization.id === cu.organization.id &&
            cs.user.id === cu.id
          ) {
            let ncs = {
              id: cs.id,
              displayed: true
            } as IUpdatedColumnSetting;
            return actions.UpdateColumnSettingFields({ payload: ncs });
          } else {
            // This shouldn't happen -- something went wrong if it does.
          }
        })
      );
  });
  hideColumn$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromColumnSelectorActions.HideColumn
        ),
        concatLatestFrom((action) => [
          this.store.select(selectColumnSettingByCCT(action.payload.container, action.payload.component, action.payload.table, action.payload.column)),
          this.store.select(selectCurrentUser),
          this.store.select(selectPossibleMetadataFields),
        ]),
        map(([action, cs, cu, pmfs]) => {
          if (
            cs === null ||
            cs.organization === undefined ||
            cs.organization === null ||
            cs.user === undefined ||
            cs.user === null
          ) {
            let mfs = pmfs.map(mf => mf.id);
            let mfid = mfs.indexOf(action.payload.column);
            let ncs: INewColumnSetting;

            console.log(cs);

            if (mfid !== -1) {
              ncs = {
                organization: cu.organization.id,
                user: cu.id,
                container: action.payload.container,
                component: action.payload.component,
                table: action.payload.table,
                column: action.payload.column,
                datatype: 'metadata',
                displayname: pmfs[mfid].name,
                displayed: false
              } as INewColumnSetting;
            } else {
              ncs = {
                ...CopyColumnSettingForNew(cs),
                organization: cu.organization.id,
                user: cu.id,
                container: action.payload.container,
                component: action.payload.component,
                table: action.payload.table,
                column: action.payload.column,
                displayed: false
              } as INewColumnSetting;
            }

            return actions.AddColumnSetting({ payload: ncs });
          } else if (
            cs.organization.id === cu.organization.id &&
            cs.user.id === cu.id
          ) {
            let ncs = {
              id: cs.id,
              displayed: false
            } as IUpdatedColumnSetting;
            return actions.UpdateColumnSettingFields({ payload: ncs });
          }
        })
      );
  });

  reorderColumnSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.ReorderColumnSettings
        ),
        concatLatestFrom((action) => [
          this.store.select(selectColumnSettingsByCCT(action.payload.container, action.payload.component, action.payload.table)),
          this.store.select(selectCurrentUser),
          this.store.select(selectPossibleMetadataFields)
        ]),
        map(([action, cs, cu, pmfs]) => {
          // Remove action columns and reduce the items to an object for faster mapping from new order
          let cS = reduceItems(cs.filter(s => s.datatype !== 'action'));

          // Map new order to existing order ids
          let newOrders = action.payload.newOrder.map(c => cS[c].order);

          // Get new order numbers for columns
          let updateOrders = adjustNewOrders(newOrders, action.payload.newOrder, action.payload.to);

          // Update column settings that have a new order number
          updateOrders.forEach(cs => {
            if (
              cS[cs.id].organization === undefined ||
              cS[cs.id].organization === null ||
              cS[cs.id].user === undefined ||
              cS[cs.id].user === null
            ) {
              // Column Setting is either global or org based, create an individualized one (copying the existing one) with the new order
              let newCS = {
                ...CopyColumnSettingForNew(cS[cs.id]),
                organization: cu.organization.id,
                user: cu.id,
                order: cs.newOrder
              } as INewColumnSetting;
              this.store.dispatch(actions.AddColumnSetting({ payload: newCS }));
            } else {
              // Column Setting exists and is individualized, just update the order of it
              let updCSField = {
                id: cs.id,
                order: cs.newOrder
              } as IUpdatedColumnSetting;
              this.store.dispatch(actions.UpdateColumnSettingFields({ payload: updCSField }));
            }
          });
        })
      )
  },
    { dispatch: false });
}
