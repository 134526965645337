import { IOrganization } from "./organization";
import { IUUID } from "./templates/uuid";
import { IIncidentSeverity } from './incidentseverity';
import { IRegion } from "./region";
import { IDistrict } from "./district";
import { IBeach } from "./beach";
import { IStation } from "./station";
import { IUser } from "./user";
import { IIncidentType } from "./incidenttype";
import { IIncident } from './incident';
import { IMetadata, INewMetadata } from "./metadata";

export interface IIncidentResponse extends IUUID {
    d?: 'IIncidentResponse';
    organization: Partial<IOrganization>;
    incident: Partial<IIncident>;
    type: Partial<IIncidentType>;
    severity: Partial<IIncidentSeverity>;
    status: string;
    location: string | null;
    region: Partial<IRegion> | null;
    district: Partial<IDistrict> | null;
    beach: Partial<IBeach> | null;
    stations: Partial<IStation[]>;
    responders: Partial<IUser[]>;
    starttime: string;
    endtime: string | null;
    metadata: IMetadata[]|[]|null;
    created: string;
    last_modified: string;
    created_by: Partial<IUser>;
    last_modified_by: Partial<IUser>;
}

export interface INewIncidentResponse {
    d?: 'INewIncidentResponse';
    organization: string;
    incident: string;
    type?: string;
    severity?: string | undefined;
    status: string;
    location?: string;
    region?: string | undefined;
    district?: string | undefined;
    beach?: string | undefined;
    stations?: string[];
    responders?: string[];
    starttime: string;
    endtime?: string;
    metadata?: IMetadata[]|INewMetadata[]|[]|null;
}

export interface IUpdatedIncidentResponse {
    d?: 'IUpdatedIncidentResponse';
    id?: string;
    organization?: string;
    incident?: string;
    type?: string;
    severity?: string | undefined;
    status?: string;
    location?: string;
    region?: string | undefined;
    district?: string | undefined;
    beach?: string | undefined;
    stations?: string[];
    responders?: string[];
    starttime?: string;
    endtime?: string;
    metadata?: IMetadata[]|[]|null;
}

export function CopyIncidentResponseForUpdate(ir: IIncidentResponse): IUpdatedIncidentResponse {
    return {
        d: 'IUpdatedIncidentResponse',
        id: ir.id,
        organization: ir.organization ? ir.organization.id : undefined,
        incident: ir.incident ? ir.incident.id : undefined,
        type: ir.type ? ir.type.id : undefined,
        severity: ir.severity ? ir.severity.id : undefined,
        status: ir.status,
        location: ir.location,
        region: ir.region ? ir.region.id : undefined,
        district: ir.district ? ir.district.id : undefined,
        beach: ir.beach ? ir.beach.id : undefined,
        stations: ir.stations ? ir.stations.map(s => s.id) : undefined,
        responders: ir.responders ? ir.responders.map(r => r.id) : undefined,
        starttime: ir.starttime,
        endtime: ir.endtime,
        metadata: ir.metadata
    } as IUpdatedIncidentResponse;
}
