import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class DroppableListService {
  citySubject;
  widgetListSubject;
  nightMode;
  listtt;
  options;
  cityList: any[];
  widgetList: any[];
  
  constructor() {
    this.citySubject = new Subject<any[]>();
    this.widgetListSubject = new Subject<any[]>();
    this.nightMode = new Subject<any[]>();

    this.listtt = new Subject<any[]>();
  }
//__________________________________________
setWidget(e,option){ 
  this.options = option
  this.widgetList = e
  this.widgetListSubject.next(this.widgetList);
}

nightmode(value){
this.nightMode.next(value);
}
modeValue(): Observable<any[]>{
  console.log(this.nightMode)
  return this.nightMode
}

getwidget(): Observable<any[]> {
  return this.widgetListSubject
 }
//_______________________________________________
setCities(e,option) {
    this.options = option
    this.cityList = e
    this.citySubject.next(this.cityList);
}
getoption(){
  return this.options
}

setList(option){
  this.listtt.next(option);
}
getlist(): Observable<any[]> {
 return this.listtt
}

  getCity(): Observable<any[]> {
   return this.citySubject
  }
}