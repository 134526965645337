// Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

// Ionic
import { IonReorderGroup } from '@ionic/angular';
import { ItemReorderEventDetail } from '@ionic/core';

// RXJS
import { Observable } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Globals
import { skipNull } from '../../common/globals';

// Interfaces
import { IWidget } from 'src/app/interfaces/widget';

// State
import { SeacomState } from 'src/app/store';

// Actions
import { SetHomepageWidgetOrder } from 'src/app/containers/home/store/home.page.actions';

// Selectors
import { selectAllWidgets } from 'src/app/containers/home/store/home.page.selectors';
import { selectBrightness } from 'src/app/store/selectors/ambiance.selectors';


@Component({
  selector: 'app-droppable',
  templateUrl: './droppable.component.html',
  styleUrls: ['./droppable.component.scss'],
})
export class DroppableComponent implements OnInit {
  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;

  public widgets: IWidget[];

  brightness$: Observable<'light' | 'dark'>;

  homepageWidgets$: Observable<IWidget[]>;

  droppableVisibilityForm!: FormGroup;

  constructor(
    private store: Store<SeacomState>,
    private formBuilder: FormBuilder
  ) { }

  doReorder(ev: Event) {
    const customEvent = ev as CustomEvent<ItemReorderEventDetail>;
    let newOrder = Array.from(this.widgets);
    customEvent.detail.complete(newOrder);

    this.store.dispatch(SetHomepageWidgetOrder({
      payload: {
        newWidgets: newOrder
      }
    }));
  }

  ngOnInit() {
    // Subscribe to ambiance brightness
    this.brightness$ = this.store.select(selectBrightness);

    this.homepageWidgets$ = this.store.select(selectAllWidgets);

    this.droppableVisibilityForm = this.formBuilder.group({});
    this.homepageWidgets$.pipe(skipNull(), debounceTime(300)).subscribe(
      (ws) => {
        this.widgets = Array.from(ws);
      }
    );
    this.homepageWidgets$.pipe(skipNull(), first()).subscribe(
      (ws) => {
        for (let widget of ws) {
          this.droppableVisibilityForm.addControl(widget.name, new FormControl(widget.visible));
        }
      }
    );
  }

  visibilityChange(widgetName: string) {
    let val = this.droppableVisibilityForm.controls[widgetName].value;
    this.widgets = this.widgets.map(w => {
      if (w.name === widgetName) {
        return {
          ...w,
          visible: val
        };
      }
      return w;
    });

    this.store.dispatch(SetHomepageWidgetOrder({
      payload: {
        newWidgets: this.widgets
      }
    }));
  }
}
