import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SettingsPageComponent } from './settings.page';
import { ProfilePageRoutesModule } from './settings.page.routing';
import { DroppableModule } from 'src/app/components/droppable/droppable.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ProfilePageRoutesModule,
    DroppableModule
  ],
  declarations: [
    SettingsPageComponent
  ]
})

export class SettingsPageModule { }


