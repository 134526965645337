import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { INotification } from '../../interfaces/seacom/notification';

import * as actions from '../actions';
import { updateItems, updateItem, addItem, deleteItem } from '../utils/itementity';


export const notificationFeatureKey = 'notifications';

export interface NotificationState {
	entities: { [id: string]: INotification },
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const NotificationInitialState: NotificationState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

export const NotificationReducer = createReducer(
	NotificationInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...NotificationInitialState
		} as NotificationState;
	}),
	// Load Notifications
	on(actions.LoadNotifications, (state, action) => {
		return {
			...state,
			loading: true,
		} as NotificationState;
	}),
	on(actions.LoadNotificationsCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as NotificationState;
	}),
	on(actions.LoadNotificationsSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as NotificationState;
	}),
	on(actions.LoadNotificationsFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as NotificationState;
	}),

	// Load Notification
	on(actions.LoadNotification, (state, action) => {
		return {
			...state,
			loading: true,
		} as NotificationState;
	}),
	on(actions.LoadNotificationSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as NotificationState;
	}),
	on(actions.LoadNotificationFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as NotificationState;
	}),

	// Update Notification
	on(actions.UpdateNotification, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateNotificationSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateNotificationFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),

	// Update Notification Fields
	on(actions.UpdateNotificationFields, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateNotificationFieldsSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateNotificationFieldsFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),

	// Add Notification
	on(actions.AddNotification, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddNotificationSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddNotificationFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),

	// Delete Notification
	on(actions.DeleteNotification, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteNotificationSuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteNotificationFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	}),
);
