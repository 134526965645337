// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { INotification } from '../../interfaces/seacom/notification';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';
import { IFeedback, INewFeedback, IUpdatedFeedback } from 'src/app/interfaces/seacom/feedback';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends ApiNodeService<IFeedback, INewFeedback, IUpdatedFeedback> {
  node = 'feedback';

  constructor(public seacomApiService: SeacomApiService) {
    super(seacomApiService);
  }

}
