// NGRX
import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';

// Interfaces
import { IBeach } from '../../interfaces/seacom/beach';

// Actions
import * as actions from '../actions';

// Utils
import { addItem, deleteItem, updateItem, updateItems } from '../utils/itementity';


export const beachFeatureKey = 'beaches';

export interface BeachState {
	entities: { [id: string] : IBeach },
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const BeachInitialState: BeachState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

export const BeachReducer = createReducer(
	BeachInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...BeachInitialState
		} as BeachState;
	}),
	// Load Beaches
	on(actions.LoadBeaches, (state, action) => {
		return {
			...state,
			loading: true,
		} as BeachState;
	}),
	on(actions.LoadBeachesCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as BeachState;
	}),
	on(actions.LoadBeachesSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as BeachState;
	}),
	on(actions.LoadBeachesFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as BeachState;
	}),

	// Load Beach
	on(actions.LoadBeach, (state, action) => {
		return {
			...state,
			loading: true,
		} as BeachState;
	}),
	on(actions.LoadBeachSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as BeachState;
	}),
	on(actions.LoadBeachFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as BeachState;
	}),

	// Update Beach
	on(actions.UpdateBeach, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateBeachSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateBeachFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),

	// Add Beach
	on(actions.AddBeach, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddBeachSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddBeachFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),

	// Delete Beach
	on(actions.DeleteBeach, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteBeachSuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteBeachFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	}),
);
