import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IFeedback } from '../../interfaces/seacom/feedback';

import * as actions from '../actions';
import { addItem, updateItem, updateItems } from '../utils/itementity';


export const feedbackFeatureKey = 'feedbacks';

export interface FeedbackState {
	entities: { [id: string] : IFeedback },
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any
}

export const FeedbackInitialState: FeedbackState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null
};

export const FeedbackReducer = createReducer(
	FeedbackInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...FeedbackInitialState
		} as FeedbackState;
	}),
	// Load Feedbacks
	on(actions.LoadFeedbacks, (state, action) => {
		return {
			...state,
			loading: true,
      loaded: false,
      loadError: null
		} as FeedbackState;
	}),
	on(actions.LoadFeedbacksCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true,
      loadError: null
		} as FeedbackState;
	}),
	on(actions.LoadFeedbacksSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true,
      loadError: null
		} as FeedbackState;
	}),
	on(actions.LoadFeedbacksFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as FeedbackState;
	}),

	// Update Feedback
	on(actions.UpdateFeedback, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateFeedbackSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateFeedbackFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),

	// Add Feedback
	on(actions.fromFeedbackPageActions.AddFeedback, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddFeedbackSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddFeedbackFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),
);
