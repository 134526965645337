// ...
import { NgModule } from '@angular/core';

import { TrueFalsePipe, UnderscoreSpacePipe, UserFullNamePipe, YesNoPipe } from './pipes';

@NgModule({
  declarations: [
    YesNoPipe,
    TrueFalsePipe,
    UnderscoreSpacePipe,
    UserFullNamePipe
  ],
  exports: [
    YesNoPipe,
    TrueFalsePipe,
    UnderscoreSpacePipe,
    UserFullNamePipe
  ]
})
export class SeacomPipesModule {}
