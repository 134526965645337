// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Ionic
import { IonicModule } from '@ionic/angular';

// Modules
import { HomePageRoutingModule } from './home-routing.module';
import { RunbookWidgetModule } from 'src/app/components/widgets/runbook-widget/runbook-widget.module';
import { NotificationsWidgetModule } from 'src/app/components/widgets/notifications-widget/notifications-widget.module';
import { TimeclockWidgetModule } from 'src/app/components/widgets/timeclock-widget/timeclock-widget.module';
import { StationsWidgetModule } from 'src/app/components/widgets/stations-widget/stations-widget.module';
import { OperationsWidgetModule } from 'src/app/components/widgets/operations-widget/operations-widget.module';

// Pages
import { HomePageComponent } from './home.page';
import { ConditionsWidgetModule } from 'src/app/components/widgets/conditions-widget/conditions-widget.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    HomePageRoutingModule,
    RunbookWidgetModule,
    NotificationsWidgetModule,
    ConditionsWidgetModule,
    TimeclockWidgetModule,
    StationsWidgetModule,
    OperationsWidgetModule
  ],
  declarations: [
    HomePageComponent
  ],
  exports: [
    HomePageComponent
  ]
})
export class HomePageModule {}
