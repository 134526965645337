import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IIncidentType } from 'src/app/interfaces/seacom/incidenttype';
import { IMetadataField } from 'src/app/interfaces/seacom/metadatafield';
import { IMetadataListChoice } from 'src/app/interfaces/seacom/metadatalistchoice';
import * as fromReducers from '../reducers/incidenttype.reducers';
import { reduceItems } from '../utils/itementity';
import { sortFactory } from '../utils/sort.utils';
import { IItemEntity } from '../../interfaces/itementity';

export const selectIncidentTypeState = createFeatureSelector<fromReducers.IncidentTypeState>(
  fromReducers.incidenttypeFeatureKey
);

export const selectIncidentTypesCount = createSelector(
  selectIncidentTypeState,
  (state: fromReducers.IncidentTypeState): number => Object.keys(state.entities).length
);

export const selectIncidentTypesLastUpdated = createSelector(
  selectIncidentTypeState,
  (state: fromReducers.IncidentTypeState): string => state.lastUpdated
);

export const selectIncidentTypesLoading = createSelector(
  selectIncidentTypeState,
  (state: fromReducers.IncidentTypeState): boolean => state.loading
);

export const selectAllIncidentTypes = createSelector(
  selectIncidentTypesCount,
  selectIncidentTypeState,
  (count: number, state: fromReducers.IncidentTypeState): IIncidentType[] => {
    if (count > 0) {
      return Object.keys(state.entities)
        .map(i => state.entities[i])
        .sort((a, b) => sortFactory(a, b, [{ field: 'order', ascending: true }]));
    } else {
      return [];
    }
  }
);

export const selectIncidentTypesByUsage = (usage: string) => createSelector(
  selectAllIncidentTypes,
  (incidentTypes: IIncidentType[]): IIncidentType[] => {
    if (incidentTypes !== undefined && incidentTypes !== null && incidentTypes.length > 0) {
      let its = incidentTypes.filter(it => it.usage === usage);
      return its;
    } else {
      return [];
    }
  }
);

export const selectPossibleMetadataFields = createSelector(
  selectIncidentTypesCount,
  selectAllIncidentTypes,
  (count: number, types: IIncidentType[]): IMetadataField[] => {
    if (count > 0) {
      let mfs = types.map(t => t.metadata_fields);
      let fields: IMetadataField[] = [];
      mfs.forEach((t) => {
        let fids = fields.map(f => f.id);
        t.forEach((mf) => {
          if (fids.indexOf(mf.id) === -1) {
            fields.push(mf);
          }
        });
      });
      return fields;
    } else {
      return [];
    }
  }
);

export const selectPossibleMetadataFieldsById = createSelector(
  selectPossibleMetadataFields,
  (fields: IMetadataField[]): { [id: string]: IMetadataField } => {
    if (fields.length > 0) {
      return reduceItems(fields);
    } else {
      return {};
    }
  }
);

export const selectMetadataListChoicesByField = (id: string) => createSelector(
  selectPossibleMetadataFields,
  (fields: IMetadataField[]): IMetadataListChoice[] => {
    let fld = fields.find(f => f.id === id);
    if (fld !== undefined) {
      if (fld.hasOwnProperty('list_choices')) {
        let res = Array.from(fld.list_choices);
        return res.sort((a, b) => sortFactory(a, b, [
          { field: 'order', ascending: true }
        ]));
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
)

export const selectMetadataListChoicesById = createSelector(
  selectPossibleMetadataFields,
  (fields: IMetadataField[]): IItemEntity<IMetadataListChoice> => {
    if (fields.length > 0) {
      let res = [];

      fields.forEach(fld => {
        if (fld.hasOwnProperty('list_choices')) {
          res = res.concat(Array.from(fld.list_choices));
        }
      });

      return reduceItems(res);
    } else {
      return {};
    }
  }
)

export const selectIncidentTypeById = (id: string) => createSelector(selectIncidentTypeState, (incidentTypes): IIncidentType => {
  return incidentTypes?.entities[id];
});

export const selectIncidentTypeByName = (name: string) => createSelector(selectAllIncidentTypes, (incidentTypes): IIncidentType => {
  return incidentTypes
    .filter(it => it.name.toLowerCase() === name.toLowerCase())[0];
});

export const selectMetadataFieldSetByType = (typeId: string) => createSelector(
  selectIncidentTypeById(typeId),
  (type: IIncidentType): IMetadataField[] => {
    return type.metadata_fields;
  }
)
