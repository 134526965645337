import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { IWeatherApiForecastResult } from '../interfaces/weather/weatherapiforecastresult';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { skipNull } from '../common/globals';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  constructor(
    public environmentService: EnvironmentService,
    public httpClient: HttpClient
  ) { }

  /** Reaches out to the weather API service and retrieves the current and future weather forecast. */
  public getDailyWeatherForecast(loc: GeolocationCoordinates, num_days: number): Observable<IWeatherApiForecastResult> {
    if(!this.environmentService.production) {
      console.log('weather loc:');
      console.log(loc);
    }
    let result = new Subject<IWeatherApiForecastResult>();
    if (loc !== null) {
      this.httpClient.get<any>(
        this.environmentService.weatherApiURL +
        '?key=' + this.environmentService.weatherApiKey +
        '&q=' + loc.latitude.toFixed(6) + ',' + loc.longitude.toFixed(6) +
        '&days=' + num_days,
        { observe: 'response' }
      )
        .pipe(
          skipNull(),
          timeout(
            this.environmentService.weatherApiTimeout
          )
        )
        .subscribe(
          (res: HttpResponse<IWeatherApiForecastResult>) => {
            switch (res.status) {
              case 200:
                result.next(res.body as IWeatherApiForecastResult);
                break;
              default:
                result.error(res.body);
                break;
            }
          },
          (err) => {
            result.error(err);
          }
        );
      
    } else {
      result.error('No location');
    }
    return result.asObservable();
  }
}
