import { createAction, props } from '@ngrx/store';
import { IBeach, INewBeach, IUpdatedBeach } from 'src/app/interfaces/seacom/beach';

// beach action types
export const LOAD_BEACHES = '[Page Effects] Load Beaches';
export const LOAD_BEACHES_CANCELLED = '[Beach Effects] Load Beaches Cancelled';
export const LOAD_BEACHES_FAIL = '[Beach Effects] Load Beaches Fail';
export const LOAD_BEACHES_SUCCESS = '[Beach Effects] Load Beaches Success';
export const LOAD_BEACH = '[Component Effects] Load Beach';
export const LOAD_BEACH_FAIL = '[Beach Effects] Load Beach Fail';
export const LOAD_BEACH_SUCCESS = '[Beach Effects] Load Beach Success';
export const UPDATE_BEACH = '[SEACOM] Update Beach';
export const UPDATE_BEACH_FAIL = '[Beach Effects] Update Beach Fail';
export const UPDATE_BEACH_SUCCESS = '[Beach Effects] Update Beach Success';
export const ADD_BEACH = '[SEACOM] Add Beach';
export const ADD_BEACH_FAIL = '[Beach Effects] Add Beach Fail';
export const ADD_BEACH_SUCCESS = '[Beach Effects] Add Beach Success';
export const DELETE_BEACH = '[SEACOM] Delete Beach';
export const DELETE_BEACH_FAIL = '[Beach Effects] Delete Beach Fail';
export const DELETE_BEACH_SUCCESS = '[Beach Effects] Delete Beach Success';

export const LoadBeaches = createAction(
	LOAD_BEACHES
);

export const LoadBeachesCancelled = createAction(
	LOAD_BEACHES_CANCELLED
);

export const LoadBeachesSuccess = createAction(
    LOAD_BEACHES_SUCCESS,
    props<{
        payload: IBeach[]
    }>()
);

export const LoadBeachesFail = createAction(
    LOAD_BEACHES_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadBeach = createAction(
	LOAD_BEACH,
    props<{
        payload: string // ID
    }>()
);

export const LoadBeachSuccess = createAction(
    LOAD_BEACH_SUCCESS,
    props<{
        payload: IBeach
    }>()
);

export const LoadBeachFail = createAction(
    LOAD_BEACH_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateBeach = createAction(
    UPDATE_BEACH,
    props<{
        payload: IUpdatedBeach
    }>()
);

export const UpdateBeachSuccess = createAction(
    UPDATE_BEACH_SUCCESS,
    props<{
        payload: IBeach
    }>()
);

export const UpdateBeachFail = createAction(
    UPDATE_BEACH_FAIL,
    props<{
        payload: any
    }>()
);

export const AddBeach = createAction(
    ADD_BEACH,
    props<{
        payload: INewBeach
    }>()
);

export const AddBeachSuccess = createAction(
    ADD_BEACH_SUCCESS,
    props<{
        payload: IBeach
    }>()
);

export const AddBeachFail = createAction(
    ADD_BEACH_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteBeach = createAction(
    DELETE_BEACH,
    props<{
        payload: string // ID
    }>()
);

export const DeleteBeachSuccess = createAction(
    DELETE_BEACH_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteBeachFail = createAction(
    DELETE_BEACH_FAIL,
    props<{
        payload: any
    }>()
);
