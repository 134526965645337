import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAmbiance } from 'src/app/interfaces/ambiance';
import * as fromReducers from '../reducers/ambiance.reducers';

export const selectAmbianceState = createFeatureSelector<fromReducers.AmbianceState>(
  fromReducers.ambianceFeatureKey
);

export const selectAmbiancesLastUpdated = createSelector(
  selectAmbianceState,
  (state: fromReducers.AmbianceState): string => state.lastUpdated
);

export const selectAmbiance = createSelector(
  selectAmbianceState,
  (state: fromReducers.AmbianceState): IAmbiance => state.ambiance
);

export const selectBrightness = createSelector(
  selectAmbianceState,
  (state: fromReducers.AmbianceState): 'light'|'dark' => state.ambiance.brightness
);
