// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// RXJS
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../../../store/actions';

// Services
import { SeacomState } from 'src/app/store';
import { Store } from '@ngrx/store';
import { selectLatestLocation, selectLocationDisabled } from '../../../store/selectors/location.selectors';
import { selectCurrentUser } from '../../../store/selectors/auth.selectors';
import { selectIncidentTypeByName } from 'src/app/store/selectors/incidenttype.selectors';
import { selectStationsWithinDistance } from 'src/app/store/selectors/station.selectors';
import { INewIncident } from 'src/app/interfaces/seacom/incident';
import { of } from 'rxjs';
import { toLocalISOString } from 'src/app/common/globals';


@Injectable()
export class HomePageEffects {

  constructor(private actions$: Actions, private router: Router, private store: Store<SeacomState>) { }

  enter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHomePageActions.Enter
        ),
        map((action) => {
          this.store.dispatch(actions.LoadNotifications());
        }),
        map((action) => {
          this.store.dispatch(actions.LoadIncidents());
        }),
        map((action) => {
          this.store.dispatch(actions.LoadIncidentTypes());
        }),
        map((action) => {
          this.store.dispatch(actions.LoadIncidentSeverities());
        }),
        map((action) => {
          this.store.dispatch(actions.LoadMetadatas());
        }),
        map((action) => {
          this.store.dispatch(actions.LoadStations());
        }),
        map((action) => {
          this.store.dispatch(actions.LoadStations());
        }),
        map((action) => {
          this.store.dispatch(actions.LoadTimeclock());
        })
      )
  },
    { dispatch: false }
  );

  navigateToNotifications$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHomePageActions.NavigateToNotificationsPage
        ),
        map((action) => {
          this.router.navigate(['/notifications']);
        }),
      )
  },
    { dispatch: false }
  );

  navigateToConditions$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHomePageActions.NavigateToConditionsPage
        ),
        map((action) => {
          this.router.navigate(['/conditions']);
        }),
      )
  },
    { dispatch: false }
  );

  navigateToOperations$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHomePageActions.NavigateToOperationsPage
        ),
        map((action) => {
          this.router.navigate(['/operations']);
        }),
      )
  },
    { dispatch: false }
  );

  navigateToRunbook$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHomePageActions.NavigateToRunbookPage
        ),
        map((action) => {
          this.router.navigate(['/runbook']);
        }),
      )
  },
    { dispatch: false }
  );

  navigateToStations$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHomePageActions.NavigateToStationsPage
        ),
        map((action) => {
          this.router.navigate(['/stations']);
        }),
      )
  },
    { dispatch: false }
  );

  createEmergencyIncident$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHomePageActions.CreateEmergencyIncident
        ),
        withLatestFrom(
          this.store.select(selectLocationDisabled),
          this.store.select(selectLatestLocation),
          this.store.select(selectCurrentUser),
          this.store.select(selectStationsWithinDistance(10)),
          this.store.select(selectIncidentTypeByName('emergency')),
        ),
        map(([action, locationDisabled, currentLocation, currentUser, stationsByDistance, emergencyType]) => {
          let emergencyIncident: INewIncident = {
            "organization": currentUser.organization.id,
            "region": stationsByDistance.length > 0 ? stationsByDistance[0].region.id : undefined,
            "district": stationsByDistance.length > 0 ? stationsByDistance[0].district.id : undefined,
            "beach": stationsByDistance.length > 0 ? stationsByDistance[0].beach.id : undefined,
            "station": stationsByDistance.length > 0 ? stationsByDistance[0].id : undefined,
            "source": currentUser.role.role.name.toLowerCase(),
            "type": emergencyType.id,
            "severity": emergencyType.default_severity.id,
            "status": "responding",
            "starttime": toLocalISOString(new Date()),
            "location": currentLocation !== undefined && currentLocation !== null && !locationDisabled ? (currentLocation.latitude.toFixed(6) + ', ' + currentLocation.longitude.toFixed(6)) : undefined,
          };

          this.store.dispatch(actions.fromHomePageActions.AddEmergencyIncident({ payload: emergencyIncident }));
        }),
      )
  },
    { dispatch: false }
  );

  addEmergencyIncident$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHomePageActions.AddEmergencyIncident
        ),
        switchMap((action) => of(actions.AddIncident({ payload: action.payload })))
      )
  });
}
