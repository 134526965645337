// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.spinner-cont {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40%;
  height: 40%;
  --color: var(--color-blue-1-light);
}

/* dark theme styles */
.dark .spinner {
  --color: var(--color-blue-1-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/components/loading-spinner/loading-spinner.component.scss","webpack://./../../../../../Ryan%20Ticer/source/repos/seacom3/ui/src/app/components/loading-spinner/loading-spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,YAAA;ACCF;;ADEA;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;ACCF;;ADEA;EACE,UAAA;EACA,WAAA;EACA,kCAAA;ACCF;;ADEA,sBAAA;AAEE;EACE,iCAAA;ACAJ","sourcesContent":[":host {\r\n  flex-grow: 1;\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.spinner-cont {\r\n  flex-grow: 1;\r\n  height: 100%;\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center\r\n}\r\n\r\n.spinner {\r\n  width: 40%;\r\n  height: 40%;\r\n  --color: var(--color-blue-1-light);\r\n}\r\n\r\n/* dark theme styles */\r\n.dark {\r\n  .spinner {\r\n    --color: var(--color-blue-1-dark);\r\n  }\r\n}\r\n",":host {\n  flex-grow: 1;\n  width: 100%;\n  height: 100%;\n}\n\n.spinner-cont {\n  flex-grow: 1;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.spinner {\n  width: 40%;\n  height: 40%;\n  --color: var(--color-blue-1-light);\n}\n\n/* dark theme styles */\n.dark .spinner {\n  --color: var(--color-blue-1-dark);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
