import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFeedback } from 'src/app/interfaces/seacom/feedback';
import * as fromReducers from '../reducers/feedback.reducers';
import { sortFactory } from '../utils/sort.utils';

export const selectFeedbackState = createFeatureSelector<fromReducers.FeedbackState>(
  fromReducers.feedbackFeatureKey
);

export const selectFeedbacksCount = createSelector(
  selectFeedbackState,
  (state: fromReducers.FeedbackState): number => Object.keys(state.entities).length
);

export const selectFeedbacksLastUpdated = createSelector(
  selectFeedbackState,
  (state: fromReducers.FeedbackState): string => state.lastUpdated
);

export const selectFeedbacksLoading = createSelector(
  selectFeedbackState,
  (state: fromReducers.FeedbackState): boolean => state.loading
);

export const selectAllFeedbacks = createSelector(
  selectFeedbacksCount,
  selectFeedbackState,
  (count: number, state: fromReducers.FeedbackState): IFeedback[] => {
    if(count > 0) {
      return Object.keys(state.entities)
        .map(f => state.entities[f])
        .sort((a,b) => sortFactory(a,b,[{field: 'created', ascending: false}]));
    } else {
      return [];
    }
  }
);

export const selectFeedbackById = (id: string) => createSelector(selectFeedbackState, (feedbacks): IFeedback => {
  return feedbacks?.entities[id];
});
