import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IDistrict } from '../../interfaces/seacom/district';

import * as actions from '../actions';
import { addItem, deleteItem, updateItem, updateItems } from '../utils/itementity';


export const districtFeatureKey = 'districts';

export interface DistrictState {
	entities: { [id: string] : IDistrict },
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const DistrictInitialState: DistrictState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

export const DistrictReducer = createReducer(
	DistrictInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...DistrictInitialState
		} as DistrictState;
	}),
	// Load Districts
	on(actions.LoadDistricts, (state, action) => {
		return {
			...state,
			loading: true,
		} as DistrictState;
	}),
	on(actions.LoadDistrictsCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as DistrictState;
	}),
	on(actions.LoadDistrictsSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as DistrictState;
	}),
	on(actions.LoadDistrictsFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as DistrictState;
	}),

	// Load District
	on(actions.LoadDistrict, (state, action) => {
		return {
			...state,
			loading: true,
		} as DistrictState;
	}),
	on(actions.LoadDistrictSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as DistrictState;
	}),
	on(actions.LoadDistrictFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as DistrictState;
	}),

	// Update District
	on(actions.UpdateDistrict, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateDistrictSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateDistrictFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),

	// Add District
	on(actions.AddDistrict, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddDistrictSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddDistrictFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),

	// Delete District
	on(actions.DeleteDistrict, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteDistrictSuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteDistrictFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	}),
);
