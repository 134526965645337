import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IStationSetting } from 'src/app/interfaces/seacom/stationsetting';
import * as fromReducers from '../reducers/stationsetting.reducers';
import { sortFactory } from '../utils/sort.utils';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { filterObject } from '../utils/filter.utils';
import { searchFactory } from '../utils/search.utils';
import { IItemEntity } from 'src/app/interfaces/itementity';

export const selectStationSettingState = createFeatureSelector<fromReducers.StationSettingState>(
  fromReducers.stationSettingFeatureKey
);

export const selectStationSettingById = (id: string) => createSelector(selectStationSettingState, (columnsettings): IStationSetting => {
  return columnsettings?.entities[id];
});

export const selectStationSettingsLastUpdated = createSelector(
  selectStationSettingState,
  (state: fromReducers.StationSettingState): string => state.lastUpdated
);

export const selectStationSettingsCount = createSelector(
  selectStationSettingState,
  (state: fromReducers.StationSettingState): number => Object.keys(state.entities).length
);

export const selectAllStationSettings = createSelector(
  selectStationSettingsCount,
  selectStationSettingState,
  (count: number, state: fromReducers.StationSettingState): IStationSetting[] => {
    if (count > 0) {
      return Object.keys(state.entities).map(i => state.entities[i])
        .sort((a, b) => sortFactory(a, b, [{ field: 'order', ascending: true }]));
    } else {
      return [];
    }
  }
);

export const selectStationSettingsByStation = createSelector(
  selectStationSettingsCount,
  selectStationSettingState,
  (count: number, state: fromReducers.StationSettingState): IItemEntity<IStationSetting> => {
    if (count > 0) {
      //return Object.keys(state.entities).map(i => state.entities[i]);
      return [...Object.keys(state.entities).map(i => state.entities[i])].reduce(
        (entities: IItemEntity<IStationSetting>, item: IStationSetting) => {
          entities[item.station.id] = item;
          return entities;
        },
        {}
      ) as IItemEntity<IStationSetting>;
    } else {
      return {};
    }
  }
);

export const selectStationSettingsInOrder = createSelector(
  selectStationSettingsCount,
  selectAllStationSettings,
  (count: number, stationSettings: IStationSetting[]): IStationSetting[] => {
    if (count > 0) {
      return stationSettings.sort((a, b) => sortFactory(a, b, [{ field: 'order', ascending: true }]))
    } else {
      return [];
    }
  }
);

export const selectStationSettingsWithFilter = (filters?: IFieldFilter[], order?: string, search?: string) => createSelector(selectAllStationSettings, (columnsettings): IStationSetting[] => {
  let res = columnsettings;

  if (filters !== undefined && filters !== null) {
    res = res.filter(i => filterObject(i, filters));
  }

  if (search !== undefined && search !== null) {
    res = res.filter(i => searchFactory(i, search));
  }

  if (order !== undefined && order !== null) {
    let sortAsc = true;
    if (order.substring(0, 1) === '-') {
      sortAsc = false;
    }
    let sortField = order.replace('-', '').replace('+', '');
    return res.sort((a, b) => sortFactory(a, b, [{ field: sortField, ascending: sortAsc }]));
  } else {
    // Sort descending by starttime by default
    return res.sort((a, b) => sortFactory(a, b, [{ field: 'order', ascending: true }]));
  }
});
