import { createAction, props } from '@ngrx/store';

// alert action types
export const SET_BRIGHTNESS = '[Components] Set Brightness';

export const SetBrightness = createAction(
	SET_BRIGHTNESS,
    props<{
        payload: {
            brightness: 'light'|'dark'
        }
    }>()
);
