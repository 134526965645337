import { NgModule, Injectable} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ColumnFilterComponent } from './column-filter.component';
import { MatTableModule } from '@angular/material/table';
import { SeacomPipesModule } from 'src/app/common/pipes.module';


@Injectable({ 
  providedIn: 'root'
})
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    SeacomPipesModule
  ],
  declarations: [ ColumnFilterComponent ],
  exports: [ ColumnFilterComponent ]
})

export class ColumnFilterModule { }



