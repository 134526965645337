import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { ConditionsWidgetComponent } from './conditions-widget.component';
import { LoadingSpinnerModule } from '../../loading-spinner/loading-spinner.module';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    LoadingSpinnerModule
  ],
  declarations: [
    ConditionsWidgetComponent
  ],
  exports: [
    ConditionsWidgetComponent
  ]
})
export class ConditionsWidgetModule { }
