import { createAction, props } from '@ngrx/store';
import { IIncidentSeverity, INewIncidentSeverity, IUpdatedIncidentSeverity } from 'src/app/interfaces/seacom/incidentseverity';

// incidentseverity action types
export const LOAD_INCIDENTSEVERITIES = '[Page Effects] Load IncidentSeverities';
export const LOAD_INCIDENTSEVERITIES_CANCELLED = '[Incident Severity Effects] Load IncidentSeverities Cancelled';
export const LOAD_INCIDENTSEVERITIES_FAIL = '[Incident Severity Effects] Load IncidentSeverities Fail';
export const LOAD_INCIDENTSEVERITIES_SUCCESS = '[Incident Severity Effects] Load IncidentSeverities Success';
export const LOAD_INCIDENTSEVERITY = '[Component Effects] Load IncidentSeverity';
export const LOAD_INCIDENTSEVERITY_FAIL = '[Incident Severity Effects] Load IncidentSeverity Fail';
export const LOAD_INCIDENTSEVERITY_SUCCESS = '[Incident Severity Effects] Load IncidentSeverity Success';
export const UPDATE_INCIDENTSEVERITY = '[SEACOM] Update IncidentSeverity';
export const UPDATE_INCIDENTSEVERITY_FAIL = '[Incident Severity Effects] Update IncidentSeverity Fail';
export const UPDATE_INCIDENTSEVERITY_SUCCESS = '[Incident Severity Effects] Update IncidentSeverity Success';
export const ADD_INCIDENTSEVERITY = '[SEACOM] Add IncidentSeverity';
export const ADD_INCIDENTSEVERITY_FAIL = '[Incident Severity Effects] Add IncidentSeverity Fail';
export const ADD_INCIDENTSEVERITY_SUCCESS = '[Incident Severity Effects] Add IncidentSeverity Success';
export const DELETE_INCIDENTSEVERITY = '[SEACOM] Delete IncidentSeverity';
export const DELETE_INCIDENTSEVERITY_FAIL = '[Incident Severity Effects] Delete IncidentSeverity Fail';
export const DELETE_INCIDENTSEVERITY_SUCCESS = '[Incident Severity Effects] Delete IncidentSeverity Success';

export const LoadIncidentSeverities = createAction(
	LOAD_INCIDENTSEVERITIES
);

export const LoadIncidentSeveritiesCancelled = createAction(
    LOAD_INCIDENTSEVERITIES_CANCELLED
);

export const LoadIncidentSeveritiesSuccess = createAction(
    LOAD_INCIDENTSEVERITIES_SUCCESS,
    props<{
        payload: IIncidentSeverity[]
    }>()
);

export const LoadIncidentSeveritiesFail = createAction(
    LOAD_INCIDENTSEVERITIES_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadIncidentSeverity = createAction(
	LOAD_INCIDENTSEVERITY,
    props<{
        payload: string // ID
    }>()
);

export const LoadIncidentSeveritySuccess = createAction(
    LOAD_INCIDENTSEVERITY_SUCCESS,
    props<{
        payload: IIncidentSeverity
    }>()
);

export const LoadIncidentSeverityFail = createAction(
    LOAD_INCIDENTSEVERITY_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateIncidentSeverity = createAction(
    UPDATE_INCIDENTSEVERITY,
    props<{
        payload: IUpdatedIncidentSeverity
    }>()
);

export const UpdateIncidentSeveritySuccess = createAction(
    UPDATE_INCIDENTSEVERITY_SUCCESS,
    props<{
        payload: IIncidentSeverity
    }>()
);

export const UpdateIncidentSeverityFail = createAction(
    UPDATE_INCIDENTSEVERITY_FAIL,
    props<{
        payload: any
    }>()
);

export const AddIncidentSeverity = createAction(
    ADD_INCIDENTSEVERITY,
    props<{
        payload: INewIncidentSeverity
    }>()
);

export const AddIncidentSeveritySuccess = createAction(
    ADD_INCIDENTSEVERITY_SUCCESS,
    props<{
        payload: IIncidentSeverity
    }>()
);

export const AddIncidentSeverityFail = createAction(
    ADD_INCIDENTSEVERITY_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteIncidentSeverity = createAction(
    DELETE_INCIDENTSEVERITY,
    props<{
        payload: string // ID
    }>()
);

export const DeleteIncidentSeveritySuccess = createAction(
    DELETE_INCIDENTSEVERITY_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteIncidentSeverityFail = createAction(
    DELETE_INCIDENTSEVERITY_FAIL,
    props<{
        payload: any
    }>()
);
