import { getBoolean, getBooleanString, toLocalISOString } from "src/app/common/globals";
import { IFieldFilter } from "src/app/interfaces/seacom/fieldfilter";
import { IIncident } from "src/app/interfaces/seacom/incident";

/*
 *
 * Universal filter factory (to be applied to one item with set of filters)
 * 
 */
export function filterObject(object: unknown, filters: IFieldFilter[]): boolean {
  let filterPassed = true;
  let res: boolean;
  filters?.forEach(
    (f) => {
      let fields = f.field.split('__');
      if (fields.length > 1) {
        if (object.hasOwnProperty(fields[0])) {
          let newFilter = Array.from(fields);
          newFilter.shift();
          let newFieldString = newFilter.join('__');
          res = filterObject(object[fields[0]], [{
            field: newFieldString,
            filter: f.filter
          } as IFieldFilter]);
          if (!res) {
            filterPassed = false;
          }
        } else {
          filterPassed = false;
        }
      } else if (object.hasOwnProperty('metadata') && (object as IIncident).metadata.length > 0) {
        let md = (object as IIncident).metadata.find(m => m.field.id === f.field);
        if (md !== undefined) {
          res = filterObject(md, [{
            field: 'data',
            op: f.op,
            filter: f.filter
          } as IFieldFilter]);
          if (!res) {
            filterPassed = false;
          }
        } else {
          filterPassed = false;
        }
      } else {
        if (object[f.field] === undefined) {
          filterPassed = false;
        } else {
          switch (typeof f.filter) {
            case 'boolean':
            case 'number':
              switch (f.op) {
                case 'eq':
                case 'in':
                  if (typeof object[f.field] !== typeof f.filter) {
                    if (typeof f.filter === 'boolean') {
                      if (getBoolean(object[f.field]) !== f.filter) {
                        filterPassed = false;
                      }
                    } else {
                      if (parseInt(object[f.field]) !== f.filter) {
                        filterPassed = false;
                      }
                    }
                  } else {
                    if (object[f.field] !== f.filter) {
                      filterPassed = false;
                    }
                  }
                  break;
                case 'gt':
                  if (object[f.field] <= f.filter) {
                    filterPassed = false;
                  }
                  break;
                case 'gte':
                  if (object[f.field] < f.filter) {
                    filterPassed = false;
                  }
                  break;
                case 'lt':
                  if (object[f.field] >= f.filter) {
                    filterPassed = false;
                  }
                  break;
                case 'lte':
                  if (object[f.field] > f.filter) {
                    filterPassed = false;
                  }
                  break;
                default:
                  // Unknown meta function fails filter
                  filterPassed = false;
                  break;
              }
              break;
            case 'string':
              switch (f.op) {
                case 'eq':
                  if (object[f.field] !== f.filter) {
                    filterPassed = false;
                  }
                  break;
                case 'gt':
                  if (object[f.field] <= f.filter) {
                    filterPassed = false;
                  }
                  break;
                case 'gte':
                  if (object[f.field] < f.filter) {
                    filterPassed = false;
                  }
                  break;
                case 'in':
                  if (typeof object[f.field] === 'boolean') {
                    if (f.filter !== getBooleanString(object[f.field])) {
                      filterPassed = false;
                    }
                  } else if (typeof object[f.field] === 'object' && object[f.field].hasOwnProperty('id')) {
                    if (object[f.field].id !== f.filter) {
                      filterPassed = false;
                    }
                  } else if (object[f.field] !== f.filter) {
                    filterPassed = false;
                  }
                  break;
                case 'lt':
                  if (object[f.field] >= f.filter) {
                    filterPassed = false;
                  }
                  break;
                case 'lte':
                  if (object[f.field] > f.filter) {
                    filterPassed = false;
                  }
                  break;
                default:
                  // Unknown meta function fails filter
                  filterPassed = false;
                  break;
              }
              break;
            case 'object':
              switch (true) {
                case Array.isArray(f.filter):
                  res = false;
                  f.filter.forEach(flt => {
                    res = res || filterObject(object, [{
                      field: f.field,
                      op: f.op,
                      filter: flt
                    }]);
                  });

                  if (!res) {
                    filterPassed = res;
                  }
                  break;
                case f.filter instanceof Date:
                  res = filterObject(object, [{
                    field: f.field,
                    op: f.op,
                    filter: toLocalISOString(f.filter as Date)
                  }]);
                  if (!res) {
                    filterPassed = false;
                  }
                  break;
                default:
                  if (typeof object[f.field] === 'object' && object[f.field].hasOwnProperty('id') && f.filter.hasOwnProperty('id')) {
                    res = filterObject(object[f.field], [{
                      field: 'id',
                      op: f.op,
                      filter: f.filter.id
                    }]);
                    if (!res) {
                      filterPassed = false;
                    }
                  } else {
                    filterPassed = false;
                  }
                  break;
              }
              break;
            default:
              filterPassed = false;
              break;
          }
        }
      }
    }
  )

  return filterPassed;
}

export function incidentFilterFactory(object: IIncident, filters: IFieldFilter[]): boolean {
  let filterPassed = true; // 
  filters?.forEach(
    (f) => {
      let fields = f.field.split('__');



      if (fields.length > 1) { // There is a meta function (gt, lt, gte, lte, etc.)
        if (object.hasOwnProperty(fields[0])) {
          switch (typeof object[fields[0]]) {
            case 'string':
              if (fields[0].indexOf('time') !== -1 || fields[0] === 'created' || fields[0] === 'last_modified') {
                // date/time field
                let mndt: Date;
                let mxdt: Date;
                let ts: string[];
                let ots = Math.round(((new Date(object[fields[0]])).getTime()) / 1000).toString();

                if (Array.isArray(f.filter)) {
                  mndt = new Date(Math.min(...f.filter.map(f => (new Date(f)).getTime())));
                  mxdt = new Date(Math.max(...f.filter.map(f => (new Date(f)).getTime())));
                  ts = f.filter.map(f => Math.round(((new Date(f)).getTime()) / 1000).toString());
                } else {
                  mndt = new Date(f.filter);
                  mxdt = new Date(f.filter);
                  ts = [Math.round(((new Date(f.filter)).getTime()) / 1000).toString()];
                }
                switch (fields[1]) {
                  case 'gt':
                    if (new Date(object[fields[0]]) <= mxdt) {
                      filterPassed = false;
                    }
                    break;
                  case 'gte':
                    if (new Date(object[fields[0]]) < mxdt && !ts.includes(ots)) {
                      filterPassed = false;
                    }
                    break;
                  case 'lt':
                    if (new Date(object[fields[0]]) > mndt || ts.includes(ots)) {
                      filterPassed = false;
                    }
                    break;
                  case 'lte':
                    if (new Date(object[fields[0]]) > mndt && !ts.includes(ots)) {
                      filterPassed = false;
                    }
                    break;
                  case 'neq':
                    if (ts.includes(ots)) {
                      filterPassed = false;
                    }
                    break;
                  case 'eq':
                    if (!ts.includes(ots)) {
                      filterPassed = false;
                    }
                    break;
                  default:
                    // Unknown filter returns no data
                    filterPassed = false;
                    break;
                }
              } else {
                // simple string field
                switch (fields[1]) {
                  case 'gt':
                    if (object[fields[0]] <= f.filter) {
                      filterPassed = false;
                    }
                    break;
                  case 'gte':
                    if (object[fields[0]] < f.filter) {
                      filterPassed = false;
                    }
                    break;
                  case 'lt':
                    if (object[fields[0]] >= f.filter) {
                      filterPassed = false;
                    }
                    break;
                  case 'lte':
                    if (object[fields[0]] > f.filter) {
                      filterPassed = false;
                    }
                    break;
                  case 'neq':
                    if (object[fields[0]] === f.filter) {
                      filterPassed = false;
                    }
                    break;
                  case 'eq':
                    if (object[fields[0]] !== f.filter) {
                      filterPassed = false;
                    }
                    break;
                  default:
                    // Unknown filter returns no data
                    filterPassed = false;
                    break;
                }
              }
              break;
            case 'object':
              // TODO
              break;
            default:
              filterPassed = false;
              break;
          }
        } else {
          if (f.filter !== null) {
            filterPassed = false;
          } else {
            filterPassed = true;
          }
        }
      } else { // No meta function, just checking equality
        switch (fields[0]) {
          case 'station':
            if (Array.isArray(f.filter)) {
              if (
                f.filter.indexOf(object[fields[0]].id) === -1 &&
                object.responses.map(ir => ir.stations)
                  .filter(
                    s => s.filter(
                      s2 => (f.filter as string[]).includes(s2.id)
                    ).length > 0
                  ).length === 0
              ) {
                filterPassed = false;
              }
            } else if (object[fields[0]].id !== f.filter && object.responses.map(ir => ir.stations).filter(s => s.map(s => s.id).indexOf(f.filter as string) !== -1).length === 0) {
              filterPassed = false;
            }
            break;
          case 'primary_responder':
          case 'secondary_responder':
          case 'tertiary_responder':
            if (Array.isArray(f.filter)) {
              if (
                f.filter.indexOf(object[fields[0]].id) === -1 &&
                object.responses.map(ir => ir.responders)
                  .filter(
                    r => r.filter(
                      r2 => (f.filter as string[]).includes(r2.id)
                    ).length > 0
                  ).length === 0
              ) {
                filterPassed = false;
              }
            } else if (object[fields[0]].id !== f.filter && object.responses.map(ir => ir.responders).filter(r => r.map(r => r.id).indexOf(f.filter as string) !== -1).length === 0) {
              filterPassed = false;
            }
            break;
          default:
            if (typeof (object[fields[0]]) === 'object') {
              if (Array.isArray(f.filter)) {
                if (f.filter.indexOf(object[fields[0]].id) === -1) {
                  filterPassed = false;
                }
              } else {
                if (object[fields[0]].id !== f.filter) {
                  filterPassed = false;
                }
              }
            } else {
              if (Array.isArray(f.filter)) {
                if (f.filter.indexOf(object[fields[0]]) === -1) {
                  filterPassed = false;
                }
              } else {
                if (object[fields[0]] !== f.filter) {
                  filterPassed = false;
                }
              }
            }
            break;
        }
      }
    }
  )
  return filterPassed;
}
