// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { INewStation, IStation, IUpdatedStation } from '../../interfaces/seacom/station';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';


@Injectable({
  providedIn: 'root'
})
export class StationsService extends ApiNodeService<IStation, INewStation, IUpdatedStation> {
  node = 'stations';

  constructor(public seacomApiService: SeacomApiService) {
    super(seacomApiService);
  }

}
