import { createReducer, on } from '@ngrx/store';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IIncident } from '../../interfaces/seacom/incident';

import * as actions from '../actions';
import { addItem, deleteItem, updateItem, updateItems } from '../utils/itementity';
import { hideColumn, repositionColumn, showColumn } from '../utils/columnentity';
import { toLocalISOString } from 'src/app/common/globals';


export const incidentFeatureKey = 'incidents';

export interface IncidentState {
	entities: IItemEntity<IIncident>,
	updatingEntities: string[],
	deletingEntities: string[],
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const IncidentInitialState: IncidentState = {
	entities: {},
	updatingEntities: [],
	deletingEntities: [],
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

export const IncidentReducer = createReducer(
	IncidentInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...IncidentInitialState
		} as IncidentState;
	}),
	// Load Incidents
	on(actions.LoadIncidents, (state, action) => {
		return {
			...state,
			loading: true,
			loaded: false,
			loadError: null
		} as IncidentState;
	}),
	on(actions.LoadIncidentsCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as IncidentState;
	}),
	on(actions.LoadIncidentsWithFilters, (state, action) => {
		return {
			...state,
			loading: true,
			loaded: false,
			loadError: null
		} as IncidentState;
	}),
	on(actions.LoadIncidentsSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as IncidentState;
	}),
	on(actions.LoadIncidentsFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as IncidentState;
	}),

	// Load Incident
	on(actions.LoadIncident, (state, action) => {
		return {
			...state,
			loading: true,
		} as IncidentState;
	}),
	on(actions.UpdateMetadataFieldsSuccess, (state, action) => {
		return {
			...state,
			loading: true,
		} as IncidentState;
	}),
	on(actions.AddMetadataSuccess, (state, action) => {
		return {
			...state,
			loading: true,
		} as IncidentState;
	}),
	on(actions.LoadIncidentSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as IncidentState;
	}),
	on(actions.LoadIncidentFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as IncidentState;
	}),

	// Update Incident
	on(actions.UpdateIncident, (state, action) => {
		let fnd = state.updatingEntities.findIndex(i => i === action.payload.id);
		let newUpdatingEntities: string[] = Array.from(state.updatingEntities);
		if (fnd === -1) {
			newUpdatingEntities.push(action.payload.id);
		}

		return {
			...state,
			updating: true,
			updatingEntities: newUpdatingEntities,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateIncidentFields, (state, action) => {
		let fnd = state.updatingEntities.findIndex(i => i === action.payload.id);
		let newUpdatingEntities: string[] = Array.from(state.updatingEntities);
		if (fnd === -1) {
			newUpdatingEntities.push(action.payload.id);
		}

		return {
			...state,
			updating: true,
			updatingEntities: newUpdatingEntities,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateIncidentSuccess, (state, action) => {
		let fnd = state.updatingEntities.findIndex(i => i === action.payload.id);
		let newUpdatingEntities: string[] = Array.from(state.updatingEntities);
		if (fnd !== -1) {
			delete newUpdatingEntities[fnd];
		}

		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updatingEntities: newUpdatingEntities,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateIncidentFail, (state, action) => {
		let fnd = state.updatingEntities.findIndex(i => i === action.payload.id);
		let newUpdatingEntities: string[] = Array.from(state.updatingEntities);
		if (fnd !== -1) {
			delete newUpdatingEntities[fnd];
		}

		return {
			...state,
			updating: false,
			updatingEntities: newUpdatingEntities,
			updated: false,
			updateError: action.payload,
		};
	}),
	// Add Incident
	on(actions.AddIncident, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.fromOperationsPageActions.AddQuickIncident, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddIncidentSuccess, (state, action) => {
		console.log(action.payload);
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddQuickIncidentSuccess, (state, action) => {
		console.log(action.payload);
		return {
			...state,
			adding: false,
			added: true,
			entities: addItem(action.payload.quickIncident, state.entities),
		};
	}),
	on(actions.AddIncidentFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),
	on(actions.AddQuickIncidentFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),

	// Delete Incident
	on(actions.DeleteIncident, (state, action) => {
		let fnd = state.deletingEntities.findIndex(i => i === action.payload);
		let newDeletingEntities: string[] = Array.from(state.deletingEntities);
		if (fnd === -1) {
			newDeletingEntities.push(action.payload);
		}

		return {
			...state,
			deletingEntities: newDeletingEntities,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteIncidentSuccess, (state, action) => {
		let fnd = state.deletingEntities.findIndex(i => i === action.payload);
		let newDeletingEntities: string[] = Array.from(state.deletingEntities);
		if (fnd !== -1) {
			delete newDeletingEntities[fnd];
		}

		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deletingEntities: newDeletingEntities,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteIncidentFail, (state, action) => {
		let fnd = state.deletingEntities.findIndex(i => i === action.payload.id);
		let newDeletingEntities: string[] = Array.from(state.deletingEntities);
		if (fnd !== -1) {
			delete newDeletingEntities[fnd];
		}

		return {
			...state,
			deleting: false,
			deletingEntities: newDeletingEntities,
			deleted: false,
			deleteError: action.payload.error,
		};
	})
);
