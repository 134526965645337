// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../actions';

// Services
import { ContainerSettingsService } from '../../services/seacom/containersettings.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { SeacomState } from '../reducers';
import { Store } from '@ngrx/store';
import { selectContainerSettingsLastUpdated } from '../selectors/containersetting.selectors';


@Injectable()
export class ContainerSettingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private containerSettingsService: ContainerSettingsService
  ) { }
  
  loadContainerSettings$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load ContainerSettings
        ofType(
          actions.fromOperationsPageActions.Enter
        ),
        concatLatestFrom((action) => this.store.select(selectContainerSettingsLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.containerSettingsService.getList().pipe(
              map((columnSettings) => actions.LoadContainerSettingsSuccess({ payload: columnSettings })),
              catchError((error) => of(actions.LoadContainerSettingsFail(error)))
            );
          } else {
            return of(actions.LoadContainerSettingsCancelled());
          }
        })
      );
  });
  loadContainerSettingsWithFilters$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load ContainerSettings with Filters
        ofType(actions.LoadContainerSettingsWithFilters),
        exhaustMap((action) => this.containerSettingsService.getList(action.filters, action.order, action.search).pipe(
          map((columnSettings) => actions.LoadContainerSettingsSuccess({ payload: columnSettings })),
          catchError((error) => of(actions.LoadContainerSettingsFail(error)))
        ))
      );
  });
  loadContainerSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load ContainerSetting
        ofType(actions.LoadContainerSetting),
        exhaustMap((action) => this.containerSettingsService.get(action.payload).pipe(
          map((columnSetting) => actions.LoadContainerSettingSuccess({ payload: columnSetting })),
          catchError((error) => of(actions.LoadContainerSettingsFail(error)))
        ))
      );
  });
  addContainerSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add ContainerSetting
        ofType(actions.AddContainerSetting),
        concatMap((action) => this.containerSettingsService.create(action.payload).pipe(
          map((columnSetting) => actions.AddContainerSettingSuccess({ payload: columnSetting })),
          catchError((error) => of(actions.AddContainerSettingFail(error)))
        ))
      );
  });
  updateContainerSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update ContainerSetting
        ofType(actions.UpdateContainerSetting),
        concatMap((action) => this.containerSettingsService.update(action.payload.id, action.payload).pipe(
          map((columnSetting) => actions.UpdateContainerSettingSuccess({ payload: columnSetting })),
          catchError((error) => of(actions.UpdateContainerSettingFail(error)))
        ))
      );
  });
  updateContainerSettingValue$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update ContainerSetting value
        ofType(actions.UpdateContainerSettingValue),
        switchMap((action) => of(actions.UpdateContainerSettingFields({ payload: { id: action.payload.id, value: action.payload.newValue }})))
      );
  });
  updateContainerSettingFields$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update ContainerSetting fields
        ofType(actions.UpdateContainerSettingFields),
        concatMap((action) => this.containerSettingsService.updateFields(action.payload.id, action.payload).pipe(
          map((columnSetting) => actions.UpdateContainerSettingSuccess({ payload: columnSetting })),
          catchError((error) => of(actions.UpdateContainerSettingFail(error)))
        ))
      );
  });
  deleteContainerSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete ContainerSetting
        ofType(actions.DeleteContainerSetting),
        mergeMap((action) =>
          this.containerSettingsService.delete(action.payload).pipe(
            map((columnSettingId) => actions.DeleteContainerSettingSuccess({ payload: columnSettingId })),
            catchError((error) => of(actions.DeleteContainerSettingFail(error)))
          )
        )
      )
  });
}
