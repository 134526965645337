import { createAction, props } from '@ngrx/store';

export const UPDATE_LOCATION = '[Position Service] Update Location';
export const LOCATION_SUCCESS = '[Location Effects] Location Update Success';
export const LOCATION_FAIL = '[Location Effects] Location Update Failure';
export const LOCATION_DISABLED = '[Location Effects] Location is Disabled';

export const UpdateLocation = createAction(
	UPDATE_LOCATION, 
	props<{
		payload: {
			location: GeolocationCoordinates
		}
	}>()
);

export const LocationSuccess = createAction(
	LOCATION_SUCCESS, 
	props<{
		payload: {
			location: GeolocationCoordinates
		}
	}>()
);

export const LocationFail = createAction(
	LOCATION_FAIL,
	props<{
		payload: {
			error: any
		}
	}>()
);

export const LocationDisabled = createAction(
	LOCATION_DISABLED,
	props<{
		payload: {
			error: any
		}
	}>()
);
