// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.digim-severities {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.125rem;
}
.digim-severities span {
  display: inline-block;
}

.digim-severity {
  color: var(--color-white-light);
  background-color: var(--color-blue-1-light);
  border: solid 1px var(--color-gray-10-light);
  border-radius: 4px;
  font-weight: 500;
  padding: 0px 6px;
}

/* dark theme styles */
.dark .digim-severity {
  color: var(--color-white-dark);
  background-color: var(--color-blue-1-dark);
  border: solid 1px var(--color-gray-10-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/components/widgets/operations-widget/operations-widget.component.scss","webpack://./../../../../../Ryan%20Ticer/source/repos/seacom3/ui/src/app/components/widgets/operations-widget/operations-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;ACCF;ADCE;EACE,qBAAA;ACCJ;;ADGA;EACE,+BAAA;EACA,2CAAA;EACA,4CAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;ACAF;;ADGA,sBAAA;AAEE;EACE,8BAAA;EACA,0CAAA;EACA,2CAAA;ACDJ","sourcesContent":[".digim-severities {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  gap: .125rem;\r\n\r\n  span {\r\n    display: inline-block;\r\n  }\r\n}\r\n\r\n.digim-severity {\r\n  color: var(--color-white-light);\r\n  background-color: var(--color-blue-1-light);\r\n  border: solid 1px var(--color-gray-10-light);\r\n  border-radius: 4px;\r\n  font-weight: 500;\r\n  padding: 0px 6px;\r\n}\r\n\r\n/* dark theme styles */\r\n.dark {\r\n  .digim-severity {\r\n    color: var(--color-white-dark);\r\n    background-color: var(--color-blue-1-dark);\r\n    border: solid 1px var(--color-gray-10-dark);\r\n  }\r\n}\r\n",".digim-severities {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  gap: 0.125rem;\n}\n.digim-severities span {\n  display: inline-block;\n}\n\n.digim-severity {\n  color: var(--color-white-light);\n  background-color: var(--color-blue-1-light);\n  border: solid 1px var(--color-gray-10-light);\n  border-radius: 4px;\n  font-weight: 500;\n  padding: 0px 6px;\n}\n\n/* dark theme styles */\n.dark .digim-severity {\n  color: var(--color-white-dark);\n  background-color: var(--color-blue-1-dark);\n  border: solid 1px var(--color-gray-10-dark);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
