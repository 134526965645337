// Angular
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';

// Ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// Shared Components
import { AppComponent } from './app.component';

// Shared Services (providers)
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { EnvironmentService } from './services/environment.service';
import { SeacomApiService } from './services/seacom/seacom-api.service';
import { AuthService } from './services/auth.service';
import { IncidentsService } from './services/seacom/incidents.service';
import { NotificationsService } from './services/seacom/notifications.service';
import { StationsService } from './services/seacom/stations.service';
import { UsersService } from './services/seacom/users.service';
import { IncidentTypesService } from './services/seacom/incidenttypes.service';
import { IncidentResponsesService } from './services/seacom/incidentresponses.service';
import { FeedbackService } from './services/seacom/feedback.service';
import { IncidentSeveritiesService } from './services/seacom/incidentseverities.service';
import { AuthGuardService } from './services/authguard.service';
import { DroppableListService } from './services/droppable-list.service';
import { LogService } from './services/log.service';
import { WeatherService } from './services/weather.service';
import { StorageService } from './services/storage.service';
import { NavigationService } from './services/navigation.service';
import { RegisterService } from './services/seacom/register.service';
import { TimeclockService } from './services/seacom/timeclock.service';

// Shared Modules (imports)
import { AppRoutingModule } from './app-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';

import { HeaderbarModule } from './components/headerbar/headerbar.module';
import { FooterbarModule } from './components/footerbar/footerbar.module';
import { IncidentFormModule } from './containers/incidentform/incidentform.module';
import { StationTileModule } from './components/stationtile/stationtile.module';
import { HomePageModule } from './containers/home/home.page.module';
import { OperationsPageModule } from './containers/operations/operations.module';

// Store Modules
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// State
import { reducers, metaReducers } from './store/reducers/index';
import { SeacomEffects } from './store/effects/index';

// Environment

// Seacom Modules
import { RunbookWidgetModule } from './components/widgets/runbook-widget/runbook-widget.module';
import { NotificationsWidgetModule } from './components/widgets/notifications-widget/notifications-widget.module';
import { ConditionsWidgetModule } from './components/widgets/conditions-widget/conditions-widget.module';
import { TimeclockWidgetModule } from './components/widgets/timeclock-widget/timeclock-widget.module';
import { StationsWidgetModule } from './components/widgets/stations-widget/stations-widget.module';
import { OperationsWidgetModule } from './components/widgets/operations-widget/operations-widget.module';
import { ColumnSelectorModule } from './components/column-selector/column-selector.module';
import { ToastService } from './services/toast.service';
import { SettingsPageModule } from './containers/settings/settings.page.module';
import { ColumnFilterModule } from './components/column-filter/column-filter.module';

// Swiper
import { register } from 'swiper/element/bundle';

// Register Swiper custom elements
register();

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // Core Functionality
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    EffectsModule.forRoot(SeacomEffects),
    StoreDevtoolsModule.instrument(),
    AppRoutingModule,

    // Dependency Modules
    HttpClientModule,
    NgxPaginationModule,

    // Dynamic Table Module
    MatTableModule,

    // Seacom Modules
    HomePageModule,
    SettingsPageModule,
    OperationsPageModule,
    HeaderbarModule,
    FooterbarModule,
    IncidentFormModule,
    StationTileModule,

    // Seacom Widgets
    RunbookWidgetModule,
    NotificationsWidgetModule,
    ConditionsWidgetModule,
    TimeclockWidgetModule,
    StationsWidgetModule,
    OperationsWidgetModule,

    // Ancillary Modules
    ColumnSelectorModule,
    ColumnFilterModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LogService,
    EnvironmentService,
    StorageService,
    AuthService,
    AuthGuardService,
    DroppableListService,
    SeacomApiService,
    SplashScreen,
    ToastService,
    StatusBar,
    InAppBrowser,
    NavigationService,
    DroppableListService,
    WeatherService,
    UsersService,
    NotificationsService,
    FeedbackService,
    IncidentResponsesService,
    IncidentsService,
    IncidentSeveritiesService,
    IncidentTypesService,
    RegisterService,
    StationsService,
    TimeclockService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent],
})
export class AppModule { }
