// Angular
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

// RXJS
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Globals
import { getBooleanString, getCheckboxValue, skipNull, filterItems, toLocalISOString } from 'src/app/common/globals';

// Interfaces
import { CopyIncidentForUpdate, IIncident, INewIncident, IUpdatedIncident } from 'src/app/interfaces/seacom/incident';
import { IIncidentType } from 'src/app/interfaces/seacom/incidenttype';
import { IStation } from 'src/app/interfaces/seacom/station';
import { IIncidentSeverity } from '../../interfaces/seacom/incidentseverity';
import { IDistrict } from 'src/app/interfaces/seacom/district';
import { IRegion } from 'src/app/interfaces/seacom/region';
import { IBeach } from 'src/app/interfaces/seacom/beach';
import { IUser } from 'src/app/interfaces/seacom/user';
import { IIncidentResponse, INewIncidentResponse, IUpdatedIncidentResponse, CopyIncidentResponseForUpdate } from 'src/app/interfaces/seacom/incidentresponse';
import { IMetadata, INewMetadata } from 'src/app/interfaces/seacom/metadata';
import { IMetadataField } from 'src/app/interfaces/seacom/metadatafield';
import { IControlBase } from 'src/app/interfaces/controlbase';
import { IUpdatedMetadata } from '../../interfaces/seacom/metadata';

// State
import { SeacomState } from 'src/app/store';

// Actions
import * as fromActions from './store/incidentform.actions';

// Selectors
import { selectIncidentIsDeleting, selectIncidentIsUpdating } from 'src/app/store/selectors/incident.selectors';
import { selectCurrentUser } from 'src/app/store/selectors/auth.selectors';
import { selectAllIncidentTypes } from 'src/app/store/selectors/incidenttype.selectors';
import { selectAllIncidentSeverities } from 'src/app/store/selectors/incidentseverity.selectors';
import { selectAllStations } from '../../store/selectors/station.selectors';
import { selectIncidentResponsesByIncidentId } from '../../store/selectors/incidentresponse.selectors';
import { selectIncidentById } from '../../store/selectors/incident.selectors';
import { selectStationsWithinDistance } from 'src/app/store/selectors/station.selectors';
import { selectBrightness } from 'src/app/store/selectors/ambiance.selectors';
import { selectAllRegions } from 'src/app/store/selectors/region.selectors';
import { selectAllBeaches } from 'src/app/store/selectors/beach.selectors';
import { selectAllDistricts } from 'src/app/store/selectors/district.selectors';
import { selectUsersByRole } from 'src/app/store/selectors/user.selectors';


@Component({
  selector: 'app-incident-form',
  templateUrl: './incidentform.component.html',
  styleUrls: ['./incidentform.component.scss']
})
export class IncidentFormComponent implements OnInit, OnDestroy {
  @Input('incidentId') incidentId: string;
  @Input('locationEnabled') locationEnabled: boolean;

  // Main form events
  @Output() closeFormEvent = new EventEmitter<void>();
  @Output() markIncidentLocation = new EventEmitter<string>();

  brightness$: Observable<'light' | 'dark'>;

  // Current User
  currentUser$ = new BehaviorSubject<IUser>(null);
  currentUserSub: Subscription

  // Current Incident
  incident$ = new BehaviorSubject<IIncident>(null);
  incidentSub: Subscription;

  // Incident Responses
  incidentResponses$: Observable<IIncidentResponse[]>;

  // Incident Types
  incidentTypes$: Observable<IIncidentType[]>;

  // Incident Severities
  incidentSeverities$: Observable<IIncidentSeverity[]>;

  // Stations
  stations$: Observable<IStation[]>;

  // Regions
  regions$: Observable<IRegion[]>;

  // Districts
  districts$: Observable<IDistrict[]>;

  // Beaches
  beaches$: Observable<IBeach[]>;

  // Responders
  responders$: Observable<IUser[]>;

  // Dispatchers
  dispatchers$: Observable<IUser[]>;

  // Supervisors
  supervisors$: Observable<IUser[]>;

  // Form components
  incidentForm!: FormGroup;
  incidentResponseForm!: FormArray;

  // Responses
  responses: (IIncidentResponse | INewIncidentResponse)[];

  // Controls
  incidentMetaControls: IControlBase[];
  incidentResponseMetaControls: IControlBase[][];

  incidentSources = [
    'supervisor',
    'dispatcher',
    'responder'
  ];

  incidentStatuses = [
    'new',
    'dispatching',
    'pending',
    'en route',
    'responding',
    'resolved',
    'closed',
  ];

  // Current incident type
  currentIncidentType: IIncidentType;

  // Is current incident updating?
  currentIncidentUpdating$: Observable<boolean>;
  incidentUpdating$ = new BehaviorSubject<boolean>(false);

  // Is current incident deleting?
  currentIncidentDeleting$: Observable<boolean>;

  // Date/Time Popover
  datePickerPopoverOpen$ = new BehaviorSubject<boolean>(false);
  datePickerTarget$ = new BehaviorSubject<{ responseId: string, incidentId: string, fieldName: string }>({ responseId: null, incidentId: null, fieldName: null });
  datePickerControl = new FormControl('');

  // Responder Popover
  responderPopoverOpen$ = new BehaviorSubject<boolean>(false);
  responderPopoverTarget$ = new BehaviorSubject<{ responseId: string, incidentId: string, fieldName: string }>({ responseId: null, incidentId: null, fieldName: null });
  responderPopoverControl = new FormControl('');

  // Dispatcher Popover
  dispatcherPopoverOpen$ = new BehaviorSubject<boolean>(false);
  dispatcherPopoverTarget$ = new BehaviorSubject<{ responseId: string, incidentId: string, fieldName: string }>({ responseId: null, incidentId: null, fieldName: null });
  dispatcherPopoverControl = new FormControl('');

  // Supervisor Popover
  supervisorPopoverOpen$ = new BehaviorSubject<boolean>(false);
  supervisorPopoverTarget$ = new BehaviorSubject<{ responseId: string, incidentId: string, fieldName: string }>({ responseId: null, incidentId: null, fieldName: null });
  supervisorPopoverControl = new FormControl('');

  constructor(
    private store: Store<SeacomState>,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(fromActions.Enter());

    // Fill observables
    this.fillObservables();

    // Create new form group for incident
    this.createIncidentForm();

    // Create new form array for incident responses
    this.createIncidentResponseForm();

    this.currentIncidentUpdating$ = this.store.select(selectIncidentIsUpdating(this.incidentId));
    this.currentIncidentDeleting$ = this.store.select(selectIncidentIsDeleting(this.incidentId));

    this.currentIncidentUpdating$.pipe(skipNull()).subscribe( // TODO: _may_ need debounce time, not sure...
      (updating) => {
        if (!updating && this.incidentUpdating$.value) { // state change from updating to not updating
          this.createIncidentForm();
          this.enableIncidentForm();
          this.incidentUpdating$.next(updating);
        } else if (updating && (!this.incidentUpdating$.value || this.incidentUpdating$.value === null)) { // state change from not updating to updating
          this.disableIncidentForm();
          this.incidentUpdating$.next(updating);
        }
      }
    );

    this.currentIncidentDeleting$.pipe(skipNull()).subscribe( // TODO: _may_ need debounce time, not sure...
      (deleting) => {
        if (deleting) { // this incident is being deleted
          // TODO: What should be done if incident is being deleted while form is open??
        }
      }
    );
  }

  ngOnDestroy() {
    this.currentUserSub.unsubscribe();
    this.incidentSub.unsubscribe();

    this.store.dispatch(fromActions.Exit());
  }

  fillObservables() {
    // Select the current brightness
    this.brightness$ = this.store.select(selectBrightness);

    // Select the current incident Id
    this.incidentSub = this.store.select(selectIncidentById(this.incidentId)).pipe(skipNull()).subscribe(this.incident$);

    // Select appropriate incident responses
    this.incidentResponses$ = this.store.select(selectIncidentResponsesByIncidentId(this.incidentId));

    // Select the current user
    this.currentUserSub = this.store.select(selectCurrentUser).pipe(skipNull()).subscribe(this.currentUser$);

    // Select Incident Types
    this.incidentTypes$ = this.store.select(selectAllIncidentTypes);

    // Select Incident Severities
    this.incidentSeverities$ = this.store.select(selectAllIncidentSeverities);

    // Select Regions
    this.regions$ = this.store.select(selectAllRegions);

    // Select Districts
    this.districts$ = this.store.select(selectAllDistricts);

    // Select Beaches
    this.beaches$ = this.store.select(selectAllBeaches);

    // Select Stations
    this.stations$ = this.store.select(selectAllStations);

    // Select Responders
    this.responders$ = this.store.select(selectUsersByRole(['responder'])).pipe(tap(console.log));

    // Select Dispatchers
    this.dispatchers$ = this.store.select(selectUsersByRole(['dispatcher']));

    // Select Supervisors
    this.supervisors$ = this.store.select(selectUsersByRole(['supervisor']));
  }

  closeForm() {
    this.closeFormEvent.emit();
  }

  createIncidentForm() {
    if (this.incident$.value !== undefined && this.incident$.value !== null) {
      let formBuildout = this.createIncidentFormFromIncident(CopyIncidentForUpdate(this.incident$.value));
      this.incidentForm = formBuildout.fg;
      this.incidentMetaControls = formBuildout.metaControls;
    } else {
      this.incidentForm = this.createNewIncidentForm();
    }
  }

  disableIncidentForm() {
    // Incident Form
    Object.keys(this.incidentForm.controls).forEach(k => this.incidentForm.controls[k].disable());

    // Incident Metadata Form
    if (this.incidentForm.controls.hasOwnProperty('metadata')) {
      Object.keys((this.incidentForm.controls['metadata'] as FormGroup).controls).forEach(k => (this.incidentForm.controls['metadata'] as FormGroup).controls[k].disable());
    }

    // Incident Responses Form
    this.incidentResponseForm.controls.forEach(c => {
      Object.keys((c as FormGroup).controls).forEach(k => (c as FormGroup).controls[k].disable());

      // Incident Response Metadata Form
      if ((c as FormGroup).controls.hasOwnProperty('metadata')) {
        Object.keys(((c as FormGroup).controls['metadata'] as FormGroup).controls).forEach(k => ((c as FormGroup).controls['metadata'] as FormGroup).controls[k].disable());
      }
    });
  }

  enableIncidentForm() {
    // Incident Form
    Object.keys(this.incidentForm.controls).forEach(k => this.incidentForm.controls[k].enable());

    // Incident Metadata Form
    if (this.incidentForm.controls.hasOwnProperty('metadata')) {
      Object.keys((this.incidentForm.controls['metadata'] as FormGroup).controls).forEach(k => (this.incidentForm.controls['metadata'] as FormGroup).controls[k].enable());
    }

    // Incident Responses Form
    this.incidentResponseForm.controls.forEach(c => {
      Object.keys((c as FormGroup).controls).forEach(k => (c as FormGroup).controls[k].enable());

      // Incident Response Metadata Form
      if ((c as FormGroup).controls.hasOwnProperty('metadata')) {
        Object.keys(((c as FormGroup).controls['metadata'] as FormGroup).controls).forEach(k => ((c as FormGroup).controls['metadata'] as FormGroup).controls[k].enable());
      }
    });
  }

  createIncidentResponseForm() {
    this.incidentResponses$.pipe(first()).subscribe(
      (irs) => {
        if (irs !== undefined && irs !== null) {
          this.responses = Array.from(irs);
          let responseFormBuildout = this.createFormArrayFromResponses(irs);

          this.incidentResponseForm = responseFormBuildout.fa;
          this.incidentResponseMetaControls = responseFormBuildout.mca;
        } else {
          this.responses = [];
          this.incidentResponseForm = this.formBuilder.array(this.responses);
        }
      }
    );
  }

  createNewResponseForm(): FormGroup {
    let formGroup: FormGroup = this.formBuilder.group({
      organization: [this.currentUser$.value.organization.id, [Validators.required]],
      incident: [this.incident$.value.id],
      type: [null],
      status: ['inprogress'],
      severity: [null],
      region: [null],
      district: [null],
      beach: [null],
      stations: this.formBuilder.array([]),
      location: [null],
      responders: this.formBuilder.array([]),
      starttime: [this.datePipe.transform(new Date(), 'short'), [Validators.required]],
      endtime: [null],
      metadata: this.formBuilder.group({})
    });

    return formGroup;
  }

  private metadataFieldsToFormGroup(metadataFields: IMetadataField[], data: IMetadata[]): { fg: FormGroup, metaControls: IControlBase[] } {
    let newFG = new FormGroup({});
    let mcA: IControlBase[] = [];
    if (metadataFields.length > 0) {
      for (let mf of metadataFields) {
        let fd = data.find(d => d.field.id === mf.id);
        let mc = {} as IControlBase;

        mc.field_id = mf.id;
        mc.controlType = mf.data_type;
        mc.required = mf.required;
        mc.name = mf.name;
        mc.description = mf.description;

        if (mf.list_choices !== undefined && mf.list_choices !== null) {
          mc.list_choices = mf.list_choices;
        }

        if (fd !== undefined && fd.data !== null) {
          mc.data_id = fd.id;

          if (mf.required) {
            switch (mf.data_type) {
              case 'bool':
                newFG.addControl(mf.id, new FormControl({ value: getBooleanString(fd.data), disabled: this.incidentUpdating$.value }));
                break;
              case 'integer':
                newFG.addControl(mf.id, new FormControl({ value: parseInt(fd.data), disabled: this.incidentUpdating$.value }, Validators.required));
                break;
              case 'list':
              case 'location':
              case 'dispatcher':
              case 'responder':
              case 'supervisor':
              case 'note':
              case 'text':
              default:
                newFG.addControl(mf.id, new FormControl({ value: fd.data, disabled: this.incidentUpdating$.value }, Validators.required));
                break;
            }
          } else {
            switch (mf.data_type) {
              case 'bool':
                newFG.addControl(mf.id, new FormControl({ value: getBooleanString(fd.data), disabled: this.incidentUpdating$.value }));
                break;
              case 'integer':
                newFG.addControl(mf.id, new FormControl({ value: parseInt(fd.data), disabled: this.incidentUpdating$.value }));
                break;
              case 'list':
              case 'location':
              case 'dispatcher':
              case 'responder':
              case 'supervisor':
              case 'note':
              case 'text':
              default:
                newFG.addControl(mf.id, new FormControl({ value: fd.data, disabled: this.incidentUpdating$.value }));
                break;
            }
          }
        } else {
          if (mf.required) {
            switch (mf.data_type) {
              case 'bool':
                newFG.addControl(mf.id, new FormControl({ value: getBooleanString(mf.default), disabled: this.incidentUpdating$.value }));
                break;
              case 'integer':
                newFG.addControl(mf.id, new FormControl({ value: parseInt(mf.default), disabled: this.incidentUpdating$.value }, Validators.required));
                break;
              case 'list':
              case 'location':
              case 'dispatcher':
              case 'responder':
              case 'supervisor':
              case 'note':
              case 'text':
              default:
                newFG.addControl(mf.id, new FormControl({ value: mf.default, disabled: this.incidentUpdating$.value }, Validators.required));
                break;
            }
          } else {
            switch (mf.data_type) {
              case 'bool':
                newFG.addControl(mf.id, new FormControl({ value: getBooleanString(mf.default), disabled: this.incidentUpdating$.value }));
                break;
              case 'integer':
                newFG.addControl(mf.id, new FormControl({ value: parseInt(mf.default), disabled: this.incidentUpdating$.value }));
                break;
              case 'list':
              case 'location':
              case 'dispatcher':
              case 'responder':
              case 'supervisor':
              case 'note':
              case 'text':
              default:
                newFG.addControl(mf.id, new FormControl({ value: mf.default, disabled: this.incidentUpdating$.value }));
                break;
            }
          }
        }
        mcA.push(mc);
      }
    }

    return { fg: newFG, metaControls: mcA };
  }

  createIncidentFormFromIncident(incident: IUpdatedIncident): { fg: FormGroup, metaControls: IControlBase[] } {
    let formGroup = this.formBuilder.group({});
    let metaControls: IControlBase[];
    let metadataBuildout: { fg: FormGroup, metaControls: IControlBase[] };

    this.incident$.pipe(skipNull(), first()).subscribe(
      (inc) => {
        this.incidentTypes$.pipe(skipNull(), first()).subscribe(
          (its) => {
            let incType = its.find(it => it.id === inc.type.id);
            let metadataFG: FormGroup;

            if (incType !== undefined) {
              metadataBuildout = this.metadataFieldsToFormGroup(incType.metadata_fields, inc.metadata);
              metadataFG = metadataBuildout.fg;
              metaControls = metadataBuildout.metaControls;
            } else {
              metadataFG = this.formBuilder.group({});
            }

            formGroup.addControl('organization', new FormControl({ value: incident.organization, disabled: this.incidentUpdating$.value }, Validators.required));
            formGroup.addControl('type', new FormControl({ value: incident.type, disabled: this.incidentUpdating$.value }, Validators.required));
            formGroup.addControl('status', new FormControl({ value: incident.status, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('severity', new FormControl({ value: incident.severity, disabled: this.incidentUpdating$.value }, Validators.required));
            formGroup.addControl('starttime', new FormControl({ value: this.datePipe.transform(incident.starttime, 'short'), disabled: this.incidentUpdating$.value }, Validators.required));
            formGroup.addControl('region', new FormControl({ value: incident.region, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('district', new FormControl({ value: incident.district, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('beach', new FormControl({ value: incident.beach, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('station', new FormControl({ value: incident.station, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('location', new FormControl({ value: incident.location, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('source', new FormControl({ value: incident.source, disabled: this.incidentUpdating$.value }, Validators.required));
            formGroup.addControl('primary_responder', new FormControl({ value: incident.primary_responder, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('secondary_responder', new FormControl({ value: incident.secondary_responder, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('tertiary_responder', new FormControl({ value: incident.tertiary_responder, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('review', new FormControl({ value: incident.review, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('archive', new FormControl({ value: incident.archive, disabled: this.incidentUpdating$.value }));
            formGroup.addControl('metadata', metadataFG);
          }
        );
      }
    );

    return { fg: formGroup, metaControls: metaControls };
  }

  get incidentMetadataFormGroup() {
    return this.incidentForm.controls['metadata'] as FormGroup
  }

  get incidentResponseFormGroups(): FormGroup[] {
    return this.incidentResponseForm.controls as FormGroup[];
  }

  createNewIncidentForm(): FormGroup {
    let formGroup = new FormGroup({});
    let stations: IStation[] = [];

    this.store.select(selectStationsWithinDistance(10)).pipe(first()).subscribe(
      (sts) => {
        stations = sts;
      }
    );

    formGroup.addControl('organization', new FormControl([this.currentUser$.value.organization.id, [Validators.required]]));
    formGroup.addControl('type', new FormControl([null, [Validators.required]]));
    formGroup.addControl('status', new FormControl(['new']));
    formGroup.addControl('severity', new FormControl([null, [Validators.required]]));
    formGroup.addControl('starttime', new FormControl([null, [Validators.required]]));
    formGroup.addControl('region', new FormControl([this.currentUser$.value.region.id]));
    formGroup.addControl('district', new FormControl([stations.length > 0 ? stations[0].district.id : null]));
    formGroup.addControl('beach', new FormControl([stations.length > 0 ? stations[0].beach.id : null]));
    formGroup.addControl('station', new FormControl([stations.length > 0 ? stations[0].id : null]));
    formGroup.addControl('location', new FormControl([this.currentUser$.value.location]));
    formGroup.addControl('source', new FormControl([this.currentUser$.value.role.role.name, [Validators.required]]));
    formGroup.addControl('primary_responder', new FormControl([null]));
    formGroup.addControl('secondary_responder', new FormControl([null]));
    formGroup.addControl('tertiary_responder', new FormControl([null]));
    formGroup.addControl('review', new FormControl([getCheckboxValue(false)]));
    formGroup.addControl('archive', new FormControl([getCheckboxValue(false)]));

    return formGroup;
  }

  createFormGroupFromResponse(response: IIncidentResponse | INewIncidentResponse): { fg: FormGroup, mc: IControlBase[] } {
    let resp: INewIncidentResponse | IUpdatedIncidentResponse;
    if (response.hasOwnProperty('id')) {
      resp = CopyIncidentResponseForUpdate(response as IIncidentResponse);
    } else {
      resp = response as INewIncidentResponse;
    }

    let respFG = this.formBuilder.group({});
    let mC: IControlBase[] = [];

    if (resp.hasOwnProperty('id')) {
      respFG.addControl('id', new FormControl((resp as IUpdatedIncidentResponse).id));
    }

    respFG.addControl('organization', new FormControl(resp.organization));
    respFG.addControl('incident', new FormControl(resp.incident));
    respFG.addControl('type', new FormControl(resp.type !== undefined && resp.type !== null ? resp.type : null));
    respFG.addControl('severity', new FormControl(resp.severity !== undefined && resp.severity !== null ? resp.severity : null));
    respFG.addControl('status', new FormControl(resp.status));
    respFG.addControl('location', new FormControl(resp.location !== undefined && resp.location !== null ? resp.location : null));
    respFG.addControl('region', new FormControl(resp.region !== undefined && resp.region !== null ? resp.region : null));
    respFG.addControl('district', new FormControl(resp.district !== undefined && resp.district !== null ? resp.district : null));
    respFG.addControl('beach', new FormControl(resp.beach !== undefined && resp.beach !== null ? resp.beach : null));
    respFG.addControl('stations', new FormControl(resp.stations !== undefined && resp.stations !== null ? resp.stations : null));
    respFG.addControl('responders', new FormControl(resp.responders !== undefined && resp.responders !== null ? resp.responders : null));
    if (resp.hasOwnProperty('id')) {
      respFG.addControl('starttime', new FormControl(resp.starttime, Validators.required));
    } else {
      respFG.addControl('starttime', new FormControl(null));
    }
    respFG.addControl('endtime', new FormControl(resp.endtime !== undefined && resp.endtime !== null ? resp.endtime : null));

    this.incidentTypes$.pipe(skipNull(), first()).subscribe(
      (its) => {
        let type = its.find(it => it.id === resp.type);
        if (type !== undefined) {
          let metaControlsRes = this.metadataFieldsToFormGroup(type.metadata_fields, response.metadata as IMetadata[]);
          mC = metaControlsRes.metaControls;
          respFG.addControl('metadata', metaControlsRes.fg);
        } else {
          mC = [];
          respFG.addControl('metadata', this.formBuilder.group({}));
        }
      }
    );

    return { fg: respFG, mc: mC };
  }

  createFormArrayFromResponses(responses: IIncidentResponse[]): { fa: FormArray, mca: IControlBase[][] } {
    let newFA = this.formBuilder.array<FormGroup>([]);
    let newMA: IControlBase[][] = [];
    for (let response of responses) {
      let respRes = this.createFormGroupFromResponse(response);
      newFA.push(respRes.fg);
      newMA.push(respRes.mc);
    }
    return { fa: newFA, mca: newMA };
  }

  private getUpdates(
    formItem: FormGroup | FormArray | FormControl,
    updatedValues: { [name: string]: any },
    name?: string
  ) {
    if (formItem instanceof FormControl) {
      if (name && formItem.dirty) {
        updatedValues[name] = formItem.value;
      }
    } else {
      for (const formControlName in formItem.controls) {
        if (formItem.controls.hasOwnProperty(formControlName)) {
          const formControl = formItem.controls[formControlName];

          if (formControl instanceof FormControl) {
            this.getUpdates(formControl, updatedValues, formControlName);
          } else if (
            formControl instanceof FormArray &&
            formControl.dirty &&
            formControl.controls.length > 0
          ) {
            updatedValues[formControlName] = [];
            this.getUpdates(formControl, updatedValues[formControlName]);
          } else if (formControl instanceof FormGroup && formControl.dirty) {
            updatedValues[formControlName] = {};
            this.getUpdates(formControl, updatedValues[formControlName]);
          }
        }
      }
    }
  }

  getIncidentChanges(): { [name: string]: any } {
    const updatedValues = {};
    this.getUpdates(this.incidentForm, updatedValues);
    return updatedValues;
  }

  getResponseChanges(responseForm: FormArray): { [name: string]: any } {
    const updatedValues = {};
    this.getUpdates(responseForm, updatedValues);
    return updatedValues;
  }

  // If stationsA is entirely found in stationsB, returns true, else, false
  stationOverlapExists(stationsA: string[], stationsB: string[]): boolean {
    if (stationsA !== undefined && stationsA !== null && stationsB !== undefined && stationsB !== null) {
      return stationsA.filter(a => stationsB.includes(a)).length === stationsA.length;
    } else {
      return false;
    }
  }

  // Check new responses for overlap with given stations
  checkNewResponseStationOverlap(stations: string[]): boolean {
    if (stations !== undefined && stations !== null && stations.length > 0) {
      return this.incidentResponseForm.controls
        .filter(rf => !(rf as FormGroup).controls.hasOwnProperty('id'))
        .filter(rf => this.stationOverlapExists(stations, ((rf as FormGroup).controls['stations'] as FormControl).value))
        .length > 0;
    } else {
      // If the given response stations is undefined/null/length 0, check incident station

      if (this.incident$.value.station !== undefined && this.incident$.value.station !== null && this.incident$.value.station.hasOwnProperty('id')) {
        return this.incidentResponseForm.controls
          .filter(rf => !(rf as FormGroup).controls.hasOwnProperty('id'))
          .filter(rf => this.stationOverlapExists([this.incident$.value.station.id], ((rf as FormGroup).controls['stations'] as FormControl).value))
          .length > 0;
      } else {
        return false;
      }
    }
  }

  saveForm(): void {
    // Incident, non-metadata
    let updatedIncident: IUpdatedIncident | INewIncident;
    let changedFields = this.getIncidentChanges();

    if (this.incident$.value !== undefined && this.incident$.value !== null) {
      updatedIncident = {
        id: this.incident$.value.id,
        ...changedFields
      } as IUpdatedIncident;
      delete (updatedIncident['metadata']);

      if (Object.keys(updatedIncident).length > 1) { // Don't submit an update if it's only the ID
        this.store.dispatch(fromActions.UpdateIncidentFormIncidentFields({
          payload: updatedIncident
        }));
      }

      let changedMetadata = changedFields['metadata']; // {[fieldid: string]: FormControl}
      if (changedMetadata !== undefined && changedMetadata !== null) {
        for (let cmk of Object.keys(changedMetadata)) {
          let mc = this.incidentMetaControls.find(mc => mc.field_id === cmk);
          if (mc !== undefined) {
            if (mc.data_id !== undefined && mc.data_id !== null) {
              // Got data ID, existing metadata record, just updating
              let updatedMetadata = {} as IUpdatedMetadata;
              if (changedMetadata[cmk] !== null) {
                // Real data present
                updatedMetadata = {
                  id: mc.data_id,
                  data: changedMetadata[cmk].toString(),
                } as IUpdatedMetadata;
              } else {
                // null data
                updatedMetadata = {
                  id: mc.data_id,
                  data: null,
                } as IUpdatedMetadata;
              }
              this.store.dispatch(fromActions.UpdateMetadata({
                payload: updatedMetadata
              }));
            } else {
              // No data ID, new metadata record

              let newMetadata = {} as INewMetadata;

              if (changedMetadata[cmk] !== null) {
                // real data
                newMetadata = {
                  organization: this.currentUser$.value.organization.id,
                  field: mc.field_id,
                  incident: this.incident$.value.id,
                  data: changedMetadata[cmk].toString(),
                  data_type: mc.controlType
                } as INewMetadata;
              } else {
                // no data
                newMetadata = {
                  organization: this.currentUser$.value.organization.id,
                  field: mc.field_id,
                  incident: this.incident$.value.id,
                  data: null,
                  data_type: mc.controlType
                } as INewMetadata;
              }
              this.store.dispatch(fromActions.CreateMetadata({
                payload: newMetadata
              }));
            }
          }
        }
      }
    } else {
      updatedIncident = {
        ...changedFields
      } as INewIncident;
      delete (updatedIncident['metadata']);
      let metadata = changedFields['metadata'];
      let mArr = metadata.keys().map(k => {
        let mc = this.incidentMetaControls.find(mc => mc.field_id === k);
        if (mc !== undefined) {
          if (mc.data_id !== undefined && mc.data_id !== null) {
            return {
              field: k,
              organization: this.currentUser$.value.organization.id,
              data_type: mc.controlType,
              data: metadata[k] as string
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      });
      updatedIncident['metadata'] = mArr;

      this.store.dispatch(fromActions.CreateIncidentFormIncident({
        payload: updatedIncident
      }));
    }

    // Existing responses have ID
    let existingRes: IIncidentResponse[] = this.responses.filter(r => r.hasOwnProperty('id')) as IIncidentResponse[];

    // Loop through existing responses in form to get updates and submit
    for (let FGK of this.incidentResponseForm.controls.keys()) {
      let updatedResponse: IUpdatedIncidentResponse;
      let resFG = this.incidentResponseForm.controls[FGK] as FormGroup;
      let res: IIncidentResponse;

      if (resFG.controls.hasOwnProperty('id')) {
        res = existingRes.find(r => r.id === resFG.controls['id'].value)

        // ID is found and matches incident response

        let changedResponseFields: { [name: string]: any } = {};
        this.getUpdates(resFG, changedResponseFields);

        updatedResponse = {
          ...changedResponseFields,
          id: res.id
        } as IUpdatedIncidentResponse;

        // If there's a new response overlapping with stations on this one, complete this one
        if ((res.status !== 'complete' || res.endtime === undefined || res.endtime === null) && this.checkNewResponseStationOverlap(updatedResponse.stations)) {
          updatedResponse.status = 'complete',
            updatedResponse.endtime = toLocalISOString(new Date());
        }

        // Remove metadata -- it will be updated separately
        delete (updatedResponse['metadata']);

        if (Object.keys(updatedResponse).length > 1) { // allowing one for the ID we added back in
          // There are updated keys after removing metadata, dispatch the updated
          this.store.dispatch(fromActions.UpdateIncidentFormResponse({
            payload: updatedResponse
          }));
        }

        let md = changedResponseFields['metadata'];

        if (md !== undefined) {
          if (Object.keys(md).length > 0) {
            // There are updated metadata keys

            for (let mk of Object.keys(md)) {
              let mc = this.incidentResponseMetaControls[FGK].find(mc => mc.field_id === mk);

              if (mc !== undefined) {
                if (mc.data_id !== undefined && mc.data_id !== null) {
                  // Metadata record already exists, just need to update it
                  let mdUpt = {} as IUpdatedMetadata;

                  if (md[mk] !== null) {
                    // Real data present
                    mdUpt = {
                      id: mc.data_id,
                      data: md[mk].toString()
                    } as IUpdatedMetadata;
                  } else {
                    // null data
                    mdUpt = {
                      id: mc.data_id,
                      data: null
                    } as IUpdatedMetadata;
                  }

                  this.store.dispatch(fromActions.UpdateMetadata({
                    payload: mdUpt
                  }));
                } else {
                  // new Metadata record for this response
                  let mdNew = {
                    organization: this.currentUser$.value.organization.id,
                    field: mk,
                    response: resFG.controls['id'].value,
                    data: md[mk].toString()
                  } as INewMetadata;

                  this.store.dispatch(fromActions.CreateMetadata({
                    payload: mdNew
                  }));
                }
              }
            }
          }
        }
      }
    }

    // New responses have no ID
    let newResponses = this.incidentResponseForm.controls.filter(rf => !(rf as FormGroup).controls.hasOwnProperty('id'));

    for (let NRK of this.incidentResponseForm.controls.keys()) {
      if (!((this.incidentResponseForm.controls[NRK] as FormGroup).controls.hasOwnProperty('id'))) {
        let resFG = this.incidentResponseForm.controls[NRK] as FormGroup;
        let newResponse = {
          ...resFG.value
        } as INewIncidentResponse;

        // If start time isn't set, set with current time
        if (!newResponse.hasOwnProperty('starttime') || newResponse['starttime'] === undefined || newResponse['starttime'] === null) {
          newResponse['starttime'] = toLocalISOString(new Date());
        }

        // Remove metadata -- it will be updated separately
        delete (newResponse['metadata']);

        let newMetadatas: INewMetadata[] = [];

        let md = resFG.controls['metadata'].value;
        if (md !== undefined && md !== null) {
          if (Object.keys(md).length > 0) {
            // There are updated metadata keys

            for (let mk of md.keys()) {
              let mc = this.incidentResponseMetaControls[NRK].find(mc => mc.field_id === mk);
              if (mc !== undefined) {
                // metadata will always be new record for new response
                let mdNew = {
                  organization: this.currentUser$.value.organization.id,
                  field: mk,
                  data: md[mk]
                } as INewMetadata;
                newMetadatas.push(mdNew);
              }
            }
          }
        }

        // Add metadata directly to response request
        newResponse['metadata'] = newMetadatas;

        // Send create request
        this.store.dispatch(fromActions.CreateIncidentFormResponse({
          payload: newResponse
        }));
      }
    }

    // Response Metadata Changes
    this.closeFormEvent.emit();
  }

  addResponse(): void {
    let responders: string[] = [];
    if (this.incident$.value.primary_responder !== undefined && this.incident$.value.primary_responder !== null) {
      responders.push(this.incident$.value.primary_responder.id);
    }
    if (this.incident$.value.secondary_responder !== undefined && this.incident$.value.secondary_responder !== null) {
      responders.push(this.incident$.value.secondary_responder.id);
    }
    if (this.incident$.value.tertiary_responder !== undefined && this.incident$.value.tertiary_responder !== null) {
      responders.push(this.incident$.value.tertiary_responder.id);
    }

    let newResponse = {
      d: 'INewIncidentResponse',
      organization: this.currentUser$.value.organization.id,
      incident: this.incident$.value.id,
      status: 'inprogress',
      region: this.incident$.value.region !== undefined && this.incident$.value.region !== null ? this.incident$.value.region.id : null,
      district: this.incident$.value.district !== undefined && this.incident$.value.district !== null ? this.incident$.value.district.id : null,
      beach: this.incident$.value.beach !== undefined && this.incident$.value.beach !== null ? this.incident$.value.beach.id : null,
      stations: this.incident$.value.station !== undefined && this.incident$.value.station !== null ? [this.incident$.value.station.id] : [],
      responders: responders
    } as INewIncidentResponse;

    this.responses.unshift(newResponse);

    let newRespForm = this.createFormGroupFromResponse(newResponse);

    this.incidentResponseForm.controls.unshift(newRespForm.fg);
    this.incidentResponseMetaControls.unshift(newRespForm.mc);
  }

  deleteIncidentResponse(responseId: string) {
    this.store.dispatch(fromActions.DeleteIncidentFormResponse({
      payload: responseId
    }));
  }

  showIncidentLocation(): void {
    this.markIncidentLocation.emit(this.incident$.value.location);
  }

  changeResponseType(idx: number, newTypeId: string) {
    this.incidentTypes$.pipe(skipNull(), first()).subscribe(
      (its) => {
        if (this.responses[idx].d === 'IIncidentResponse') {
          this.responses[idx].type = its.find(it => it.id === newTypeId);
        } else {
          this.responses[idx].type = newTypeId;
        }
      }
    )
  }

  // Date/Time Picker Popover
  editResponseDateField(responseId: string, fieldName: string) {
    // Set current target
    this.datePickerTarget$.next({ responseId: responseId, incidentId: null, fieldName: fieldName });

    // Open the picker
    this.datePickerPopoverOpen$.next(true);
  }

  editIncidentDateField(incidentId: string, fieldName: string) {
    // Set current target
    this.datePickerTarget$.next({ incidentId: incidentId, responseId: null, fieldName: fieldName });

    // Open the picker
    this.datePickerPopoverOpen$.next(true);
  }

  datePickerSave() {
    if (this.datePickerTarget$.value.incidentId !== null) {
      // incident field edit
      // TODO
    } else if (this.datePickerTarget$.value.responseId !== null) {
      // response field edit
      // TODO
    }
    // no else - if nothing targeted, should ignore
  }

  datePickerCancel() {
    // Reset current target
    this.datePickerTarget$.next({ incidentId: null, responseId: null, fieldName: null });

    // Close the picker
    this.datePickerPopoverOpen$.next(false);
  }

  regionChange(event) {
    this.incidentForm.controls['district'].setValue(null);
    this.incidentForm.controls['beach'].setValue(null);
  }

  districtChange(event) {
    if (event.detail.value !== null) {
      this.districts$.pipe(filterItems(d => d.id === event.detail.value), first()).subscribe(
        (districts) => {
          if (districts !== undefined && districts !== null) {
            this.incidentForm.controls['region'].setValue(districts[0].region ? districts[0].region.id : null);
          }
        }
      );
    }
    this.incidentForm.controls['beach'].setValue(null);
  }

  beachChange(event) {
    if (event.detail.value !== null) {
      this.beaches$.pipe(filterItems(b => b.id === event.detail.value), first()).subscribe(
        (beaches) => {
          if (beaches !== undefined && beaches !== null) {
            this.incidentForm.controls['district'].setValue(beaches[0].district ? beaches[0].district.id : null);
          }
        }
      );
    }
  }

  stationChange(event) {
    if (event.detail.value !== null) {
      this.stations$.pipe(filterItems(s => s.id === event.detail.value), first()).subscribe(
        (stations) => {
          if (stations !== undefined && stations !== null) {
            this.incidentForm.controls['beach'].setValue(stations[0].beach ? stations[0].beach.id : null);
          }
        }
      );
    }
  }

  // Responder popover
  editResponseResponderField(responseId: string, fieldName: string) {
    // Set current target
    this.responderPopoverTarget$.next({ responseId: responseId, incidentId: null, fieldName: fieldName });

    // Open the picker
    this.responderPopoverOpen$.next(true);
  }

  editIncidentResponderField(incidentId: string, fieldName: string) {
    // Set current target
    this.responderPopoverTarget$.next({ incidentId: incidentId, responseId: null, fieldName: fieldName });

    // Open the picker
    this.responderPopoverOpen$.next(true);
  }

  responderPopoverSave() {
    if (this.responderPopoverTarget$.value.incidentId !== null) {
      // incident field edit
      // TODO
    } else if (this.responderPopoverTarget$.value.responseId !== null) {
      // response field edit
      // TODO
    }
    // no else - if nothing targeted, should ignore
  }

  responderPopoverCancel() {
    // Reset current target
    this.responderPopoverTarget$.next({ incidentId: null, responseId: null, fieldName: null });

    // Close the picker
    this.responderPopoverOpen$.next(false);
  }


  // Dispatcher popover
  editResponseDispatcherField(responseId: string, fieldName: string) {
    // Set current target
    this.dispatcherPopoverTarget$.next({ responseId: responseId, incidentId: null, fieldName: fieldName });

    // Open the picker
    this.dispatcherPopoverOpen$.next(true);
  }

  editIncidentDispatcherField(incidentId: string, fieldName: string) {
    // Set current target
    this.dispatcherPopoverTarget$.next({ incidentId: incidentId, responseId: null, fieldName: fieldName });

    // Open the picker
    this.dispatcherPopoverOpen$.next(true);
  }

  dispatcherPopoverSave() {
    if (this.dispatcherPopoverTarget$.value.incidentId !== null) {
      // incident field edit
      // TODO
    } else if (this.dispatcherPopoverTarget$.value.responseId !== null) {
      // response field edit
      // TODO
    }
    // no else - if nothing targeted, should ignore
  }

  dispatcherPopoverCancel() {
    // Reset current target
    this.dispatcherPopoverTarget$.next({ incidentId: null, responseId: null, fieldName: null });

    // Close the picker
    this.dispatcherPopoverOpen$.next(false);
  }


  // Supervisor popover
  editResponseSupervisorField(responseId: string, fieldName: string) {
    // Set current target
    this.supervisorPopoverTarget$.next({ responseId: responseId, incidentId: null, fieldName: fieldName });

    // Open the picker
    this.supervisorPopoverOpen$.next(true);
  }

  editIncidentSupervisorField(incidentId: string, fieldName: string) {
    // Set current target
    this.supervisorPopoverTarget$.next({ incidentId: incidentId, responseId: null, fieldName: fieldName });

    // Open the picker
    this.supervisorPopoverOpen$.next(true);
  }

  supervisorPopoverSave() {
    if (this.supervisorPopoverTarget$.value.incidentId !== null) {
      // incident field edit
      // TODO
    } else if (this.supervisorPopoverTarget$.value.responseId !== null) {
      // response field edit
      // TODO
    }
    // no else - if nothing targeted, should ignore
  }

  supervisorPopoverCancel() {
    // Reset current target
    this.supervisorPopoverTarget$.next({ incidentId: null, responseId: null, fieldName: null });

    // Close the picker
    this.supervisorPopoverOpen$.next(false);
  }
}
