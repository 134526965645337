import { createAction, props } from '@ngrx/store';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { IStationSetting, INewStationSetting, IUpdatedStationSetting } from '../../interfaces/seacom/stationsetting';

// stationSetting action types
export const LOAD_STATIONSETTINGS = '[Page Effects] Load StationSettings';
export const LOAD_STATIONSETTINGS_CANCELLED = '[StationSetting Effects] Load StationSettings Cancelled';
export const LOAD_STATIONSETTINGS_WITH_FILTERS = '[SEACOM] Load StationSettings With Filters';
export const LOAD_STATIONSETTINGS_FAIL = '[StationSetting Effects] Load StationSettings Fail';
export const LOAD_STATIONSETTINGS_SUCCESS = '[StationSetting Effects] Load StationSettings Success';
export const LOAD_STATIONSETTING = '[Component Effects] Load StationSetting';
export const LOAD_STATIONSETTING_FAIL = '[StationSetting Effects] Load StationSetting Fail';
export const LOAD_STATIONSETTING_SUCCESS = '[StationSetting Effects] Load StationSetting Success';
export const UPDATE_STATIONSETTING = '[SEACOM] Update StationSetting';
export const UPDATE_STATIONSETTING_FIELDS = '[SEACOM] Update StationSetting Fields';
export const UPDATE_STATIONSETTING_FAIL = '[StationSetting Effects] Update StationSetting Fail';
export const UPDATE_STATIONSETTING_SUCCESS = '[StationSetting Effects] Update StationSetting Success';
export const ADD_STATIONSETTING = '[SEACOM] Add StationSetting';
export const ADD_STATIONSETTING_FAIL = '[StationSetting Effects] Add StationSetting Fail';
export const ADD_STATIONSETTING_SUCCESS = '[StationSetting Effects] Add StationSetting Success';
export const DELETE_STATIONSETTING = '[SEACOM] Delete StationSetting';
export const DELETE_STATIONSETTING_FAIL = '[StationSetting Effects] Delete StationSetting Fail';
export const DELETE_STATIONSETTING_SUCCESS = '[StationSetting Effects] Delete StationSetting Success';
export const UPDATE_STATION_ORDER = '[Page Effects] Update Station Order';
export const UPDATE_STATION_ORDER_FAIL = '[Station Setting Effects] Update Station Order Fail';
export const UPDATE_STATION_ORDER_SUCCESS = '[Station Setting Effects] Update Station Order Success';

export const LoadStationSettings = createAction(
	LOAD_STATIONSETTINGS
);

export const LoadStationSettingsCancelled = createAction(
    LOAD_STATIONSETTINGS_CANCELLED
);

export const LoadStationSettingsWithFilters = createAction(
    LOAD_STATIONSETTINGS_WITH_FILTERS,
    props<{
        filters?: IFieldFilter[],
        order?: string,
        search?: string
    }>()
);

export const LoadStationSettingsSuccess = createAction(
    LOAD_STATIONSETTINGS_SUCCESS,
    props<{
        payload: IStationSetting[]
    }>()
);

export const LoadStationSettingsFail = createAction(
    LOAD_STATIONSETTINGS_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadStationSetting = createAction(
	LOAD_STATIONSETTING,
    props<{
        payload: string // ID
    }>()
);

export const LoadStationSettingSuccess = createAction(
    LOAD_STATIONSETTING_SUCCESS,
    props<{
        payload: IStationSetting
    }>()
);

export const LoadStationSettingFail = createAction(
    LOAD_STATIONSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateStationSetting = createAction(
    UPDATE_STATIONSETTING,
    props<{
        payload: IUpdatedStationSetting
    }>()
);

export const UpdateStationSettingFields = createAction(
    UPDATE_STATIONSETTING_FIELDS,
    props<{
        payload: IUpdatedStationSetting
    }>()
);

export const UpdateStationSettingSuccess = createAction(
    UPDATE_STATIONSETTING_SUCCESS,
    props<{
        payload: IStationSetting
    }>()
);

export const UpdateStationSettingFail = createAction(
    UPDATE_STATIONSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const AddStationSetting = createAction(
    ADD_STATIONSETTING,
    props<{
        payload: INewStationSetting
    }>()
);

export const AddStationSettingSuccess = createAction(
    ADD_STATIONSETTING_SUCCESS,
    props<{
        payload: IStationSetting
    }>()
);

export const AddStationSettingFail = createAction(
    ADD_STATIONSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteStationSetting = createAction(
    DELETE_STATIONSETTING,
    props<{
        payload: string // ID
    }>()
);

export const DeleteStationSettingSuccess = createAction(
    DELETE_STATIONSETTING_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteStationSettingFail = createAction(
    DELETE_STATIONSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateStationOrder = createAction(
    UPDATE_STATION_ORDER,
    props<{
        payload: {
            newOrder: string[],
            from: number,
            to: number
        }
    }>()
);

export const UpdateStationOrderSuccess = createAction(
    UPDATE_STATION_ORDER_SUCCESS
);

export const UpdateStationOrderFail = createAction(
    UPDATE_STATION_ORDER_FAIL
);
