// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// RXJS
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../../../store/actions';



@Injectable()
export class HeaderbarEffects {

  constructor(private actions$: Actions, private router: Router) { }

  navigateToHomepage$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHeaderbarActions.NavigateToHomePage
        ),
        map((action) => {
          this.router.navigate(['/home']);
        }),
      )
  },
  { dispatch: false }
  );

  navigateToFeedback$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHeaderbarActions.NavigateToFeedbackPage
        ),
        map((action) => {
          this.router.navigate(['/feedback']);
        }),
      )
  },
  { dispatch: false }
  );

  navigateToSettings$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromHeaderbarActions.NavigateToSettingsPage
        ),
        map((action) => {
          this.router.navigate(['/settings']);
        }),
      )
  },
  { dispatch: false }
  );

  logout$ = createEffect(() => {
    return this.actions$
    .pipe(
      ofType(
        actions.fromHeaderbarActions.Logout
      ),
      switchMap((_) => of(actions.Logout()))
    );
  });
}
