import { createAction, props } from '@ngrx/store';
import { ICurrentWeather } from 'src/app/interfaces/weather/currentweather';
import { IWeatherForecast } from 'src/app/interfaces/weather/weatherforecast';
import { IWeatherLocation } from 'src/app/interfaces/weather/weatherlocation';

// weather types
export const LOAD_WEATHER = '[SEACOM] Load Weather';
export const LOAD_WEATHER_CANCELLED = '[Weather Effects] Load Weather Cancelled';
export const LOAD_WEATHER_FAIL = '[Weather Effects] Load Weather Fail';
export const LOAD_WEATHER_SUCCESS = '[Weather Effects] Load Weather Success';

export const LoadWeather = createAction(
	LOAD_WEATHER
);

export const LoadWeatherCancelled = createAction(
    LOAD_WEATHER_CANCELLED
);

export const LoadWeatherSuccess = createAction(
    LOAD_WEATHER_SUCCESS,
    props<{
        location: IWeatherLocation,
        current: ICurrentWeather,
        forecast: IWeatherForecast
    }>()
);

export const LoadWeatherFail = createAction(
    LOAD_WEATHER_FAIL,
    props<{
        error: any
    }>()
);
