import { createAction, props } from '@ngrx/store';
import { INewStation, IStation, IUpdatedStation } from 'src/app/interfaces/seacom/station';

// station action types
export const LOAD_STATIONS = '[Page Effects] Load Stations';
export const LOAD_STATIONS_CANCELLED = '[Station Effects] Load Stations Cancelled';
export const LOAD_STATIONS_FAIL = '[Station Effects] Load Stations Fail';
export const LOAD_STATIONS_SUCCESS = '[Station Effects] Load Stations Success';
export const LOAD_STATION = '[Component Effects] Load Station';
export const LOAD_STATION_FAIL = '[Station Effects] Load Station Fail';
export const LOAD_STATION_SUCCESS = '[Station Effects] Load Station Success';
export const UPDATE_STATION = '[SEACOM] Update Station';
export const UPDATE_STATION_FIELDS = '[SEACOM] Update Station Fields';
export const UPDATE_STATION_FAIL = '[Station Effects] Update Station Fail';
export const UPDATE_STATION_RESPONDERS_FAIL = '[Station Effects] Update Station Responders Fail';
export const UPDATE_STATION_SUCCESS = '[Station Effects] Update Station Success';
export const UPDATE_STATION_RESPONDERS_SUCCESS = '[Station Effects] Update Station Responders Success';
export const UPDATE_STATION_STATUS_SUCCESS = '[Station Effects] Update Station Status Success';
export const UPDATE_STATION_STATUS_FAIL = '[Station Effects] Update Station Status Fail';
export const ADD_STATION = '[SEACOM] Add Station';
export const ADD_STATION_FAIL = '[Station Effects] Add Station Fail';
export const ADD_STATION_SUCCESS = '[Station Effects] Add Station Success';
export const DELETE_STATION = '[SEACOM] Delete Station';
export const DELETE_STATION_FAIL = '[Station Effects] Delete Station Fail';
export const DELETE_STATION_SUCCESS = '[Station Effects] Delete Station Success';

export const LoadStations = createAction(
	LOAD_STATIONS
);

export const LoadStationsCancelled = createAction(
	LOAD_STATIONS_CANCELLED
);

export const LoadStationsSuccess = createAction(
    LOAD_STATIONS_SUCCESS,
    props<{
        payload: IStation[]
    }>()
);

export const LoadStationsFail = createAction(
    LOAD_STATIONS_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadStation = createAction(
	LOAD_STATION,
    props<{
        payload: string // ID
    }>()
);

export const LoadStationSuccess = createAction(
    LOAD_STATION_SUCCESS,
    props<{
        payload: IStation
    }>()
);

export const LoadStationFail = createAction(
    LOAD_STATION_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateStation = createAction(
    UPDATE_STATION,
    props<{
        payload: IUpdatedStation
    }>()
);

export const UpdateStationFields = createAction(
    UPDATE_STATION_FIELDS,
    props<{
        payload: IUpdatedStation
    }>()
);

export const SetStationStatusSuccess = createAction(
    UPDATE_STATION_STATUS_SUCCESS,
    props<{
        payload: IStation
    }>()
);

export const UpdateStationRespondersSuccess = createAction(
    UPDATE_STATION_RESPONDERS_SUCCESS,
    props<{
        payload: IStation
    }>()
);


export const UpdateStationSuccess = createAction(
    UPDATE_STATION_SUCCESS,
    props<{
        payload: IStation
    }>()
);

export const UpdateStationFail = createAction(
    UPDATE_STATION_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateStationRespondersFail = createAction(
    UPDATE_STATION_RESPONDERS_FAIL,
    props<{
        payload: any
    }>()
);

export const SetStationStatusFail = createAction(
    UPDATE_STATION_STATUS_FAIL,
    props<{
        payload: any
    }>()
);

export const AddStation = createAction(
    ADD_STATION,
    props<{
        payload: INewStation
    }>()
);

export const AddStationSuccess = createAction(
    ADD_STATION_SUCCESS,
    props<{
        payload: IStation
    }>()
);

export const AddStationFail = createAction(
    ADD_STATION_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteStation = createAction(
    DELETE_STATION,
    props<{
        payload: string // ID
    }>()
);

export const DeleteStationSuccess = createAction(
    DELETE_STATION_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteStationFail = createAction(
    DELETE_STATION_FAIL,
    props<{
        payload: any
    }>()
);
