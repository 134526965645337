// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// State
import { SeacomState } from '../reducers';

// Actions
import * as actions from '../actions';

// Selectors
import { selectStationSettingsByStation, selectStationSettingsLastUpdated } from '../selectors/stationsetting.selectors';
import { selectCurrentUser } from '../selectors/auth.selectors';

// Interfaces
import { CopyStationSettingForNew, INewStationSetting, IStationSetting, IUpdatedStationSetting } from 'src/app/interfaces/seacom/stationsetting';

// Services
import { EnvironmentService } from 'src/app/services/environment.service';
import { StationSettingsService } from '../../services/seacom/stationsettings.service';

// Utilities
import { skipNull } from 'src/app/common/globals';


@Injectable()
export class StationSettingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private stationSettingsService: StationSettingsService
  ) { }

  loadStationSettings$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load StationSettings
        ofType(
          actions.LoadStationSettings
        ),
        concatLatestFrom((action) => this.store.select(selectStationSettingsLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if (now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.stationSettingsService.getList().pipe(
              map((stationSettings) => actions.LoadStationSettingsSuccess({ payload: stationSettings })),
              catchError((error) => of(actions.LoadStationSettingsFail(error)))
            );
          } else {
            return of(actions.LoadStationSettingsCancelled());
          }
        })
      );
  });
  loadStationSettingsWithFilters$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load StationSettings with Filters
        ofType(actions.LoadStationSettingsWithFilters),
        exhaustMap((action) => this.stationSettingsService.getList(action.filters, action.order, action.search).pipe(
          map((stationSettings) => actions.LoadStationSettingsSuccess({ payload: stationSettings })),
          catchError((error) => of(actions.LoadStationSettingsFail(error)))
        ))
      );
  });
  loadStationSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load StationSetting
        ofType(actions.LoadStationSetting),
        exhaustMap((action) => this.stationSettingsService.get(action.payload).pipe(
          map((stationSetting) => actions.LoadStationSettingSuccess({ payload: stationSetting })),
          catchError((error) => of(actions.LoadStationSettingsFail(error)))
        ))
      );
  });
  addStationSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add StationSetting
        ofType(actions.AddStationSetting),
        concatMap((action) => this.stationSettingsService.create(action.payload).pipe(
          map((stationSetting) => actions.AddStationSettingSuccess({ payload: stationSetting })),
          catchError((error) => of(actions.AddStationSettingFail(error)))
        ))
      );
  });
  updateStationSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update StationSetting
        ofType(actions.UpdateStationSetting),
        concatMap((action) => this.stationSettingsService.update(action.payload.id, action.payload).pipe(
          map((stationSetting) => actions.UpdateStationSettingSuccess({ payload: stationSetting })),
          catchError((error) => of(actions.UpdateStationSettingFail(error)))
        ))
      );
  });
  updateStationSettingFields$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update StationSetting fields
        ofType(actions.UpdateStationSettingFields),
        concatMap((action) => this.stationSettingsService.updateFields(action.payload.id, action.payload).pipe(
          map((stationSetting) => actions.UpdateStationSettingSuccess({ payload: stationSetting })),
          catchError((error) => of(actions.UpdateStationSettingFail(error)))
        ))
      );
  });
  deleteStationSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete StationSetting
        ofType(actions.DeleteStationSetting),
        mergeMap((action) =>
          this.stationSettingsService.delete(action.payload).pipe(
            map((stationSettingId) => actions.DeleteStationSettingSuccess({ payload: stationSettingId })),
            catchError((error) => of(actions.DeleteStationSettingFail(error)))
          )
        )
      )
  });

  updateStationOrder$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Station Order
        ofType(actions.UpdateStationOrder),
        concatLatestFrom(() => [
          this.store.select(selectStationSettingsByStation).pipe(skipNull()),
          this.store.select(selectCurrentUser).pipe(skipNull())
        ]),
        map(([action, ss, cu]) => {
          if (
            action.payload.from !== undefined &&
            action.payload.from !== null &&
            action.payload.to !== undefined &&
            action.payload.to !== null &&
            action.payload.from !== action.payload.to
          ) {
            // Handle from FROM item, but only if it has a station setting (non default order)
            let from = action.payload.from;
            let postFrom = action.payload.from + 1;
            let to = action.payload.to;
            let postTo = action.payload.to + 1;

            let fromStationId = action.payload.newOrder[from];
            let fromStationSetting: IStationSetting = null;
            if (ss.hasOwnProperty(fromStationId)) {
              fromStationSetting = ss[fromStationId];
              if (
                fromStationSetting.organization === undefined ||
                fromStationSetting.organization === null ||
                fromStationSetting.user === undefined ||
                fromStationSetting.user === null
              ) {
                // Station Setting is either global or org based, create an individualized one (copying the existing one) with the new after
                let newCS = {
                  ...CopyStationSettingForNew(fromStationSetting),
                  organization: cu.organization.id,
                  user: cu.id,
                  after: from > 0 ? action.payload.newOrder[from - 1] : null
                } as INewStationSetting;
                this.store.dispatch(actions.AddStationSetting({ payload: newCS }));
              } else {
                // Station Setting exists and is individualized, just update the after of it
                let updSSField = {
                  id: fromStationSetting.id,
                  after: from > 0 ? action.payload.newOrder[from - 1] : null
                } as IUpdatedStationSetting;
                this.store.dispatch(actions.UpdateStationSettingFields({ payload: updSSField }));
              }
            } else {
              // Station Setting doesn't exist -- create a new one
              let newCS = {
                organization: cu.organization.id,
                user: cu.id,
                station: action.payload.newOrder[from],
                after: from > 0 ? action.payload.newOrder[from - 1] : null
              } as INewStationSetting;
              this.store.dispatch(actions.AddStationSetting({ payload: newCS }));
            }

            // Handle the station AFTER the FROM item -- but only if it has a station setting
            let postFromStationId: string = null;
            let postFromStationSetting: IStationSetting = null;

            if (postFrom !== to && postFrom <= (action.payload.newOrder.length - 1) && ss.hasOwnProperty(action.payload.newOrder[postFrom])) {
              postFromStationId = action.payload.newOrder[postFrom];
              postFromStationSetting = ss[postFromStationId];
              if (
                postFromStationSetting.organization === undefined ||
                postFromStationSetting.organization === null ||
                postFromStationSetting.user === undefined ||
                postFromStationSetting.user === null
              ) {
                // Station Setting is either global or org based, create an individualized one (copying the existing one) with the new after
                let newCS = {
                  ...CopyStationSettingForNew(postFromStationSetting),
                  organization: cu.organization.id,
                  user: cu.id,
                  after: postFrom > 0 ? action.payload.newOrder[postFrom - 1] : null
                } as INewStationSetting;
                this.store.dispatch(actions.AddStationSetting({ payload: newCS }));
              } else {
                // Station Setting exists and is individualized, just update the after of it
                let updSSField = {
                  id: postFromStationSetting.id,
                  after: postFrom > 0 ? action.payload.newOrder[postFrom - 1] : null
                } as IUpdatedStationSetting;
                this.store.dispatch(actions.UpdateStationSettingFields({ payload: updSSField }));
              }
            }

            // Handle the TO item

            let toStationId = action.payload.newOrder[to];
            let toStationSetting: IStationSetting = null;
            if (ss.hasOwnProperty(toStationId)) {
              toStationSetting = ss[toStationId];
              if (
                toStationSetting.organization === undefined ||
                toStationSetting.organization === null ||
                toStationSetting.user === undefined ||
                toStationSetting.user === null
              ) {
                // Station Setting is either global or org based, create an individualized one (copying the existing one) with the new after
                let newCS = {
                  ...CopyStationSettingForNew(toStationSetting),
                  organization: cu.organization.id,
                  user: cu.id,
                  after: to > 0 ? action.payload.newOrder[to - 1] : null
                } as INewStationSetting;
                this.store.dispatch(actions.AddStationSetting({ payload: newCS }));
              } else {
                // Station Setting exists and is individualized, just update the after of it
                let updSSField = {
                  id: toStationSetting.id,
                  after: to > 0 ? action.payload.newOrder[to - 1] : null
                } as IUpdatedStationSetting;
                this.store.dispatch(actions.UpdateStationSettingFields({ payload: updSSField }));
              }
            } else {
              // Station Setting doesn't exist -- create a new one
              let newCS = {
                organization: cu.organization.id,
                user: cu.id,
                station: action.payload.newOrder[to],
                after: to > 0 ? action.payload.newOrder[to - 1] : null
              } as INewStationSetting;
              this.store.dispatch(actions.AddStationSetting({ payload: newCS }));
            }

            // Handle the station AFTER the TO item -- but only if it has a station setting
            let postToStationId: string = null;
            let postToStationSetting: IStationSetting = null;

            if (postTo !== from && postTo <= (action.payload.newOrder.length - 1) && ss.hasOwnProperty(action.payload.newOrder[postTo])) {
              postToStationId = action.payload.newOrder[postTo];
              postToStationSetting = ss[postToStationId];
              if (
                postToStationSetting.organization === undefined ||
                postToStationSetting.organization === null ||
                postToStationSetting.user === undefined ||
                postToStationSetting.user === null
              ) {
                // Station Setting is either global or org based, create an individualized one (copying the existing one) with the new after
                let newCS = {
                  ...CopyStationSettingForNew(postToStationSetting),
                  organization: cu.organization.id,
                  user: cu.id,
                  after: toStationId
                } as INewStationSetting;
                this.store.dispatch(actions.AddStationSetting({ payload: newCS }));
              } else {
                // Station Setting exists and is individualized, just update the after of it
                let updSSField = {
                  id: postToStationSetting.id,
                  after: toStationId
                } as IUpdatedStationSetting;
                this.store.dispatch(actions.UpdateStationSettingFields({ payload: updSSField }));
              }
            }
          }

        })
      )
  },
    { dispatch: false });
}
