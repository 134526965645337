import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-runbook-widget',
  templateUrl: './runbook-widget.component.html',
  styleUrls: ['../widget.styles.scss', './runbook-widget.component.scss']
})
export class RunbookWidgetComponent implements OnInit {
  @Input() brightness$: Observable<'light'|'dark'>;
  @Input('ActiveIncidentsCount') activeIncidentsCount: number;
  @Input('IncidentsLastUpdated') incidentsLastUpdated: string;
  @Input('Loading$') incidentsLoading$: Observable<boolean>;
  @Output() hideWidgetEvent = new EventEmitter<string>();
  @Output() navigateToRunbookEvent = new EventEmitter<void>();

  constructor(
  ) { }

  ngOnInit() {
  }

  navigateToRunbookPage(event) {
    event.stopPropagation();
    this.navigateToRunbookEvent.emit();
  }

  hideWidget() {
    this.hideWidgetEvent.emit('runbook');
  }
}
