import { createReducer, on } from '@ngrx/store';

import * as actions from '../../../store/actions';

export const runbookPageFeatureKey = 'runbookpage';

export interface RunbookPageState {
  incidentFormOpen: boolean,
  incidentFormIncident: string
};

export const RunbookPageInitialState: RunbookPageState = {
  incidentFormOpen: false,
  incidentFormIncident: null
};

export const RunbookPageReducer = createReducer(
  RunbookPageInitialState,
  on(actions.ReturnToLogin, (state, action) => {
    return {
      ...RunbookPageInitialState
    } as RunbookPageState;
  }),
  on(actions.fromRunbookPageActions.Enter, (state, action) => {
    return {
      ...state,
      incidentFormOpen: false,
      incidentFormIncident: null
    } as RunbookPageState;
  }),
  on(actions.fromRunbookPageActions.Exit, (state, action) => {
    return {
      ...state
    } as RunbookPageState;
  }),
  on(actions.fromRunbookPageActions.DisplayIncidentForm, (state, action) => {
    return {
      ...state,
      incidentFormOpen: true,
      incidentFormIncident: action.payload.incidentId
    } as RunbookPageState;
  }),
  on(actions.fromRunbookPageActions.CloseIncidentForm, (state, action) => {
    return {
      ...state,
      incidentFormOpen: false,
      incidentFormIncident: null
    } as RunbookPageState;
  })
);
