import { createAction, props } from '@ngrx/store';
import { IIncidentType, INewIncidentType, IUpdatedIncidentType } from 'src/app/interfaces/seacom/incidenttype';

// incidenttype action types
export const LOAD_INCIDENTTYPES = '[Page Effects] Load IncidentTypes';
export const LOAD_INCIDENTTYPES_CANCELLED = '[Incident Type Effects] Load IncidentTypes Cancelled';
export const LOAD_INCIDENTTYPES_FAIL = '[Incident Type Effects] Load IncidentTypes Fail';
export const LOAD_INCIDENTTYPES_SUCCESS = '[Incident Type Effects] Load IncidentTypes Success';
export const LOAD_INCIDENTTYPE = '[Component Effects] Load IncidentType';
export const LOAD_INCIDENTTYPE_FAIL = '[Incident Type Effects] Load IncidentType Fail';
export const LOAD_INCIDENTTYPE_SUCCESS = '[Incident Type Effects] Load IncidentType Success';
export const UPDATE_INCIDENTTYPE = '[SEACOM] Update IncidentType';
export const UPDATE_INCIDENTTYPE_FAIL = '[Incident Type Effects] Update IncidentType Fail';
export const UPDATE_INCIDENTTYPE_SUCCESS = '[Incident Type Effects] Update IncidentType Success';
export const ADD_INCIDENTTYPE = '[SEACOM] Add IncidentType';
export const ADD_INCIDENTTYPE_FAIL = '[Incident Type Effects] Add IncidentType Fail';
export const ADD_INCIDENTTYPE_SUCCESS = '[Incident Type Effects] Add IncidentType Success';
export const DELETE_INCIDENTTYPE = '[SEACOM] Delete IncidentType';
export const DELETE_INCIDENTTYPE_FAIL = '[Incident Type Effects] Delete IncidentType Fail';
export const DELETE_INCIDENTTYPE_SUCCESS = '[Incident Type Effects] Delete IncidentType Success';

export const LoadIncidentTypes = createAction(
	LOAD_INCIDENTTYPES
);

export const LoadIncidentTypesCancelled = createAction(
    LOAD_INCIDENTTYPES_CANCELLED
);

export const LoadIncidentTypesSuccess = createAction(
    LOAD_INCIDENTTYPES_SUCCESS,
    props<{
        payload: IIncidentType[]
    }>()
);

export const LoadIncidentTypesFail = createAction(
    LOAD_INCIDENTTYPES_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadIncidentType = createAction(
	LOAD_INCIDENTTYPE,
    props<{
        payload: string // ID
    }>()
);

export const LoadIncidentTypeSuccess = createAction(
    LOAD_INCIDENTTYPE_SUCCESS,
    props<{
        payload: IIncidentType
    }>()
);

export const LoadIncidentTypeFail = createAction(
    LOAD_INCIDENTTYPE_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateIncidentType = createAction(
    UPDATE_INCIDENTTYPE,
    props<{
        payload: IUpdatedIncidentType
    }>()
);

export const UpdateIncidentTypeSuccess = createAction(
    UPDATE_INCIDENTTYPE_SUCCESS,
    props<{
        payload: IIncidentType
    }>()
);

export const UpdateIncidentTypeFail = createAction(
    UPDATE_INCIDENTTYPE_FAIL,
    props<{
        payload: any
    }>()
);

export const AddIncidentType = createAction(
    ADD_INCIDENTTYPE,
    props<{
        payload: INewIncidentType
    }>()
);

export const AddIncidentTypeSuccess = createAction(
    ADD_INCIDENTTYPE_SUCCESS,
    props<{
        payload: IIncidentType
    }>()
);

export const AddIncidentTypeFail = createAction(
    ADD_INCIDENTTYPE_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteIncidentType = createAction(
    DELETE_INCIDENTTYPE,
    props<{
        payload: string // ID
    }>()
);

export const DeleteIncidentTypeSuccess = createAction(
    DELETE_INCIDENTTYPE_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteIncidentTypeFail = createAction(
    DELETE_INCIDENTTYPE_FAIL,
    props<{
        payload: any
    }>()
);
