// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { IIncidentSeverity, INewIncidentSeverity, IUpdatedIncidentSeverity } from '../../interfaces/seacom/incidentseverity';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';


@Injectable({
  providedIn: 'root'
})
export class IncidentSeveritiesService extends ApiNodeService<IIncidentSeverity, INewIncidentSeverity, IUpdatedIncidentSeverity> {
  node = 'incidentseverities';

  constructor(public seacomApiService: SeacomApiService) {
    super(seacomApiService);
  }

}
