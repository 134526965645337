import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IUser } from '../../interfaces/seacom/user';

import * as actions from '../actions';
import { deleteItem, updateItem, updateItems } from '../utils/itementity';


export const userFeatureKey = 'users';

export interface UserState {
	entities: { [id: string]: IUser },
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const UserInitialState: UserState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

export const UserReducer = createReducer(
	UserInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...UserInitialState
		} as UserState;
	}),
	// Load Users
	on(actions.LoadUsers, (state, action) => {
		return {
			...state,
			loading: true,
		} as UserState;
	}),
	on(actions.LoadUsersCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as UserState;
	}),
	on(actions.LoadUsersSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as UserState;
	}),
	on(actions.LoadUsersFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as UserState;
	}),

	// Load User
	on(actions.LoadUser, (state, action) => {
		return {
			...state,
			loading: true,
		} as UserState;
	}),
	on(actions.LoadUserSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as UserState;
	}),
	on(actions.LoadUserFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as UserState;
	}),

	// Update User
	on(actions.UpdateUser, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateUserSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateUserFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),

	// Delete User
	on(actions.DeleteUser, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteUserSuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteUserFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	}),
);
