import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IColumnSetting } from 'src/app/interfaces/seacom/columnsetting';
import * as fromReducers from '../reducers/table.reducers';
import { sortFactory } from '../utils/sort.utils';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { filterObject } from '../utils/filter.utils';
import { searchFactory } from '../utils/search.utils';
import { selectPossibleMetadataFields } from './incidenttype.selectors';
import { IMetadataField } from 'src/app/interfaces/seacom/metadatafield';
import { selectCurrentUser } from './auth.selectors';
import { IUser } from 'src/app/interfaces/seacom/user';
import { orderColumnSettings } from '../utils/columnsettings.utils';
import { ISortField } from 'src/app/interfaces/sortfield';
import { IItemEntity } from 'src/app/interfaces/itementity';
import { reduceItems } from '../utils/itementity';
import { toLocalISOString } from 'src/app/common/globals';

export const selectTableState = createFeatureSelector<fromReducers.TableState>(
  fromReducers.tableFeatureKey
);

/*
* Entity counts
*/

export const selectColumnSettingsCount = createSelector(
  selectTableState,
  (state: fromReducers.TableState): number => Object.keys(state.columnSettings.entities).length
);

export const selectFiltersSettingsCount = createSelector(
  selectTableState,
  (state: fromReducers.TableState): number => Object.keys(state.filterSettings.entities).length
);

export const selectSearchSettingsCount = createSelector(
  selectTableState,
  (state: fromReducers.TableState): number => Object.keys(state.searchSettings.entities).length
);

export const selectSortSettingsCount = createSelector(
  selectTableState,
  (state: fromReducers.TableState): number => Object.keys(state.sortSettings.entities).length
);

/*
* Select by ID
*/

export const selectColumnSettingById = (id: string) => createSelector(selectTableState, (tableState): IColumnSetting => {
  return tableState.columnSettings?.entities[id]; // id is API assigned UUID
});

export const selectFilterSettingsById = (id: string) => createSelector(selectTableState, (tableState): IFieldFilter[] => {
  return tableState.filterSettings?.entities[id]; // id is container-component-table
});

export const selectSearchSettingById = (id: string) => createSelector(selectTableState, (tableState): string => {
  return tableState.searchSettings?.entities[id]; // id is container-component-table
});

export const selectSortSetingsById = (id: string) => createSelector(selectTableState, (tableState): ISortField[] => {
  return tableState.sortSettings?.entities[id]; // id is container-component-table
});

/*
* Select Last Updated
*/

export const selectColumnSettingsLastUpdated = createSelector(
  selectTableState,
  (state: fromReducers.TableState): string => state.columnSettings.lastUpdated
);

/*
* Select Loading
*/

export const selectColumnSettingsLoading = createSelector(
  selectTableState,
  (state: fromReducers.TableState): boolean => state.columnSettings.loading
);

/*
* Select All
*/

export const selectAllColumnSettings = createSelector(
  selectColumnSettingsCount,
  selectTableState,
  (count: number, state: fromReducers.TableState): IColumnSetting[] => {
    if(count > 0) {
      return Object.keys(state.columnSettings.entities).map(i => state.columnSettings.entities[i])
        .sort((a, b) => sortFactory(a, b, [{field: 'column', ascending: true}]));
    } else {
      return [];
    }
  }
);

export const selectAllFilterSettings = createSelector(
  selectFiltersSettingsCount,
  selectTableState,
  (count: number, state: fromReducers.TableState): IFieldFilter[][] => {
    if(count > 0) {
      return Object.keys(state.filterSettings.entities).map(i => state.filterSettings.entities[i]);
    } else {
      return [];
    }
  }
);

/*
* Select by Column
*/

export const selectColumnSettingsByColumn = (column: string) => createSelector(selectAllColumnSettings, (columnsettings): IColumnSetting[] => {
  if(columnsettings.length > 0) {
    return columnsettings.filter(cs => cs.column === column);
  } else {
    return [];
  }
});

/*
* Select by Container and Table
*/

export const selectColumnSettingsByCCT = (container: string, component: string, table: string) => createSelector(
  selectAllColumnSettings,
  selectPossibleMetadataFields,
  selectCurrentUser,
  (columnsettings: IColumnSetting[], fields: IMetadataField[], cu: IUser): IColumnSetting[] => {
  let res: IColumnSetting[];
  if(columnsettings.length > 0) {
    res = columnsettings.filter(cs => cs.container === container && cs.component === component && cs.table === table);
  } else {
    res = [];
  }

  if(table === 'incidents') {
    let cols = res.filter(cs => cs.datatype === 'metadata').map(cs => cs.column);
    fields.forEach(f => {
      if(cols.indexOf(f.id) === -1) {
        res.push({
          d: 'IColumnSetting',
          id: f.id,
          organization: undefined,
          user: undefined,
          container: container,
          component: component,
          table: table,
          column: f.id,
          datatype: 'metadata',
          displayname: f.name,
          displayed: false,
          order: 262144,
          created: toLocalISOString(new Date()),
          created_by: cu,
          last_modified: toLocalISOString(new Date()),
          last_modified_by: cu
        } as IColumnSetting);
      }
    });
  }
  return res;
});

export const selectColumnSettingByCCT = (container: string, component: string, table: string, column: string) => createSelector(
  selectColumnSettingsByCCT(container, component, table),
  (columnSettings: IColumnSetting[]): IColumnSetting => {
    let res = columnSettings.filter(cs => cs.column === column);
    
    if(res.length > 0) {
      return res[0];
    } else {
      return null;
    }
  }
);

/*
* Select by Container, Component, and Table
*/

export const selectIsColumnSelectorOpenByCCT = (container: string, component: string, table: string) => createSelector(
  selectTableState,
  (state: fromReducers.TableState): boolean => {
    let cctId = container + ':' + component + ':' + table;
    if(state.columnSelectorOpen.entities.hasOwnProperty(cctId)) {
      return state.columnSelectorOpen.entities[cctId];
    } else {
      return false;
    }
});

export const selectTableRowExpansionByCCT = (container: string, component: string, table: string) => createSelector(
  selectTableState,
  (state: fromReducers.TableState): { [id: string]: boolean } => {
    let cctId = container + ':' + component + ':' + table;
    if(state.rowExpanded.entities.hasOwnProperty(cctId)) {
      return [...state.rowExpanded.entities[cctId]].reduce(
        (entities: { [id: string]: boolean }, item: { entityId: string, expanded: boolean }) => {
          entities[item.entityId] = item.expanded;
          return entities;
        },
        {}
      ) as { [id: string]: boolean };
    } else {
      return {};
    }
});

export const selectFilterSettingsByCCT = (container: string, component: string, table: string) => createSelector(
  selectTableState,
  (state: fromReducers.TableState): IFieldFilter[] => {
    let cctId = container + ':' + component + ':' + table;
    if(state.filterSettings.entities.hasOwnProperty(cctId)) {
      return state.filterSettings.entities[cctId];
    } else {
      return [];
    }
});

export const selectSearchSettingByCCT = (container: string, component: string, table: string) => createSelector(
  selectTableState,
  (state: fromReducers.TableState): string => {
    let cctId = container + ':' + component + ':' + table;
    if(state.searchSettings.entities.hasOwnProperty(cctId)) {
      return state.searchSettings.entities[cctId];
    } else {
      return null;
    }
});

export const selectSortSettingsByCCT = (container: string, component: string, table: string) => createSelector(
  selectTableState,
  (state: fromReducers.TableState): ISortField[] => {
    let cctId = container + ':' + component + ':' + table;
    if(state.sortSettings.entities.hasOwnProperty(cctId)) {
      return state.sortSettings.entities[cctId];
    } else {
      return [];
    }
});

/*
* Select Displayed
*/

export const selectDisplayedColumnSettingNamesByCCT = (container: string, component: string, table: string) => createSelector(selectColumnSettingsByCCT(container, component, table), (columnsettings): string[] => {
  if(columnsettings.length > 0) {
    return columnsettings.filter(cs => cs.displayed).map(cs => cs.column);
  } else {
    return [];
  }
});

export const selectOrderedColumnSettingsByCCT = (container: string, component: string, table: string) => createSelector(selectColumnSettingsByCCT(container, component, table), (columnsettings): IColumnSetting[] => {
  if(columnsettings.length > 0) {
    return orderColumnSettings(columnsettings);
  } else {
    return [];
  }
});

export const selectDisplayedColumnSettingsByCCT = (container: string, component: string, table: string) => createSelector(selectOrderedColumnSettingsByCCT(container, component, table), (columnsettings): IColumnSetting[] => {
  if(columnsettings.length > 0) {
    return columnsettings.filter(cs => cs.displayed === true);
  } else {
    return [];
  }
});

export const selectDisplayedOrderedColumnSettingsByCCT = (container: string, component: string, table: string) => createSelector(selectDisplayedColumnSettingsByCCT(container, component, table), (columnsettings): IColumnSetting[] => {
  if(columnsettings.length > 0) {
    return orderColumnSettings(columnsettings);
  } else {
    return [];
  }
});

export const selectDisplayedOrderedColumnSettingNamesByCCT = (container: string, component: string, table: string) => createSelector(selectDisplayedOrderedColumnSettingsByCCT(container, component, table), (columnsettings): string[] => {
  if(columnsettings.length > 0) {
    return columnsettings.map(cs => cs.column);
  } else {
    return [];
  }
});
