import { createAction, props } from "@ngrx/store";
import { IPage } from "src/app/interfaces/page";
import { INewIncident, IUpdatedIncident } from "src/app/interfaces/seacom/incident";
import { INewIncidentResponse, IUpdatedIncidentResponse } from "src/app/interfaces/seacom/incidentresponse";
import { INewMetadata, IUpdatedMetadata } from "src/app/interfaces/seacom/metadata";
import { IFieldFilter } from '../../../interfaces/seacom/fieldfilter';

const actionKey = '[Runbook Page] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

export const ChangePage = createAction(
  actionKey + 'Change Page',
  props<{ payload: { page: IPage }}>()
);

export const AddFieldFilter = createAction(
  actionKey + 'Add Field Filter',
  props<{ payload: { filter: IFieldFilter }}>()
);

export const RemoveFieldFilter = createAction(
  actionKey + 'Add Field Filter',
  props<{ payload: { fieldName: string }}>()
);

export const ChangeSortField = createAction(
  actionKey + 'Change Sort Field',
  props<{ payload: { fieldName: string, ascending: boolean }}>()
);

export const DisplayIncidentListColumn = createAction(
  actionKey + 'Display Table Column',
  props<{ payload: { column: string, datatype: string }}>()
);

export const HideIncidentListColumn = createAction(
  actionKey + 'Hide Table Column',
  props<{ payload: { column: string, datatype: string }}>()
);

export const DisplayIncidentForm = createAction(
  actionKey + 'Display Incident Form',
  props<{ payload: { incidentId: string }}>()
);

const incidentFormKey = '[Incident Form] ';

export const CloseIncidentForm = createAction(
  actionKey + incidentFormKey + 'Close Incident Form'
);

// Incident actions
export const CreateIncidentFormIncident = createAction(
  actionKey + incidentFormKey + 'Create Incident',
  props<{ payload: INewIncident }>()
);

export const UpdateIncidentFormIncident = createAction(
  actionKey + incidentFormKey + 'Update Incident',
  props<{ payload: IUpdatedIncident }>()
);

// Incident Response actions
export const CreateIncidentFormResponse = createAction(
  actionKey + incidentFormKey + 'Create Incident Response',
  props<{ payload: INewIncidentResponse }>()
);

export const UpdateIncidentFormResponse = createAction(
  actionKey + incidentFormKey + 'Update Incident Response',
  props<{ payload: IUpdatedIncidentResponse }>()
);

export const DeleteIncidentFormResponse = createAction(
  actionKey + incidentFormKey + 'Delete Incident Response',
  props<{ payload: string }>()
);

// Metadata actions
export const CreateMetadata = createAction(
  actionKey + incidentFormKey + 'Create Metadata',
  props<{ payload: INewMetadata }>()
);

export const UpdateMetadata = createAction(
  actionKey + incidentFormKey + 'Update Metadata',
  props<{ payload: IUpdatedMetadata }>()
);
