import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IIncident } from 'src/app/interfaces/seacom/incident';
import * as fromReducers from './operations.page.reducers';
import { sortFactory } from '../../../store/utils/sort.utils';
import { selectActiveAndRecentIncidents, selectAllIncidents, selectIncidentsWithLocation, selectLatestIncidentsInProgressByStation } from '../../../store/selectors/incident.selectors';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { filterObject, incidentFilterFactory } from '../../../store/utils/filter.utils';
import { selectAllStations, selectAllStationsInOrder, selectStationsWithLocation } from '../../../store/selectors/station.selectors';
import { IStation } from 'src/app/interfaces/seacom/station';
import { selectAllIncidentResponses, selectIncidentResponsesWithLocation, selectLatestIncidentResponseInProgressByStation } from '../../../store/selectors/incidentresponse.selectors';
import { IIncidentResponse } from 'src/app/interfaces/seacom/incidentresponse';
import { selectAllIncidentTypes } from '../../../store/selectors/incidenttype.selectors';
import { IIncidentType } from 'src/app/interfaces/seacom/incidenttype';
import { IMapFilterSet } from 'src/app/interfaces/mapfilter';
import { selectContainerSettingByContainerComponentAndSetting } from '../../../store/selectors/containersetting.selectors';
import { getBoolean } from 'src/app/common/globals';

export const selectOperationsPageState = createFeatureSelector<fromReducers.OperationsPageState>(
  fromReducers.operationsPageFeatureKey
);

export const selectIncidentFormOpen = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): boolean => state.incidentFormOpen
);

export const selectIncidentFormIncidentId = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): string => state.incidentFormIncident
);

export const selectIncidentFormIncident = createSelector(
  selectIncidentFormIncidentId,
  selectAllIncidents,
  (id: string, incidents: IIncident[]): IIncident => {
    let res = incidents.find(i => i.id === id);
    if(res !== undefined) {
      return res;
    } else {
      return null;
    }
  }
);

export const selectIncidentFormResponses = createSelector(
  selectIncidentFormIncidentId,
  selectAllIncidentResponses,
  (id: string, responses: IIncidentResponse[]): IIncidentResponse[] => {
    return responses.filter(ir => ir.incident.id === id).sort((a,b) => sortFactory(a,b,[{field: 'starttime', ascending: false}]));
  }
);

// Transfer Popover Open
export const selectTransferPopoverOpen = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): boolean => state.transferPopoverOpen
);

// Quick Incident Choice Popover Open
export const selectQuickIncidentChoicePopoverOpen = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): boolean => state.quickIncidentChoicePopoverOpen
);

// Slide-ables
export const selectStationsListExpanded = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): boolean => state.stationsListExpanded
);

export const selectIncidentsListExpanded = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): boolean => state.incidentsListExpanded
);

export const selectMapExpanded = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): boolean => state.mapExpanded
);

export const selectLastExpandedSwipeIn = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): null|'map'|'incidents'|'stations' => state.lastExpandedSwipeIn
);

export const selectExpandedStation = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): string => state.expandedStationId
);

export const selectStationSet = createSelector(
  selectAllStationsInOrder,
  selectLatestIncidentsInProgressByStation,
  selectLatestIncidentResponseInProgressByStation,
  selectAllIncidentTypes,
  (
    sts: IStation[],
    libs: { [id: string]: IIncident },
    lirbs: { [id: string]: IIncidentResponse },
    its: IIncidentType[]
  ): { station: IStation, incident: IIncident, incType: IIncidentType, response: IIncidentResponse }[] => {
    return sts.map(s => {
      let incType: IIncidentType = null;
      if(lirbs[s.id] !== undefined && lirbs[s.id] !== null && lirbs[s.id].type !== undefined && lirbs[s.id].type !== null) {
        incType = its.find(it => it.id === lirbs[s.id].type.id);
      }
      return {
        station: s,
        incident: libs[s.id],
        response: lirbs[s.id],
        incType: incType
      };
    });
  }
);

export const selectTransferringStationId = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): string => state.stationTransferring
);

export const selectTransferringStation = createSelector(
  selectTransferringStationId,
  selectAllStations,
  (id: string, stas: IStation[]): IStation => {
    let res = stas.find(s => s.id === id);
    return res !== undefined ? res : null;
  }
);

// Operations map selectors
export const selectOperationsMapCenterCoordinates = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): { lat: number, lng: number } => state.map.centerCoordinates
);

export const selectOperationsMapBounds = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): google.maps.LatLngBoundsLiteral => state.map.bounds
);

export const selectOperationsMapZoom = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): number => state.map.zoomLevel
);

export const selectOperationsMapType = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): 'roadmap'|'satellite'|'hybrid'|'terrain' => state.map.mapTypeId
);

export const selectOperationsMapFilters = createSelector(
  selectOperationsPageState,
  (state: fromReducers.OperationsPageState): IMapFilterSet => {
    return state.mapFilters;
  }
);

export const selectOperationsMapFilteredStations = createSelector(
  selectStationsWithLocation,
  selectOperationsMapFilters,
  (stations: IStation[], filters: IMapFilterSet): IStation[] => {
    let filteredStations = stations;
    if(filters.stations.length > 0) {
      filteredStations = filteredStations.filter(s => filters.stations.indexOf(s.id) !== -1);
    }

    if(filters.beaches.length > 0) {
      filteredStations = filteredStations.filter(s => {
        if(s.beach !== undefined && s.beach !== null && s.beach.id !== undefined && s.beach.id !== null) {
          if(filters.beaches.indexOf(s.beach.id) !== -1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }

    if(filters.districts.length > 0) {
      filteredStations = filteredStations.filter(s => {
        if(s.district !== undefined && s.district !== null && s.district.id !== undefined && s.district.id !== null) {
          if(filters.districts.indexOf(s.district.id) !== -1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }

    if(filters.regions.length > 0) {
      filteredStations = filteredStations.filter(s => {
        if(s.region !== undefined && s.region !== null && s.region.id !== undefined && s.region.id !== null) {
          if(filters.regions.indexOf(s.region.id) !== -1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }

    return filteredStations;
  }
);

export const selectOperationsMapFilteredIncidents = createSelector(
  selectIncidentsWithLocation,
  selectOperationsMapFilters,
  (incidents: IIncident[], filters: IMapFilterSet): IIncident[] => {
    let filteredIncidents = incidents;

    let filterPassed = true;

    if(filters.stations.length > 0) {
      filteredIncidents = filteredIncidents.filter(i => {
        return incidentFilterFactory(i, filters.stations.map(s => { return { field: 'station', filter: s } as IFieldFilter }));
      });
    }

    if(filters.beaches.length > 0) {
      filteredIncidents = filteredIncidents.filter(i => {
        return incidentFilterFactory(i, filters.beaches.map(s => { return { field: 'beach', filter: s } as IFieldFilter }));
      });
    }

    if(filters.districts.length > 0) {
      filteredIncidents = filteredIncidents.filter(i => {
        return incidentFilterFactory(i, filters.districts.map(s => { return { field: 'district', filter: s } as IFieldFilter }));
      });
    }

    if(filters.regions.length > 0) {
      filteredIncidents = filteredIncidents.filter(i => {
        return incidentFilterFactory(i, filters.regions.map(s => { return { field: 'region', filter: s } as IFieldFilter }));
      });
    }

    return filteredIncidents;
  }
);

export const selectOperationsMapFilteredResponses = createSelector(
  selectIncidentResponsesWithLocation,
  selectOperationsMapFilters,
  (responses: IIncidentResponse[], filters: IMapFilterSet): IIncidentResponse[] => {
    let filteredResponses = responses;
    
    if(filters.stations.length > 0) {
      filteredResponses = filteredResponses.filter(s => {
        if(s.stations !== undefined && s.stations !== null && s.stations.length > 0) {
          if(filters.stations.filter(s => responses.map(r => r.stations.map(s => s.id).includes(s)).length > 0).length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }

    if(filters.beaches.length > 0) {
      filteredResponses = filteredResponses.filter(s => {
        if(s.beach !== undefined && s.beach !== null && s.beach.id !== undefined && s.beach.id !== null) {
          if(filters.beaches.indexOf(s.beach.id) !== -1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }

    if(filters.districts.length > 0) {
      filteredResponses = filteredResponses.filter(s => {
        if(s.district !== undefined && s.district !== null && s.district.id !== undefined && s.district.id !== null) {
          if(filters.districts.indexOf(s.district.id) !== -1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }

    if(filters.regions.length > 0) {
      filteredResponses = filteredResponses.filter(s => {
        if(s.region !== undefined && s.region !== null && s.region.id !== undefined && s.region.id !== null) {
          if(filters.regions.indexOf(s.region.id) !== -1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }

    return filteredResponses;
  }
);

export const selectOperationsIncidentsListPinnedStatus = () => createSelector(selectContainerSettingByContainerComponentAndSetting('operations', 'incidents', 'pinned'), (pinned): boolean => {
  if(pinned !== null) {
    return getBoolean(pinned.value);
  } else {
    return false;
  }
});
