// Angular
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

// Ionic
import { PopoverController } from '@ionic/angular';

// RXJS
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Globals
import { getBoolean, searchItems, searchItemsField, searchUsers, skipNull } from 'src/app/common/globals';

// Interfaces
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { IColumnSetting } from 'src/app/interfaces/seacom/columnsetting';
import { IMetadataField } from 'src/app/interfaces/seacom/metadatafield';

// State
import { SeacomState } from 'src/app/store';

// Selectors
import { selectFilterSettingsByCCT } from 'src/app/store/selectors/table.selectors';
import { selectMetadataListChoicesByField, selectPossibleMetadataFieldsById } from 'src/app/store/selectors/incidenttype.selectors';
import { selectUsersByRole } from 'src/app/store/selectors/user.selectors';
import { selectBrightness } from 'src/app/store/selectors/ambiance.selectors';


@Component({
  selector: 'app-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss']
})
export class ColumnFilterComponent implements OnInit, OnDestroy {
  @Input('container') container: string;
  @Input('component') component: string;
  @Input('table') table: string;
  @Input('column') column: IColumnSetting;
  @Input('options') options$: Observable<any[]>;
  @Input('anotherFilterStatusChange') anotherFilterStatusChange$: Observable<{ column: string, status: boolean }>;
  
  @Output() filterStatusChange = new EventEmitter<{ column: string, status: boolean }>();
  @Output() replaceFilters = new EventEmitter<{
    container: string,
    component: string,
    table: string,
    newFilters: IFieldFilter[]
  }>();
  @Output() removeFilters = new EventEmitter<{
    container: string,
    component: string,
    table: string,
    column: string
  }>();

  isFilterOpen$ = new BehaviorSubject<boolean>(false);

  displayOptions$: Observable<any[]>;

  filters$: Observable<IFieldFilter[]>;

  brightness$: Observable<'light'|'dark'>;

  possibleMetadataFields$: Observable<{ [id: string]: IMetadataField }>;

  columnSearchForm = this.formBuilder.group({
    search: new FormControl(null)
  });;
  columnOptionsForm = this.formBuilder.group({});

  searchInputChanges$ = new BehaviorSubject<any>('');

  subscriptions = new Subscription();

  checkedValues: string[] = [];

  constructor(
    private store: Store<SeacomState>,
    private formBuilder: FormBuilder,
    private popoverController: PopoverController
  ) {
  }

  ngOnInit() {
    // Subscribe to ambiance brightness
    this.brightness$ = this.store.select(selectBrightness);
    
    // Listen for other column filter instance state changes so we can hide ourselves if one of the others displays itself
    this.anotherFilterStatusChange$.subscribe(
      (chg) => {
        this.anotherFilterStatusChange(chg);
      }
    );

    this.filters$ = this.store.select(selectFilterSettingsByCCT(this.container, this.component, this.table));

    this.possibleMetadataFields$ = this.store.select(selectPossibleMetadataFieldsById);

    this.subscriptions.add(this.columnSearchForm.controls['search'].valueChanges.subscribe(this.searchInputChanges$));

    // Take our input options, filter them based on search input field, set our subject for display
    if (this.column.datatype.includes('name')) {
      this.displayOptions$ = this.options$.pipe(searchItemsField(this.searchInputChanges$, 'name'));
    } else if (this.column.datatype === 'user') {
      this.displayOptions$ = this.options$.pipe(searchUsers(this.searchInputChanges$));
    } else if (this.column.datatype === 'metadata') {
      let mdType = this.getMetadataFieldDataType(this.column.column);

      switch (mdType) {
        //'bool' | 'dispatcher' | 'integer' | 'list' | 'location' | 'note' | 'responder' | 'supervisor' | 'text'
        case 'list':
          this.displayOptions$ = this.store.select(selectMetadataListChoicesByField(this.column.column)).pipe(searchItemsField(this.searchInputChanges$, 'choice'));
          break;
        case 'responder':
          this.displayOptions$ = this.store.select(selectUsersByRole(['responder'])).pipe(searchUsers(this.searchInputChanges$));
          break;
        case 'dispatcher':
          this.displayOptions$ = this.store.select(selectUsersByRole(['dispatcher'])).pipe(searchUsers(this.searchInputChanges$));
          break;
        case 'supervisor':
          this.displayOptions$ = this.store.select(selectUsersByRole(['supervisor'])).pipe(searchUsers(this.searchInputChanges$));
          break;
      }
    } else {
      this.displayOptions$ = this.options$.pipe(searchItems(this.searchInputChanges$));
    }

    if (this.column.datatype === 'metadata') { // Metadata column
      let mdType = this.getMetadataFieldDataType(this.column.column);

      switch (mdType) {
        //'bool' | 'dispatcher' | 'integer' | 'list' | 'location' | 'note' | 'responder' | 'supervisor' | 'text'
        case 'bool':
          this.subscriptions.add(this.displayOptions$.pipe(skipNull()).subscribe(
            (ndo) => {
              if (
                ndo !== undefined &&
                ndo !== null
              ) {
                this.filters$.pipe(first()).subscribe(
                  (filters) => {
                    if (filters !== undefined && filters !== null) {
                      let existingFlt = filters.filter(f => f.field === this.column.column);
                      ndo.forEach((o, i) => {
                        if (existingFlt[0].filter.indexOf(o) !== -1) {
                          this.columnOptionsForm.addControl(o, new FormControl(true));
                        } else {
                          this.columnOptionsForm.addControl(o, new FormControl(false));
                        }
                      });
                    } else {
                      ndo.forEach((o, i) => {
                        this.columnOptionsForm.addControl(o, new FormControl(false));
                      });
                    }
                  }
                );
              }
            }
          ));
          break;
        case 'integer':
          this.filters$.pipe(first()).subscribe(
            (filters) => {
              if (filters !== undefined && filters !== null) {
                let existingFlt = filters.filter(f => f.field === this.column.column);
                if (existingFlt.length > 0) {
                  this.columnOptionsForm.addControl('op', new FormControl(existingFlt[0].op));
                  this.columnOptionsForm.addControl('val', new FormControl(existingFlt[0].filter));
                } else {
                  this.columnOptionsForm.addControl('op', new FormControl('eq'));
                  this.columnOptionsForm.addControl('val', new FormControl());
                }
              } else {
                this.columnOptionsForm.addControl('op', new FormControl('eq'));
                this.columnOptionsForm.addControl('val', new FormControl());
              }
            }
          );
          break;
        case 'location':
        case 'note':
        case 'text':
          // These filters are not available.
          break;
        case 'list':
        case 'responder':
        case 'dispatcher':
        case 'supervisor':
          this.subscriptions.add(this.displayOptions$.pipe(skipNull()).subscribe(
            (ndo) => {
              if (
                ndo !== undefined &&
                ndo !== null
              ) {
                this.filters$.pipe(first()).subscribe(
                  (filters) => {
                    if (filters !== undefined && filters !== null) {
                      let existingFlt = filters.filter(f => f.field === this.column.column);
                      ndo.forEach((o, i) => {
                        if (existingFlt.length > 0 && existingFlt[0].filter.indexOf(o.id) !== -1) {
                          this.columnOptionsForm.addControl(o.id, new FormControl(true));
                        } else {
                          this.columnOptionsForm.addControl(o.id, new FormControl(false));
                        }
                      });
                    } else {
                      ndo.forEach((o, i) => {
                        this.columnOptionsForm.addControl(o.id, new FormControl(false));
                      });
                    }
                  }
                );
              }
            }
          ));
          break;
      }

    } else { // NOT a metadata column
      // Add dynamic form controls for checkboxes based on number of options
      this.subscriptions.add(this.displayOptions$.pipe(skipNull()).subscribe(
        (ndo) => {
          if (
            ndo !== undefined &&
            ndo !== null
          ) {
            this.filters$.pipe(first()).subscribe(
              (filters) => {
                if (filters !== undefined && filters !== null) {
                  let existingFlt = filters.filter(f => f.field === this.column.column);
                  if (existingFlt.length > 0) {
                    switch (this.column.datatype) {
                      case 'string':
                      case 'titlestring':
                        ndo.forEach((o, i) => {
                          if (existingFlt[0].filter.indexOf(o) !== -1) {
                            this.columnOptionsForm.addControl(o, new FormControl(true));
                          } else {
                            this.columnOptionsForm.addControl(o, new FormControl(false));
                          }
                        });
                        break;
                      case 'namedstring':
                      case 'namedtitlestring':
                        ndo.forEach((o, i) => {
                          if (existingFlt[0].filter.indexOf(o.id) !== -1) {
                            this.columnOptionsForm.addControl(o.id, new FormControl(true));
                          } else {
                            this.columnOptionsForm.addControl(o.id, new FormControl(false));
                          }
                        });
                        break;
                      case 'datetime':
                        let stime = existingFlt.find(f => f.op === 'gte');
                        let etime = existingFlt.find(f => f.op === 'lte');
                        if (stime !== undefined) {
                          this.columnOptionsForm.addControl('starttime', new FormControl(stime.filter));
                        } else {
                          this.columnOptionsForm.addControl('starttime', new FormControl());
                        }

                        if (etime !== undefined) {
                          this.columnOptionsForm.addControl('endtime', new FormControl(etime.filter));
                        } else {
                          this.columnOptionsForm.addControl('endtime', new FormControl());
                        }
                        break;
                      case 'user':
                        ndo.forEach((o, i) => {
                          if (existingFlt[0].filter.indexOf(o.id) !== -1) {
                            this.columnOptionsForm.addControl(o.id, new FormControl(true));
                          } else {
                            this.columnOptionsForm.addControl(o.id, new FormControl(false));
                          }
                        });
                        break;
                      case 'yesno':
                      case 'truefalse':
                        ndo.forEach((o, i) => {
                          if (existingFlt[0].filter.indexOf(o) !== -1) {
                            this.columnOptionsForm.addControl(o, new FormControl(true));
                          } else {
                            this.columnOptionsForm.addControl(o, new FormControl(false));
                          }
                        });
                        break;
                    }
                  } else {
                    switch (this.column.datatype) {
                      case 'string':
                      case 'titlestring':
                        ndo.forEach((o, i) => {
                          this.columnOptionsForm.addControl(o, new FormControl(false));
                        });
                        break;
                      case 'namedstring':
                      case 'namedtitlestring':
                      case 'user':
                        ndo.forEach((o, i) => {
                          this.columnOptionsForm.addControl(o.id, new FormControl(false));
                        });
                        break;
                      case 'datetime':
                        this.columnOptionsForm.addControl('starttime', new FormControl());
                        this.columnOptionsForm.addControl('endtime', new FormControl());
                        break;
                      case 'yesno':
                      case 'truefalse':
                        ndo.forEach((o, i) => {
                          this.columnOptionsForm.addControl(o, new FormControl(false));
                        });
                        break;
                    }
                  }
                } else {
                  ndo.forEach((o, i) => this.columnOptionsForm.addControl(i.toString(), new FormControl()));
                }
              }
            );
          }
        }
      ));
    }


  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  anotherFilterStatusChange(change: { column: string, status: boolean }) {
    if (change !== undefined && change !== null) {
      if (change.column !== this.column.column && change.status === true && this.isFilterOpen$.value === true) {
        this.popoverController.dismiss();
        this.isFilterOpen$.next(false);
      }
    }
  }

  filterOpened() {
    this.isFilterOpen$.next(true);
    this.filterStatusChange.emit({ column: this.column.column, status: true });
  }

  filterClosed() {
    this.isFilterOpen$.next(false);
    this.filterStatusChange.emit({ column: this.column.column, status: false });
  }

  getMetadataFieldDataType(id: string): 'bool' | 'dispatcher' | 'integer' | 'list' | 'location' | 'note' | 'responder' | 'supervisor' | 'text' {
    let res: 'bool' | 'dispatcher' | 'integer' | 'list' | 'location' | 'note' | 'responder' | 'supervisor' | 'text' = null;

    this.possibleMetadataFields$.pipe(skipNull(), first()).subscribe(
      (mfs) => {
        if (mfs.hasOwnProperty(id)) {
          res = mfs[id].data_type;
        }
      }
    );

    return res;
  }

  applyButton() {
    let newFilters: IFieldFilter[] = [];
    let selected: string[];

    // Collect form data into field filter
    switch (this.column.datatype) {
      case 'string':
      case 'titlestring':
      case 'namedstring':
      case 'namedtitlestring':
      case 'user':
        selected = Object.keys(this.columnOptionsForm.controls).map(k => {
          return {
            val: k,
            checked: this.columnOptionsForm.controls[k].value
          };
        }).filter(i => i.checked === true).map(i => i.val);

        if (selected.length > 0) {
          let newFilter = {
            field: this.column.column,
            op: 'in',
            filter: selected
          } as IFieldFilter;

          newFilters.push(newFilter);
        }
        break;
      case 'datetime':
        let starttime = this.columnOptionsForm.controls['starttime'].value;
        let endtime = this.columnOptionsForm.controls['endtime'].value;
        if(this.columnOptionsForm.controls['endtime'].pristine) {
          endtime = new Date();
        }

        let sTimeFilter = {
          field: this.column.column,
          op: 'gte',
          filter: starttime
        } as IFieldFilter;

        newFilters.push(sTimeFilter);

        let eTimeFilter = {
          field: this.column.column,
          op: 'lte',
          filter: endtime
        } as IFieldFilter;

        newFilters.push(eTimeFilter);
        break;
      case 'truefalse':
      case 'yesno':
        selected = Object.keys(this.columnOptionsForm.controls).map(k => {
          return {
            val: k,
            checked: getBoolean(this.columnOptionsForm.controls[k].value)
          };
        }).filter(i => i.checked === true).map(i => i.val);

        if (selected.length > 0) {
          let newFilter = {
            field: this.column.column,
            op: 'in',
            filter: selected
          } as IFieldFilter;

          newFilters.push(newFilter);
        }
        break;
      case 'metadata':
        switch (this.getMetadataFieldDataType(this.column.column)) {
          case 'location':
          case 'note':
          case 'text':
            // These types are not yet supported.
            break;
          case 'integer':
            let newFilter = {
              field: this.column.column,
              op: this.columnOptionsForm.controls['op'].value,
              filter: Number(this.columnOptionsForm.controls['val'].value)
            } as IFieldFilter;

            newFilters.push(newFilter);
            break;
          case 'bool':
            selected = Object.keys(this.columnOptionsForm.controls).map(k => {
              return {
                val: k,
                checked: getBoolean(this.columnOptionsForm.controls[k].value)
              };
            }).filter(i => i.checked === true).map(i => i.val);
    
            if (selected.length > 0) {
              let newFilter = {
                field: this.column.column,
                op: 'in',
                filter: selected
              } as IFieldFilter;
    
              newFilters.push(newFilter);
            }
            break;
          case 'list':
          case 'responder':
          case 'dispatcher':
          case 'supervisor':
            selected = Object.keys(this.columnOptionsForm.controls).map(k => {
              return {
                val: k,
                checked: this.columnOptionsForm.controls[k].value
              };
            }).filter(i => i.checked === true).map(i => i.val);

            if (selected.length > 0) {
              let newFilter = {
                field: this.column.column,
                op: 'in',
                filter: selected
              } as IFieldFilter;

              newFilters.push(newFilter);
            }
            break;
        }
        break;
    }

    if (newFilters.length > 0) {
      // Emit filters change
      this.replaceFilters.emit({
        container: this.container,
        component: this.component,
        table: this.table,
        newFilters: newFilters
      });

    } else {
      this.removeFilters.emit({
        container: this.container,
        component: this.component,
        table: this.table,
        column: this.column.column
      });
    }

    // Close popover
    this.popoverController.dismiss();
  }

  resetButton() {
    // Dispatch state change
    this.removeFilters.emit({
      container: this.container,
      component: this.component,
      table: this.table,
      column: this.column.column
    });

    // Reset form controls to all be false
    if (this.column.datatype !== 'datetime') {
      Object.keys(this.columnOptionsForm.controls).forEach(k => this.columnOptionsForm.controls[k].setValue(false));
    } else {
      this.columnOptionsForm.controls['starttime'].setValue(null);
      this.columnOptionsForm.controls['endtime'].setValue(null);
    }

    // Close popover
    this.popoverController.dismiss();
  }
}
