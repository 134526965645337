import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IncidentFormComponent } from './incidentform.component';
import { ResponseFormModule } from 'src/app/components/responseform/responseform.module';


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        ResponseFormModule
    ],
    declarations: [
        IncidentFormComponent,
    ],
    exports: [
        IncidentFormComponent,
    ],
    providers: [
        DatePipe
    ]
})

export class IncidentFormModule { }
