// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';

// RXJS
import { Observable } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';

// Interfaces
import { IUser } from 'src/app/interfaces/seacom/user';

// Services
import { SeacomApiService } from 'src/app/services/seacom/seacom-api.service';

// State
import { SeacomState } from 'src/app/store';

// Selectors
import { selectCurrentUser } from 'src/app/store/selectors/auth.selectors';
import { selectBrightness } from 'src/app/store/selectors/ambiance.selectors';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPageComponent implements OnInit, OnDestroy {
  brightness$: Observable<'light'|'dark'>;

  currentUser$: Observable<IUser>;

  constructor(
    public seacomApi: SeacomApiService, 
    private store: Store<SeacomState>,
  ) { }

  ngOnInit() {
    this.brightness$ = this.store.select(selectBrightness);
    
    this.subscribeToCurrentUser();
  }

  ngOnDestroy() {
  }

  subscribeToCurrentUser(){
    this.currentUser$ = this.store.select(selectCurrentUser);
  }
}
