// Angular
import { Injectable } from '@angular/core';

// RXJS
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// Actions
import * as actions from '../actions';

// State
import { SeacomState } from '../reducers';


@Injectable()
export class LocationEffects {
	constructor(
		private actions$: Actions,
		public store: Store<SeacomState>
	) { }

	startLocationTracking$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				actions.fromFooterbarActions.Enter
			),
			switchMap(() => {
				return new Observable(observer => {
					const watchId = navigator.geolocation.watchPosition(
						(position) => {
							observer.next(position);
						},
						(error) => {
							observer.error(error);
						}
					);
		
					return () => {
						navigator.geolocation.clearWatch(watchId);
					}
				});
			}),
			map((position: GeolocationPosition) => {
				const coords: GeolocationCoordinates = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
					altitude: position.coords.altitude,
					accuracy: position.coords.accuracy,
					altitudeAccuracy: position.coords.altitudeAccuracy,
					heading: position.coords.heading,
					speed: position.coords.speed
				};
				return actions.LocationSuccess({ payload: { location: coords } })
			}),
			catchError(error => {
				if (error.code === 1) {
					return of(actions.LocationDisabled({ payload: { error: error } }));
				} else {
					return of(actions.LocationFail({ payload: { error: error } }));
				}
			})
		)
	});

	getWeather$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				actions.LocationSuccess
			),
			map(() => {
				return actions.LoadWeather();
			})
		)
	});
}
