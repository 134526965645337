// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// State
import { SeacomState } from '../reducers';

// Actions
import * as actions from '../actions';

// Selectors
import { selectBeachesLastUpdated } from '../selectors/beach.selectors';

// Services
import { EnvironmentService } from 'src/app/services/environment.service';
import { BeachesService } from 'src/app/services/seacom/beaches.service';



@Injectable()
export class BeachEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private beachesService: BeachesService) { }

  loadBeaches$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Beaches
        ofType(
          actions.LoadBeaches
        ),
        concatLatestFrom((action) => this.store.select(selectBeachesLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.beachesService.getList().pipe(
              map((beaches) => actions.LoadBeachesSuccess({ payload: beaches })),
              catchError((error) => of(actions.LoadBeachesFail(error)))
            );
          } else {
            return of(actions.LoadBeachesCancelled());
          }
        })
      );
  });
  loadBeach$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Beach
        ofType(actions.LoadBeach),
        exhaustMap((action) =>
          this.beachesService.get(action.payload).pipe(
            map((beach) => actions.LoadBeachSuccess({ payload: beach })),
            catchError((error) => of(actions.LoadBeachesFail(error)))
          )
        )
      );
  });
  addBeach$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add Beach
        ofType(actions.AddBeach),
        concatMap((action) =>
          this.beachesService.create(action.payload).pipe(
            map((beach) => actions.AddBeachSuccess({ payload: beach })),
            catchError((error) => of(actions.AddBeachFail(error)))
          )
        )
      );
  });
  updateBeach$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Beach
        ofType(actions.UpdateBeach),
        concatMap((action) =>
          this.beachesService.update(action.payload.id, action.payload).pipe(
            map((beach) => actions.UpdateBeachSuccess({ payload: beach })),
            catchError((error) => of(actions.UpdateBeachFail(error)))
          )
        )
      );
  });
  deleteBeach$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete Beach
        ofType(actions.DeleteBeach),
        mergeMap((action) =>
          this.beachesService.delete(action.payload).pipe(
            map((beachId) => actions.DeleteBeachSuccess({ payload: beachId })),
            catchError((error) => of(actions.DeleteBeachFail(error)))
          )
        )
      )
  });
}
