import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';

import * as actions from '../actions';


export const locationFeatureKey = 'location';

export interface LocationState {
	location: GeolocationCoordinates,
	locationDisabled: boolean,
	lastUpdated: string,
	updating: boolean,
	updated: boolean,
	updateError?: any
}

export const LocationInitialState: LocationState = {
	location: null,
	locationDisabled: false,
	lastUpdated: toLocalISOString(new Date('000000000000')),
	updating: false,
	updated: false,
	updateError: null
};

export const LocationReducer = createReducer(
	LocationInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...LocationInitialState
		} as LocationState;
	}),
	on(actions.UpdateLocation, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false
		} as LocationState;
	}),
	on(actions.LocationSuccess, (state, action) => {
		const loc: GeolocationCoordinates = {
			latitude: action.payload.location.latitude,
			longitude: action.payload.location.longitude,
			altitude: action.payload.location.altitude,
			accuracy: action.payload.location.accuracy,
			altitudeAccuracy: action.payload.location.altitudeAccuracy,
			heading: action.payload.location.heading,
			speed: action.payload.location.speed
		};
		return {
			...state,
			location: loc,
			lastUpdated: toLocalISOString(new Date()),
			updating: false,
			updated: true
		} as LocationState;
	}),
	on(actions.LocationFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload.error,
		} as LocationState;
	}),
	on(actions.LocationDisabled, (state, action) => {
		return {
			...state,
			location: null,
			locationDisabled: true,
			updating: false,
			updated: false,
			updateError: action.payload.error,
		} as LocationState;
	}),
);
