export function searchFactory(object: unknown, search): boolean {
  let searchPassed = false;
  Object.keys(object).forEach(
    (k) => {
      if(typeof(object[k]) === 'string') {
        switch(search.substring(0,1)) {
          case '=': // exact match search
            if(object[k] === search.substring(1)) {
              searchPassed = true;
            }
            break;
          case '^': // starts with search
            if(object[k].indexOf(search.substring(1)) === 0) {
              searchPassed = true;
            }
            break;
          case '@': // full text search
            if(object[k] === search.substring(1)) {
              searchPassed = true;
            }
            break;
          case '$': // regex search
            if(object[k].match(search.substring(1)).length > 0) {
              searchPassed = true;
            }
            break;
          default: // case-insensitive partial match
            if((object[k] as string).toLowerCase().indexOf(search.substring(1).toLowerCase()) !== -1) {
              searchPassed = true;
            }
            break;
        }
      }
    }
  )
  return searchPassed;
}
