// Angular
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Ionic
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

// RXJS
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

// NGRX
import { ActionsSubject, Store } from '@ngrx/store';

// Store
import { LogoutCancel, LogoutConfirm, SeacomState, SetBrightness } from './store';

// Interfaces
import { IIncident } from './interfaces/seacom/incident';

// Services
import { EnvironmentService } from './services/environment.service';
import { DroppableListService } from './services/droppablelist.service';

// Actions
import * as fromActions from './app.actions';

// Selectors
import { selectIsAuthenticated, selectLoggingIn, selectLoggingOut, selectLoginError, selectConfirmingLogout } from './store/selectors/auth.selectors';
import { selectBrightness } from './store/selectors/ambiance.selectors';
import { skipNull } from './common/globals';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  brightness$: Observable<'light' | 'dark'>;

  // Auth observables
  loggingIn$: Observable<boolean>;
  loggingInSub: Subscription;

  loggingOut$: Observable<boolean>;
  confirmingLogout$: Observable<boolean>;
  loggingOutSub: Subscription;

  loginError$: Observable<string>;
  loginErrorSub: Subscription;

  isAuthenticated$: Observable<boolean>;

  // Incident observables
  addIncidentSuccess$: Observable<{ type: string, payload: IIncident }>;
  addIncidentFailure$: Observable<any>;

  // Auth notifications
  loggingInLoadingEl: HTMLIonLoadingElement;
  loggingInLoadingDisplayed$ = new BehaviorSubject<boolean>(null);
  loggingOutLoadingEl: HTMLIonLoadingElement;
  loginErrorToastEl: HTMLIonToastElement | void;

  constructor(
    private ngZone: NgZone,
    public droppableListService: DroppableListService,
    public environmentService: EnvironmentService,
    public platform: Platform,
    public statusBar: StatusBar,
    public splashScreen: SplashScreen,
    private store: Store<SeacomState>,
    private loadingController: LoadingController,
    private toastCtrl: ToastController
  ) { }

  async ngOnInit() {
    console.log('Version:', this.environmentService.version);

    this.ngZone.runOutsideAngular(() => {
      /* Detect when device is in dark or light mode and update ambiance brightness to match */
      const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');

      // Check the initial value of the media query
      if (colorSchemeQuery.matches) {
        // The device is in dark mode
        this.store.dispatch(SetBrightness({ payload: { brightness: 'dark' } }));
      } else {
        // The device is in light mode
        this.store.dispatch(SetBrightness({ payload: { brightness: 'light' } }));
      }

      colorSchemeQuery.addEventListener('change', (event: MediaQueryListEvent) => {
        if (event.matches) {
          // Apply the dark mode color palette
          this.store.dispatch(SetBrightness({ payload: { brightness: 'dark' } }));
        } else {
          // Apply the light mode color palette
          this.store.dispatch(SetBrightness({ payload: { brightness: 'light' } }));
        }
      });
    });

    this.brightness$ = this.store.select(selectBrightness);

    this.store.dispatch(fromActions.Enter());

    this.loggingIn$ = this.store.select(selectLoggingIn);
    this.loggingOut$ = this.store.select(selectLoggingOut);
    this.confirmingLogout$ = this.store.select(selectConfirmingLogout);

    this.isAuthenticated$ = this.store.select(selectIsAuthenticated);
  }

  ngOnDestroy() {
    this.store.dispatch(fromActions.Exit());
  }

  async addIncidentSuccess(incident: IIncident): Promise<void> {
    const toast = await this.toastCtrl.create({
      cssClass: 'success-toast',
      position: 'middle',
      message: 'Incident ' + incident.incident_number + ' has been created successfully.',
      duration: 2500,
    }).then((toast) => toast.present());
  }

  async addIncidentFailure(error: any): Promise<void> {
    console.log('Error while creating incident: ');
    console.log(error);
    const toast = await this.toastCtrl.create({
      position: 'middle',
      message: 'Incident creation failed! Please try again momentarily.',
      // header: 'Saved',
      duration: 3000,
      color: 'danger',
      cssClass: 'failure-toast',
    });
    toast.present();
  }

  confirmLogout(event: MouseEvent) {
    event.stopPropagation();

    this.store.dispatch(LogoutConfirm());
  }

  cancelLogout(event: MouseEvent | undefined = undefined) {
    if (event !== undefined) {
      event.stopPropagation();
    }

    this.store.dispatch(LogoutCancel());
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

    });
  }
}
