import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/authguard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./containers/login/login.page.module').then(m => m.LoginPageModule),
    //canLoad: [AuthService]
  },
  {
    path: 'home',
    loadChildren: () => import('./containers/home/home.page.module').then(m => m.HomePageModule),
    canActivate: [AuthGuardService],
    data: {
      role: [
        'responder',
        'dispatcher',
        'supervisor',
        'org_admin',
        'seacom_admin'
      ]
    }
  },
  {
    path: 'notifications',
    loadChildren: () => import('./containers/notifications/notifications.module').then(m => m.NotificationsPageModule),
    canActivate: [AuthGuardService],
    data: {
      role: [
        'responder',
        'dispatcher',
        'supervisor',
        'org_admin',
        'seacom_admin'
      ]
    }
  },
  {
    path: 'operations',
    loadChildren: () => import('./containers/operations/operations.module').then(m => m.OperationsPageModule),
    canActivate: [AuthGuardService],
    data: {
      role: [
        'dispatcher',
        'supervisor',
        'org_admin',
        'seacom_admin'
      ]
    }
  },
  {
    path: 'feedback',
    loadChildren: () => import('./containers/feedback/feedback.module').then(m => m.FeedbackPageModule),
    canActivate: [AuthGuardService],
    data: {
      role: [
        'responder',
        'dispatcher',
        'supervisor',
        'org_admin',
        'seacom_admin'
      ]
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('./containers/settings/settings.page.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuardService],
    data: {
      preload: true,
      role: [
        'responder',
        'dispatcher',
        'supervisor',
        'org_admin',
        'seacom_admin'
      ]
    },
  },
  {
    path: 'runbook',
    loadChildren: () => import('./containers/runbook/runbook.page.module').then(m => m.RunbookPageModule),
    canActivate: [AuthGuardService],
    data: {
      role: [
        'responder',
        'dispatcher',
        'supervisor',
        'org_admin',
        'seacom_admin'
      ]
    }
  },
  {
    path: 'conditions',
    loadChildren: () => import('./containers/conditions/conditions.page.module').then(m => m.ConditionsPageModule),
    canActivate: [AuthGuardService],
    data: {
      preload: false,
      role: [
        'responder',
        'dispatcher',
        'supervisor',
        'org_admin',
        'seacom_admin'
      ]
    },
  },
  {
    path: 'stations',
    loadChildren: () => import('./containers/stations/stations.page.module').then(m => m.StationsPageModule),
    canActivate: [AuthGuardService],
    data: {
      preload: false,
      role: [
        'responder',
        'dispatcher',
        'supervisor',
        'org_admin',
        'seacom_admin'
      ]
    },
  },
  {
    path: '**',
    redirectTo: 'login'
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
