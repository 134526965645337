// Angular CDK
import { moveItemInArray } from '@angular/cdk/drag-drop';

// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Interfaces
import { IItemEntity } from 'src/app/interfaces/itementity';
import { IStation } from 'src/app/interfaces/seacom/station';
import { IStationSetting } from 'src/app/interfaces/seacom/stationsetting';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { IIncident } from 'src/app/interfaces/seacom/incident';

// Reducers
import * as fromReducers from '../reducers/station.reducers';

// Selectors
import { selectAllStationSettings } from './stationsetting.selectors';
import { selectFilterSettingsByCCT, selectSearchSettingByCCT, selectTableRowExpansionByCCT } from './table.selectors';
import { selectIncidentsByStations } from 'src/app/store/selectors/incident.selectors';

// Utilities
import { sortFactory } from '../utils/sort.utils';
import { searchFactory } from '../utils/search.utils';
import { filterObject } from '../utils/filter.utils';
import { selectLatestLocation } from './location.selectors';


function sortStationsBySettings(stations: IStation[], settings: IStationSetting[]): IStation[] {
  let res = Array.from(stations);

  // Sort by name first
  res = res.sort((a, b) => sortFactory(a, b, [{ field: 'name', ascending: true }]));

  // Sort by station setting next
  settings.forEach(ss => {
    let stIdx = res.findIndex(st => st.id === ss.station.id);
    if (stIdx !== -1) {
      let st = [];
      if (ss.after === null && stIdx !== 0) {
        moveItemInArray(res, stIdx, 0);
        /* st = res.splice(stIdx, 1);
        res.splice(0, 0, st[0]); */
      } else if (
        (ss.after !== null && stIdx > 0 && res[stIdx - 1].id !== ss.after) ||
        (ss.after !== null && stIdx === 0)
      ) {
        let neighIdx = res.findIndex(s => s.id === ss.after);
        if (neighIdx !== -1) {

          /* st = res.splice(stIdx, 1);*/
          if (stIdx < neighIdx) {
            moveItemInArray(res, stIdx, neighIdx);
            /* res.splice(neighIdx, 0, st[0]); */
          } else {
            /* res.splice(neighIdx+1, 0, st[0]); */
            moveItemInArray(res, stIdx, neighIdx + 1);
          }
        }
      }
    }
  });

  return res;
}

export const selectStationState = createFeatureSelector<fromReducers.StationState>(
  fromReducers.stationFeatureKey
);

export const selectStationEntities = createSelector(
  selectStationState,
  (state: fromReducers.StationState): IItemEntity<IStation> => state.entities
);

export const selectStationsLastUpdated = createSelector(
  selectStationState,
  (state: fromReducers.StationState): string => state.lastUpdated
);

export const selectStationsLoading = createSelector(
  selectStationState,
  (state: fromReducers.StationState): boolean => state.loading
);

export const selectStationsCount = createSelector(
  selectStationState,
  (state: fromReducers.StationState): number => Object.keys(state.entities).length
);

export const selectAllStations = createSelector(
  selectStationState,
  (state: fromReducers.StationState): IStation[] => {
    return Object.keys(state.entities).map(i => state.entities[i]);
  }
);

export const selectAllStationsInOrder = createSelector(
  selectAllStations,
  selectAllStationSettings,
  (stations: IStation[], stationSettings: IStationSetting[]): IStation[] => {
    if (stations !== undefined && stations !== null && stations.length > 0) {
      return sortStationsBySettings(stations, stationSettings);
    } else {
      return [];
    }
  }
);

export const selectStationsCountByStatus = (status: string) => createSelector(
  selectAllStations,
  (stations: IStation[]): number => stations.filter(s => s.status === status).length
);

export const selectStationById = (id: string) => createSelector(selectStationState, (stations): IStation => {
  return stations?.entities[id];
});

export const selectStationsByIds = (ids: string[]) => createSelector(
  selectAllStations,
  selectAllStationSettings,
  (stations: IStation[], stationSettings: IStationSetting[]): IStation[] => {
    return sortStationsBySettings(stations.filter(s => ids.indexOf(s.id) !== -1), stationSettings);
  });

export const selectStationsWithLocation = createSelector(
  selectAllStations,
  (stations: IStation[]): IStation[] => {
    return stations.filter(s => s.location !== undefined && s.location !== null);
  });

export const selectStationTableData = (container: string, component: string, table: string) => createSelector(
  selectSearchSettingByCCT(container, component, table),
  selectFilterSettingsByCCT(container, component, table),
  selectAllStationSettings,
  selectAllStations,
  (searchSetting: string, filterSettings: IFieldFilter[], stationSettings: IStationSetting[], stations: IStation[]): IStation[] => {
    let res = Array.from(stations);

    res = sortStationsBySettings(res, stationSettings);

    // search or filter
    res = res.filter(i => {
      if (searchSetting !== null) {
        return searchFactory(i, searchSetting);
      } else {
        return filterObject(i, filterSettings);
      }
    });

    // sort
    return res;
  }
);

export const selectExpandedStationRows = (container: string, component: string, table: string) => createSelector(
  selectTableRowExpansionByCCT(container, component, table),
  selectAllStations,
  (rowsExpanded: { [id: string]: boolean }, stations: IStation[]): { [id: string]: boolean } => {
    return stations.reduce(
      (entities: { [id: string]: boolean }, item: IStation) => {
        if (rowsExpanded.hasOwnProperty(item.id)) {
          entities[item.id] = rowsExpanded[item.id];
        } else {
          entities[item.id] = false;
        }

        return entities;
      },
      {}
    ) as { [id: string]: boolean };
  }
)

export const selectStationTableDataWithIncidents = (container: string, component: string, table: string) => createSelector(
  selectStationTableData(container, component, table),
  selectIncidentsByStations,
  (tableData: IStation[], incidents: { [id: string]: IIncident[] }): IStation[] => {
    let res = Array.from(tableData);
    return res.map(r => {
      if (incidents.hasOwnProperty(r.id)) {
        return {
          ...r,
          incidents: incidents[r.id]
        };
      } else {
        return {
          ...r,
          incidents: []
        };
      }
    });
  }
);

export const selectStationsWithinDistance = (distance: number) => createSelector(
  selectAllStations,
  selectLatestLocation,
  (stations: IStation[], coordinates: GeolocationCoordinates): IStation[] => {
    const EARTH_RADIUS_IN_METERS = 6371000;

    if (coordinates !== undefined && coordinates !== null) {
      const { latitude, longitude } = coordinates;

      // Convert the latitude and longitude of the given position to radians
      const radians = {
        latitude: latitude * Math.PI / 180,
        longitude: longitude * Math.PI / 180,
      };

      // Calculate the distance between the given position and each object
      const distances = stations.map(object => {
        if (object.location !== undefined && object.location !== null) {
          const [objectLatitude, objectLongitude] = object.location.split(',').map(coordinate => parseFloat(coordinate));

          // Convert the object's latitude and longitude to radians
          const objectRadians = {
            latitude: objectLatitude * Math.PI / 180,
            longitude: objectLongitude * Math.PI / 180,
          };

          const distanceInRadians = Math.acos(
            Math.sin(radians.latitude) * Math.sin(objectRadians.latitude)
            + Math.cos(radians.latitude) * Math.cos(objectRadians.latitude)
            * Math.cos(objectRadians.longitude - radians.longitude)
          );

          return distanceInRadians * EARTH_RADIUS_IN_METERS;
        } else {
          return undefined;
        }
      });

      // Sort the objects by their distance to the given position
      return stations
        .map((object, index) => ({ object, distance: distances[index] }))
        .filter(item => item.distance <= distance && item.distance !== undefined)
        .sort((a, b) => a.distance - b.distance)
        .map(item => item.object);
    } else {
      return [];
    }
  }
);

export const selectStationsByDistance = createSelector(
  selectAllStations,
  selectLatestLocation,
  (stations: IStation[], coordinates: GeolocationCoordinates): IStation[] => {
    const EARTH_RADIUS_IN_METERS = 6371000;

    if (coordinates !== undefined && coordinates !== null) {
      const { latitude, longitude } = coordinates;

      // Convert the latitude and longitude of the given position to radians
      const radians = {
        latitude: latitude * Math.PI / 180,
        longitude: longitude * Math.PI / 180,
      };

      // Calculate the distance between the given position and each object
      const distances = stations.map(object => {
        if (object.location !== undefined && object.location !== null) {
          const [objectLatitude, objectLongitude] = object.location.split(',').map(coordinate => parseFloat(coordinate));

          // Convert the object's latitude and longitude to radians
          const objectRadians = {
            latitude: objectLatitude * Math.PI / 180,
            longitude: objectLongitude * Math.PI / 180,
          };

          const distanceInRadians = Math.acos(
            Math.sin(radians.latitude) * Math.sin(objectRadians.latitude)
            + Math.cos(radians.latitude) * Math.cos(objectRadians.latitude)
            * Math.cos(objectRadians.longitude - radians.longitude)
          );

          return distanceInRadians * EARTH_RADIUS_IN_METERS;
        } else {
          return undefined;
        }
      });

      // Sort the objects by their distance to the given position
      return stations
        .map((object, index) => ({ object, distance: distances[index] }))
        .filter(item => item.distance !== undefined)
        .sort((a, b) => a.distance - b.distance)
        .map(item => item.object);
    } else {
      return [];
    }
  }
);
