import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers/location.reducers';

export const selectLocationState = createFeatureSelector<fromReducers.LocationState>(
  fromReducers.locationFeatureKey
);

export const selectLocationsLastUpdated = createSelector(
  selectLocationState,
  (state: fromReducers.LocationState): string => state.lastUpdated
);

export const selectLocationDisabled = createSelector(
  selectLocationState,
  (state: fromReducers.LocationState): boolean => state.locationDisabled
);

export const selectLatestLocation = createSelector(
  selectLocationState,
  (state: fromReducers.LocationState): GeolocationCoordinates => state.location
);

export const selectLatestLocationString = createSelector(
  selectLatestLocation,
  (location: GeolocationCoordinates): string => {
      if(
        location !== null &&
        location !== undefined &&
        location.latitude !== null &&
        location.latitude !== undefined &&
        location.longitude !== null &&
        location.longitude !== undefined
      ) {
        return location.latitude.toFixed(6) + ', ' + location.longitude.toFixed(6);
      } else {
        return null;
      }
    }
);
