// Angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// Ionic
import { ToastController } from '@ionic/angular';

// Ngrx
import { Store } from '@ngrx/store';

// RXJS
import { Observable } from 'rxjs';

// Globals
import { skipNull } from 'src/app/common/globals';

// Interfaces
import { INotification } from '../../interfaces/seacom/notification';
import { IUser } from 'src/app/interfaces/seacom/user';
import { ICurrentWeather } from 'src/app/interfaces/weather/currentweather';
import { IHourlyWeatherForecastDetail } from 'src/app/interfaces/weather/hourlyweatherforecastdetail';
import { IIncidentType } from 'src/app/interfaces/seacom/incidenttype';
import { IWeatherLocation } from 'src/app/interfaces/weather/weatherlocation';
import { IIncident } from 'src/app/interfaces/seacom/incident';
import { IWidget } from 'src/app/interfaces/widget';

// State
import { SeacomState } from 'src/app/store';

// Selectors
import { selectCurrentUser } from 'src/app/store/selectors/auth.selectors';
import { selectLatestLocation } from 'src/app/store/selectors/location.selectors';
import {
  selectActiveIncidentsCount,
  selectIncidentsLastUpdated,
  selectIncidentsLoading,
  selectOpenIncidentsCount,
  selectOpenIncidentsCountBySeverity
} from 'src/app/store/selectors/incident.selectors';
import {
  selectNotificationsByStatus,
  selectNotificationsCountByStatus,
  selectNotificationsLastUpdated,
  selectNotificationsLoading
} from 'src/app/store/selectors/notification.selectors';
import {
  selectCurrentWeather,
  selectWeatherForecastByHour,
  selectWeatherLoading,
  selectWeatherLocation
} from 'src/app/store/selectors/weather.selectors';
import {
  selectClockedInStatus,
  selectDailyHours,
  selectTimeclocksLoading,
  selectWeeklyHours
} from 'src/app/store/selectors/timeclock.selectors';
import { selectLocationDisabled } from '../../store/selectors/location.selectors';
import { selectBrightness } from 'src/app/store/selectors/ambiance.selectors';
import { selectIncidentTypeByName } from '../../store/selectors/incidenttype.selectors';
import { selectStationsCount, selectStationsCountByStatus, selectStationsLoading } from '../../store/selectors/station.selectors';
import { selectDisplayedWidgets } from './store/home.page.selectors';

// Actions
import * as fromActions from './store/home.page.actions';


@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
  brightness$: Observable<'light'|'dark'>;

  currentUser$: Observable<IUser>;

  // widgets
  public displayedWidgets$: Observable<IWidget[]>;

  // notifications
  public newNotifications$: Observable<INotification[]>;
  public newNotificationsCount$: Observable<number>;
  public notificationsLastUpdated$: Observable<string>;
  public notificationsLoading$: Observable<boolean>;

  // location
  public locationDisabled$: Observable<boolean>;
  public currentLocation$: Observable<GeolocationCoordinates>;

  // weather
  public currentWeather$: Observable<ICurrentWeather>;
  public weatherLocation$: Observable<IWeatherLocation>;
  public upcomingWeather$: Observable<IHourlyWeatherForecastDetail>;
  public upcomingWeatherTime: Date;
  public weatherLoading$: Observable<boolean>;

  // Hours
  public weeklyHours$: Observable<number>;
  public dailyHours$: Observable<number>;

  // Timeclock
  public clockedIn$: Observable<boolean>;
  public timeclockLoading$: Observable<boolean>;

  // incidents
  public incidentsLoading$: Observable<boolean>;
  public activeIncidentsCount$: Observable<number>;
  public incidentsLastUpdated$: Observable<string>;
  public openIncidentsCount$: Observable<number>;
  public openIncidentCountsBySeverity$: Observable<{ name: string, count: number, color: string, bgcolor: string }[]>;
  public createIncidentSuccess$: Observable<IIncident>;
  public createIncidentFailure$: Observable<any>;

  // incident type
  public emergencyIncidentType$: Observable<IIncidentType>;

  // stations
  public stationsLoading$: Observable<boolean>;
  public inServiceStationsCount$: Observable<number>;
  public outOfServiceStationsCount$: Observable<number>;
  public totalStationsCount$: Observable<number>;

  constructor(
    private store: Store<SeacomState>,
    public router: Router,
    public toastController: ToastController,
  ) { }

  statusMessage: string;

  ngOnInit() {
    this.store.dispatch(fromActions.Enter());

    this.brightness$ = this.store.select(selectBrightness);

    // Current user
    this.currentUser$ = this.store.select(selectCurrentUser);

    // Display widgets
    this.displayedWidgets$ = this.store.select(selectDisplayedWidgets);

    this.subscribeToData();
  }

  ngOnDestroy() {
    this.store.dispatch(fromActions.Exit());
  }

  private subscribeToData(): void {
    // TODO: Create selector that provides my personal incident count instead of requiring params
    // Subscriptions
    this.currentUser$.subscribe(
      (user) => {
        if (user !== undefined && user !== null &&
          user.role !== undefined && user.role !== null &&
          user.role.role !== undefined && user.role.role !== null &&
          user.role.role.name !== undefined && user.role.role.name !== null
        ) {
          // My Incident count
          this.activeIncidentsCount$ = this.store.select(selectActiveIncidentsCount);
        }
      }
    );

    // Incidents Loading
    this.incidentsLoading$ = this.store.select(selectIncidentsLoading);

    // Incidents last updated
    this.incidentsLastUpdated$ = this.store.select(selectIncidentsLastUpdated).pipe(skipNull());

    // Emergency Incident Type
    this.emergencyIncidentType$ = this.store.select(selectIncidentTypeByName('emergency'));

    // Open Incidents
    this.openIncidentsCount$ = this.store.select(selectOpenIncidentsCount).pipe(skipNull());
    this.openIncidentCountsBySeverity$ = this.store.select(selectOpenIncidentsCountBySeverity).pipe(skipNull());

    // Notifications loading
    this.notificationsLoading$ = this.store.select(selectNotificationsLoading);

    // New Notification counts
    this.newNotificationsCount$ = this.store.select(selectNotificationsCountByStatus('new')).pipe(skipNull());

    // New Notification list
    this.newNotifications$ = this.store.select(selectNotificationsByStatus('new')).pipe(skipNull());

    // Notifications last updated
    this.notificationsLastUpdated$ = this.store.select(selectNotificationsLastUpdated).pipe(skipNull());

    // Stations Loading
    this.stationsLoading$ = this.store.select(selectStationsLoading);

    // Station Counts
    this.totalStationsCount$ = this.store.select(selectStationsCount).pipe(skipNull());
    this.inServiceStationsCount$ = this.store.select(selectStationsCountByStatus('in_service')).pipe(skipNull());
    this.outOfServiceStationsCount$ = this.store.select(selectStationsCountByStatus('out_of_service')).pipe(skipNull());

    this.locationDisabled$ = this.store.select(selectLocationDisabled);
    
    // Current location
    this.currentLocation$ = this.store.select(selectLatestLocation).pipe(skipNull());

    // Weather loading
    this.weatherLoading$ = this.store.select(selectWeatherLoading);

    // Current weather
    this.currentWeather$ = this.store.select(selectCurrentWeather).pipe(skipNull());

    // Weather location
    this.weatherLocation$ = this.store.select(selectWeatherLocation).pipe(skipNull());

    // upcoming (in 2 hrs) weather forecast
    this.upcomingWeatherTime = new Date((new Date().getTime()) + (2 * 60 * 60 * 1000)) // 2 hrs
    this.upcomingWeather$ = this.store.select(selectWeatherForecastByHour(this.upcomingWeatherTime)).pipe(skipNull());

    // Timeclock loading
    this.timeclockLoading$ = this.store.select(selectTimeclocksLoading)

    // Current Daily Hours
    this.dailyHours$ = this.store.select(selectDailyHours);

    // Current Weekly Hours
    this.weeklyHours$ = this.store.select(selectWeeklyHours);

    // Clocked in status
    this.clockedIn$ = this.store.select(selectClockedInStatus);

  }

  hideWidget(widget: string) {
    this.store.dispatch(fromActions.HideWidget({ payload: { widgetName: widget } }));
  }

  // Clock in
  clockIn() {
    this.store.dispatch(fromActions.TimeclockClockIn({ payload: { type: 'work', start: new Date() } }));
  }

  async clockInFailure(err: any): Promise<void> {
    console.log('Error while clocking in: ');
    console.log(err);
    const toast = await this.toastController.create({
      message: 'Clock in failed! Please try again momentarily.',
      duration: 2000,
      cssClass: 'digim-toast-failure',
      position: 'top'
    });
    toast.present();
  }

  // Clock out
  clockOut() {
    this.store.dispatch(fromActions.TimeclockClockOut({ payload: { end: new Date() } }));
  }

  async clockOutFailure(err: any): Promise<void> {
    console.log('Error while clocking out: ');
    console.log(err);
    const toast = await this.toastController.create({
      message: 'Clock out failed! Please try again momentarily.',
      duration: 2000,
      cssClass: 'digim-toast-failure',
      position: 'top'
    });
    toast.present();
  }

  // Incident creation
  createEmergencyIncident() {
    this.store.dispatch(fromActions.CreateEmergencyIncident());
  }

  navigateToRunbook() {
    this.store.dispatch(fromActions.NavigateToRunbookPage());
  }

  navigateToConditions() {
    this.store.dispatch(fromActions.NavigateToConditionsPage());
  }

  navigateToStations() {
    this.store.dispatch(fromActions.NavigateToStationsPage());
  }

  navigateToNotifications() {
    this.store.dispatch(fromActions.NavigateToNotificationsPage());
  }

  navigateToOperations() {
    this.store.dispatch(fromActions.NavigateToOperationsPage());
  }
}
