// Angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RXJS
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';

// Interfaces
import { IIncidentResponse, INewIncidentResponse } from 'src/app/interfaces/seacom/incidentresponse';
import { IIncidentType } from 'src/app/interfaces/seacom/incidenttype';
import { IUser } from 'src/app/interfaces/seacom/user';
import { IControlBase } from '../../interfaces/controlbase';
import { IStation } from '../../interfaces/seacom/station';


@Component({
  selector: 'app-response-form',
  templateUrl: './responseform.component.html',
  styleUrls: ['./responseform.component.scss']
})
export class ResponseFormComponent implements OnInit {
  @Input() brightness$: Observable<'light'|'dark'>;
  @Input('response') response: IIncidentResponse|INewIncidentResponse;
  @Input('formGroup') formGroup!: FormGroup;
  @Input('metadataControls') metadataControls: IControlBase[];
  @Input('incidentTypes') incidentTypes: IIncidentType[];
  @Input('stations') stations: IStation[];
  @Input('responders') responders: IUser[];
  @Input('dispatchers') dispatchers: IUser[];
  @Input('supervisors') supervisors: IUser[];

  @Output() showDateTimePopEvent = new EventEmitter<{ fieldName: string }>();
  @Output() changeTypeEvent = new EventEmitter<string>(); // new incident response type id
  @Output() deleteResponseEvent = new EventEmitter<string>(); // incidentResponse.id

  timerSubscription: Subscription;
  timer$ = new BehaviorSubject<string>('00:00'); // used for displayable timer
  time: number = 0;

  currentResponseStyle$ = new BehaviorSubject<{ color?: string, background?: string }>({});

  constructor() { }

  ngOnInit() {
    // Only want to start the timer if the response is still in progress
    if(
      (this.response.endtime === null || this.response.endtime === undefined) && 
      (this.response.starttime !== undefined && this.response.starttime !== null) && 
      this.response.status === 'inprogress') {
      this.startTimer();
    } else if(
      (this.response.endtime !== undefined && this.response.endtime !== null) && 
      (this.response.starttime !== undefined && this.response.starttime !== null)) {
      this.updateTimer();
    }
  }

  updateTimer() {
    if (this.formGroup.controls['starttime'].value !== undefined && this.formGroup.controls['starttime'].value !== null) {
      if(this.formGroup.controls['endtime'].value !== undefined && this.formGroup.controls['endtime'].value !== null) {
        let startDate = new Date(this.formGroup.controls['starttime'].value);
        let endDate = new Date(this.formGroup.controls['endtime'].value);

        this.time = Math.round((endDate.getTime() - startDate.getTime()) / 1000);
      } else {
        let startDate = new Date(this.formGroup.controls['starttime'].value);

        this.time = Math.round(((new Date()).getTime() - startDate.getTime()) / 1000);
      }

      let hour = Math.floor(this.time / 3600);
      let minute = Math.floor((this.time - (hour * 3600)) / 60);
      let second = this.time - (hour * 3600) - (minute * 60);

      let newTime =
        (hour > 0 ? hour.toString() + ':' : '') +
          minute.toString().padStart(2, '0') + ':' +
          second.toString().padStart(2, '0');

      this.timer$.next(newTime);
    }
  }

  updateResponseStyle() {
    let curType = this.incidentTypes.find(it => it.id === this.formGroup.controls['type'].value);
    if(curType !== undefined) {
      if (curType.time_monitoring !== undefined && curType.time_monitoring) {
        if (this.time >= curType.critical_time) {
          this.currentResponseStyle$.next({
            color: curType.critical_color,
            background: curType.critical_bgcolor
          });
        } else if (this.time >= curType.warning_time) {
          this.currentResponseStyle$.next({
            color: curType.warning_color,
            background: curType.warning_bgcolor
          });
        } else {
          this.currentResponseStyle$.next({});
        }
      } else {
        this.currentResponseStyle$.next({});
      }
    } else {
      this.currentResponseStyle$.next({});
    }
  }

  startTimer() {
    try {
      this.timerSubscription.unsubscribe();
    } catch { }

    this.timerSubscription = interval(1000).subscribe(
      () => {
        //********Runs once a second!********/
        this.updateTimer();

        this.updateResponseStyle();
      }
    );
  }

  stopTimer() {
    try {
      this.timerSubscription.unsubscribe();
    } catch { }
    this.timer$.next('00:00');
    this.time = 0;

    this.updateResponseStyle();
  }

  editResponseStartDate() {
    this.showDateTimePopEvent.emit({ fieldName: 'starttime' });
  }

  editResponseEndDate() {
    this.showDateTimePopEvent.emit({ fieldName: 'endtime' });
  }

}
