// Angular
import { Injectable } from '@angular/core';

// RXJS
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Globals
import { skipNull } from 'src/app/common/globals';

// Store
import { SeacomState } from '../reducers';

// Actions
import * as actions from '../actions';

// Selectors
import { selectLatestLocation, selectLocationDisabled } from '../selectors/location.selectors';

// Services
import { WeatherService } from '../../services/weather.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { selectWeatherLastUpdated } from '../selectors/weather.selectors';


@Injectable()
export class WeatherEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private weatherService: WeatherService
  ) { }

  getWeatherForecast$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.LoadWeather
        ),
        withLatestFrom(
          this.store.select(selectLocationDisabled),
          this.store.select(selectLatestLocation),
          this.store.select(selectWeatherLastUpdated),
        ),
        switchMap(([action, locationDisabled, location, lastUpdated]) => {
          if (!locationDisabled) {
            let now = (new Date());
            let lu = (new Date(lastUpdated));

            if (location === null || location.latitude === null || location.longitude === null) {
              return of(actions.LoadWeatherFail({ error: 'No Location' }));
            }

            const loc: GeolocationCoordinates = {
              latitude: location.latitude,
              longitude: location.longitude,
              altitude: location.altitude,
              accuracy: location.accuracy,
              altitudeAccuracy: location.altitudeAccuracy,
              heading: location.heading,
              speed: location.speed
            };

            if (now.getTime() - lu.getTime() > this.environmentService.weatherApiCacheTime) {
              return this.weatherService.getDailyWeatherForecast(loc, 10).pipe(
                map((result) => actions.LoadWeatherSuccess({ location: result.location, current: result.current, forecast: result.forecast })),
                catchError((error) => of(actions.LoadWeatherFail({ error })))
              );
            } else {
              return of(actions.LoadWeatherCancelled());
            }
          } else {
            return of(actions.LoadWeatherCancelled());
          }
        })
      )
  });
}
