import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IContainerSetting } from 'src/app/interfaces/seacom/containersetting';
import * as fromReducers from '../reducers/containersetting.reducers';
import { sortFactory } from '../utils/sort.utils';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { filterObject } from '../utils/filter.utils';
import { searchFactory } from '../utils/search.utils';

export const selectContainerSettingState = createFeatureSelector<fromReducers.ContainerSettingState>(
  fromReducers.containerSettingFeatureKey
);

export const selectContainerSettingById = (id: string) => createSelector(selectContainerSettingState, (containersettings): IContainerSetting => {
  return containersettings?.entities[id];
});

export const selectContainerSettingsCount = createSelector(
  selectContainerSettingState,
  (state: fromReducers.ContainerSettingState): number => Object.keys(state.entities).length
);

export const selectContainerSettingsLastUpdated = createSelector(
  selectContainerSettingState,
  (state: fromReducers.ContainerSettingState): string => state.lastUpdated
);

export const selectContainerSettingsLoading = createSelector(
  selectContainerSettingState,
  (state: fromReducers.ContainerSettingState): boolean => state.loading
);

export const selectAllContainerSettings = createSelector(
  selectContainerSettingsCount,
  selectContainerSettingState,
  (count: number, state: fromReducers.ContainerSettingState): IContainerSetting[] => {
    if(count > 0) {
      return Object.keys(state.entities).map(i => state.entities[i])
        .sort((a, b) => sortFactory(a, b, [{field: 'container', ascending: true}]));
    } else {
      return [];
    }
  }
);

export const selectContainerSettingsWithFilter = (filters?: IFieldFilter[], order?: string, search?: string) => createSelector(selectAllContainerSettings, (containersettings): IContainerSetting[] => {
  let res = containersettings;
  console.log('filters:');
  console.log(filters);

  if (filters !== undefined && filters !== null) {
    res = res.filter(i => filterObject(i, filters));
  }

  if (search !== undefined && search !== null) {
    res = res.filter(i => searchFactory(i, search));
  }

  if (order !== undefined && order !== null) {
    let sortAsc = true;
    if (order.substring(0, 1) === '-') {
      sortAsc = false;
    }
    let sortField = order.replace('-', '').replace('+', '');
    return res.sort((a, b) => sortFactory(a, b, [{field: sortField, ascending: sortAsc}]));
  } else {
    // Sort descending by starttime by default
    return res.sort((a, b) => sortFactory(a, b, [{field: 'container', ascending: true}]));
  }
});

export const selectContainerSettingsByContainer = (container: string) => createSelector(selectAllContainerSettings, (containersettings): IContainerSetting[] => {
  if(containersettings.length > 0) {
    return containersettings.filter(cs => cs.container === container).sort((a, b) => sortFactory(a, b, [{field: 'component', ascending: true}]));
  } else {
    return [];
  }
});

export const selectContainerSettingsByContainerAndComponent = (container: string, component: string) => createSelector(selectContainerSettingsByContainer(container), (containersettings): IContainerSetting[] => {
  if(containersettings.length > 0) {
    return containersettings.filter(cs => cs.component === component).sort((a, b) => sortFactory(a, b, [{field: 'setting', ascending: true}]));
  } else {
    return [];
  }
});

export const selectContainerSettingByContainerComponentAndSetting = (container: string, component: string, setting: string) => createSelector(selectContainerSettingsByContainerAndComponent(container, component), (containersettings): IContainerSetting => {
  if(containersettings.length > 0) {
    let res = containersettings.filter(cs => cs.setting === setting);
    if(res !== undefined && res.length > 0) {
      return res[0];
    } else {
      return null;
    }
  } else {
    return null;
  }
});
