import { createAction, props } from '@ngrx/store';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { IContainerSetting, INewContainerSetting, IUpdatedContainerSetting } from '../..//interfaces/seacom/containersetting';

// columnSetting action types
export const LOAD_CONTAINERSETTINGS = '[Page Effects] Load ContainerSettings';
export const LOAD_CONTAINERSETTINGS_CANCELLED = '[ContainerSetting Effects] Load ContainerSettings Cancelled';
export const LOAD_CONTAINERSETTINGS_WITH_FILTERS = '[SEACOM] Load ContainerSettings With Filters';
export const LOAD_CONTAINERSETTINGS_FAIL = '[ContainerSetting Effects] Load ContainerSettings Fail';
export const LOAD_CONTAINERSETTINGS_SUCCESS = '[ContainerSetting Effects] Load ContainerSettings Success';
export const LOAD_CONTAINERSETTING = '[Component Effects] Load ContainerSetting';
export const LOAD_CONTAINERSETTING_FAIL = '[ContainerSetting Effects] Load ContainerSetting Fail';
export const LOAD_CONTAINERSETTING_SUCCESS = '[ContainerSetting Effects] Load ContainerSetting Success';
export const UPDATE_CONTAINERSETTING = '[SEACOM] Update ContainerSetting';
export const UPDATE_CONTAINERSETTING_VALUE = '[SEACOM] Update ContainerSetting Value';
export const UPDATE_CONTAINERSETTING_FIELDS = '[SEACOM] Update ContainerSetting Fields';
export const UPDATE_CONTAINERSETTING_FAIL = '[ContainerSetting Effects] Update ContainerSetting Fail';
export const UPDATE_CONTAINERSETTING_SUCCESS = '[ContainerSetting Effects] Update ContainerSetting Success';
export const ADD_CONTAINERSETTING = '[SEACOM] Add ContainerSetting';
export const ADD_CONTAINERSETTING_FAIL = '[ContainerSetting Effects] Add ContainerSetting Fail';
export const ADD_CONTAINERSETTING_SUCCESS = '[ContainerSetting Effects] Add ContainerSetting Success';
export const DELETE_CONTAINERSETTING = '[SEACOM] Delete ContainerSetting';
export const DELETE_CONTAINERSETTING_FAIL = '[ContainerSetting Effects] Delete ContainerSetting Fail';
export const DELETE_CONTAINERSETTING_SUCCESS = '[ContainerSetting Effects] Delete ContainerSetting Success';

export const LoadContainerSettings = createAction(
	LOAD_CONTAINERSETTINGS
);

export const LoadContainerSettingsCancelled = createAction(
	LOAD_CONTAINERSETTINGS_CANCELLED
);

export const LoadContainerSettingsWithFilters = createAction(
    LOAD_CONTAINERSETTINGS_WITH_FILTERS,
    props<{
        filters?: IFieldFilter[],
        order?: string,
        search?: string
    }>()
);

export const LoadContainerSettingsSuccess = createAction(
    LOAD_CONTAINERSETTINGS_SUCCESS,
    props<{
        payload: IContainerSetting[]
    }>()
);

export const LoadContainerSettingsFail = createAction(
    LOAD_CONTAINERSETTINGS_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadContainerSetting = createAction(
	LOAD_CONTAINERSETTING,
    props<{
        payload: string // ID
    }>()
);

export const LoadContainerSettingSuccess = createAction(
    LOAD_CONTAINERSETTING_SUCCESS,
    props<{
        payload: IContainerSetting
    }>()
);

export const LoadContainerSettingFail = createAction(
    LOAD_CONTAINERSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateContainerSetting = createAction(
    UPDATE_CONTAINERSETTING,
    props<{
        payload: IUpdatedContainerSetting
    }>()
);

export const UpdateContainerSettingValue = createAction(
    UPDATE_CONTAINERSETTING_VALUE,
    props<{
        payload: {
            id: string,
            newValue: string
        }
    }>()
);

export const UpdateContainerSettingFields = createAction(
    UPDATE_CONTAINERSETTING_FIELDS,
    props<{
        payload: IUpdatedContainerSetting
    }>()
);

export const UpdateContainerSettingSuccess = createAction(
    UPDATE_CONTAINERSETTING_SUCCESS,
    props<{
        payload: IContainerSetting
    }>()
);

export const UpdateContainerSettingFail = createAction(
    UPDATE_CONTAINERSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const AddContainerSetting = createAction(
    ADD_CONTAINERSETTING,
    props<{
        payload: INewContainerSetting
    }>()
);

export const AddContainerSettingSuccess = createAction(
    ADD_CONTAINERSETTING_SUCCESS,
    props<{
        payload: IContainerSetting
    }>()
);

export const AddContainerSettingFail = createAction(
    ADD_CONTAINERSETTING_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteContainerSetting = createAction(
    DELETE_CONTAINERSETTING,
    props<{
        payload: string // ID
    }>()
);

export const DeleteContainerSettingSuccess = createAction(
    DELETE_CONTAINERSETTING_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteContainerSettingFail = createAction(
    DELETE_CONTAINERSETTING_FAIL,
    props<{
        payload: any
    }>()
);
