// Angular
import { Injectable } from '@angular/core';

// RXJS
import { map } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../../../store/actions';

// State
import { SeacomState } from '../../../store/reducers';

// Services
import { IncidentResponsesService } from 'src/app/services/seacom/incidentresponses.service';

@Injectable()
export class IncidentFormEffects {

  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private incidentResponsesService: IncidentResponsesService
  ) { }

  enter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromIncidentFormActions.Enter
        ),
        map(() => {
          this.store.dispatch(actions.LoadRegions());
        }),
        map(() => {
          this.store.dispatch(actions.LoadDistricts());
        }),
        map(() => {
          this.store.dispatch(actions.LoadBeaches());
        }),
        map(() => {
          this.store.dispatch(actions.LoadStations());
        }),
        map(() => {
          this.store.dispatch(actions.LoadUsers());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidents());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidentSeverities());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidentTypes());
        }),
        map(() => {
          this.store.dispatch(actions.LoadMetadatas());
        }),
        map(() => {
          this.store.dispatch(actions.LoadIncidentResponses());
        })
      )
  },
  { dispatch: false });

  // Incident operations
  createIncidentFormIncident$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromIncidentFormActions.CreateIncidentFormIncident
        ),
        map((action) => actions.AddIncident({ payload: action.payload }))
      );
  });

  updateIncidentFormIncidentFields$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromIncidentFormActions.UpdateIncidentFormIncidentFields
        ),
        map((action) => actions.UpdateIncidentFields({ payload: action.payload }))
      );
  });

  // Response operations
  createIncidentFormResponse$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromIncidentFormActions.CreateIncidentFormResponse
        ),
        map((action) => actions.AddIncidentResponse({ payload: action.payload }))
      );
  });

  updateIncidentFormResponse$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromIncidentFormActions.UpdateIncidentFormResponse
        ),
        map((action) => actions.UpdateIncidentResponseFields({ payload: action.payload }))
      );
  });

  deleteIncidentFormResponse$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromIncidentFormActions.DeleteIncidentFormResponse
        ),
        map((action) => actions.DeleteIncidentResponse({ payload: action.payload }))
      );
  });

  // Metadata operations
  createMetadata$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromIncidentFormActions.CreateMetadata
        ),
        map((action) => actions.AddMetadata({ payload: action.payload }))
      );
  });

  updateMetadata$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromIncidentFormActions.UpdateMetadata
        ),
        map((action) => actions.UpdateMetadataFields({ payload: action.payload }))
      );
  });
}