// Angular
import { Component, OnInit, OnDestroy, Directive, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

// Angular Material
import {
    CdkDragDrop,
    moveItemInArray,
} from '@angular/cdk/drag-drop';

// RXJS
import { Observable, of, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Globals
import { selectItemsField, skipNull } from 'src/app/common/globals';

// Store
import { SeacomState } from 'src/app/store';

// Interfaces
import { IIncident, INewIncident } from 'src/app/interfaces/seacom/incident';
import { IIncidentType } from 'src/app/interfaces/seacom/incidenttype';
import { IIncidentSeverity } from 'src/app/interfaces/seacom/incidentseverity';
import { IMetadata } from 'src/app/interfaces/seacom/metadata';
import { IIncidentResponse, INewIncidentResponse } from 'src/app/interfaces/seacom/incidentresponse';
import { IRegion } from 'src/app/interfaces/seacom/region';
import { IDistrict } from 'src/app/interfaces/seacom/district';
import { IBeach } from 'src/app/interfaces/seacom/beach';
import { IStation } from '../../interfaces/seacom/station';
import { IUser } from 'src/app/interfaces/seacom/user';
import { IColumnSetting } from 'src/app/interfaces/seacom/columnsetting';
import { IStationSet } from 'src/app/interfaces/stationset';

// Selectors
import * as fromSelectors from './store/operations.page.selectors';
import { selectAllIncidents, selectIncidentsLoading, selectIncidentTableData, selectLatestIncidentByStation } from '../../store/selectors/incident.selectors';
import { selectAllRegions } from 'src/app/store/selectors/region.selectors';
import { selectAllDistricts } from 'src/app/store/selectors/district.selectors';
import { selectAllBeaches } from 'src/app/store/selectors/beach.selectors';
import { selectAllStations, selectStationsLoading, selectStationTableData } from 'src/app/store/selectors/station.selectors';
import { selectAllUsers, selectUserById, selectUsersByRole } from 'src/app/store/selectors/user.selectors';
import { selectAllIncidentTypes, selectIncidentTypesByUsage } from 'src/app/store/selectors/incidenttype.selectors';
import { selectAllIncidentSeverities } from 'src/app/store/selectors/incidentseverity.selectors';
import { selectCurrentUser } from 'src/app/store/selectors/auth.selectors';
import { selectLatestIncidentResponseByStation } from '../../store/selectors/incidentresponse.selectors';
import { selectOperationsIncidentsListPinnedStatus } from './store/operations.page.selectors';
import { selectDisplayedOrderedColumnSettingNamesByCCT, selectDisplayedOrderedColumnSettingsByCCT, selectOrderedColumnSettingsByCCT } from 'src/app/store/selectors/table.selectors';

// Actions
import * as fromActions from './store/operations.page.actions';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { IMetadataListChoice } from 'src/app/interfaces/seacom/metadatalistchoice';
import { selectMetadataListChoicesById } from '../../store/selectors/incidenttype.selectors';
import { selectBrightness } from 'src/app/store/selectors/ambiance.selectors';



@Component({
    selector: 'app-operationsview',
    templateUrl: './operations.page.html',
    styleUrls: ['./operations.page.scss'],
})
export class OperationsPage implements OnInit, OnDestroy {
    @ViewChild('OperationsView') operationsView: ElementRef;
    @ViewChild('SwipeInButtons') swipeInButtons: ElementRef;
    @ViewChild('IncidentsList') incidentsList: ElementRef;
    @ViewChild('StationsList') stationsList: ElementRef;
    @ViewChild('MapViewContainer') mapView: ElementRef;
    @ViewChild('PinnedIncidentsList') pinnedIncidentsList: ElementRef;

    // Brightness
    brightness$: Observable<'light' | 'dark'>;

    // Slideables
    stationsListExpanded$: Observable<boolean>;
    incidentsListExpanded$: Observable<boolean>;
    mapExpanded$: Observable<boolean>;

    // Popovers
    transferPopoverOpen$: Observable<boolean>;
    transferringStation$: Observable<IStation>;
    quickIncidentPopoverOpen$: Observable<boolean>;

    // Incident Form
    incidentFormOpen$: Observable<boolean>;
    incidentFormIncidentId$: Observable<string>;

    // Stations
    stationsLoading$: Observable<boolean>;
    stations$: Observable<IStation[]>;
    stationsSet$: Observable<IStationSet[]>;
    expandedStation$: Observable<string>;

    // Stations List
    stationsListColumnNames$: Observable<string[]>;
    stationsListColumns$: Observable<IColumnSetting[]>;
    displayedStationsListColumns$: Observable<IColumnSetting[]>;
    stationsColumnFilterStatus$ = new Subject<{ column: string, status: boolean }>();

    // Incidents
    incidentsLoading$: Observable<boolean>;
    incidentTypes$: Observable<IIncidentType[]>;
    responseIncidentTypes$: Observable<IIncidentType[]>;
    incidentSeverities$: Observable<IIncidentSeverity[]>;
    latestIncidentsByStation$: Observable<{ [id: string]: IIncident }>;
    latestIncidentResponseByStation$: Observable<{ [id: string]: IIncidentResponse }>;

    // Incidents List
    incidents$: Observable<IIncident[]>;
    incidentTableData$: Observable<IIncident[]>;
    incidentListColumnNames$: Observable<string[]>;
    incidentListColumns$: Observable<IColumnSetting[]>;
    displayedIncidentListColumns$: Observable<IColumnSetting[]>;
    incidentsListPinned$: Observable<boolean>;
    incidentsColumnFilterStatus$ = new Subject<{ column: string, status: boolean }>();

    // MetadataListChoices
    metadataListChoicesById$: Observable<{ [id: string]: IMetadataListChoice }>;

    // Regions
    regions$: Observable<IRegion[]>;

    // Districts
    districts$: Observable<IDistrict[]>;

    // Beaches
    beaches$: Observable<IBeach[]>;

    // Current user
    currentUser$: Observable<IUser>;

    // Other users
    users$: Observable<IUser[]>;
    responders$: Observable<IUser[]>;
    dispatchers$: Observable<IUser[]>;
    supervisors$: Observable<IUser[]>;

    // Forms
    selectedStationControl = new FormControl('');
    selectedQITypeControl = new FormControl('');

    // Incidents List
    currentIncidentsListPage = 0;

    // Stations List
    stationTableData$: Observable<IStation[]>;
    currentStationsListPage = 0;

    // Last Expanded Slide-in
    lastExpandedSwipeIn$: Observable<null | 'map' | 'incidents' | 'stations'>;

    constructor(
        private store: Store<SeacomState>,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        // Dispatch Operations Page Enter action (triggers data load)
        this.store.dispatch(fromActions.Enter());

        // Brightness
        this.brightness$ = this.store.select(selectBrightness);

        // Station Tile related
        this.stationsSet$ = this.store.select(fromSelectors.selectStationSet);

        // Stations Loading
        this.stationsLoading$ = this.store.select(selectStationsLoading)

        // Stations List related
        this.expandedStation$ = this.store.select(fromSelectors.selectExpandedStation);
        this.stationTableData$ = this.store.select(selectStationTableData('operations', 'stations', 'stations'));

        // Incident form related
        this.incidentFormOpen$ = this.store.select(fromSelectors.selectIncidentFormOpen);
        this.incidentFormIncidentId$ = this.store.select(fromSelectors.selectIncidentFormIncidentId);

        // Expandables
        this.stationsListExpanded$ = this.store.select(fromSelectors.selectStationsListExpanded);
        this.incidentsListExpanded$ = this.store.select(fromSelectors.selectIncidentsListExpanded);
        this.mapExpanded$ = this.store.select(fromSelectors.selectMapExpanded);
        this.lastExpandedSwipeIn$ = this.store.select(fromSelectors.selectLastExpandedSwipeIn);

        // Incidents list related
        this.incidents$ = this.store.select(selectAllIncidents);
        this.incidentTableData$ = this.store.select(selectIncidentTableData('operations', 'incidents', 'incidents'));

        // Metadata related
        this.metadataListChoicesById$ = this.store.select(selectMetadataListChoicesById);

        // Popovers
        this.transferPopoverOpen$ = this.store.select(fromSelectors.selectTransferPopoverOpen);
        this.transferringStation$ = this.store.select(fromSelectors.selectTransferringStation);
        this.quickIncidentPopoverOpen$ = this.store.select(fromSelectors.selectQuickIncidentChoicePopoverOpen);

        // Contextuals
        this.regions$ = this.store.select(selectAllRegions);
        this.districts$ = this.store.select(selectAllDistricts);
        this.beaches$ = this.store.select(selectAllBeaches);

        // Subscribe to other data
        this.subscribeToCurrentUser();
        this.subscribeToStationsListColumns();
        this.subscribeToStations();
        this.subscribeToIncidentsListColumns();
        this.subscribeToIncidents();
        this.subscribeToIncidentTypes();
        this.subscribeToUsers();
        this.subscribeToResponders();
        this.subscribeToDispatchers();
        this.subscribeToSupervisors();
    }

    ngOnDestroy() {
    }

    subscribeToIncidentsListColumns() {
        // Subscribe to incident list columns
        this.displayedIncidentListColumns$ = this.store.select(selectDisplayedOrderedColumnSettingsByCCT('operations', 'incidents', 'incidents')).pipe(skipNull());
        this.incidentListColumns$ = this.store.select(selectOrderedColumnSettingsByCCT('operations', 'incidents', 'incidents'));
        this.incidentListColumnNames$ = this.store.select(selectDisplayedOrderedColumnSettingNamesByCCT('operations', 'incidents', 'incidents')).pipe(skipNull());
    }

    subscribeToIncidents() {
        this.incidentsLoading$ = this.store.select(selectIncidentsLoading);
        this.incidentsListPinned$ = this.store.select(selectOperationsIncidentsListPinnedStatus());
        this.incidentSeverities$ = this.store.select(selectAllIncidentSeverities);
        this.latestIncidentsByStation$ = this.store.select(selectLatestIncidentByStation(['responding']));
        this.latestIncidentResponseByStation$ = this.store.select(selectLatestIncidentResponseByStation(['inprogress']));
    }

    subscribeToStationsListColumns() {
        // Subscribe to stations list columns
        this.displayedStationsListColumns$ = this.store.select(selectDisplayedOrderedColumnSettingsByCCT('operations', 'stations', 'stations')).pipe(skipNull());
        this.stationsListColumns$ = this.store.select(selectOrderedColumnSettingsByCCT('operations', 'stations', 'stations'));
        this.stationsListColumnNames$ = this.store.select(selectDisplayedOrderedColumnSettingNamesByCCT('operations', 'stations', 'stations')).pipe(skipNull());
    }

    subscribeToStations() {
        this.stations$ = this.store.select(selectAllStations);
    }

    subscribeToCurrentUser() {
        this.currentUser$ = this.store.select(selectCurrentUser);
    }

    subscribeToUsers() {
        this.users$ = this.store.select(selectAllUsers).pipe(skipNull());
    }

    subscribeToResponders() {
        this.responders$ = this.store.select(selectUsersByRole(['responder'])).pipe(skipNull());
    }

    subscribeToDispatchers() {
        this.dispatchers$ = this.store.select(selectUsersByRole(['dispatcher'])).pipe(skipNull());
    }

    subscribeToSupervisors() {
        this.supervisors$ = this.store.select(selectUsersByRole(['supervisor'])).pipe(skipNull());
    }

    subscribeToIncidentTypes() {
        this.incidentTypes$ = this.store.select(selectAllIncidentTypes).pipe(skipNull());
        this.responseIncidentTypes$ = this.store.select(selectIncidentTypesByUsage('response'));
    }

    // Toggle side displays
    toggleStationsList(event: MouseEvent) {
        if(event.cancelable) {
            event.stopPropagation();
        }
        this.store.dispatch(fromActions.ToggleStationsList());
    }
    toggleIncidentsList(event: MouseEvent) {
        if(event.cancelable) {
            event.stopPropagation();
        }
        this.store.dispatch(fromActions.ToggleIncidentsList());
    }
    toggleMap(event: MouseEvent) {
        if(event.cancelable) {
            event.stopPropagation();
        }
        this.store.dispatch(fromActions.ToggleMap());
    }

    openIncidentForm(incidentId: string) {
        console.log("Opening incident form for incident id: " + incidentId);
        this.store.dispatch(fromActions.DisplayIncidentForm({ payload: { incidentId: incidentId } }));
    }

    closeIncidentForm() {
        this.store.dispatch(fromActions.CloseIncidentForm());
    }

    filterListedIncidentsByStation(sid: string) {
        this.store.dispatch(fromActions.FilterIncidentsListByStation({ payload: { stationId: sid } }));
    }

    addQuickIncident(data: { stationId: string, quickIncident: INewIncident, firstResponse: INewIncidentResponse }) {
        this.store.dispatch(fromActions.AddQuickIncident({
            payload: {
                stationId: data.stationId,
                quickIncident: data.quickIncident,
                firstResponse: data.firstResponse
            }
        }));
    }

    addIncidentResponse(newIncidentResponse: INewIncidentResponse) {
        this.store.dispatch(fromActions.AddStationIncidentResponse({
            payload: {
                incidentResponse: newIncidentResponse
            }
        }));
    }

    endIncidentResponse(stationId: string) {
        this.store.dispatch(fromActions.EndStationResponse({ payload: { stationId: stationId } }));
    }

    updateStationResponders(event: { stationId: string, responders: string[] }) {
        this.store.dispatch(fromActions.UpdateStationResponders({
            payload: {
                stationId: event.stationId,
                responders: event.responders
            }
        }));
    }

    displayQuickIncidentOptions(sourceStationId: string) {
        this.store.dispatch(fromActions.DisplayQuickIncidentChoicePopover({
            payload: {
                stationId: sourceStationId
            }
        }));
    }

    setStationStatus(event: { stationId: string, status: 'in_service' | 'out_of_service' }) {
        this.store.dispatch(fromActions.SetStationStatus({ payload: { stationId: event.stationId, status: event.status } }));
    }

    toggleStationExpansion(stationId: string) {
        this.store.dispatch(fromActions.ToggleStationExpansion({ payload: { stationId: stationId } }));
    }

    focusMapOnIncident(incidentId: string) {
        this.store.dispatch(fromActions.FocusMapOnIncident({ payload: { incidentId: incidentId } }));
    }

    openIncidentTransferPopover(stationId: string) {
        this.store.dispatch(fromActions.OpenIncidentTransferPopover({
            payload: {
                stationId: stationId
            }
        }));
    }

    confirmTransfer(event) {
        event.stopPropagation();
        this.store.dispatch(fromActions.TransferIncidentToStation({
            payload: {
                destinationStationId: this.selectedStationControl.value
            }
        }));
    }

    cancelTransfer(event) {
        this.store.dispatch(fromActions.CancelStationIncidentTransfer());
    }

    confirmQuickIncident(event) {
        event.stopPropagation();
        this.store.dispatch(fromActions.ConfirmQuickIncidentChoice({
            payload: {
                incidentTypeId: this.selectedQITypeControl.value
            }
        }));
    }
    cancelQuickIncident(event) {
        event.stopPropagation();
        this.store.dispatch(fromActions.CancelQuickIncidentChoice());
    }
    stationsListPageChange(newPage: number) {
        this.currentStationsListPage = newPage;
    }
    incidentsListPageChange(newPage: number) {
        this.currentIncidentsListPage = newPage;
    }

    pinIncidentsList() {
        this.store.dispatch(fromActions.PinIncidentsList());
    }

    unpinIncidentsList() {
        this.store.dispatch(fromActions.UnpinIncidentsList());
    }

    floatingContainerStyle(mapExpanded: boolean, incidentsListExpanded: boolean, stationsListExpanded: boolean, incidentsListPinned: boolean, lastExpandedSwipeIn: null | 'map' | 'incidents' | 'stations'): { [klass: string]: any } {
        //console.log("LE: " + lastExpandedSwipeIn + " OV: " + (this.operationsView?.nativeElement?.offsetWidth ? this.operationsView.nativeElement.offsetWidth : 0) + " SIB: " + (this.swipeInButtons?.nativeElement?.offsetWidth ? this.swipeInButtons.nativeElement.offsetWidth : 0) + " Map: " + (mapExpanded === true ? Math.max((this.mapView?.nativeElement?.offsetWidth ? this.mapView.nativeElement.offsetWidth : 0), 180) : 0) + " Pinned Incidents: " + (this.pinnedIncidentsList?.nativeElement?.offsetWidth ? this.pinnedIncidentsList.nativeElement.offsetWidth : 0) + " Incidents: " + (this.incidentsList?.nativeElement?.offsetWidth ? this.incidentsList.nativeElement.offsetWidth : 0) + " Stations: " + (this.stationsList?.nativeElement?.offsetWidth ? this.stationsList.nativeElement.offsetWidth : 0));
        if (this.operationsView?.nativeElement?.offsetWidth && this.swipeInButtons?.nativeElement?.offsetWidth &&
            (
                (mapExpanded === true ? Math.max((this.mapView?.nativeElement?.offsetWidth ? this.mapView.nativeElement.offsetWidth : 0), 180) : 0) +
                (this.incidentsList?.nativeElement?.offsetWidth ? this.incidentsList.nativeElement.offsetWidth : 0) +
                (this.pinnedIncidentsList?.nativeElement?.offsetWidth ? this.pinnedIncidentsList.nativeElement.offsetWidth : 0) +
                (this.stationsList?.nativeElement?.offsetWidth ? this.stationsList.nativeElement.offsetWidth : 0)
            ) >
            (
                this.operationsView.nativeElement.offsetWidth -
                this.swipeInButtons.nativeElement.offsetWidth
            )
        ) {
            // Not enough room with everything to fit in the horizontal viewing space
            if (
                (mapExpanded === false || lastExpandedSwipeIn === 'map') &&
                (stationsListExpanded === false || lastExpandedSwipeIn === 'stations') &&
                incidentsListPinned === true
            ) {
                this.store.dispatch(fromActions.UnpinIncidentsList());
            } else {
                if (mapExpanded === true && lastExpandedSwipeIn !== 'map') {
                    this.store.dispatch(fromActions.HideMap());
                }
                if (incidentsListExpanded === true && lastExpandedSwipeIn !== 'incidents') {
                    this.store.dispatch(fromActions.HideIncidentsList());
                }
                if (stationsListExpanded === true && lastExpandedSwipeIn !== 'stations') {
                    this.store.dispatch(fromActions.HideStationsList());
                }    
            }
        }

        let res = {} as { [klass: string]: any };

        if(this.operationsView?.nativeElement?.offsetWidth) {
            res['max-width'] = this.operationsView.nativeElement.offsetWidth - (this.pinnedIncidentsList?.nativeElement?.offsetWidth ? this.pinnedIncidentsList.nativeElement.offsetWidth : 0) + 'px';
        }

        if (mapExpanded !== undefined && mapExpanded !== null && mapExpanded === true) {
            res['width'] = '100%';
        }

        if (this.pinnedIncidentsList?.nativeElement?.offsetWidth && incidentsListPinned === true) {
            res['right'] = this.pinnedIncidentsList.nativeElement.offsetWidth + 'px';
        } else {
            res['right'] = '0px';
        }

        return res;
    }

    mapViewStyle(mapExpanded: boolean, incidentsListExpanded: boolean, stationsListExpanded: boolean, incidentsListPinned: boolean, lastExpandedSwipeIn: null | 'map' | 'incidents' | 'stations'): { [klass: string]: any } {
        let style: { [klass: string]: any } = {};
        if (this.operationsView?.nativeElement?.offsetWidth) {
            let newWidth = this.operationsView?.nativeElement?.offsetWidth - (
                (this.swipeInButtons?.nativeElement?.offsetWidth ? this.swipeInButtons.nativeElement.offsetWidth : 0) +
                (incidentsListExpanded === true && this.incidentsList?.nativeElement?.offsetWidth ? this.incidentsList.nativeElement.offsetWidth : 0) +
                (incidentsListPinned === true && this.pinnedIncidentsList?.nativeElement?.offsetWidth ? this.pinnedIncidentsList.nativeElement.offsetWidth : 0) +
                (stationsListExpanded === true && this.stationsList?.nativeElement?.offsetWidth ? this.stationsList.nativeElement.offsetWidth : 0)
            );
            style['width'] = newWidth + 'px';
        }
        return style;
    }

    incidentsListStyle(mapExpanded: boolean, incidentsListExpanded: boolean, stationsListExpanded: boolean, incidentsListPinned: boolean, lastExpandedSwipeIn: null | 'map' | 'incidents' | 'stations'): { [klass: string]: any } {
        let style: { [klass: string]: any } = {};
        if (this.operationsView?.nativeElement?.offsetWidth && this.swipeInButtons?.nativeElement?.offsetWidth) {
            let newMaxWidth = this.operationsView?.nativeElement?.offsetWidth - (this.swipeInButtons?.nativeElement?.offsetWidth ? this.swipeInButtons.nativeElement.offsetWidth : 0);
            style['max-width'] = newMaxWidth + 'px';
        }
        return style;
    }

    stationsListStyle(mapExpanded: boolean, incidentsListExpanded: boolean, stationsListExpanded: boolean, incidentsListPinned: boolean, lastExpandedSwipeIn: null | 'map' | 'incidents' | 'stations'): { [klass: string]: any } {
        let style: { [klass: string]: any } = {};
        if (this.operationsView?.nativeElement?.offsetWidth && this.swipeInButtons?.nativeElement?.offsetWidth) {
            let newMaxWidth = this.operationsView?.nativeElement?.offsetWidth - (this.swipeInButtons?.nativeElement?.offsetWidth ? this.swipeInButtons.nativeElement.offsetWidth : 0);
            style['max-width'] = newMaxWidth + 'px';
        }
        return style;
    }


    getIncidentMetadataById(incident: IIncident, id: string): IMetadata {
        if (incident.metadata !== undefined && incident.metadata !== null) {
            let res = incident.metadata.find(m => m.field.id === id);
            if (res !== undefined) {
                return res;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    getUserById(id: string): Observable<IUser> {
        return this.store.select(selectUserById(id));
    }

    getMetadataListChoiceById(id: string): string | null {
        let res = null;

        //console.log(id);
        this.metadataListChoicesById$.pipe(first()).subscribe((lcs) => {
            if (lcs !== undefined && lcs !== null) {
                if (lcs.hasOwnProperty(id)) {
                    res = lcs[id].choice;
                }
            }
        });

        return res;
    }

    getOptions(table: string, column: IColumnSetting): Observable<any[]> {
        switch (table) {
            case 'incidents':
                switch (column.column) {
                    case 'incident_number':
                        return this.incidents$.pipe(selectItemsField('incident_number'));
                    case 'type':
                        return this.incidentTypes$;
                    case 'severity':
                        return this.incidentSeverities$;
                    case 'status':
                        return of([
                            'new',
                            'dispatching',
                            'pending',
                            'en route',
                            'responding',
                            'resolved',
                            'closed'
                        ]);
                    case 'source':
                        return of([
                            'public',
                            'responder',
                            'dispatcher',
                            'supervisor',
                            'org_admin',
                            'seacom_admin'
                        ]);
                    case 'location':
                        return of([]);
                    case 'region':
                        return this.regions$;
                    case 'district':
                        return this.districts$;
                    case 'beach':
                        return this.beaches$;
                    case 'station':
                        return this.stations$;
                    case 'primary_responder':
                    case 'secondary_responder':
                    case 'tertiary_responder':
                        return this.responders$;
                    case 'created':
                    case 'last_modified':
                    case 'starttime':
                    case 'dispatchtime':
                    case 'pendingtime':
                    case 'enroutetime':
                    case 'respondingtime':
                    case 'resolvedtime':
                    case 'closuretime':
                        return of([]);
                    case 'review':
                    case 'archive':
                        return of([true, false] as boolean[]);
                    case 'created_by':
                    case 'last_modified_by':
                        return this.users$;
                    default:
                        return of([]);
                }
                break;
            case 'stations':
                switch (column.column) {
                    case 'name':
                        return this.stations$.pipe(selectItemsField('name'));
                    case 'region':
                        return this.regions$;
                    case 'district':
                        return this.districts$;
                    case 'beach':
                        return this.beaches$;
                    case 'mobility':
                        return of(['stationary', 'mobile']);
                    case 'status':
                        return of(['in_service', 'out_of_service']);
                    case 'created':
                    case 'last_modified':
                        return of([]);
                    case 'created_by':
                    case 'last_modified_by':
                        return this.users$;
                    default:
                        return of([]);
                }
                break;
            default:
                return of([]);
        }
    }

    columnFilterStatusChange(table: string, change: { column: string, status: boolean }) {
        switch (table) {
            case 'incidents':
                this.incidentsColumnFilterStatus$.next(change);
                break;
            case 'stations':
                this.stationsColumnFilterStatus$.next(change);
                break;
        }
    }

    onStationListRowDrop(event: CdkDragDrop<string[]>) {
        // Swap the elements around
        this.stationTableData$.pipe(skipNull(), first()).subscribe(
            (std) => {
                if (std !== undefined && std !== null) {
                    let newOrder = Array.from(std).map(s => s.id);
                    moveItemInArray(newOrder, event.previousIndex, event.currentIndex);
                    this.store.dispatch(fromActions.UpdateStationOrder({
                        payload: {
                            newOrder: newOrder,
                            from: event.previousIndex,
                            to: event.currentIndex
                        }
                    }));
                }
            }
        );
    }

    replaceFilters(event: {
        container: string,
        component: string,
        table: string,
        newFilters: IFieldFilter[]
    }) {
        this.store.dispatch(fromActions.ReplaceColumnFilters({
            payload: {
                ...event
            }
        }));
    }

    removeFilters(event: {
        container: string,
        component: string,
        table: string,
        column: string
    }) {
        this.store.dispatch(fromActions.RemoveColumnFilters({
            payload: {
                ...event
            }
        }));
    }

    clearIncidentFilters() {
        this.store.dispatch(fromActions.RevertIncidentsListFilters());
    }

    clearStationFilters() {
        this.store.dispatch(fromActions.RevertStationsListFilters());
    }
}


@Directive({
    selector: '[appVerticalScroll]'
})
export class VerticalScrollDirective {
    constructor(private element: ElementRef) { }

    @HostListener('wheel', ['$event'])
    public onScroll(event: WheelEvent) {
        console.log('onScroll', event);
        this.element.nativeElement.scrollTop += event.deltaY;
    }
}
