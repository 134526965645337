import { createReducer, on } from '@ngrx/store';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { IItemEntity } from 'src/app/interfaces/itementity';
import { IColumnSetting, INewColumnSetting } from 'src/app/interfaces/seacom/columnsetting';

import * as actions from '../actions';
import { addItem, deleteItem, itemArray, updateItem, updateItems } from '../utils/itementity';
import { ISortField } from 'src/app/interfaces/sortfield';
import { getNewColumnOrder, orderColumnSettings } from '../utils/columnsettings.utils';
import { toLocalISOString } from 'src/app/common/globals';


export const tableFeatureKey = 'tables';

export interface TableState {
	columnSettings: {
		entities: IItemEntity<IColumnSetting>,
		lastUpdated: string,
		loading: boolean,
		loaded: boolean,
		loadError?: any,
		updating: boolean,
		updated: boolean,
		updateError?: any,
		adding: boolean,
		added: boolean,
		addError?: any,
		deleting: boolean,
		deleted: boolean,
		deleteError?: any
	},
	columnSelectorOpen: {
		entities: IItemEntity<boolean> // id is 'container:component:table'
	},
	rowExpanded: {
		entities: IItemEntity<{ entityId: string, expanded: boolean }[]>
	},
	filterSettings: {
		entities: IItemEntity<IFieldFilter[]> // id is 'container:component:table'
	},
	searchSettings: {
		entities: IItemEntity<string> // id is 'container:component:table', value is search value
	}
	sortSettings: {
		entities: IItemEntity<ISortField[]> // id is 'container:component:table', value is sort order (with one or more column names)
	}
}

export const TableInitialState: TableState = {
	columnSettings: {
		entities: {},
		lastUpdated: toLocalISOString(new Date('000000000000')),
		loading: false,
		loaded: false,
		loadError: null,
		updating: false,
		updated: false,
		updateError: null,
		adding: false,
		added: false,
		addError: null,
		deleting: false,
		deleted: false,
		deleteError: null
	},
	columnSelectorOpen: {
		entities: {} // id is 'container:component:table'
	},
	rowExpanded: {
		entities: {}
	},
	filterSettings: {
		entities: {}, // id is 'container:component:table'
	},
	searchSettings: {
		entities: {},
	},
	sortSettings: {
		entities: {
			['incidents:incidents:incidents']: [{
				field: 'incident_number',
				ascending: false
			}],
			['dispatcher:incidents:incidents']: [{
				field: 'incident_number',
				ascending: false
			}],
			['stations:stations:incidents']: [{
				field: 'incident_number',
				ascending: false
			}]
		}, // id is 'container:component:table', value is array of sort fields in order
	}
};

function findColumnSetting(csetting: IColumnSetting | INewColumnSetting, entities: IItemEntity<IColumnSetting>): string {
	let arr = itemArray(entities);

	arr = arr.filter(cs => cs.container === csetting.container && cs.component === csetting.component && cs.table === csetting.table && cs.column === csetting.column);

	if (arr.length > 0) {
		return arr[0].id;
	} else {
		return null;
	}
}

function updateColumnSettingsItems(items: IColumnSetting[], entities: IItemEntity<IColumnSetting>): IItemEntity<IColumnSetting> {
	let newEntities = { ...entities };
	for (let cs of items) {
		let ecs = findColumnSetting(cs, newEntities);
		if (ecs !== null) {
			delete newEntities[ecs];
		}
	}
	return updateItems(items, newEntities);
}

export const TableReducer = createReducer(
	TableInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...TableInitialState
		} as TableState;
	}),
	/*
	*
	*
	* Table Column Settings
	*
	*
	*/
	on(actions.LoadColumnSettings, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				loading: true,
				loaded: false,
				loadError: null
			}
		} as TableState;
	}),
	on(actions.LoadColumnSettingsCancelled, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				loading: false,
				loaded: true
			}
		} as TableState;
	}),
	on(actions.LoadColumnSettingsWithFilters, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				loading: true,
				loaded: false,
				loadError: null
			}
		} as TableState;
	}),
	on(actions.LoadColumnSettingsSuccess, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				entities: updateColumnSettingsItems(action.payload, state.columnSettings.entities),
				lastUpdated: toLocalISOString(new Date()),
				loading: false,
				loaded: true
			}
		} as TableState;
	}),
	on(actions.LoadColumnSettingsFail, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				loading: false,
				loaded: false,
				loadError: action.payload,
			}
		} as TableState;
	}),

	// Load ColumnSetting
	on(actions.LoadColumnSetting, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				loading: true
			}
		} as TableState;
	}),
	on(actions.UpdateMetadataFieldsSuccess, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				loading: true
			}
		} as TableState;
	}),
	on(actions.AddMetadataSuccess, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				loading: true
			}
		} as TableState;
	}),
	on(actions.LoadColumnSettingSuccess, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				entities: updateItem(action.payload, state.columnSettings.entities),
				loading: false,
				loaded: true
			}
		} as TableState;
	}),
	on(actions.LoadColumnSettingFail, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				loading: false,
				loaded: false,
				loadError: action.payload
			}
		} as TableState;
	}),

	// Update ColumnSetting
	on(actions.UpdateColumnSetting, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				updating: true,
				updated: false,
				updateError: null
			}
		};
	}),
	on(actions.UpdateColumnSettingSuccess, (state, action) => {
		let csid = findColumnSetting(action.payload, state.columnSettings.entities);
		let newEntities = { ...state.columnSettings.entities };
		if (csid !== null) {
			newEntities = deleteItem(csid, newEntities);
		}

		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				entities: updateItem(action.payload, newEntities),
				updating: false,
				updated: true,
				updateError: null
			}
		};
	}),
	on(actions.UpdateColumnSettingFail, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				updating: false,
				updated: false,
				updateError: action.payload
			}
		};
	}),

	// Update ColumnSetting Fields
	on(actions.UpdateColumnSettingFields, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				updating: true,
				updated: false,
				updateError: null
			}
		};
	}),

	// Add ColumnSetting
	on(actions.AddColumnSetting, (state, action) => {
		let newItem = { ...action.payload } as INewColumnSetting;
		if (!newItem.hasOwnProperty('id')) {
			newItem.id = Math.floor(Math.random() * Math.random()).toString();
		}
		let csid = findColumnSetting(action.payload, state.columnSettings.entities);
		let newEntities = { ...state.columnSettings.entities };
		if (csid !== null) {
			let updItem = {
				...newItem,
				d: 'IColumnSetting',
				id: csid,
				organization: {
					...state.columnSettings.entities[csid].organization
				},
				user: {
					...state.columnSettings.entities[csid].user
				},
				last_modified: toLocalISOString(new Date())
			} as IColumnSetting;
			newEntities = updateItem(updItem, state.columnSettings.entities);
		} else {
			let fakeItem = {
				...newItem,
				d: 'IColumnSetting',
				created: toLocalISOString(new Date()),
				last_modified: toLocalISOString(new Date()),
				created_by: '',
				last_modified_by: ''
			} as IColumnSetting;
			newEntities = addItem(fakeItem, state.columnSettings.entities);
		}

		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				entities: newEntities,
				adding: true,
				added: false,
				addError: null
			}
		};
	}),
	on(actions.AddColumnSettingSuccess, (state, action) => {
		let csid = findColumnSetting(action.payload, state.columnSettings.entities);
		let newEntities = { ...state.columnSettings.entities };
		if (csid !== null) {
			newEntities = deleteItem(csid, newEntities);
		}

		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				entities: addItem(action.payload, newEntities),
				adding: false,
				added: true,
				addError: null
			}
		};
	}),
	on(actions.AddColumnSettingFail, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				adding: false,
				added: false,
				addError: action.payload
			}
		};
	}),

	// Delete ColumnSetting
	on(actions.DeleteColumnSetting, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				deleting: true,
				deleted: false,
				deleteError: null
			}
		};
	}),
	on(actions.DeleteColumnSettingSuccess, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				entities: deleteItem(action.payload, state.columnSettings.entities),
				deleting: false,
				deleted: true,
				deleteError: null
			}
		};
	}),
	on(actions.DeleteColumnSettingFail, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				deleting: false,
				deleted: false,
				deleteError: action.payload
			}
		};
	}),
	on(actions.ReorderColumnSettings, (state, action) => {
		/* let colSettings = itemArray(state.columnSettings.entities).filter(cs => cs.container === action.payload.container && cs.component === action.payload.component && cs.table === action.payload.table);
		let newOrder = getNewColumnOrder(
			colSettings,
			action.payload.column,
			action.payload.newIndex
		);
		let newEntities = {
			...state.columnSettings.entities
		};
		if(newOrder !== null) {
			newEntities = {
				...state.columnSettings.entities,
				[newOrder.id]: {
					...newEntities[newOrder.id],
					order: newOrder.newOrder
				}
			};
		}
		return {
			...state,
			columnSettings: {
				...state.columnSettings,
				entities: newEntities
			}
		}; */
		return {
			...state,
			columnSettings: {
				...state.columnSettings
			}
		};
	}),
	on(actions.fromColumnSelectorActions.ReorderColumns, (state, action) => {
		return {
			...state,
			columnSettings: {
				...state.columnSettings
			}
		}
	}),

	/*
	*
	* Column Selector State
	*
	*/

	on(actions.OpenColumnSelector, (state, action) => {
		return {
			...state,
			columnSelectorOpen: {
				...state.columnSelectorOpen,
				entities: {
					...state.columnSelectorOpen.entities,
					[action.payload.container + ':' + action.payload.component + ':' + action.payload.table]: true
				}
			}
		};
	}),
	on(actions.CloseColumnSelector, (state, action) => {
		return {
			...state,
			columnSelectorOpen: {
				...state.columnSelectorOpen,
				entities: {
					...state.columnSelectorOpen.entities,
					[action.payload.container + ':' + action.payload.component + ':' + action.payload.table]: false
				}
			}
		};
	}),
	on(actions.ToggleColumnSelector, (state, action) => {
		let newValue: boolean;
		let cctId = action.payload.container + ':' + action.payload.component + ':' + action.payload.table;

		if (state.columnSelectorOpen.entities.hasOwnProperty(cctId)) {
			newValue = !state.columnSelectorOpen.entities[cctId];
		} else {
			newValue = true;
		}

		return {
			...state,
			columnSelectorOpen: {
				...state.columnSelectorOpen,
				entities: {
					...state.columnSelectorOpen.entities,
					[cctId]: newValue
				}
			}
		};
	}),

	/*
	*
	* Table Row Expansion State
	*
	*/

	on(actions.ExpandTableRow, (state, action) => {
		let cctId = action.payload.container + ':' + action.payload.component + ':' + action.payload.table;
		let newExpanded = Array.from(state.rowExpanded.entities[cctId]);
		let idx = newExpanded.findIndex(r => r.entityId === action.payload.entityId);

		if (idx !== -1) {
			newExpanded.splice(idx, 1, {
				...newExpanded[idx],
				expanded: true
			});
		} else {
			newExpanded.push({
				entityId: action.payload.entityId,
				expanded: true
			});
		}

		return {
			...state,
			rowExpanded: {
				...state.rowExpanded,
				entities: {
					...state.rowExpanded.entities,
					[cctId]: newExpanded
				}
			}
		};
	}),
	on(actions.CollapseTableRow, (state, action) => {
		let cctId = action.payload.container + ':' + action.payload.component + ':' + action.payload.table;
		let newExpanded = Array.from(state.rowExpanded.entities[cctId]);
		let idx = newExpanded.findIndex(r => r.entityId === action.payload.entityId);

		if (idx !== -1) {
			newExpanded.splice(idx, 1, {
				...newExpanded[idx],
				expanded: false
			});
		} else {
			newExpanded.push({
				entityId: action.payload.entityId,
				expanded: false
			});
		}

		return {
			...state,
			rowExpanded: {
				...state.rowExpanded,
				entities: {
					...state.rowExpanded.entities,
					[cctId]: newExpanded
				}
			}
		};
	}),
	on(actions.ToggleTableRowExpansion, (state, action) => {
		let cctId = action.payload.container + ':' + action.payload.component + ':' + action.payload.table;
		let newExpanded: Array<{ entityId: string, expanded: boolean }> = [];
		if (state.rowExpanded.entities.hasOwnProperty(cctId)) {
			newExpanded = Array.from(state.rowExpanded.entities[cctId]);
		}

		let idx = newExpanded.findIndex(r => r.entityId === action.payload.entityId);

		if (idx !== -1) {
			newExpanded.splice(idx, 1, {
				...newExpanded[idx],
				expanded: !newExpanded[idx].expanded
			});
		} else {
			newExpanded.push({
				entityId: action.payload.entityId,
				expanded: true
			});
		}

		return {
			...state,
			rowExpanded: {
				...state.rowExpanded,
				entities: {
					...state.rowExpanded.entities,
					[cctId]: newExpanded
				}
			}
		};
	}),

	/*
	*
	* Table Filters State
	*
	*/
	on(actions.ReplaceColumnFilters, (state, action) => {
		let newColumnFilters: Array<IFieldFilter>;
		let cctId = action.payload.container + ':' + action.payload.component + ':' + action.payload.table;
		if (state.filterSettings.entities.hasOwnProperty(cctId)) {
			newColumnFilters = Array.from(state.filterSettings.entities[cctId]);
			newColumnFilters = newColumnFilters.filter(f => f.field !== action.payload.newFilters[0].field);
			action.payload.newFilters.forEach(nf => newColumnFilters.push(nf));
		} else {
			newColumnFilters = action.payload.newFilters;
		}
		let newFilterSettings = {
			...state.filterSettings.entities,
			[cctId]: newColumnFilters
		};
		return {
			...state,
			filterSettings: {
				...state.filterSettings,
				entities: newFilterSettings
			}
		}
	}),
	on(actions.RemoveColumnFilter, (state, action) => {
		let newColumnFilters: Array<IFieldFilter>;
		let cctId = action.payload.container + ':' + action.payload.component + ':' + action.payload.table;
		if (state.filterSettings.entities.hasOwnProperty(cctId)) {
			newColumnFilters = Array.from(state.filterSettings.entities[cctId]);
			newColumnFilters = newColumnFilters.filter(f => f.field !== action.payload.column);
		}
		let newFilterSettings = {
			...state.filterSettings.entities,
			[cctId]: newColumnFilters
		};
		return {
			...state,
			filterSettings: {
				...state.filterSettings,
				entities: newFilterSettings
			}
		}
	}),
	on(actions.ReplaceTableFilters, (state, action) => {
		let newFilterSettings = {
			...state.filterSettings.entities,
			[action.payload.container + ':' + action.payload.component + ':' + action.payload.table]: action.payload.newFilters
		};
		return {
			...state,
			filterSettings: {
				...state.filterSettings,
				entities: newFilterSettings
			}
		}
	}),
	on(actions.RemoveTableFilters, (state, action) => {
		let newFilterSettings = { ...state.filterSettings.entities } as IItemEntity<IFieldFilter[]>;

		if (state.filterSettings.entities.hasOwnProperty(action.payload.container + ':' + action.payload.component + ':' + action.payload.table)) {
			delete newFilterSettings[action.payload.container + ':' + action.payload.component + ':' + action.payload.table];
		}

		return {
			...state,
			filterSettings: {
				...state.filterSettings,
				entities: newFilterSettings
			}
		}
	})
);

