import { createAction, props } from "@ngrx/store";

const actionKey = '[Seacom App] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

export const ClearLoginError = createAction(
	actionKey + 'Clear Login Error'
);
