// Angular
import { Component, Input, OnInit, OnDestroy } from '@angular/core';

// RXJS
import { Observable } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';

// Interfaces
import { IColumnSetting } from 'src/app/interfaces/seacom/columnsetting';

// State
import { SeacomState } from 'src/app/store';

// Actions
import * as fromActions from './store/columnselector.actions';

// Selectors
import { selectIsColumnSelectorOpenByCCT, selectOrderedColumnSettingsByCCT } from '../../store/selectors/table.selectors';
import { selectBrightness } from 'src/app/store/selectors/ambiance.selectors';


@Component({
  selector: 'app-column-selector',
  templateUrl: './column-selector.component.html',
  styleUrls: ['./column-selector.component.scss'],
})
export class ColumnSelectorComponent implements OnInit, OnDestroy {
  @Input() container: string;
  @Input() component: string;
  @Input() table: string;

  brightness$: Observable<'light'|'dark'>;

  columns$: Observable<IColumnSetting[]>;
  isSelectorOpen$: Observable<boolean>;

  constructor(private store: Store<SeacomState>) { }

  ngOnInit() {
    // Subscribe to ambiance brightness
    this.brightness$ = this.store.select(selectBrightness);

    this.columns$ = this.store.select(selectOrderedColumnSettingsByCCT(this.container, this.component, this.table));
    this.isSelectorOpen$ = this.store.select(selectIsColumnSelectorOpenByCCT(this.container, this.component, this.table));
  }

  ngOnDestroy() {
  }

  displayColumn(event: {column: string}) {
    this.store.dispatch(fromActions.DisplayColumn({
      payload: {
        container: this.container,
        component: this.component,
        table: this.table,
        ...event
      }
    }));
  }

  hideColumn(event: {column: string}) {
    this.store.dispatch(fromActions.HideColumn({
      payload: {
        container: this.container,
        component: this.component,
        table: this.table,
        ...event
      }
    }));
  }

  reorderColumns(event: { newOrder: string[], from: number, to: number }) {
    this.store.dispatch(fromActions.ReorderColumns({ payload: {
      container: this.container,
      component: this.component,
      table: this.table,
      newOrder: event.newOrder,
      from: event.from,
      to: event.to
    }}));
  }

  toggleSelectorOpen() {
    this.store.dispatch(fromActions.ToggleColumnSelector({
      payload: {
        container: this.container,
        component: this.component,
        table: this.table
      }
    }));
  }
}
