// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, exhaustMap, map, mergeMap, switchMap, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../actions';

// Services
import { DistrictsService } from 'src/app/services/seacom/districts.service';
import { SeacomState } from '../reducers';
import { Store } from '@ngrx/store';
import { EnvironmentService } from 'src/app/services/environment.service';
import { selectDistrictsLastUpdated } from '../selectors/district.selectors';

@Injectable()
export class DistrictEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private districtsService: DistrictsService
  ) { }

  loadDistricts$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Districts
        ofType(
          actions.LoadDistricts
        ),
        concatLatestFrom((action) => this.store.select(selectDistrictsLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.districtsService.getList().pipe(
              map((districts) => actions.LoadDistrictsSuccess({ payload: districts })),
              catchError((error) => of(actions.LoadDistrictsFail(error)))
            );
          } else {
            return of(actions.LoadDistrictsCancelled());
          }
        })
      );
  });
  loadDistrict$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load District
        ofType(actions.LoadDistrict),
        exhaustMap((action) =>
          this.districtsService.get(action.payload).pipe(
            map((district) => actions.LoadDistrictSuccess({ payload: district })),
            catchError((error) => of(actions.LoadDistrictsFail(error)))
          )
        )
      );
  });
  addDistrict$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add District
        ofType(actions.AddDistrict),
        concatMap((action) =>
          this.districtsService.create(action.payload).pipe(
            map((district) => actions.AddDistrictSuccess({ payload: district })),
            catchError((error) => of(actions.AddDistrictFail(error)))
          )
        )
      );
  });
  updateDistrict$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update District
        ofType(actions.UpdateDistrict),
        concatMap((action) =>
          this.districtsService.update(action.payload.id, action.payload).pipe(
            map((district) => actions.UpdateDistrictSuccess({ payload: district })),
            catchError((error) => of(actions.UpdateDistrictFail(error)))
          )
        )
      );
  });
  deleteDistrict$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete District
        ofType(actions.DeleteDistrict),
        mergeMap((action) =>
          this.districtsService.delete(action.payload).pipe(
            map((districtId) => actions.DeleteDistrictSuccess({ payload: districtId })),
            catchError((error) => of(actions.DeleteDistrictFail(error)))
          )
        )
      )
  });
}
