// Angular
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

// RXJS
import { Observable } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Store
import { SeacomState } from '../../store/reducers/index';

// Actions
import * as fromActions from './store/footerbar.actions';

// Selectors
import { selectLatestLocationString } from 'src/app/store/selectors/location.selectors';
import { selectBrightness } from 'src/app/store/selectors/ambiance.selectors';
import { selectLocationDisabled } from '../../store/selectors/location.selectors';


@Component({
  selector: 'app-footerbar',
  templateUrl: './footerbar.component.html',
  styleUrls: ['./footerbar.component.scss'],
})
export class FooterbarComponent implements OnInit {
  brightness$: Observable<string>;
  locationDisabled$: Observable<boolean>;
  currentLocationString$: Observable<string>;

  brightnessForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<SeacomState>
  ) { }

  ngOnInit() {
    this.store.dispatch(fromActions.Enter());

    this.brightnessForm = this.formBuilder.group({
      ['brightnessControl']: new FormControl()
    });

    this.subscribeToBrightness();
    this.subscribeToPosition();
  }

  ngOnDestroy() {
    this.store.dispatch(fromActions.Exit());
  }

  subscribeToPosition(): void {
    this.locationDisabled$ = this.store.select(selectLocationDisabled);
    this.currentLocationString$ = this.store.select(selectLatestLocationString);
  }

  subscribeToBrightness(): void {
    this.brightness$ = this.store.select(selectBrightness);
    this.brightness$.pipe(debounceTime(300)).subscribe(
      (b) => {
        if (b !== undefined && b !== null) {
          this.brightnessForm.controls['brightnessControl'].setValue(b === 'dark' ? true : false);
        }
      }
    );
  }

  changeBrightness() {
    this.brightness$.pipe(first()).subscribe(
      (b) => {
        if (b !== undefined && b !== null) {
          if(this.brightnessForm.controls['brightnessControl'].value === true) {
            this.store.dispatch(fromActions.ChangeBrightness({ payload: { brightness: 'dark' } }));      
          } else {
            this.store.dispatch(fromActions.ChangeBrightness({ payload: { brightness: 'light' } }));
          }
        }
      }
    )
  }

  navigateBack() {
    this.store.dispatch(fromActions.NavigateBack());
  }
}
