import { createAction, props } from '@ngrx/store';
import { IFieldFilter } from 'src/app/interfaces/seacom/fieldfilter';
import { IIncident, IUpdatedIncident } from 'src/app/interfaces/seacom/incident';
import { INewIncidentResponse } from 'src/app/interfaces/seacom/incidentresponse';
import { INewIncident } from '../../interfaces/seacom/incident';

// incident action types
export const LOAD_INCIDENTS = '[Page Effects] Load Incidents';
export const LOAD_INCIDENTS_CANCELLED = '[Incident Effects] Load Incidents Cancelled';
export const LOAD_INCIDENTS_WITH_FILTERS = '[Component Effects] Load Incidents With Filters';
export const LOAD_INCIDENTS_FAIL = '[Incident Effects] Load Incidents Fail';
export const LOAD_INCIDENTS_SUCCESS = '[Incident Effects] Load Incidents Success';
export const LOAD_INCIDENT = '[Component Effects] Load Incident';
export const LOAD_INCIDENT_FAIL = '[Incident Effects] Load Incident Fail';
export const LOAD_INCIDENT_SUCCESS = '[Incident Effects] Load Incident Success';
export const UPDATE_INCIDENT = '[SEACOM] Update Incident';
export const UPDATE_INCIDENT_FIELDS = '[SEACOM] Update Incident Fields';
export const UPDATE_INCIDENT_FAIL = '[Incident Effects] Update Incident Fail';
export const UPDATE_INCIDENT_SUCCESS = '[Incident Effects] Update Incident Success';
export const ADD_INCIDENT = '[SEACOM] Add Incident';
export const ADD_INCIDENT_FAIL = '[Incident Effects] Add Incident Fail';
export const ADD_INCIDENT_SUCCESS = '[Incident Effects] Add Incident Success';
export const ADD_QUICK_INCIDENT_SUCCESS = '[Incident Effects] Add Quick Incident Success';
export const ADD_QUICK_INCIDENT_FAIL = '[Incident Effects] Add Quick Incident Fail';
export const DELETE_INCIDENT = '[SEACOM] Delete Incident';
export const DELETE_INCIDENT_FAIL = '[Incident Effects] Delete Incident Fail';
export const DELETE_INCIDENT_SUCCESS = '[Incident Effects] Delete Incident Success';
export const HIDE_INCIDENT_LIST_COL = '[SEACOM] Hide Incident List Column';
export const SHOW_INCIDENT_LIST_COL = '[SEACOM] Show Incident List Column';
export const REORDER_INCIDENT_LIST_COL = '[SEACOM] Reorder Incident List Column';
export const HIDE_DISPATCH_INCIDENT_LIST_COL = '[SEACOM] Hide Dispatch Incident List Column';
export const SHOW_DISPATCH_INCIDENT_LIST_COL = '[SEACOM] Show Dispatch Incident List Column';
export const REORDER_DISPATCH_INCIDENT_LIST_COL = '[SEACOM] Reorder Dispatch Incident List Column';

export const LoadIncidents = createAction(
	LOAD_INCIDENTS
);

export const LoadIncidentsCancelled = createAction(
    LOAD_INCIDENTS_CANCELLED
);

export const LoadIncidentsWithFilters = createAction(
    LOAD_INCIDENTS_WITH_FILTERS,
    props<{
        filters?: IFieldFilter[],
        order?: string,
        search?: string
    }>()
);

export const LoadIncidentsSuccess = createAction(
    LOAD_INCIDENTS_SUCCESS,
    props<{
        payload: IIncident[]
    }>()
);

export const LoadIncidentsFail = createAction(
    LOAD_INCIDENTS_FAIL,
    props<{
        payload: any
    }>()
);

export const LoadIncident = createAction(
	LOAD_INCIDENT,
    props<{
        payload: string // ID
    }>()
);

export const LoadIncidentSuccess = createAction(
    LOAD_INCIDENT_SUCCESS,
    props<{
        payload: IIncident
    }>()
);

export const LoadIncidentFail = createAction(
    LOAD_INCIDENT_FAIL,
    props<{
        payload: any
    }>()
);

export const UpdateIncident = createAction(
    UPDATE_INCIDENT,
    props<{
        payload: IUpdatedIncident
    }>()
);

export const UpdateIncidentFields = createAction(
    UPDATE_INCIDENT_FIELDS,
    props<{
        payload: IUpdatedIncident
    }>()
);

export const UpdateIncidentSuccess = createAction(
    UPDATE_INCIDENT_SUCCESS,
    props<{
        payload: IIncident
    }>()
);

export const UpdateIncidentFail = createAction(
    UPDATE_INCIDENT_FAIL,
    props<{
        payload: {
            id: string,
            error: any
        }
    }>()
);

export const AddIncident = createAction(
    ADD_INCIDENT,
    props<{
        payload: INewIncident
    }>()
);

export const AddIncidentSuccess = createAction(
    ADD_INCIDENT_SUCCESS,
    props<{
        payload: IIncident
    }>()
);

export const AddQuickIncidentSuccess = createAction(
    ADD_QUICK_INCIDENT_SUCCESS,
    props<{
        payload: {
            quickIncident: IIncident,
            firstResponse: INewIncidentResponse
        }
    }>()
);

export const AddIncidentFail = createAction(
    ADD_INCIDENT_FAIL,
    props<{
        payload: any
    }>()
);

export const AddQuickIncidentFail = createAction(
    ADD_QUICK_INCIDENT_FAIL,
    props<{
        payload: any
    }>()
);

export const DeleteIncident = createAction(
    DELETE_INCIDENT,
    props<{
        payload: string // ID
    }>()
);

export const DeleteIncidentSuccess = createAction(
    DELETE_INCIDENT_SUCCESS,
    props<{
        payload: string // ID
    }>()
);

export const DeleteIncidentFail = createAction(
    DELETE_INCIDENT_FAIL,
    props<{
        payload: {
            id: string,
            error: any
        }
    }>()
);

export const HideIncidentListColumn = createAction(
    HIDE_INCIDENT_LIST_COL,
    props<{
        column: string
    }>()
);

export const ShowIncidentListColumn = createAction(
    SHOW_INCIDENT_LIST_COL,
    props<{
        column: string
    }>()
);

export const ReorderIncidentListColumn = createAction(
    REORDER_INCIDENT_LIST_COL,
    props<{
        column: string,
        order: number
    }>()
);

export const HideDispatchIncidentListColumn = createAction(
    HIDE_DISPATCH_INCIDENT_LIST_COL,
    props<{
        column: string
    }>()
);

export const ShowDispatchIncidentListColumn = createAction(
    SHOW_DISPATCH_INCIDENT_LIST_COL,
    props<{
        column: string
    }>()
);

export const ReorderDispatchIncidentListColumn = createAction(
    REORDER_DISPATCH_INCIDENT_LIST_COL,
    props<{
        column: string,
        order: number
    }>()
);
