// NGRX
import { createAction, props } from "@ngrx/store";

const actionKey = '[Column Selector] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

export const ToggleColumnSelector = createAction(
  actionKey + 'Toggle Column Selector',
  props<{
    payload: {
      container: string,
      component: string,
      table: string
    }
  }>()
);

export const DisplayColumn = createAction(
  actionKey + 'Display Table Column',
  props<{ payload: { container: string, component: string, table: string, column: string } }>()
);

export const HideColumn = createAction(
  actionKey + 'Hide Table Column',
  props<{ payload: { container: string, component: string, table: string, column: string } }>()
);

export const ReorderColumns = createAction(
  actionKey + 'Reorder Columns',
  props<{ payload: { 
    container: string,
    component: string,
    table: string,
    newOrder: string[],
    from: number,
    to: number
  } }>()
);
