// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../actions';

// Services
import { IncidentTypesService } from 'src/app/services/seacom/incidenttypes.service';
import { Store } from '@ngrx/store';
import { SeacomState } from '../reducers';
import { EnvironmentService } from 'src/app/services/environment.service';
import { selectIncidentTypesLastUpdated } from '../selectors/incidenttype.selectors';

@Injectable()
export class IncidentTypeEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private incidentTypesService: IncidentTypesService
  ) { }

  loadIncidentTypes$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load IncidentTypes
        ofType(
          actions.LoadIncidentTypes
        ),
        concatLatestFrom((action) => this.store.select(selectIncidentTypesLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.incidentTypesService.getList().pipe(
              map((incidenttypes) => actions.LoadIncidentTypesSuccess({ payload: incidenttypes })),
              catchError((error) => of(actions.LoadIncidentTypesFail(error)))
            );
          } else {
            return of(actions.LoadIncidentTypesCancelled());
          }
        })
      );
  });
  loadIncidentType$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load IncidentType
        ofType(actions.LoadIncidentType),
        exhaustMap((action) =>
          this.incidentTypesService.get(action.payload).pipe(
            map((incidenttype) => actions.LoadIncidentTypeSuccess({ payload: incidenttype })),
            catchError((error) => of(actions.LoadIncidentTypesFail(error)))
          )
        )
      );
  });
  addIncidentType$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add IncidentType
        ofType(actions.AddIncidentType),
        concatMap((action) =>
          this.incidentTypesService.create(action.payload).pipe(
            map((incidenttype) => actions.AddIncidentTypeSuccess({ payload: incidenttype })),
            catchError((error) => of(actions.AddIncidentTypeFail(error)))
          )
        )
      );
  });
  updateIncidentType$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update IncidentType
        ofType(actions.UpdateIncidentType),
        concatMap((action) =>
          this.incidentTypesService.update(action.payload.id, action.payload).pipe(
            map((incidenttype) => actions.UpdateIncidentTypeSuccess({ payload: incidenttype })),
            catchError((error) => of(actions.UpdateIncidentTypeFail(error)))
          )
        )
      );
  });
  deleteIncidentType$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete IncidentType
        ofType(actions.DeleteIncidentType),
        mergeMap((action) =>
          this.incidentTypesService.delete(action.payload).pipe(
            map((incidenttypeId) => actions.DeleteIncidentTypeSuccess({ payload: incidenttypeId })),
            catchError((error) => of(actions.DeleteIncidentTypeFail(error)))
          )
        )
      )
  });

  
}
