// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { IBeach, INewBeach, IUpdatedBeach } from '../../interfaces/seacom/beach';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';


@Injectable({
  providedIn: 'root'
})
export class BeachesService extends ApiNodeService<IBeach, INewBeach, IUpdatedBeach> {
  node = 'beaches';

  constructor(public seacomApiService: SeacomApiService) {
    super(seacomApiService);
  }

}
