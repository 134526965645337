import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IDistrict } from 'src/app/interfaces/seacom/district';
import * as fromReducers from '../reducers/district.reducers';
import { sortFactory } from '../utils/sort.utils';

export const selectDistrictState = createFeatureSelector<fromReducers.DistrictState>(
  fromReducers.districtFeatureKey
);

export const selectDistrictsCount = createSelector(
  selectDistrictState,
  (state: fromReducers.DistrictState): number => Object.keys(state.entities).length
);

export const selectDistrictsLastUpdated = createSelector(
  selectDistrictState,
  (state: fromReducers.DistrictState): string => state.lastUpdated
);

export const selectDistrictsLoading = createSelector(
  selectDistrictState,
  (state: fromReducers.DistrictState): boolean => state.loading
);

export const selectAllDistricts = createSelector(
  selectDistrictsCount,
  selectDistrictState,
  (count: number, state: fromReducers.DistrictState): IDistrict[] => {
    if(count > 0) {
      return Object.keys(state.entities)
        .map(d => state.entities[d])
        .sort((a,b) => sortFactory(a,b,[{field: 'name', ascending: true}]));
    } else {
      return [];
    }
  }
);

export const selectDistrictById = (id: string) => createSelector(selectDistrictState, (districts): IDistrict => {
  return districts?.entities[id];
});
