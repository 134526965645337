// Angular
import { Injectable } from '@angular/core';

// RXJS
import { map } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../../../store/actions';

// State
import { SeacomState } from '../../../store/reducers';

@Injectable()
export class ConditionsPageEffects {

  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>
  ) { }

  enter$ = createEffect(() => {
    return this.actions$
    .pipe(
      ofType(
        actions.fromConditionsPageActions.Enter
      ),
      map(() => {
        this.store.dispatch(actions.LoadWeather());
      })
    );
  },
  { dispatch: false });
}
