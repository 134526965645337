// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Ionic
import { IonicModule } from '@ionic/angular';

// Components
import { HeaderbarComponent } from './headerbar.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule
    ],
    declarations: [
        HeaderbarComponent,
    ],
    exports: [
        HeaderbarComponent,
    ]
})

export class HeaderbarModule { }



