// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';

// Actions
import * as actions from '../actions';

// Services
import { IncidentResponsesService } from 'src/app/services/seacom/incidentresponses.service';
import { SeacomState } from '../reducers';
import { Store } from '@ngrx/store';
import { selectLatestIncidentByStationIdAndStatus } from '../selectors/incident.selectors';
import { selectIncidentResponsesLastUpdated, selectLatestIncidentResponseInProgressByStationId } from '../selectors/incidentresponse.selectors';
import { INewIncidentResponse } from 'src/app/interfaces/seacom/incidentresponse';
import { selectTransferringStation } from '../../containers/operations/store/operations.page.selectors';
import { IIncident } from 'src/app/interfaces/seacom/incident';
import { EnvironmentService } from 'src/app/services/environment.service';
import { toLocalISOString } from 'src/app/common/globals';

@Injectable()
export class IncidentResponseEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private incidentResponsesService: IncidentResponsesService
  ) {}

  loadIncidentResponses$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load IncidentResponses
        ofType(
          actions.LoadIncidentResponses
        ),
        concatLatestFrom((action) => this.store.select(selectIncidentResponsesLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.incidentResponsesService.getList().pipe(
              map((incidentresponses) => actions.LoadIncidentResponsesSuccess({ payload: incidentresponses })),
              catchError((error) => of(actions.LoadIncidentResponsesFail(error)))
            );
          } else {
            return of(actions.LoadIncidentResponsesCancelled());
          }
        })
      );
  });
  loadIncidentResponse$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load IncidentResponse
        ofType(actions.LoadIncidentResponse),
        exhaustMap((action) =>
          this.incidentResponsesService.get(action.payload).pipe(
            map((incidentresponse) => actions.LoadIncidentResponseSuccess({ payload: incidentresponse })),
            catchError((error) => {
              console.log(error);
              return of(actions.LoadIncidentResponsesFail(error));
            })
          )
        ),
      );
  });
  loadIncidentResponseWithNewMetadata$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load IncidentResponse
        ofType(
          actions.UpdateMetadataFieldsSuccess,
          actions.AddMetadataSuccess
        ),
        exhaustMap((action) => {
          if (action.payload.response !== undefined && action.payload.response !== null) {
            return this.incidentResponsesService.get(action.payload.response.id).pipe(
              map((incidentresponse) => actions.LoadIncidentResponseSuccess({ payload: incidentresponse })),
              catchError((error) => {
                console.log(error);
                return of(actions.LoadIncidentResponsesFail(error));
              })
            );
          } else {
            return of(actions.LoadIncidentResponsesFail({ payload: 'Not response related' }));
          }
        }),
      );
  });
  addIncidentResponse$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add IncidentResponse
        ofType(actions.AddIncidentResponse),
        concatMap((action) => {
          return this.incidentResponsesService.create(action.payload).pipe(
            map((incidentResponse) => actions.AddIncidentResponseSuccess({ payload: incidentResponse })),
            catchError((error) => of(actions.AddIncidentResponseFail(error)))
          )
        }
        ),
      );
  });
  addStationIncidentResponse$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add IncidentResponse
        ofType(actions.fromOperationsPageActions.AddStationIncidentResponse),
        concatMap((action) => this.incidentResponsesService.create(action.payload.incidentResponse).pipe(
          map((incidentResponse) => actions.AddStationIncidentResponseSuccess({ payload: incidentResponse })),
          catchError((error) => of(actions.AddStationIncidentResponseFail(error)))
        )
        ),
      );
  });
  addQuickIncidentResponse$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add IncidentResponse
        ofType(actions.AddQuickIncidentSuccess),
        concatMap((action) => {
          return this.incidentResponsesService.create(action.payload.firstResponse).pipe(
            map((incidentResponse) => actions.AddQuickIncidentResponseSuccess({ payload: incidentResponse })),
            catchError((error) => of(actions.AddQuickIncidentResponseFail(error)))
          )
        }
        ),
      );
  });
  transferIncidentToStation$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.fromOperationsPageActions.TransferIncidentToStation
        ),
        concatLatestFrom((action) => this.store.select(selectTransferringStation)),
        concatLatestFrom(([action, station]) => this.store.select(selectLatestIncidentByStationIdAndStatus(station.id, ['en route', 'responding']))),
        concatMap(([[action, station], incident]) => {
          let ci = incident as IIncident;
          let newResponse = {
            organization: ci.organization.id,
            incident: ci.id,
            status: 'inprogress',
            location: station.location,
            region: ci.region ? ci.region.id : undefined,
            district: ci.district ? ci.district.id : undefined,
            beach: ci.beach ? ci.beach.id : undefined,
            stations: [action.payload.destinationStationId],
            responders: station.responders.map(u => u.id),
            starttime: toLocalISOString(new Date())
          } as INewIncidentResponse;
          return this.incidentResponsesService
            .create(newResponse)
            .pipe(
              map((newResponse) => actions.StationIncidentTransferSuccess({ payload: newResponse })),
              catchError((error) => {
                return of(actions.StationIncidentTransferFail({ payload: error }));
              })
            )
        })
      );
  });
  updateIncidentResponse$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update IncidentResponse
        ofType(actions.UpdateIncidentResponse),
        concatMap((action) => {
          return this.incidentResponsesService.update(action.payload.id, action.payload).pipe(
            map((incidentresponse) => actions.UpdateIncidentResponseSuccess({ payload: incidentresponse })),
            catchError((error) => of(actions.UpdateIncidentResponseFail(error)))
          )
        })
      );
  });
  endStationResponse$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update IncidentResponse
        ofType(actions.fromOperationsPageActions.EndStationResponse),
        concatLatestFrom((action) => this.store.select(selectLatestIncidentResponseInProgressByStationId(action.payload.stationId))),
        concatMap(([action, incidentResponse]) => this.incidentResponsesService.updateFields(incidentResponse.id, { endtime: toLocalISOString(new Date()), status: 'complete' }).pipe(
          map((incidentresponse) => actions.EndStationResponseSuccess({ payload: incidentresponse })),
          catchError((error) => of(actions.EndStationResponseFail(error)))
        ))
      );
  });
  updateIncidentResponseFields$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update IncidentResponse
        ofType(actions.UpdateIncidentResponseFields),
        concatMap((action) => this.incidentResponsesService.updateFields(action.payload.id, action.payload).pipe(
          map((incidentresponse) => actions.UpdateIncidentResponseSuccess({ payload: incidentresponse })),
          catchError((error) => of(actions.UpdateIncidentResponseFail(error)))
        )
        )
      );
  });
  deleteIncidentResponse$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete IncidentResponse
        ofType(actions.DeleteIncidentResponse),
        mergeMap((action) =>
          this.incidentResponsesService.delete(action.payload).pipe(
            map((incidentresponseId) => actions.DeleteIncidentResponseSuccess({ payload: incidentresponseId })),
            catchError((error) => of(actions.DeleteIncidentResponseFail(error)))
          )
        )
      )
  });
}
