import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IWeatherForecast } from 'src/app/interfaces/weather/weatherforecast';
import * as fromReducers from '../reducers/weather.reducers';
import { ICurrentWeather } from '../../interfaces/weather/currentweather';
import { IDailyWeatherForecast } from 'src/app/interfaces/weather/dailyweatherforecast';
import { IHourlyWeatherForecastDetail } from 'src/app/interfaces/weather/hourlyweatherforecastdetail';
import { IWeatherLocation } from 'src/app/interfaces/weather/weatherlocation';

export const selectWeatherState = createFeatureSelector<fromReducers.WeatherState>(
  fromReducers.weatherFeatureKey
);

export const selectWeatherLastUpdated = createSelector(
  selectWeatherState,
  (state: fromReducers.WeatherState): string => state.lastUpdated
);

export const selectWeatherLoading = createSelector(
  selectWeatherState,
  (state: fromReducers.WeatherState): boolean => state.loading
);

export const selectCurrentWeather = createSelector(
  selectWeatherState,
  (state: fromReducers.WeatherState): ICurrentWeather => state.current
);

export const selectWeatherLocation = createSelector(
  selectWeatherState,
  (state: fromReducers.WeatherState): IWeatherLocation => state.location
);

export const selectWeatherForecast = createSelector(
  selectWeatherState,
  (state: fromReducers.WeatherState): IWeatherForecast => state.forecast
);

export const selectDailyWeatherForecasts = createSelector(
  selectWeatherState,
  (state: fromReducers.WeatherState): IDailyWeatherForecast[] => {
    if(state.forecast !== undefined && state.forecast.forecastday !== undefined) {
      return state.forecast.forecastday;
    } else {
      return undefined;
    }
  }
);

export const selectHourlyWeatherForecasts = createSelector(
  selectWeatherState,
  (state: fromReducers.WeatherState): IHourlyWeatherForecastDetail[] => {
    if(state.forecast !== undefined && state.forecast.forecastday !== undefined && state.forecast.forecastday.length > 0 && state.forecast.forecastday[0].hour !== undefined) {
      return state.forecast?.forecastday[0].hour
    } else {
      return undefined;
    }
});

export const selectWeatherForecastByHour = (date: Date) => createSelector(selectDailyWeatherForecasts, (days): IHourlyWeatherForecastDetail => {
  if(days !== undefined) {
    const targetDay = days
      .find(x => {
        if(x.date === (date.getFullYear().toString() + '-' + (date.getMonth()+1).toString().padStart(2,'0') + '-' + date.getDate().toString().padStart(2,'0'))) {
          return true;
        } else {
          return false;
        }
      });
    if(targetDay !== undefined && targetDay !== null) {
      return targetDay.hour
        .filter(x => {
          return (parseInt(x.time.substring(x.time.length-5).split(':')[0]) === date.getHours());
        })[0];
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
});
