import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { IonReorderGroup } from '@ionic/angular';
import { ItemReorderEventDetail } from '@ionic/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { filterItems, skipNull } from 'src/app/common/globals';
import { IColumnSetting } from 'src/app/interfaces/seacom/columnsetting';
import { IWidget } from 'src/app/interfaces/widget';


@Component({
    selector: 'app-column-selector-list',
    templateUrl: './column-selector-list.component.html',
    styleUrls: ['./column-selector-list.component.scss'],
})
export class ColumnSelectorListComponent implements OnInit, OnDestroy {
    @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;
    @Input() brightness$: Observable<'light'|'dark'>;
    @Input() columns$: Observable<IColumnSetting[]>;
    @Output() displayColumn = new EventEmitter<{ column: string, datatype: string }>();
    @Output() hideColumn = new EventEmitter<{ column: string, datatype: string }>();
    @Output() reorderColumns = new EventEmitter<{ newOrder: string[], from: number, to: number }>();

    public widgets: IWidget[];

    columnSubscriptions = new Subscription();

    cols$: Observable<IColumnSetting[]>;

    columnVisibilityForm!: FormGroup;

    constructor(
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.columnVisibilityForm = this.formBuilder.group({});

        this.columnSubscriptions.add(
            this.columns$.pipe(debounceTime(300)).subscribe(
                (cols) => {
                    if (cols !== undefined && cols !== null) {
                        cols.forEach(c => {
                            if (this.columnVisibilityForm.controls.hasOwnProperty(c.column)) {
                                this.columnVisibilityForm.controls[c.column].patchValue(c.displayed);
                                this.columnVisibilityForm.controls[c.column].markAsPristine();
                            } else {
                                this.columnVisibilityForm.addControl(c.column, new FormControl(c.displayed));
                            }
                        });
                    }
                }
            )
        );

        this.cols$ = this.columns$.pipe(filterItems(i => i.datatype !== 'action')); /* .pipe(tap(s => {
            console.log(s.map(cs => cs.id));
        })); */
    }

    ngOnDestroy() {
        this.columnSubscriptions.unsubscribe();
    }

    doReorder(ev: Event) {
        const customEvent = ev as CustomEvent<ItemReorderEventDetail>;

        if (customEvent.detail.from !== customEvent.detail.to) {
            this.columnSubscriptions.add(
                this.cols$.pipe(skipNull(), first()).subscribe(
                    (cols) => {
                        if (cols !== undefined && cols !== null) {
                            let reorderCols = cols.map(c => c.id);
                            customEvent.detail.complete(reorderCols);

                            this.reorderColumns.emit({
                                newOrder: reorderCols,
                                from: customEvent.detail.from,
                                to: customEvent.detail.to
                            });
                        }
                    }
                )
            );
        }

        customEvent.detail.complete();
    }

    checkboxChanged(column: string, datatype: string) {
        if (this.columnVisibilityForm.controls.hasOwnProperty(column)) {
            let v = this.columnVisibilityForm.controls[column].value;
            if (v) {
                this.displayColumn.emit({ column: column, datatype: datatype });
            } else {
                this.hideColumn.emit({ column: column, datatype: datatype });
            }
        }
    }
}
