import { createReducer, on } from '@ngrx/store';
import { IWeatherForecast } from 'src/app/interfaces/weather/weatherforecast';

import * as actions from '../actions';
import { IWeatherApiForecastResult } from '../../interfaces/weather/weatherapiforecastresult';
import { IWeatherLocation } from 'src/app/interfaces/weather/weatherlocation';
import { ICurrentWeather } from 'src/app/interfaces/weather/currentweather';
import { toLocalISOString } from 'src/app/common/globals';


export const weatherFeatureKey = 'weather';

export interface WeatherState {
	location: IWeatherLocation,
	current: ICurrentWeather,
	forecast: IWeatherForecast,
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any
}

export const WeatherInitialState: WeatherState = {
	location: undefined,
	current: undefined,
	forecast: undefined,
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null
};

export const WeatherReducer = createReducer(
	WeatherInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...WeatherInitialState
		} as WeatherState;
	}),
	// Load Weather
	on(actions.LoadWeather, (state, action) => {
		return {
			...state,
			loading: true,
		} as WeatherState;
	}),
	on(actions.LoadWeatherCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as WeatherState;
	}),
	on(actions.LoadWeatherSuccess, (state, action) => {
		return {
			...state,
			location: action.location,
			current: action.current,
			forecast: action.forecast,
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as WeatherState;
	}),
	on(actions.LoadWeatherFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.error,
		} as WeatherState;
	}),
);
