import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ICurrentWeather } from 'src/app/interfaces/weather/currentweather';
import { IHourlyWeatherForecastDetail } from 'src/app/interfaces/weather/hourlyweatherforecastdetail';

@Component({
  selector: 'app-conditions-widget',
  templateUrl: './conditions-widget.component.html',
  styleUrls: ['../widget.styles.scss', './conditions-widget.component.scss']
})
export class ConditionsWidgetComponent implements OnInit {
  @Input() brightness$: Observable<'light'|'dark'>;
  @Input('CurrentWeather') currentWeather: ICurrentWeather;
  @Input('UpcomingWeather') upcomingWeather: IHourlyWeatherForecastDetail;
  @Input('UpcomingWeatherTime') upcomingWeatherTime: Date;
  @Input('LocationDisabled') locationDisabled: boolean;
  @Input('CurrentLocation') currentLocation: GeolocationCoordinates;
  @Input('Loading$') weatherLoading$: Observable<boolean>;
  @Output() hideWidgetEvent = new EventEmitter<string>();
  @Output() navigateToConditionsEvent = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  navigateToConditionsPage(event) {
    event.stopPropagation();
    this.navigateToConditionsEvent.emit();
  }

  hideWidget() {
    this.hideWidgetEvent.emit('conditions');
  }

}
