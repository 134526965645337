import { createAction, props } from "@ngrx/store";

// Interfaces
import { IFieldFilter } from "src/app/interfaces/seacom/fieldfilter";
import { IPage } from 'src/app/interfaces/page';

const actionKey = '[Stations Page] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

// Pagination
export const ChangePage = createAction(
  actionKey + 'Change Page',
  props<{ payload: { page: IPage }}>()
);

// Field Filters
export const AddFieldFilter = createAction(
  actionKey + 'Add Field Filter',
  props<{ payload: { filter: IFieldFilter }}>()
)

export const RemoveFieldFilter = createAction(
  actionKey + 'Add Field Filter',
  props<{ payload: { fieldName: string }}>()
)

export const ChangeSortField = createAction(
  actionKey + 'Change Sort Field',
  props<{ payload: { fieldName: string }}>()
)

// Station row incidents expansion
export const ToggleStationRowExpansion = createAction(
  actionKey + 'Toggle Station Row Expansion',
  props<{ payload: { stationId: string }}>()
)
