// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Materials
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Ionic
import { IonicModule } from '@ionic/angular';

// NGRX
import { StoreModule } from '@ngrx/store';

// NGX
import { NgxPaginationModule } from 'ngx-pagination';

// Store
import { reducers } from '../../store';

// Routing Module
import { OperationsPageRoutingModule } from './operations-routing.module';

// Component
import { OperationsPage } from './operations.page';

// Child Component Modules
import { IncidentFormModule } from '../incidentform/incidentform.module';
import { StationTileModule } from '../../components/stationtile/stationtile.module';
import { OperationsMapModule } from 'src/app/components/operations-map/operations-map.module';
import { ColumnSelectorModule } from '../../components/column-selector/column-selector.module';
import { SeacomPipesModule } from 'src/app/common/pipes.module';
import { LoadingSpinnerModule } from 'src/app/components/loading-spinner/loading-spinner.module';
import { ColumnFilterModule } from 'src/app/components/column-filter/column-filter.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    NgxPaginationModule,
    OperationsPageRoutingModule,
    IncidentFormModule,
    StationTileModule,
    OperationsMapModule,
    MatTableModule,
    DragDropModule,
    ColumnSelectorModule,
    ColumnFilterModule,
    SeacomPipesModule,
    LoadingSpinnerModule
  ],
  declarations: [
    OperationsPage
  ]
})
export class OperationsPageModule { }
