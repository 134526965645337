import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IContainerSetting, INewContainerSetting, IUpdatedContainerSetting } from '../../interfaces/seacom/containersetting';

import * as actions from '../actions';
import { addItem, deleteItem, updateItem, updateItems } from '../utils/itementity';


export const containerSettingFeatureKey = 'containerSettings';

export interface ContainerSettingState {
	entities: IItemEntity<IContainerSetting>,
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const ContainerSettingInitialState: ContainerSettingState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

function findContainerSetting(csetting: IContainerSetting | INewContainerSetting, entities: IItemEntity<IContainerSetting>): string {
	let arr = Object.keys(entities).map(k => entities[k]);
	arr = arr.filter(cs => cs.container === csetting.container && cs.component === csetting.component && cs.setting === csetting.setting);
	if (arr.length > 0) {
		return arr[0].id;
	} else {
		return null;
	}
}

function updateContainerSettingsItems(items: IContainerSetting[], entities: IItemEntity<IContainerSetting>): IItemEntity<IContainerSetting> {
	let newEntities = { ...entities };
	for (let cs of items) {
		let ecs = findContainerSetting(cs, newEntities);
		if (ecs !== null) {
			delete newEntities[ecs];
		}
	}
	return updateItems(items, newEntities);
}

export const ContainerSettingReducer = createReducer(
	ContainerSettingInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...ContainerSettingInitialState
		} as ContainerSettingState;
	}),
	// Load ContainerSettings
	on(actions.LoadContainerSettings, (state, action) => {
		return {
			...state,
			loading: true,
			loaded: false,
			loadError: null
		} as ContainerSettingState;
	}),
	on(actions.LoadContainerSettingsCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as ContainerSettingState;
	}),
	on(actions.LoadContainerSettingsWithFilters, (state, action) => {
		return {
			...state,
			loading: true,
			loaded: false,
			loadError: null
		} as ContainerSettingState;
	}),
	on(actions.LoadContainerSettingsSuccess, (state, action) => {
		return {
			...state,
			entities: updateContainerSettingsItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as ContainerSettingState;
	}),
	on(actions.LoadContainerSettingsFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as ContainerSettingState;
	}),

	// Load ContainerSetting
	on(actions.LoadContainerSetting, (state, action) => {
		return {
			...state,
			loading: true,
		} as ContainerSettingState;
	}),
	on(actions.UpdateMetadataFieldsSuccess, (state, action) => {
		return {
			...state,
			loading: true,
		} as ContainerSettingState;
	}),
	on(actions.AddMetadataSuccess, (state, action) => {
		return {
			...state,
			loading: true,
		} as ContainerSettingState;
	}),
	on(actions.LoadContainerSettingSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as ContainerSettingState;
	}),
	on(actions.LoadContainerSettingFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as ContainerSettingState;
	}),

	// Update ContainerSetting
	on(actions.UpdateContainerSetting, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateContainerSettingValue, (state, action) => {
		let newEntities = {
			...state.entities
		};
		if (state.entities.hasOwnProperty(action.payload.id)) {
			newEntities[action.payload.id] = {
				...state.entities[action.payload.id],
				value: action.payload.newValue
			};
		}
		return {
			...state,
			entities: newEntities
		};
	}),
	on(actions.UpdateContainerSettingFields, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateContainerSettingValue, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateContainerSettingSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateContainerSettingFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),
	// Add ContainerSetting
	on(actions.AddContainerSetting, (state, action) => {
		let newItem = { ...action.payload } as INewContainerSetting;
		if (!newItem.hasOwnProperty('id')) {
			newItem.id = Math.floor(Math.random() * Math.random()).toString();
		}
		let csid = findContainerSetting(action.payload, state.entities);
		let newEntities = { ...state.entities };
		if (csid !== null) {
			let updItem = {
				...newItem,
				d: 'IContainerSetting',
				id: csid,
				organization: {
					...state.entities[csid].organization
				},
				user: {
					...state.entities[csid].user
				},
				last_modified: toLocalISOString(new Date())
			} as IContainerSetting;
			newEntities = updateItem(updItem, state.entities);
		} else {
			let fakeItem = {
				...newItem,
				d: 'IContainerSetting',
				created: toLocalISOString(new Date()),
				last_modified: toLocalISOString(new Date()),
				created_by: '',
				last_modified_by: ''
			} as IContainerSetting;
			newEntities = addItem(fakeItem, state.entities);
		}

		return {
			...state,
			entities: newEntities,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddContainerSettingSuccess, (state, action) => {
		let newEntities = { ...state.entities };
		let csid = findContainerSetting(action.payload, state.entities);

		if (csid !== null) {
			newEntities = deleteItem(csid, newEntities);
		}

		return {
			...state,
			entities: addItem(action.payload, newEntities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddContainerSettingFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),

	// Delete ContainerSetting
	on(actions.DeleteContainerSetting, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteContainerSettingSuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteContainerSettingFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	})
);
