// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { INotification, INewNotification, IUpdatedNotification } from '../../interfaces/seacom/notification';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends ApiNodeService<INotification, INewNotification, IUpdatedNotification> {
  node = 'notifications';

  constructor(public seacomApiService: SeacomApiService) {
    super(seacomApiService);
  }

}
