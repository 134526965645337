import { IColumnSetting } from "src/app/interfaces/seacom/columnsetting";
import { sortFactory } from "./sort.utils";

export function orderColumnSettings(csettings: IColumnSetting[]): IColumnSetting[] {
	let res = csettings.sort((a, b) =>
		sortFactory(a, b, [
			//{ field: 'displayed', ascending: false },
			{ field: 'order', ascending: true },
			{ field: 'displayname', ascending: true }
		]
		));

	return res;
}

export function getNewColumnOrder(columnSettings: IColumnSetting[], column: string, newIndex: number): { id: string, newOrder: number } {
	console.log(columnSettings.map(s => s.column));
	let tableColumns = orderColumnSettings(columnSettings);
	tableColumns = tableColumns.filter(cs => cs.datatype !== 'action');
	let fromIndex = tableColumns.map(cs => cs.column).indexOf(column);
	let csetting = tableColumns[fromIndex];

	let targetIndex = newIndex;
	console.log(tableColumns.map(cs => cs.column));
	console.log(fromIndex);
	console.log(targetIndex);

	let res: { id: string, newOrder: number };

	if (targetIndex === fromIndex) {
		res = {
			id: tableColumns[fromIndex].id,
			newOrder: tableColumns[fromIndex].order
		};
		return res;
	}

	// column settings are sorted by displayed -> order -> displayname
	// remove column settings from the array that do not match our displayed value
	console.log(tableColumns);
	let idxAdj = 0;
	let disCols = 0;
	tableColumns = tableColumns.filter((cs, idx) => {
		console.log(idx);
		if (cs.displayed !== tableColumns[fromIndex].displayed) {
			disCols = disCols + 1;
			if (idx < targetIndex) {
				idxAdj = idxAdj - 1;
				console.log(idxAdj);
			}
			return false;
		} else {
			return true;
		}
	});
	if(targetIndex <= disCols && csetting.displayed === false) {
		targetIndex = 0;
	} else if(targetIndex > disCols && csetting.displayed === true) {
		targetIndex = tableColumns.length-1;
	}	else {
		targetIndex = targetIndex + idxAdj;
	}
	
	if (targetIndex > (tableColumns.length - 1)) {
		targetIndex = (tableColumns.length - 1);
	}

	console.log(tableColumns.map(s => s.column));
	console.log(targetIndex);

	// Remove existing instance of column
	tableColumns = tableColumns.filter(cs => cs.column !== column);

	console.log(tableColumns.map(s => s.column));
	console.log(targetIndex);

	// Lower the target index if targeting the end of the array, and the current column uses the special "end of table" order (262145)
	if (targetIndex === (tableColumns.length - 1) && tableColumns[tableColumns.length - 1].order === 262145) {
		targetIndex = targetIndex - 1;
	}

	// Raise the target index if targeting the beginning of the array, and the current column uses the special "start of table" order (-1)
	if (targetIndex === 0 && tableColumns[0].order === -1) {
		targetIndex = targetIndex + 1;
	}

	let upperOrder: number;
	let lowerOrder: number;

	if (targetIndex === 0) {
		// Beginning of list
		lowerOrder = 0;
		upperOrder = tableColumns[targetIndex].order;
	} else {
		// Somewhere else or End of list
		lowerOrder = tableColumns[targetIndex - 1].order;
		upperOrder = tableColumns[targetIndex].order;
	}

	if (lowerOrder === upperOrder) {
		return null;
	} else if (Math.round((upperOrder - lowerOrder) / 2) < 1) {
		if (fromIndex !== -1) {
			res = {
				id: csetting.id,
				newOrder: lowerOrder + 1
			};

		} else {
			res = {
				id: null,
				newOrder: lowerOrder + 1
			};
		}
	} else {
		if (fromIndex !== -1) {
			res = {
				id: csetting.id,
				newOrder: lowerOrder + Math.round((upperOrder - lowerOrder) / 2)
			};
		} else {
			res = {
				id: null,
				newOrder: lowerOrder + Math.round((upperOrder - lowerOrder) / 2)
			};
		}
	}

	return res;
}
