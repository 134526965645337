// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Interfaces
import { IAuthTokenSet } from 'src/app/interfaces/seacom/authtokenset';
import { IUser } from 'src/app/interfaces/seacom/user';

// Reducers
import * as fromAuth from '../reducers/auth.reducers';

export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  fromAuth.authFeatureKey
);

export const selectLoginError = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): string => state.loginError
);

export const selectAPIError = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): string => state.apiError
);

export const selectTokens = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): IAuthTokenSet => {
    return {
      d: 'IAuthTokenSet',
      access: state.accessToken,
      refresh: state.refreshToken
    };
  }
);

export const selectAccessToken = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): string => state.accessToken
);

export const selectRefreshToken = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): string => state.refreshToken
);

export const selectAccessTokenExpires = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): number => state.accessExpiresAt
);

export const selectRefreshTokenExpires = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): number => state.refreshExpiresAt
);

export const selectLoggingIn = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): boolean => state.loggingIn
);

export const selectLoggingOut = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): boolean => state.loggingOut
);

export const selectConfirmingLogout = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): boolean => state.confirmingLogout
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): boolean => state.isAuthenticated
);

export const selectCurrentUser = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): IUser => state.user
);

export const selectUserStartScreen = createSelector(
  selectCurrentUser,
  (cu: IUser): string => {
    if(cu !== undefined && cu !== null && cu.hasOwnProperty('start_screen')) {
      return cu.start_screen;
    } else {
      return null;
    }
  }
);

export const selectCurrentUserRole = createSelector(
  selectCurrentUser,
  (cu: IUser): string => {
    if(cu !== undefined && cu !== null && cu?.role?.role?.name) {
      return cu.role.role.name;
    } else {
      return null;
    }
  }
);

export const selectIsSeacomAdmin = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): boolean => state.user.role.role.name === 'seacom_admin'
);

export const selectIsOrgAdmin = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): boolean => state.user.role.role.name === 'org_admin'
);

export const selectIsSupervisor = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): boolean => state.user.role.role.name === 'supervisor'
);

export const selectIsDispatcher = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): boolean => state.user.role.role.name === 'dispatcher'
);

export const selectIsResponder = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): boolean => state.user.role.role.name === 'responder'
);

export const selectIsPublic = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState): boolean => state.user.role.role.name === 'public'
);
