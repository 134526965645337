import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject } from 'rxjs';
import { skipNull } from 'src/app/common/globals';
import { IRegister, IRegisterResult } from 'src/app/interfaces/seacom/register';
import { SeacomApiService } from './seacom-api.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  node = 'accounts/register';

  constructor(
    public seacomApiService: SeacomApiService
  ) {}

  public registerUser(data: IRegister) {
    let regResult = new AsyncSubject<IRegisterResult>();
    this.seacomApiService.createItem(this.node, data as object, false).pipe(skipNull()).subscribe(
      (res: HttpResponse<any>) => {
        if (res) {
          switch (res.status) {
            case 201:
              if (res.body !== undefined) {
                regResult.next(res.body as IRegisterResult);
              } else {
                regResult.next(null);
              }
              regResult.complete();
              break;
            default:
              regResult.error(res.body);
              regResult.complete();
              break;
          }
        }
      },
      (err) => {
        regResult.error(err);
        regResult.complete();
      }
    );
    return regResult.asObservable();
  }
}
