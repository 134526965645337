import { createAction, props } from "@ngrx/store";

const actionKey = '[Settings Page] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

export const UpdateWidgetOrder = createAction(
  actionKey + 'Update Widget Order',
  props<{ payload: { widgetOrder: string[] }}>()
);

export const HideWidget = createAction(
  actionKey + 'Hide Widget',
  props<{ payload: { widgetName: string }}>()
)

export const DisplayWidget = createAction(
  actionKey + 'Display Widget',
  props<{ payload: { widgetName: string }}>()
)

