import { createReducer, on, State } from '@ngrx/store';
import { IUser } from '../../interfaces/seacom/user';

import * as actions from '../actions';
import { fromLoginPageActions } from '../actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  user: IUser;
  accessToken: string | null;
  refreshToken: string | null;
  accessExpiresAt: number;
  refreshExpiresAt: number;
  loggingIn: boolean;
  confirmingLogout: boolean;
  loggingOut: boolean;
  isAuthenticated: boolean;
  loginError: string | null;
  apiError: string | null;
}

export const AuthInitialState: AuthState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  accessExpiresAt: null,
  refreshExpiresAt: null,
  loggingIn: false,
  confirmingLogout: false,
  loggingOut: false,
  isAuthenticated: false,
  loginError: null,
  apiError: null
};

export const AuthReducer = createReducer(
  AuthInitialState,
  on(actions.ClearAuthAtEnter, (state, action) => {
    return {
      ...state,
      isAuthenticated: false,
      loggingIn: false,
      loggingOut: false,
      loginError: null,
      apiError: null
    };
  }),
  on(actions.ClearAuthAtExit, (state, action) => {
    return {
      ...state,
      isAuthenticated: false,
      loggingIn: false,
      loggingOut: false,
      loginError: null,
      apiError: null
    };
  }),
  on(actions.CheckAPIAtEnter, (state, action) => {
    return {
      ...state
    };
  }),
  on(actions.CheckTokensAtEnter, (state, action) => {
    return {
      ...state
    };
  }),
  on(actions.ReturnToLogin, (state, action) => {
    return {
      ...AuthInitialState,
      apiError: !!action.payload.apiError ? action.payload.apiError : null,
      loginError: !!action.payload.loginError ? action.payload.loginError : null,
    };
  }),
  on(actions.TokenLogin, (state, action) => {
    return {
      ...state,
      loggingIn: true,
      loggingOut: false
    };
  }),
  on(actions.UserLogin, (state, action) => {
    return {
      ...state,
      loggingIn: true,
      loggingOut: false
    };
  }),
  on(actions.CompleteLogin, (state, action) => {
    return {
      ...state,
      user: action.payload.user ? action.payload.user : state.user,
      accessToken: action.payload.accessToken,
      refreshToken: action.payload.refreshToken,
      accessExpiresAt: action.payload.accessExpiresAt,
      refreshExpiresAt: action.payload.refreshExpiresAt,
      isAuthenticated: true,
      loginError: null,
      apiError: null
    };
  }),
  on(actions.ClearLoggingIn, (state, action) => {
    return {
      ...state,
      loggingIn: false
    };
  }),
  on(actions.TokenExpiryCheck, (state, action) => {
    return {
      ...state
    };
  }),
  on(actions.RefreshTokens, (state, action) => {
    return {
      ...state
    };
  }),
  on(actions.RefreshTokensSuccess, (state, action) => {
    return {
      ...state,
      accessToken: action.payload.accessToken,
      refreshToken: action.payload.refreshToken,
      accessExpiresAt: action.payload.accessExpiresAt,
      refreshExpiresAt: action.payload.refreshExpiresAt
    };
  }),
  on(actions.RefreshTokensFailure, (state, action) => {
    return {
      ...state
    };
  }),
  on(actions.Logout, (state, action) => {
    return {
      ...state,
      confirmingLogout: true
    };
  }),
  on(actions.LogoutConfirm, (state, action) => {
    return {
      ...state,
      confirmingLogout: false,
      loggingOut: true
    };
  }),
  on(actions.ClearLoggingOut, (state, action) => {
    return {
      ...state,
      loggingOut: false
    };
  }),
  on(actions.LogoutCancel, (state, action) => {
    return {
      ...state,
      confirmingLogout: false,
      loggingOut: false
    };
  })
);
