import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INotification } from 'src/app/interfaces/seacom/notification';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notifications-widget',
  templateUrl: './notifications-widget.component.html',
  styleUrls: ['../widget.styles.scss', './notifications-widget.component.scss']
})
export class NotificationsWidgetComponent implements OnInit {
  @Input() brightness$: Observable<'light'|'dark'>;
  @Input('NewNotificationsCount') newNotificationsCount: number;
  @Input('NewNotifications') newNotifications: INotification[];
  @Input('Loading$') notificationsLoading$: Observable<boolean>;
  @Output() hideWidgetEvent = new EventEmitter<string>();
  @Output() navigateToNotificationsEvent = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  navigateToNotificationsPage(event) {
    event.stopPropagation();
    this.navigateToNotificationsEvent.emit();
  }

  hideWidget() {
    this.hideWidgetEvent.emit('notifications');
  }
}
