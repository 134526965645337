import { IItemEntity } from "src/app/interfaces/itementity";
import { IUUID } from "src/app/interfaces/seacom/templates/uuid";

export function reduceItems<T extends IUUID>(items: T[]): IItemEntity<T> {
  return [...items].reduce(
    (entities: IItemEntity<T>, item: T) => {
      entities[item.id] = item;
      return entities;
    },
    {}
  ) as IItemEntity<T>;
}

export function itemArray<T>(items: IItemEntity<T>): Array<T> {
  return Array.from(Object.keys(items).map(k => items[k]));
}

export function updateItems<T extends IUUID>(items: T[], entities: IItemEntity<T>): IItemEntity<T> {
  const is = items;
  const es = entities;
  if (items.length > 0) {
    return is.reduce(
      (entities: IItemEntity<T>, item: T) => {
        return {
          ...entities,
          [item.id]: item
        };
      },
      {
        ...es
      }
    ) as IItemEntity<T>;
  } else {
    return {};
  }
}

export function addItem<T extends IUUID>(item: T, entities: IItemEntity<T>): IItemEntity<T> {
  const i = { ...item } as T;
  return { ...entities, [i.id]: i } as IItemEntity<T>;
}

export function updateItem<T extends IUUID>(item: T, entities: IItemEntity<T>): IItemEntity<T> {
  const i = item;
  const es = entities;
  return { ...es, [i.id]: i } as IItemEntity<T>;
}

export function deleteItem<T extends IUUID>(itemId: string, entities: IItemEntity<T>): IItemEntity<T> {
  const id = itemId;
  const es = { ...entities };
  if (es[id] !== undefined) {
    delete es[id];
  }
  return es;
}
