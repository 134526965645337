import { createReducer, on } from '@ngrx/store';
import { IWidget } from 'src/app/interfaces/widget';

import * as actions from '../../../store/actions';


export const homepageFeatureKey = 'homepage';

export interface HomepageState {
	widgets: IWidget[]
}

export const HomepageInitialState: HomepageState = {
	widgets: [
		{ name: 'runbook', displayName: 'Runbook', visible: true },
		{ name: 'notifications', displayName: 'Notifications', visible: true },
		{ name: 'conditions', displayName: 'Conditions', visible: true },
		{ name: 'timeclock', displayName: 'Time Clock', visible: true },
		{ name: 'stations', displayName: 'Stations', visible: true },
		{ name: 'operations', roles: ['dispatcher', 'supervisor', 'org_admin', 'seacom_admin'], displayName: 'Operations', visible: true },
	]
};

export const HomepageReducer = createReducer(
	HomepageInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...HomepageInitialState
		} as HomepageState;
	}),
	on(actions.fromHomePageActions.Enter, (state, action) => {
		return {
			...state
		} as HomepageState;
	}),
	on(actions.fromHomePageActions.Exit, (state, action) => {
		return {
			...state
		} as HomepageState;
	}),
	on(actions.fromHomePageActions.HideWidget, (state, action) => {
		let updatedWidgets = state.widgets.map(w => {
			if (w.name === action.payload.widgetName) {
				return {
					...w,
					visible: false
				};
			}
			return w;
		});
		return {
			...state,
			widgets: updatedWidgets
		} as HomepageState;
	}),
	on(actions.fromHomePageActions.NavigateToNotificationsPage, (state, action) => {
		return {
			...state,
		} as HomepageState;
	}),
	on(actions.fromHomePageActions.NavigateToConditionsPage, (state, action) => {
		return {
			...state,
		} as HomepageState;
	}),
	on(actions.fromHomePageActions.NavigateToOperationsPage, (state, action) => {
		return {
			...state,
		} as HomepageState;
	}),
	on(actions.fromHomePageActions.NavigateToRunbookPage, (state, action) => {
		return {
			...state,
		} as HomepageState;
	}),
	on(actions.fromHomePageActions.NavigateToStationsPage, (state, action) => {
		return {
			...state,
		};
	}),
	on(actions.fromHomePageActions.SetHomepageWidgetOrder, (state, action) => {
		return {
			...state,
			widgets: action.payload.newWidgets
		};
	}),
);
