import { NgModule ,CUSTOM_ELEMENTS_SCHEMA, Injectable} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ColumnSelectorComponent } from '../column-selector/column-selector.component';
import { ColumnSelectorListModule } from '../column-selector-list/column-selector-list.module';


@Injectable({ 
  providedIn: 'root'
})
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ColumnSelectorListModule
  ],
  declarations: [ ColumnSelectorComponent ],
  exports: [ ColumnSelectorComponent ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class ColumnSelectorModule { }



