import { createAction, props } from '@ngrx/store';
import { ICredentials } from 'src/app/interfaces/seacom/credentials';
import { IUser } from '../../interfaces/seacom/user';
import { IAuthTokenSet } from '../../interfaces/seacom/authtokenset';

// Unauthenticated actions
export const CLEAR_AUTH_AT_ENTER: string = '[Component Effects] Clear Authenticated State At Enter';
export const CHECK_API_AT_ENTER: string = '[App Component] Check API At Enter';
export const CHECK_TOKENS_AT_ENTER: string = '[App Component] Check Tokens At Enter';
export const RETURN_TO_LOGIN: string = '[Auth Effect] Return To Login';

// Authenticationg actions
export const TOKEN_LOGIN: string = '[App Component] Token Login';
export const USER_LOGIN: string = '[Login Component] User Login';
export const COMPLETE_LOGIN: string = '[Auth Effects] Complete Login';
export const CLEAR_LOGGING_IN: string = '[Auth Effects] Clear Logging In';

// Token Expiry actions
export const TOKEN_EXPIRY_CHECK: string = '[Auth Effect] Token Expiry Check';

// Token Refresh actions
export const REFRESH_TOKENS: string = '[Auth Effect] Refresh Tokens';
export const REFRESH_TOKENS_SUCCESS: string = '[Auth Effect] Refresh Tokens Success';
export const REFRESH_TOKENS_FAILURE: string = '[Auth Effect] Refresh Tokens Failure';

// Logout actions
export const LOGOUT: string = '[App Component] Logout Request';
export const LOGOUT_CANCEL: string = '[App Component] Logout Cancelled';
export const LOGOUT_CONFIRM: string = '[App Component] Logout Confirmed';
export const CLEAR_LOGGING_OUT: string = '[Auth Effects] Clear Logging Out';

// Exit actions
export const CLEAR_AUTH_AT_EXIT: string = '[Component Effects] Clear Authenticated State at Exit';


export const ClearAuthAtEnter = createAction(
	CLEAR_AUTH_AT_ENTER
);

export const CheckAPIAtEnter = createAction(
	CHECK_API_AT_ENTER
);

export const CheckTokensAtEnter = createAction(
	CHECK_TOKENS_AT_ENTER
);

export const ReturnToLogin = createAction(
	RETURN_TO_LOGIN,
	props<{ payload: { 
		loginError: string|null,
		apiError: string|null
	}}>()
);

export const TokenLogin = createAction(
	TOKEN_LOGIN,
	props<{ tokens: IAuthTokenSet }>()
);

export const UserLogin = createAction(
	USER_LOGIN,
	props<{ credentials: ICredentials }>()
);

export const CompleteLogin = createAction(
	COMPLETE_LOGIN,
	props<{
		payload: { 
			user: IUser|undefined,
			accessToken: string,
			refreshToken: string,
			accessExpiresAt: number,
			refreshExpiresAt: number
	 	}
	}>()
);

export const ClearLoggingIn = createAction(
	CLEAR_LOGGING_IN
);

export const TokenExpiryCheck = createAction(
	TOKEN_EXPIRY_CHECK
);

export const RefreshTokens = createAction(
	REFRESH_TOKENS
);

export const RefreshTokensSuccess = createAction(
	REFRESH_TOKENS_SUCCESS,
	props<{
		payload: { 
			accessToken: string,
			refreshToken: string,
			accessExpiresAt: number,
			refreshExpiresAt: number
	 	}
	}>()
);

export const RefreshTokensFailure = createAction(
	REFRESH_TOKENS_FAILURE
);

export const Logout = createAction(
	LOGOUT
);

export const LogoutCancel = createAction(
	LOGOUT_CANCEL
);

export const LogoutConfirm = createAction(
	LOGOUT_CONFIRM
);

export const ClearLoggingOut = createAction(
	CLEAR_LOGGING_OUT
);

export const ClearAuthAtExit = createAction(
	CLEAR_AUTH_AT_EXIT
);
