// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// RXJS
import { of } from 'rxjs';
import { catchError, map, exhaustMap, switchMap, first } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// State
import { SeacomState } from '../reducers/index';

// Services
import { AuthService } from '../../services/auth.service';

// Actions
import * as actions from '../actions';

// Selectors
import { selectCurrentUser, selectTokens } from '../selectors/auth.selectors';

// Globals
import { skipNull } from 'src/app/common/globals';



@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private authService: AuthService,
    private router: Router
  ) { }

  clearAuthAtEnter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.ClearAuthAtEnter
        ),
        switchMap(() => of(actions.CheckAPIAtEnter()))
      );
  });

  checkAPIAtEnter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.CheckAPIAtEnter
        ),
        switchMap(() => this.authService.checkAPIReachability()),
        switchMap(reachable =>
          reachable === true
            ? of(actions.CheckTokensAtEnter())
            : of(actions.ReturnToLogin({ payload: { apiError: "API is currently unavailable. Please try again momentarily.", loginError: "" } }))
        ),
        catchError(() => of(actions.ReturnToLogin({ payload: { apiError: "API is currently unavailable. Please try again momentarily.", loginError: "" } }))),
      );
  });

  checkTokensAtEnter$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.CheckTokensAtEnter
        ),
        switchMap(() => this.store.select(selectTokens).pipe(first())),
        switchMap((tokens) =>
          this.authService.checkTokenSanity(tokens) === true
            ? of(actions.TokenLogin({ tokens: tokens }))
            : of(actions.ReturnToLogin({ payload: { apiError: "", loginError: "" } }))
        ),
        catchError((error) => of(actions.ReturnToLogin({ payload: { apiError: "", loginError: "" } })))
      );
  });

  returnToLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          actions.ReturnToLogin
        ),
        map(() => {
          // Redirecting to login page -- stop subscribing to token updates
          this.authService.unsubscribeForTokenExp();

          this.router.navigate(['/login']).then(() => {
            this.store.dispatch(actions.ClearLoggingOut());
          });
        })
      );
    },
    { dispatch: false }
  );

  userLogin$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.UserLogin
        ),
        switchMap((action) =>
          this.authService.login(action.credentials.email, action.credentials.password).pipe(
            map((loginResult) =>
              actions.CompleteLogin({
                payload: {
                  user: loginResult.user,
                  accessToken: loginResult.accessToken,
                  refreshToken: loginResult.refreshToken,
                  accessExpiresAt: loginResult.accessExpiresAt,
                  refreshExpiresAt: loginResult.refreshExpiresAt
                }
              })
            ),
            catchError((error) =>
              of(actions.ReturnToLogin({ payload: { loginError: error, apiError: null } }))
            )
          )
        )
      );
  });

  tokenLogin$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.TokenLogin
        ),
        switchMap((action) =>
          this.authService.loginWithTokens(action.tokens).pipe(
            map((loginResult) =>
              actions.CompleteLogin({
                payload: {
                  user: loginResult.user,
                  accessToken: loginResult.accessToken,
                  refreshToken: loginResult.refreshToken,
                  accessExpiresAt: loginResult.accessExpiresAt,
                  refreshExpiresAt: loginResult.refreshExpiresAt
                }
              })
            ),
            catchError((error) =>
              of(actions.ReturnToLogin({ payload: { loginError: null, apiError: null } }))
            )
          )
        )
      );
  });

  completeLogin$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.CompleteLogin
        ),
        switchMap(() => this.store.select(selectCurrentUser).pipe(skipNull(), first())),
        map((cu) => {
          // Tokens have been updated; start monitoring for token expiration and refresh
          this.authService.subscribeForTokenExp();

          if (this.router.url === null || this.router.url === '/' || this.router.url === '/login') {
            // Fresh browse
            if (cu.start_screen !== undefined && cu.start_screen !== null) {
              // User has no start screen set
              this.router.navigate([cu.start_screen]).then(() => {
                this.store.dispatch(actions.ClearLoggingIn());
              });
            } else {
              // User has a start screen set
              this.router.navigate(['/home']).then(() => {
                this.store.dispatch(actions.ClearLoggingIn());
              });
            }
          } else {
            // URL was browsed to
            this.store.dispatch(actions.ClearLoggingIn());
          }
        })
      );
  },
    { dispatch: false });

  refreshTokens$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.RefreshTokens
        ),
        switchMap(() => this.store.select(selectTokens).pipe(first())),
        switchMap((tokens) => this.authService.refreshTokens(tokens).pipe(
          map((refreshResult) => actions.RefreshTokensSuccess({
            payload: {
              accessToken: refreshResult.accessToken,
              refreshToken: refreshResult.refreshToken,
              accessExpiresAt: refreshResult.accessExpiresAt,
              refreshExpiresAt: refreshResult.refreshExpiresAt
            }
          })),
          catchError((error) => of(actions.RefreshTokensFailure()))
        )
        )
      );
  });

  refreshTokensSuccess$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.RefreshTokensSuccess
        ),
        map(() => {
          // Tokens have been updated, restart monitoring for token expiration and refresh
          this.authService.subscribeForTokenExp();
        })
      );
  },
    { dispatch: false });

  refreshTokenFailure$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          actions.RefreshTokensFailure
        ),
        switchMap(() => of(actions.ReturnToLogin({
          payload: {
            loginError: "You have been logged out from elsewhere.",
            apiError: ""
          }
        })))
      )
  }
  );

  logoutConfirmed$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(
            actions.LogoutConfirm
          ),
          exhaustMap(() => this.authService.logout()),
          switchMap(() => of(actions.ReturnToLogin({
            payload: {
              apiError: null,
              loginError: null
            }
          })))
        );
    }
  );
}
