import { createAction, props } from "@ngrx/store";
import { ICredentials } from "src/app/interfaces/seacom/credentials";

const actionKey = '[Login Page] ';

export const Enter = createAction(
  actionKey + 'Enter'
);

export const Exit = createAction(
  actionKey + 'Exit'
);

export const Login = createAction(
  actionKey + 'Login',
  props<{ payload: { credentials: ICredentials }}>()
);
