import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IStationSetting } from '../../interfaces/seacom/stationsetting';

import * as actions from '../actions';
import { addItem, deleteItem, updateItem, updateItems } from '../utils/itementity';


export const stationSettingFeatureKey = 'stationSettings';

export interface StationSettingState {
	entities: IItemEntity<IStationSetting>,
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const StationSettingInitialState: StationSettingState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

function findStationSetting(ssetting: IStationSetting, entities: IItemEntity<IStationSetting>): string {
	let arr = Object.keys(entities).map(k => entities[k]);
	arr = arr.filter(ss => ss.station.id === ssetting.station.id);
	if (arr.length > 0) {
		return arr[0].id;
	} else {
		return null;
	}
}

export const StationSettingReducer = createReducer(
	StationSettingInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...StationSettingInitialState
		} as StationSettingState;
	}),
	// Load StationSettings
	on(actions.LoadStationSettings, (state, action) => {
		return {
			...state,
			loading: true,
			loaded: false,
			loadError: null
		} as StationSettingState;
	}),
	on(actions.LoadStationSettingsCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as StationSettingState;
	}),
	on(actions.LoadStationSettingsWithFilters, (state, action) => {
		return {
			...state,
			loading: true,
			loaded: false,
			loadError: null
		} as StationSettingState;
	}),
	on(actions.LoadStationSettingsSuccess, (state, action) => {
		let newEntities = { ...state.entities };

		action.payload.forEach(ls => {
			let ssid = findStationSetting(ls, state.entities);

			if (ssid !== null) {
				newEntities = deleteItem(ssid, newEntities);
			}
		});

		return {
			...state,
			entities: updateItems(action.payload, newEntities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as StationSettingState;
	}),
	on(actions.LoadStationSettingsFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as StationSettingState;
	}),

	// Load StationSetting
	on(actions.LoadStationSetting, (state, action) => {
		return {
			...state,
			loading: true,
		} as StationSettingState;
	}),
	on(actions.LoadStationSettingSuccess, (state, action) => {
		let ssid = findStationSetting(action.payload, state.entities);
		let newEntities = { ...state.entities };
		if (ssid !== null && ssid !== action.payload.id) {
			newEntities = deleteItem(ssid, newEntities);
		}

		return {
			...state,
			entities: updateItem(action.payload, newEntities),
			loading: false,
			loaded: true
		} as StationSettingState;
	}),
	on(actions.LoadStationSettingFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as StationSettingState;
	}),

	// Update StationSetting
	on(actions.UpdateStationSetting, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateStationSettingSuccess, (state, action) => {
		let ssid = findStationSetting(action.payload, state.entities);
		let newEntities = { ...state.entities };

		if (ssid !== null && ssid !== action.payload.id) {
			newEntities = deleteItem(ssid, newEntities);
		}

		newEntities = updateItem(action.payload, newEntities);

		return {
			...state,
			entities: newEntities,
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateStationSettingFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),
	// Add StationSetting
	on(actions.AddStationSetting, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddStationSettingSuccess, (state, action) => {
		let ssid = findStationSetting(action.payload, state.entities);
		let newEntities = { ...state.entities };
		if (ssid !== null) {
			newEntities = deleteItem(ssid, newEntities);
		}

		return {
			...state,
			entities: addItem(action.payload, newEntities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddStationSettingFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),

	// Delete StationSetting
	on(actions.DeleteStationSetting, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteStationSettingSuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteStationSettingFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	}),
	on(actions.UpdateStationOrder, (state, action) => {
		return {
			...state
		};
	})
);
