// Angular
import { Injectable } from '@angular/core';

// Interfaces
import { IStationSetting, INewStationSetting, IUpdatedStationSetting } from 'src/app/interfaces/seacom/stationsetting';

// Services
import { ApiNodeService } from './templates/apinode.service';
import { SeacomApiService } from './seacom-api.service';


@Injectable({
  providedIn: 'root'
})
export class StationSettingsService extends ApiNodeService<IStationSetting, INewStationSetting, IUpdatedStationSetting> {
  node = 'stations/settings';

  constructor(public seacomApiService: SeacomApiService) {
    super(seacomApiService);
  }

}
