// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Ionic
import { IonicModule } from '@ionic/angular';

// Component
import { StationTileComponent } from './stationtile.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
  declarations: [StationTileComponent],
  exports: [StationTileComponent],
  providers: []
})
export class StationTileModule {}
