// Angular
import { Injectable } from '@angular/core';

// RXJS
import { catchError, concatMap, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

// NGRX
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

// Actions
import * as actions from '../actions';

// Services
import { RegionsService } from 'src/app/services/seacom/regions.service';
import { Store } from '@ngrx/store';
import { SeacomState } from '../reducers';
import { EnvironmentService } from 'src/app/services/environment.service';
import { selectRegionsLastUpdated } from '../selectors/region.selectors';

@Injectable()
export class RegionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SeacomState>,
    private environmentService: EnvironmentService,
    private regionsService: RegionsService
  ) { }

  loadRegions$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Regions
        ofType(
          actions.LoadRegions
        ),
        concatLatestFrom((action) => this.store.select(selectRegionsLastUpdated)),
        exhaustMap(([action, lastUpdated]) => {
          let now = (new Date());
          let lu = (new Date(lastUpdated));

          if(now.getTime() - lu.getTime() > this.environmentService.apiCacheTime) {
            return this.regionsService.getList().pipe(
              map((regions) => actions.LoadRegionsSuccess({ payload: regions })),
              catchError((error) => of(actions.LoadRegionsFail(error)))
            );
          } else {
            return of(actions.LoadRegionsCancelled());
          }
        })
      );
  });
  loadRegion$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Load Region
        ofType(actions.LoadRegion),
        exhaustMap((action) =>
          this.regionsService.get(action.payload).pipe(
            map((region) => actions.LoadRegionSuccess({ payload: region })),
            catchError((error) => of(actions.LoadRegionsFail(error)))
          )
        )
      );
  });
  addRegion$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Add Region
        ofType(actions.AddRegion),
        concatMap((action) =>
          this.regionsService.create(action.payload).pipe(
            map((region) => actions.AddRegionSuccess({ payload: region })),
            catchError((error) => of(actions.AddRegionFail(error)))
          )
        )
      );
  });
  updateRegion$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Update Region
        ofType(actions.UpdateRegion),
        concatMap((action) =>
          this.regionsService.update(action.payload.id, action.payload).pipe(
            map((region) => actions.UpdateRegionSuccess({ payload: region })),
            catchError((error) => of(actions.UpdateRegionFail(error)))
          )
        )
      );
  });
  deleteRegion$ = createEffect(() => {
    return this.actions$
      .pipe(
        // Delete Region
        ofType(actions.DeleteRegion),
        mergeMap((action) =>
          this.regionsService.delete(action.payload).pipe(
            map((regionId) => actions.DeleteRegionSuccess({ payload: regionId })),
            catchError((error) => of(actions.DeleteRegionFail(error)))
          )
        )
      )
  });
}
