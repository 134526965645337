import { createReducer, on } from '@ngrx/store';
import { toLocalISOString } from 'src/app/common/globals';
import { IItemEntity } from 'src/app/interfaces/itementity';

import { IIncidentType } from '../../interfaces/seacom/incidenttype';

import * as actions from '../actions';
import { addItem, deleteItem, updateItem, updateItems } from '../utils/itementity';


export const incidenttypeFeatureKey = 'incidenttypes';

export interface IncidentTypeState {
	entities: IItemEntity<IIncidentType>,
	lastUpdated: string,
	loading: boolean,
	loaded: boolean,
	loadError?: any,
	updating: boolean,
	updated: boolean,
	updateError?: any,
	adding: boolean,
	added: boolean,
	addError?: any,
	deleting: boolean,
	deleted: boolean,
	deleteError?: any
}

export const IncidentTypeInitialState: IncidentTypeState = {
	entities: {},
	lastUpdated: toLocalISOString(new Date('000000000000')),
	loading: false,
	loaded: false,
	loadError: null,
	updating: false,
	updated: false,
	updateError: null,
	adding: false,
	added: false,
	addError: null,
	deleting: false,
	deleted: false,
	deleteError: null
};

export const IncidentTypeReducer = createReducer(
	IncidentTypeInitialState,
	on(actions.ReturnToLogin, (state, action) => {
		return {
			...IncidentTypeInitialState
		} as IncidentTypeState;
	}),
	// Load IncidentTypes
	on(actions.LoadIncidentTypes, (state, action) => {
		return {
			...state,
			loading: true,
		} as IncidentTypeState;
	}),
	on(actions.LoadIncidentTypesCancelled, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: true
		} as IncidentTypeState;
	}),
	on(actions.LoadIncidentTypesSuccess, (state, action) => {
		return {
			...state,
			entities: updateItems(action.payload, state.entities),
			lastUpdated: toLocalISOString(new Date()),
			loading: false,
			loaded: true
		} as IncidentTypeState;
	}),
	on(actions.LoadIncidentTypesFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as IncidentTypeState;
	}),

	// Load IncidentType
	on(actions.LoadIncidentType, (state, action) => {
		return {
			...state,
			loading: true,
		} as IncidentTypeState;
	}),
	on(actions.LoadIncidentTypeSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			loading: false,
			loaded: true
		} as IncidentTypeState;
	}),
	on(actions.LoadIncidentTypeFail, (state, action) => {
		return {
			...state,
			loading: false,
			loaded: false,
			loadError: action.payload,
		} as IncidentTypeState;
	}),

	// Update IncidentType
	on(actions.UpdateIncidentType, (state, action) => {
		return {
			...state,
			updating: true,
			updated: false,
			updateError: null,
		};
	}),
	on(actions.UpdateIncidentTypeSuccess, (state, action) => {
		return {
			...state,
			entities: updateItem(action.payload, state.entities),
			updating: false,
			updated: true,
			updateError: null,
		};
	}),
	on(actions.UpdateIncidentTypeFail, (state, action) => {
		return {
			...state,
			updating: false,
			updated: false,
			updateError: action.payload,
		};
	}),

	// Add IncidentType
	on(actions.AddIncidentType, (state, action) => {
		return {
			...state,
			adding: true,
			added: false,
			addError: null,
		};
	}),
	on(actions.AddIncidentTypeSuccess, (state, action) => {
		return {
			...state,
			entities: addItem(action.payload, state.entities),
			adding: false,
			added: true,
			addError: null,
		};
	}),
	on(actions.AddIncidentTypeFail, (state, action) => {
		return {
			...state,
			adding: false,
			added: false,
			addError: action.payload,
		};
	}),

	// Delete IncidentType
	on(actions.DeleteIncidentType, (state, action) => {
		return {
			...state,
			deleting: true,
			deleted: false,
			deleteError: null,
		};
	}),
	on(actions.DeleteIncidentTypeSuccess, (state, action) => {
		return {
			...state,
			entities: deleteItem(action.payload, state.entities),
			deleting: false,
			deleted: true,
			deleteError: null,
		};
	}),
	on(actions.DeleteIncidentTypeFail, (state, action) => {
		return {
			...state,
			deleting: false,
			deleted: false,
			deleteError: action.payload,
		};
	}),
);
